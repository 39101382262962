import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html'
})


export class AppFooterComponent  implements OnInit{

    public labelLogo: string;
    public lang: string = "pt-BR";



constructor(
    private translateConfigService: TranslateConfigService,
    private translateService: TranslateService
){}


    ngOnInit() {
        this.lang = this.translateConfigService.getLanguage();
        this.changeLanguage(this.lang);
    }

    changeLanguage(type: string) {

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));

        //Need 50ms to get and set de language by cookie
        this.translateConfigService.changeLanguage(type);
        (async () => {
            await sleep(50);
            this.labelLogo = this.translateService.instant('label.field_sales_service');
        })();
    }
}
