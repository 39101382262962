<div class="formgrid grid">
    <div class="field col-12">
        <label class="mb1 font-bold">Tipo Documento</label>
        <sf-dropdown-generic [list]="docTypeList"
            (selectedItemOutput)="reciverFeedbackDocType($event)"></sf-dropdown-generic>
        <small class="p-invalid" *ngIf="getFiledValidation(!scArchiveTypeId)"
            translate>validation_errors.required_field</small>
    </div>
</div>

<div class="formgrid grid">
    <div class="field col-12">
        <div class="card flex justify-content-center">
            <p-fileUpload #fileUpload [disabled]="fieldValidation" name="files[]" (uploadHandler)="onUpload($event)"
                [multiple]="true" accept="image/*" maxFileSize="1000000" chooseLabel="Adicionar" cancelLabel="Cancelar"
                chooseStyleClass="p-button-info" uploadStyleClass="p-button-info" cancelStyleClass="p-button-info"
                customUpload="true">
                <ng-template pTemplate="content">
                    <ul *ngIf="uploadedFiles.length">
                        <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
                    </ul>
                </ng-template>
            </p-fileUpload>
        </div>
    </div>
</div>

<div *ngIf="fileZoomVisible" class="overlay">
    <p-dialog header="Zoom" [(visible)]="fileZoomVisible" [style]="{width: '80vw', height: '80vh'}">
        <div class="card flex justify-content-center">
            <p-image [src]="this.fileZoomUrl" [preview]="true" alt="Image" width="250">
                <ng-template pTemplate="indicator">
                    <i class="pi pi-check"></i>
                </ng-template>
            </p-image>
        </div>
    </p-dialog>
</div>

<div class="formgrid col-12">

    <p-galleria *ngIf="loadFiles" [(visible)]="loadFiles" [(value)]="listPersonFile"
        [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '90vw', 'min-height': '100px'}"
        [numVisible]="5" [circular]="true" [showItemNavigators]="true">
        <ng-template pTemplate="item" let-itemPerson>
            <div class="formgrid grid">
                <!-- <div class="field-checkbox field col-12">                        
                    <button *ngIf="itemPerson.inTheService" pButton pRipple icon="pi pi-times"
                        class="p-button-warning" label="Remover Documento"
                        (click)="updOrderOrderServiceFile(itemPerson.scPersonId, itemPerson.id, itemPerson.inTheService)"></button>
                    <button *ngIf="!itemPerson.inTheService" pButton pRipple icon="pi pi-check"
                        class="p-button-success" label="Adicionar Documento"
                        (click)="updOrderOrderServiceFile(itemPerson.scPersonId, itemPerson.id, itemPerson.inTheService)"></button>
                </div> -->
                <div class="field col-12">
                    <img (click)="openFileZoom(itemPerson.pictureUri)" [src]="itemPerson.pictureUri" height="190"
                        width="100%" />
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="thumbnail" let-itemPerson>
            <div class="grid grid-nogutter justify-content-center">
                <img height="50" width="50" [src]="itemPerson.pictureUri" />
            </div>
        </ng-template>
        <ng-template pTemplate="caption" let-itemPerson>
            <h5 style="margin-bottom: .5rem; color: #ffffff;">{{ itemPerson.createdAt | date : 'dd/MM/yyyy HH:mm:ss' }} - {{itemPerson.archiveType}}
            </h5>
            <p>{{ itemPerson.userName }}</p>
        </ng-template>
    </p-galleria>
    <label *ngIf="listPersonFile.length == 0" translate>label.empt</label>
</div>