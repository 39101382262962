import { DropDown } from 'src/app/shared/model/base-model';
import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { ScClaimService } from '../sc-claim.service';


@Component({
    selector: 'sf-sc-claim-dropdown',
    templateUrl: './sc-claim-dropdown.component.html',
    styleUrls: ['./sc-claim-dropdown.component.scss']
})

export class ScClaimDropdownComponent implements OnInit{

    public list : DropDown[];
    @Input() placeHolderLanguage: string;
    public selectedItem: DropDown;

    @Input() id: string;
    @Output() chosedItem = new EventEmitter();

    constructor(  private scClaimService: ScClaimService){ }

    ngOnInit(): void {
        this.getList();
    }

    getList() {
        this.scClaimService.getDropDownList().subscribe(
          {
            next: (res) => {
                if (res)
                    this.list = res.data;
                    if (this.list !== null)
                        this.selectedItem = this.list.find(x => x.id == this.id?.toString());
            },
            error: (err) => {
                console.log(`ClaimDropDown - getList : ${err}.`);
            }
        });
      }

    feedback(){
        this.chosedItem.emit(this.selectedItem.id)
    }

}
