
<p-timeline [value]="list" styleClass="customized-timeline">
    <ng-template pTemplate="marker" let-status>
        <span class="timeline-custom" [style.backgroundColor]="status.statusStageColor">
            <i ngClass="pi pi-circle" [style.color]="status.statusStageColor"></i>
        </span>
    </ng-template>




    <ng-template pTemplate="content" let-status>
        <small *ngIf="status !== undefined" [style.color]="status.statusStageColor" style="font-size: 12px;"><a href="javascript:void(0)" [style.color]="status.statusStageColor" (click)="getStatusDetail(status)"><label  class="font-bold text-lg">{{status.statusStageName}} -  {{status.statusDescription}}</label></a></small><br>
        <div class="grid mt-2 mb-2">
            <!-- <div class="col-12">
                <small style="font-size: 12px;"><label style="font-size: 12px; font-weight: bold;"><span translate>label.status</span>:</label><label style="font-size: 12px; font-weight: normal;"> {{status.statusDescription}}</label></small>
            </div> -->
            <div class="col-12">
                <label class="font-bold text-sm"><span translate>label.date</span>:</label><label class="font-normal text-sm"> {{status.createdAt  | date: 'dd/MM/yyyy HH:mm'}}</label>
            </div>
            <div class="col-12">
                <small  class="font-bold text-sm"><label style="font-size: 12px; font-weight: bold;"><span translate>label.user</span>:</label><label  class="font-normal text-sm"> {{status.userLogin}}</label></small>
            </div>
            <div class="col-12">
                <small  class="font-bold text-sm"><label style="font-size: 12px; font-weight: bold;"><span translate>label.name</span>:</label><label  class="font-normal text-sm"> {{status.userName}}</label></small>
            </div>

        </div>

    </ng-template>
    <ng-template pTemplate="opposite" let-status>
    </ng-template>
</p-timeline>

