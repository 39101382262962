

import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { ScUserService } from 'src/app/pages/sc-user/sc-user.service';
import { DropDown } from 'src/app/shared/model/base-model';

@Component({
    selector: 'sfc-dropdown-clerk',
    templateUrl: './sfc-dropdown-clerk.component.html',
    styleUrls: ['./sfc-dropdown-clerk.component.scss']
})

export class SfcDropDownClerkComponent implements OnInit {

    public list : DropDown[] = [];
    public selectedItem: DropDown;
    
    
    @Input() id: string;
    @Input() disabled: boolean;

    @Output() selectedItemOutput = new EventEmitter();


    constructor(private userService: ScUserService){ }

    ngOnInit(): void {
    
        if (this.disabled == null)
            this.disabled = false;

        this.getList();
    }

    ngOnChanges(): void {
        this.getList();
    }

    getList() {
        this.userService.getUserDropDownList('CLERK').subscribe(
            {
                next: (res) => {
                    if (res)
                        this.list = res.data;
                },
                error: (err) => {
                    console.log(`SfcAutenticacaoComponent - getUserClerk : ${err}.`);
                }
            });


    }

    reciverFeedbackUserClerk(event: DropDown) {

        if (event == null || event == undefined) {
            this.selectedItem = null;
        }
        else {
            this.selectedItem = event;
            this.feedback();
        }
    }


    feedback(){            
        this.selectedItemOutput.emit(this.selectedItem);
    }

}
