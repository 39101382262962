import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { LoaderState } from './loader';

@Injectable()

export class LoaderService {
    private contador = 0;

    private loaderSubject = new Subject<LoaderState>();

    loaderState = this.loaderSubject.asObservable();

    constructor() { }

    show() {
        this.contador++;
        this.loaderSubject.next(<LoaderState>{show: true});
    }

    hide() {
        this.contador--;
        if(this.contador <= 0) {
            this.contador = 0;
            this.loaderSubject.next(<LoaderState>{show: false});
        }
    }
}