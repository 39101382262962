import { NgModule } from "@angular/core";
import { ScDocumentTypeListComponent } from "./sc-document-type/sc-document-type-list/sc-document-type-list.component";
import { CommonModule } from "@angular/common";
import { SfGridGenericModule } from "src/app/shared/components/grid-generic/grid-generic.module";
import { NotificationsModule } from "src/app/shared/notifications/notifications.module";
import { LoaderModule } from "src/app/shared/components/loader/loader.module";

@NgModule({
    declarations: [ScDocumentTypeListComponent],
    exports: [ScDocumentTypeListComponent],
    imports: [
        CommonModule,
        SfGridGenericModule,
        NotificationsModule,
        LoaderModule
    ]
})
export class ScDocumentModule {}