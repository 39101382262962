<div class="exception-body">

    <div class="exception-topbar">
        <a href="#" class="logo">
            <img src="assets/layout/images/logo.png" alt="california-layout"/>
        </a>
    </div>

    <img class="exception-image" src="assets/layout/images/image-access.png" alt="california-layout" />

    <div class="exception-panel">
        <img class="exception-icon" src="assets/layout/images/icon-access.png" alt="california-layout" />
        <h1 class="exception-summary">Access Denied</h1>
        <p class="exception-detail">
            You don't have the necessary permissons.<br>
            Try to search again or use the Go to Dashboard button below.
        </p>
        <div>
            <button type="button" pButton label="Go To Dashboard" [routerLink]="['/']"></button>
        </div>
    </div>
</div>
