import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SfcReconhecimentoFirma, SfcReconhecimentoFirmaItem, SfcReconhecimentoFirmaList } from "./sfc-reconhecimento-firma.model";


@Injectable({
    providedIn: 'any'
})

export class SfcReconhecimentoFirmaService{

    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/Claim/';
    }

    getItem(id: string): Observable<SfcReconhecimentoFirmaItem> {
        let url = this.environmentUrl + id;
        return this.httpClient.get<SfcReconhecimentoFirmaItem>(url);
    }

    getList(): Observable<SfcReconhecimentoFirmaList> {
        let url = this.environmentUrl;
        return this.httpClient.get<SfcReconhecimentoFirmaList>(url);
    }

    addItem(model: SfcReconhecimentoFirma) {
        return this.httpClient.post(this.environmentUrl, model);
    }

    updItem(model: SfcReconhecimentoFirma, userId: string) {

        let url = this.environmentUrl + model.id;
        return this.httpClient.put(url, model);
    }

    delItem(id: string) {
        return this.httpClient.delete(this.environmentUrl + id);
    }

}
