import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subscriber } from 'rxjs';
import { ScUserDetailItem, ScUserItem, ScUserList, ScUserOutput, ScUserStatusHist, ScUserStatusHistList, ScUserUpdateFlagInput, ScUserUpdateInput } from './sc-user.model';
import { DropDownMeta } from 'src/app/shared/model/base-model';


@Injectable({
    providedIn: 'root'
  })

export class ScUserService{

    private environmentUrl: string = '';


    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/User/';
    }

    getItem(id: string): Observable<ScUserItem> {
        let url = this.environmentUrl + id;
        return this.httpClient.get<ScUserItem>(url);
    }

    getItemByLogin(email: string ,  login: string): Observable<ScUserItem> {
        let url = this.environmentUrl + "checkUserExists/"+email+"/"+login;
        return this.httpClient.get<ScUserItem>(url);
    }

    getItemDetail(id: string): Observable<ScUserDetailItem> {
        let url = this.environmentUrl+"detail/user/" + id;
        console.log(id);
        return this.httpClient.get<ScUserDetailItem>(url);
    }

    getList() : Observable<ScUserList>{
        let url = this.environmentUrl;
        return this.httpClient.get<ScUserList>(url);
    }

    getUserRole(id: string, language: string)
    {
        let url = this.environmentUrl + 'role/'+ id+"?language="+ language;
        return this.httpClient.get<ScUserItem>(url);
    }

    getUserDropDownList(role: string): Observable<DropDownMeta> {
        let url = this.environmentUrl + 'DropDown?search='+ role;
        return this.httpClient.get<DropDownMeta>(url);
    }

    geTimeLine(userId: string, language: string) : Observable<ScUserStatusHistList>{
        let url = this.environmentUrl+'status/Hist?language='+ language+'&search='+ userId;
        return this.httpClient.get<ScUserStatusHistList>(url);
    }

    getUserPicture(idUsuario?: number) : Observable<any>{
        let url = this.environmentUrl+"UserPicture/"+idUsuario;
        return this.httpClient.get<any>(url);
    }


    addItem(model: ScUserOutput, userId: string, language: string){
        let input: ScUserUpdateFlagInput = this.adapt(model, userId, language);
        return this.httpClient.post(this.environmentUrl, input);
    }

    updItem(model: ScUserOutput, files: File[], userId: string, language: string){
        let input: ScUserUpdateFlagInput = this.adapt(model, userId, language);
        let url = this.environmentUrl + model.id;
        console.log(input);
        return this.httpClient.put(url, input);
    }

    uploadItem(files: File[], id: string){
        if (files != null && files.length > 0)
        {
            const formData = new FormData();

            // files.forEach((file: File) => {
                //formData.append('json', dataRequest);
                formData.append('file', files[0], files[0].name);
            // });

            let url = this.environmentUrl+ "UploadAvatar/" + id;
            return this.httpClient.post(url, formData);
        }
    }

    delItem(id: string) {
        return this.httpClient.delete(this.environmentUrl+id);
    }

    resetPwd(id: string, email: string, userId:string, language: string){
        let input: ScUserUpdateFlagInput = this.adaptFlag(id, false, userId, "forgot", language);
        let url = this.environmentUrl +"Forgot/" + email;
        return this.httpClient.put(url, input);
    }

    unBlock(id: string, userId:string, language: string){
        let input: ScUserUpdateFlagInput = this.adaptFlag(id, false, userId, "isBlock", language);
        let url = this.environmentUrl +"Blocked/" + id;
        return this.httpClient.put(url, input);
    }

    setEmailValidation(id: string, userId:string, language: string){
        let input: ScUserUpdateFlagInput = this.adaptFlag(id, true, userId, "isEmailConfirmed", language);
        let url = this.environmentUrl +"EmailConfirmed/" + id;
        return this.httpClient.put(url, input);
    }

    unsetFirstAccess(id: string, userId:string, language: string){
        let input: ScUserUpdateFlagInput = this.adaptFlag(id, true, userId, "isFirstAcess", language);
        let url = this.environmentUrl +"FirstAccess/" + id;
        return this.httpClient.put(url, input);
    }



    adaptFlag(id:string, isTrue: boolean, userId: string, type: string, language: string){

        var input: ScUserUpdateFlagInput = {};

        input.id = id;
        input.isTrue = isTrue;
        input.type = type;
        input.userId = userId;
        return input;
    }

    adapt(model: ScUserOutput, userId: string, language: string){

        var input: ScUserUpdateInput = {};

        if (model.id != null && model.id != undefined && model.id != ""){
            input.id = model.id;
        }
        input.login = model.login;
        input.email = model.email;
        input.name = model.name;
        input.phone = model.phone;
        input.password = model.password;
        input.passwordChangeToken = model.passwordChangeToken;
        input.languageCode = model.languageCode;
        input.pictureUri = model.pictureUri;
        input.mobileCode = model.mobileCode;
        input.mobileVersion = model.mobileVersion;
        input.numberSuccessfulLogin = model.numberSuccessfulLogin;
        input.numberFailedLogin = model.numberFailedLogin;
        input.isActive = model.isActive;
        input.isFirstAccess = model.isFirstAccess;
        input.isBlocked = model.isBlocked;
        input.isEmailConfirmed = model.isEmailConfirmed;
        input.isAdmin = model.isAdmin;
        //input.tokenDate = model.tokenDate;
        //input.webLoginDate = model.webLoginDate;
      //  input.mobileLoginDate = model.mobileLoginDate;
        input.scUserStatusId = model.scUserStatusId;
        input.slCommissionTypeId = model.slCommissionTypeId;
        input.scCompanyLastAccessId = model.scCompanyLastAccessId;
        input.scOrganizationStructureId = model.scOrganizationStructureId;
        input.skills = model.skills;
        input.scRoleId = model.scRoleId;
        input.languageCode = language;
        input.userId = userId;
        input.scUserSignatureId = model.scUserSignatureId;



        return input;
    }

    isEmail(search:string):boolean
    {
        var  serchfind:boolean;
        var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        serchfind = regexp.test(search);
        return serchfind
    }
}


