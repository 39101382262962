import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { DropDown } from 'src/app/shared/model/base-model';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ScOrganizationStructure } from '../sc-organization-structure.model';
import { ScOrganizationStructureService } from '../sc-organization-structure.service';






@Component({
    selector: 'sc-organization-structure-grid',
    templateUrl: './sc-organization-structure-grid.component.html',
    styleUrls: ['./sc-organization-structure-grid.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})

export class ScOrganizationStructureGridComponent implements OnInit {

    public list: ScOrganizationStructure[] = [];
    public id: string;
    public item: ScOrganizationStructure;
    public userId: string = null;

    public submitted: boolean;
    public displayMsg: boolean = false;
    public displayDialog: boolean;
    public dialogHeader: string;
    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-warning p-m-1 mr-1";

    public flUpdate: boolean = true;

    constructor(  private OrganizationStructureService: ScOrganizationStructureService,
                    private messageService: MessageService,
                    private confirmationService: ConfirmationService,
                    private translateService: TranslateService,
                    private translateConfigService: TranslateConfigService,
                    private notificationsService: NotificationsService,
                    private breadcrumbService: BreadcrumbService,
                    private userTokenService: UserTokenService)
        { }

    ngOnInit(): void {

        this.userId = this.userTokenService.getUserId();

        this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN']);

        if (this.flUpdate == false)
        {
            this.classBtnUpd = "p-button p-button-secondary p-m-1 mr-1";
            this.classBtnDel = this.classBtnUpd;
        }

        this.getList();
    }

    getList() {
        this.OrganizationStructureService.getList().subscribe(
            {
            next: (res) => {
                if (res)
                    this.list = res.data;
                this.translate();
            },
            error: (err) => {
                console.log(`ScOrganizationStructureGrid - getLista : ${err}.`);
            }
        });
    }

    getItem(id) {
        this.OrganizationStructureService.getItem(id).subscribe(
        {
            next: (res) =>
            {
                if (res) {
                    this.item = res.data;
                }
            },
            error: (err) => {
                console.log(`ScOrganizationStructureGrid - getItem : ${err}.`);
            }
        });
    }


    addItem() {
        this.item = {};
        this.submitted = false;
        this.displayDialog = true;
    }

    updItem(item: ScOrganizationStructure) {
        this.item = {...item};
        this.displayDialog = true;
    }

    delItem(item: ScOrganizationStructure) {

        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_delete')+' ' + item.name + '?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.OrganizationStructureService
                .delItem(item.id)
                .subscribe(
                {
                    next: () =>
                    {
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.sc-OrganizationStructure'), this.translateService.instant('message.record_deleted'));
                        this.getList();
                    },
                    error: (err) => {
                        this.notificationsService.toastNotify('warn', this.translateService.instant('title.sc-OrganizationStructure'), this.translateService.instant('api_response.ERROR'));
                        console.log(`ScOrganizationStructureGrid - delItem : ${err}.`);
                    }
                });
            }
      });
    }

    saveItem() {
        this.submitted = true;
        if (this.item.code.trim()) {
            if (this.item.id) {

            this.OrganizationStructureService
            .updItem(this.item, this.userId)
            .subscribe(
                {
                next: () => {
                    this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_updated'), life: 3000});
                    this.getList();
                },
                error: (err) => console.log(err)
            });

            } else {
                this.OrganizationStructureService
                    .addItem(this.item, this.userId)
                    .subscribe(
                    {
                    next: () => {
                        this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_inserted'), life: 3000});
                    this.getList();
                    },
                    error: (err) => console.log(err)
                    });
            }

            this.list = [...this.list]
            this.displayDialog = false;
            this.item = {};
        }
    }

    translate()
    {
        const labAccessControl = this.translateService.instant('label.access_control')+" ";
        const labOrganizationStructure = " "+this.translateService.instant('label.OrganizationStructures');
        this.breadcrumbService.setItems([{label: labAccessControl},{label: labOrganizationStructure, routerLink: ['OrganizationStructure']} ]);

        this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')

        this.dialogHeader = this.translateService.instant('title.sc_OrganizationStructure_edit');

    }

    hideDialog() {
        this.displayDialog = false;
        this.submitted = false;
    }



    clear(table: Table) {
        table.clear();
    }


}
