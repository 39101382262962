<!-- <h1>Componente de reconhecimento-firma</h1>
<br>{{id}} -->
<div class="formgrid grid">
    <div class="field col-12 md:col-6 lg:col-6 xl:col-6 mt-5">

        <div class="formgrid grid">
            <div class="field col-6">
                <label for="scPerson" class="mb1 font-bold">Cliente</label>
                <!-- <sf-sc-person-dropdown id="person" componentType="basic" [id]="" (chosedItem)="reciverFeedbackPerson($event)"
                    [placeHolderLanguage]="'label.client' | translate"></sf-sc-person-dropdown> -->
                <input type="text" pInputText id="scPerson" name="scPerson" ngDefaultControl [(ngModel)]="scPerson" disabled required />
            </div>
        <!-- </div>
        <div class="formgrid grid"> -->
            <div class="field col-6">
                <label for="service" class="mb1 font-bold">Serviço</label>
                <input type="text" pInputText id="service" name="service" ngDefaultControl [(ngModel)]="item.name" disabled required />
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12">
                <label class="mb1 font-bold">Escrevente</label>
                <sfc-dropdown-clerk [id]="item.scUserClerkId" (selectedItemOutput)="reciverFeedbackUserClerk($event)" [disabled]="isDisabled"></sfc-dropdown-clerk>                
                <small class="p-invalid" *ngIf="getFiledValidation(!item.scUserClerkId)" translate>validation_errors.required_field</small>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12">
                <label class="mb1 font-bold">Tributação</label>
                <sf-dropdown-generic [list]="taxTypeList" [id]="item.woOrderServiceTaxTypeId"
                    (selectedItemOutput)="reciverFeedbackTaxType($event)" [disabled]="isDisabled"></sf-dropdown-generic>
                <small class="p-invalid" *ngIf="getFiledValidation(!item.woOrderServiceTaxTypeId)" translate>validation_errors.required_field</small>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12">
                <label for="description" class="mb1 font-bold">Descrição</label>
                <input type="text" pInputText id="description" name="description" ngDefaultControl [(ngModel)]="item.description" class="mt-1" class="w-full" />
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-6">
                <label class="mb1 font-bold">Tipo Documento</label>
                <sf-dropdown-generic [list]="docTypeList" [id]="item.scArchiveTypeId"
                    (selectedItemOutput)="reciverFeedbackDocType($event)" [disabled]="isDisabled"></sf-dropdown-generic>
                <small class="p-invalid" *ngIf="getFiledValidation(!item.scArchiveTypeId)" translate>validation_errors.required_field</small>
            </div>        
            <div class="field col-6">
                <label for="documentDescription" class="mb1 font-bold">Descrição Documento</label>
                <input type="text" pInputText id="documentDescription" name="documentDescription" ngDefaultControl [(ngModel)]="item.documentDescription" class="mt-1" class="w-full" />
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-6">
                <label class="mb1 font-bold">Quantidade de Cópias</label>
                <!-- <input type="number" pInputText id="copy" [(ngModel)]="" class="mt-1" disabled class="w-full"/> -->
                <p-inputNumber name="copy" ngDefaultControl [(ngModel)]="item.pageQty" [showButtons]="true" inputId="copy" [min]="1" [max]="100"
                    [disabled]="isDisabled" class="w-full"> </p-inputNumber>
            </div>

            <div class="field col-6 mt-4">
                <!-- <label class="mb1 font-bold">Selos disponíveis: <span class="font-bold">{{stampQty}}</span></label><br>
                <label class="mb1 font-bold">Selos atual: <span class="font-bold">{{item.stamp}}</span></label> -->
                <wo-notarial-seal-reserve *ngIf="load" [woNotarialSealId]="item.woNotorialSealId" [cssClass]="'field col-12'" (selectedItemOutput)="reserveNotarialSeal($event)"></wo-notarial-seal-reserve>
            </div>
        </div>        

    </div>

    <div class="vertical-line field col-12 md:col-6 lg:col-6 xl:col-6 mt-5">

        <div class="formgrid grid">
            <div class="field col-12">
                <label class="mb1 font-bold">Selecionar Cliente para Reconhecimento</label>
                <sf-sc-person-dropdown id="personReconhecimento" componentType="basic"
                    (chosedItem)="reciverFeedbackPerson($event)"
                    [placeHolderLanguage]="'label.client' | translate"></sf-sc-person-dropdown>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12">
                <label class="mb1 font-bold">Clientes a reconhecer</label>
                <p-table *ngIf="listPerson.length > 0" #dt [value]="listPerson" dataKey="id" [paginator]="false"
                    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped" 
                    selectionMode="single">
                    <!-- <ng-template pTemplate="caption">
                </ng-template> -->

                    <ng-template pTemplate="header">
                        <!-- <tr>
                    <th style="text-align: left;" pSortableColumn="name" rowspan="2">Nome<p-sortIcon field="name"></p-sortIcon></th>                    
                </tr> -->
                        <tr>
                            <th style="width: 35%; text-align: left;">CPF/CNPJ</th>
                            <th style="width: 35%; text-align: left;">Nome</th>
                            <!-- <th style="text-align: left;">Firmas cadastradas</th> -->
                            <th style="width: 30%; text-align: center;"  rowspan="1" *ngIf="canExecuteService"><span translate>label.actions</span></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-itemPerson let-rowIndex="rowIndex">
                        <tr [pSelectableRow]="itemPerson" [pSelectableRowIndex]="rowIndex" [class]="rowIndex === rowIndexHighlight ? pHighlight :  null" (click)="loadImages(itemPerson.id, rowIndex)">
                            <td style="text-align: left;">{{itemPerson.cpf}}</td>
                            <td style="text-align: left;">{{itemPerson.name}}</td>
                            <!-- <td style="text-align: left;"></td> -->
                            <td *ngIf="canExecuteService" style="text-align: center;">
                                <p-button icon="pi pi-times" [styleClass]="classBtnDel" [pTooltip]="'button.delete' | translate" (click)="delItemPerson(itemPerson)" [disabled]="!canExecuteService"></p-button>
                                <!-- <p-button icon="pi pi-pencil" [styleClass]="classBtnUpd" [pTooltip]="'button.edit' | translate" (click)="updItem(item)" [disabled]="!canExecuteService"></p-button> -->
                                <p-button icon="pi pi-cloud-upload" styleClass="p-button p-button-info mr-1" label="" (click)="uploadFiles(itemPerson)"
                                    pTooltip="Adicionar imagem Reconhecimento"></p-button>
                                <p-button icon="pi pi-search" styleClass="p-button p-button-secondary" label="" (click)="loadImages(itemPerson.id, index)"
                                    pTooltip="Visualizar arquivos"></p-button>
                            </td>
                        </tr>                                           
                    </ng-template>
                </p-table>
            </div>

            <div class="field col-12">
                
                <div class="p-inputgroup">
                    <label class="mb1 font-bold">Firmas reconhecidas</label>
                    <div  style="margin-left: 10px; margin-top: -20px;">
                        
                    </div>
                </div>
                
                <p-galleria *ngIf="loadFiles" [(visible)]="loadFiles" [(value)]="listPersonFile" [responsiveOptions]="responsiveOptions" [containerStyle]="{'min-height': '100px'}" [numVisible]="3" [circular]="true" [showItemNavigators]="true">
                    <ng-template pTemplate="item" let-itemPerson>
                        <div class="formgrid grid">
                            <div class="field-checkbox field col-12">                        
                                <button *ngIf="itemPerson.inTheService" pButton pRipple icon="pi pi-times"
                                    class="p-button-warning" label="Remover Documento"
                                    (click)="updOrderOrderServiceFile(itemPerson.scPersonId, itemPerson.id, itemPerson.inTheService)"></button>
                                <button *ngIf="!itemPerson.inTheService" pButton pRipple icon="pi pi-check"
                                    class="p-button-success" label="Adicionar Documento"
                                    (click)="updOrderOrderServiceFile(itemPerson.scPersonId, itemPerson.id, itemPerson.inTheService)"></button>
                            </div>
                            <div class="field col-12">
                                <img (click)="openFileZoom(itemPerson.pictureUri)" [src]="itemPerson.pictureUri" height="190" width="100%" />
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="thumbnail" let-itemPerson>
                        <div class="grid grid-nogutter justify-content-center">
                            <img height="50" width="50" [src]="itemPerson.pictureUri" />
                        </div>
                    </ng-template>
                    <ng-template pTemplate="caption" let-itemPerson>
                        <h5 style="margin-bottom: .5rem; color: #ffffff;">{{ itemPerson.createdAt | date : 'dd/MM/yyyy HH:mm:ss' }} - {{itemPerson.archiveType}}</h5>
                        <p>{{ itemPerson.userName }}</p>
                    </ng-template>
                </p-galleria>
                <label *ngIf="listPersonFile.length == 0" translate>label.empt</label>
            </div>    

            <div class="p-field col-12">
                <div style="float: right; display: flex; align-items: flex-end; gap: 8px;">            
                    <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;"
                        class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate"
                        (click)="feedbackCancel('cancel')"></button>
                    <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;"
                        class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="saveItem()"
                        [disabled]="fieldValidation"></button>
                </div>
            </div>
            
        </div>

        
        
    </div>

    <!-- <div class="col-12 mt-3">
        <div style="float: right;">            
            <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;"
                class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate"
                (click)="feedbackCancel('cancel')"></button>
            <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;"
                class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate"
                (click)="saveItem()" [disabled]="fieldValidation"></button>
        </div>
    </div> -->
</div>

<div *ngIf="uploadFilesModal" class="overlay">
    <p-dialog header="Upload Firmas Reconhecidas" [(visible)]="uploadFilesModal" [style]="{width: '50vw', height: '50vh'}">
        <div class="card flex justify-content-center">
            <p-toast></p-toast>
            <p-fileUpload name="reconhecimento[]" (uploadHandler)="onUpload($event)" [multiple]="true" accept="image/*" maxFileSize="1000000"
                chooseLabel="Adicionar" cancelLabel="Cancelar" chooseStyleClass="p-button-info" uploadStyleClass="p-button-info" cancelStyleClass="p-button-info"
                customUpload="true">
                <ng-template pTemplate="content">
                    <ul *ngIf="uploadedFiles.length">
                        <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
                    </ul>
                </ng-template>                
            </p-fileUpload>
        </div>
    </p-dialog>
</div>

<div *ngIf="fileZoomVisible" class="overlay">
    <p-dialog header="Zoom" [(visible)]="fileZoomVisible" [style]="{width: '80vw', height: '80vh'}">
        <div class="card flex justify-content-center">
            <p-image [src]="this.fileZoomUrl" [preview]="true" alt="Image" width="250">
                <ng-template pTemplate="indicator">
                    <i class="pi pi-check"></i>
                </ng-template>
            </p-image>
        </div>
    </p-dialog>
</div>