import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "YesNo"
})
export class SimNaoPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return value ? "YES" : "NO";
  }
}
