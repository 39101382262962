import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ScArchiveTypeService } from 'src/app/core/services/sc-archive-type/sc-archive-type.service';
import { ScPersonService } from 'src/app/core/services/sc-person/sc-person.service';
import { ScPersonFileOutput } from 'src/app/models/response/sc-person.model';
import { DropDown } from '../../model/base-model';
import { TranslateService } from '@ngx-translate/core';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'sf-sc-person-file',
  templateUrl: './sc-person-file.component.html',
  styleUrls: ['./sc-person-file.component.scss']
})
export class ScPersonFileComponent implements OnInit {

  @ViewChild('fileUpload', {static: false}) fileUpload: FileUpload;
  @Input() scPersonId: string;
  @Input() scArchiveTypeId: string = '00000000-0000-0000-0000-000000000000';
  @Input() woOrderOrderServiceId: string;
  docTypeList: DropDown[] = [];
  listPersonFile: ScPersonFileOutput[] = [];
  uploadedFiles: any[] = [];
  loadFiles: boolean = false;
  rowIndexHighlight: number = 0;
  responsiveOptions: any[];
  fileZoomVisible: boolean = false;
  fileZoomUrl: string = '';
  fieldValidation: boolean = false;

  constructor(private scArchiveTypeService: ScArchiveTypeService,
    private personService: ScPersonService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.getDocumentType();
    this.loadImages(this.scPersonId, this.scArchiveTypeId, 0);
  }

  messageSuccess(message: string) {
    this.messageService.add({ severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 3000 });
  }

  messageError(message: string, consoleMsg: string) {
    this.messageService.add({ severity: 'warn', summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 3000 });
    console.log(consoleMsg);
  }

  getDocumentType() {
    this.scArchiveTypeService.getDropDownList().subscribe(
      {
        next: (res) => {
          if (res)
            this.docTypeList = res.data;
        },
        error: (err) => {
          this.messageError('api_response.ERROR', `ScPersonFileComponent - getDocumentType : ${err}.`);
        }
      });
  }

  reciverFeedbackDocType(id: string) {
    if (id == null || id == "" || id == undefined) {
        this.scArchiveTypeId = null;
    }
    else {
        this.scArchiveTypeId = id;
    }
  }

  onUpload(event) {
    this.uploadedFiles = event.files;
    this.savePics();
  }

  savePics() {
    this.personService
      .uploadFile(this.uploadedFiles, this.scPersonId, this.scArchiveTypeId, this.woOrderOrderServiceId)
      .subscribe(
        {
          next: () => {
            this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: 'Upload realizado com sucesso.' });
            this.uploadedFiles = [];
            this.fileUpload.clear();
            this.loadImages(this.scPersonId, this.scPersonId, 0);
          },
          error: (err) => {
            this.messageService.add({ severity: 'error', summary: 'File Uploaded', detail: `ScPersonFileComponent - onUpload : ${err}.` });
            console.log(err);
          }
        });
  }

  loadImages(scPersonId: string, scArchiveTypeId: string, index: number) {
    this.rowIndexHighlight = index;

    this.loadFiles = false;
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 5
      },
      {
        breakpoint: '768px',
        numVisible: 3
      },
      {
        breakpoint: '560px',
        numVisible: 1
      }
    ];

    this.getPersonFileList(scPersonId, scArchiveTypeId, null);
  }

  getPersonFileList(scPersonId: string, scArchiveTypeId: string, woOrderOrderServiceId: string) {
  
    this.personService.getListFile(scPersonId, scArchiveTypeId, woOrderOrderServiceId).subscribe(
      {
        next: (res) => {
          if (res)
            this.listPersonFile = res.data.sort((a, b) => Number(b.inTheService) - Number(a.inTheService) || (a.archiveType.localeCompare(b.archiveType)));

          this.loadFiles = true;
        },
        error: (err) => {
          console.log(`ScPersonFileComponent - getPersonFileList : ${err}.`);
        }
      });
  }

  openFileZoom(url: string) {
    this.fileZoomVisible = true;
    this.fileZoomUrl = url;
  }

  getFiledValidation(value: boolean) {
    this.fieldValidation = value;
    return this.fieldValidation;
  }
}