

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DropDownMeta } from 'src/app/shared/model/base-model';
import { ScRole, ScRoleItem, ScRoleList, ScRoleOutput, ScRoleUpdateInput } from './sc-role.model';

@Injectable({
    providedIn: 'any'
})

export class ScRoleService{

    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/Role/';
    }

    getItem(id: string): Observable<ScRoleItem> {
        let url = this.environmentUrl + id;
        return this.httpClient.get<ScRoleItem>(url);
    }

    getItemByUserId(userId: string, language: string): Observable<ScRoleItem> {
        let url = this.environmentUrl + userId+'?language='+language;
        return this.httpClient.get<ScRoleItem>(url);
    }

    getList(language: string): Observable<ScRoleList> {
        let url = this.environmentUrl+ "?language="+language;
        return this.httpClient.get<ScRoleList>(url);
    }

    // getRoleClaimList(language: string): Observable<DropDownMeta> {
    //     let url = this.environmentUrl+ "?language="+language;
    //     return this.httpClient.get<DropDownMeta>(url);
    // }

    addItem(model: ScRoleOutput, userId: string, language: string) {
        let modelUpd: ScRoleUpdateInput = {};

        modelUpd.code = model.code;
        modelUpd.name = model.name;
        modelUpd.icon = model.icon;
        modelUpd.color = model.color;
        modelUpd.isActive = model.isActive;
        modelUpd.scRoleTypeId = model.scRoleTypeId;
        modelUpd.userId = userId;
        modelUpd.claims = model.claims;
        modelUpd.languageCode = language;

        return this.httpClient.post(this.environmentUrl, modelUpd);
    }

    updItem(model: ScRoleOutput, userId: string, language: string) {

        let url = this.environmentUrl + model.id;
        let modelUpd: ScRoleUpdateInput = {};

        modelUpd.id = model.id;
        modelUpd.code = model.code;
        modelUpd.name = model.name;
        modelUpd.icon = model.icon;
        modelUpd.color = model.color;
        modelUpd.isActive = model.isActive;
        modelUpd.scRoleTypeId = model.scRoleTypeId;
        modelUpd.userId = userId;
        modelUpd.claims = model.claims;
        modelUpd.languageCode = language;

        return this.httpClient.put(url, modelUpd);
    }

    delItem(id: string) {
        return this.httpClient.delete(this.environmentUrl + id);
    }

    getDropDownList(language: string): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown/?language="+language;
        return this.httpClient.get<DropDownMeta>(url);
    }

    getDropDownFullList(language: string): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDownFull/?language="+language;
        return this.httpClient.get<DropDownMeta>(url);
    }

    getRoleTypeDropDownList(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "type/dropdown";
        return this.httpClient.get<DropDownMeta>(url);
    }

}
