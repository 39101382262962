import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { DropDownMeta } from "src/app/shared/model/base-model";
import { ScArchiveTypeList, ScArchiveTypeOutput } from "src/app/models/response/sc-archive-type.response";

@Injectable({
    providedIn: 'any'
})
export class ScArchiveTypeService {
    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient) {
        this.environmentUrl = environment.ApiCore + '/Archive/type/';
    }

    getItem(id: string): Observable<ScArchiveTypeOutput> {
        let url = this.environmentUrl + id;
        return this.httpClient.get<ScArchiveTypeOutput>(url);
    }

    getList(scCompanyTypeId: string, language: string): Observable<ScArchiveTypeList> {
        let url = this.environmentUrl + "?search="+scCompanyTypeId+"&language="+language;
        return this.httpClient.get<ScArchiveTypeList>(url);
    }

    addItem(model: ScArchiveTypeOutput): Observable<ScArchiveTypeOutput> {
        return this.httpClient.post(this.environmentUrl, model);
    }

    updItem(id: string, model: ScArchiveTypeOutput) {
        let url = this.environmentUrl + id;
        return this.httpClient.put(url, model);
    }

    delItem(id: string) {
        return this.httpClient.delete(this.environmentUrl + id);
    }

    getDropDownList(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown";
        return this.httpClient.get<DropDownMeta>(url);
    }
}