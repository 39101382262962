import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'cria-env',
    templateUrl: './env.component.html'
})
export class EnvComponent implements OnInit{
    @Input() env: string ='DEV';
    ngOnInit(): void {
        this.env = environment.env;
    }
   


}