import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { PaCashierOpen, PaCashier, PaCashierTransactionRegister } from "./pa-payment.model";

@Injectable({
    providedIn: 'any'
})

export class PaCashierService {
    private environmentUrl: string = "";

    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore + '/PaCashier/';
    }

    openCash(model: PaCashier, pointOfSaleId: string) {
        const url = `${this.environmentUrl}register/${pointOfSaleId}/open`;
        const input = this.createPaCashierOpen(model);

        return this.httpClient.post(url, input);
    }

    closeCash(model: PaCashier, cashierRegisterId: string) {
        const url = `${this.environmentUrl}${cashierRegisterId}/close`;
        const input = this.createPaCashierOpen(model);

        return this.httpClient.post(url, input);
    }

    cashTransaction(model: PaCashierTransactionRegister, cashierRegisterId: string) {
        const url = `${this.environmentUrl}${cashierRegisterId}/transaction`;
        const input = this.createPaCashierTransactionRegister(model);

        return this.httpClient.post(url, input);
    }

    createPaCashierTransactionRegister(model: PaCashierTransactionRegister): PaCashierTransactionRegister {
        return {
            ...model
        };
    }

    createPaCashierOpen(model: PaCashier): PaCashierOpen {
        return {
            data: { ...model }
        };
    }

}
