import { DropDown } from 'src/app/shared/model/base-model';
import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { ScOrganizationStructureService } from '../sc-organization-structure.service';



@Component({
    selector: 'sf-sc-organization-structure-dropdown',
    templateUrl: './sc-organization-structure-dropdown.component.html',
    styleUrls: ['./sc-organization-structure-dropdown.component.scss']
})

export class ScOrganizationStructureDropdownComponent implements OnInit{

    public list : DropDown[];
    public selectedItem: DropDown;
    public deleteItem: DropDown;

    @Input() placeHolderLanguage: string;
    @Input() id: string;
    @Input() idDelete: string;
    @Output() chosedItem = new EventEmitter();

    constructor(  private scOrganizationStructureService: ScOrganizationStructureService){ }

    ngOnInit(): void {
        this.getList();
    }

    ngOnChanges(): void {
        this.getList();
    }

    getList() {
        this.scOrganizationStructureService.getDropDownList().subscribe(
          {
            next: (res) => {
                if (res)
                    this.list = res.data;


                if (this.list !== null){
                    this.selectedItem = this.list.find(x => x.id == this.id?.toString());
                    this.deleteItem = this.list.find(x => x.id == this.idDelete?.toString());

                    if (this.deleteItem != null || this.deleteItem != undefined)
                    {
                        let index = this.list.findIndex(d => d.id === this.deleteItem.id);
                        this.list.splice(index, 1);
                    }
                }
            },
            error: (err) => {
                console.log(`OrganizationStructureDropDown - getList : ${err}.`);
            }
        });
      }

    feedback(){
        this.chosedItem.emit(this.selectedItem)
    }

}
