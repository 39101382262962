<div class="stepsdemo-content">
    <p-card>
        <ng-template pTemplate="title"> <span translate class="font-bold">label.companyUser</span> </ng-template>
        <ng-template pTemplate="subtitle"> <span class="text-lg font-bold" translate>label.enterCompanyUser</span> </ng-template>
        <ng-template pTemplate="content">
            <div class="formgrid grid text-lg">
                
                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="email"><span translate>label.email</span>*</label>
                    <input
                        id="email"
                        type="text"
                        required
                        pInputText
                        [(ngModel)]="step.userEmail"
                        [ngClass]="{ 'ng-invalid ng-dirty': ((!step.userEmail || !isEmail) && submitted) }"
                    />
                    <small *ngIf="(!step.userEmail && submitted)" class="p-error">
                        <span translate>label.required_fields</span>
                    </small>
                    <small *ngIf="(!isEmail && submitted)" class="p-error">
                        <span translate>label.invalidEmail</span>
                    </small>
                </div>

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="name"><span translate>label.name</span>*</label>
                    <input
                        id="name"
                        type="text"
                        required
                        pInputText
                        [(ngModel)]="step.userName"
                        [ngClass]="{ 'ng-invalid ng-dirty': (!step.userName && submitted) }"
                    />
                    <small *ngIf="(!step.userName && submitted)" class="p-error">
                        <span translate>label.required_fields</span>
                    </small>
                </div>

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="listaPerfil"><span translate>label.access_profile</span>*</label>
                    <sf-dropdown-generic
                        [list]="roleList"
                        [id]="1"
                        (selectedItemOutput)="reciverFeedbackRole($event)"
                        [ngClass]="{ 'ng-invalid ng-dirty': (!scRoleId && submitted) }"
                    ></sf-dropdown-generic>
                    <small *ngIf="(!scRoleId && submitted)" class="p-error">
                        <span translate>label.required_fields</span>
                    </small>
                </div>

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="phone">
                        <span translate>label.phone_number</span>
                    </label>
                    <p-inputMask
                        inputId="phone"
                        mask="(99)99999-9999"
                        [(ngModel)]="step.phone"
                    ></p-inputMask>
                </div>

            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="grid grid-nogutter justify-content-between">
                <p-button [label]="'label.back' | translate" (onClick)="prevPage()" icon="pi pi-angle-left"></p-button>
                <p-button [label]="'label.next' | translate" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
            </div>
        </ng-template>
    </p-card>
</div>
