import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ScCompanyService } from '../../../sc-company.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ScCompanyInfoInput } from '../../../sc-company.model';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ConstantsService } from 'src/app/core/services/constants.service';



@Component({
    selector: 'sc-company-add-step-confirm',
    templateUrl: './sc-company-add-step-confirm.component.html',
    //styleUrls: ['./sc-company-add.component.scss'],
    //providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})


export class ScCompanyAddStepConfirmComponent implements OnInit {

    public step: ScCompanyInfoInput = {};
    public submitted: boolean = false;
    public modules: string = "";
    public configs: string = "";
    public language: string = 'pt-BR';

    constructor(private companyService: ScCompanyService,
                private messageService: MessageService,
                private translateService: TranslateService,
                private constantsService: ConstantsService,
                private translateConfigService: TranslateConfigService
            ) {}


    ngOnInit() {
        this.language = this.translateConfigService.getLanguage();
        this.step = this.companyService.getAddSteps();

        console.log(this.step);

        this.step.modules.forEach(element => {
            this.modules += element.name+"; ";
        });

        this.step.configs.forEach(element => {
            this.configs += element.name+"; ";
        });
    }


    save() {
            //this.companyService.dialogCompanyAddNext.emit();
            //this.companyService.complete();

            this.companyService.addItem(this.step)
            .subscribe({
                next: () => {
                    this.messageSuccess(this.translateService.instant('message.record_inserted'));
                    this.companyService.dialogCompanyAdd.emit();
                },
                error: (err) => {
                    this.messageError(this.translateService.instant('api_response.ERROR'));
                    console.log(`CompanyDetail - updItem : ${err}.`);
                }
            });
    }

    prevPage() {
        this.companyService.dialogCompanyAddBack.emit();
    }

    messageSuccess(message: string){
        this.messageService.add({severity: this.constantsService.SEVERITY_SUCCESS, summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 3000});
    }

    messageError(message: string){
        this.messageService.add({severity: this.constantsService.SEVERITY_WARNING, summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 3000});
    }
}
