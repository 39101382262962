import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropDownGenericComponent } from './dropdown-generic/dropdown-generic.component';
import { DropdownModule } from 'primeng/dropdown';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownColorComponent } from './dropdown-color/dropdown-color.component';
import { DropdownIconComponent } from './dropdown-icon/dropdown-icon.component';

@NgModule({

    declarations: [DropDownGenericComponent, DropdownColorComponent, DropdownIconComponent],
    exports: [DropDownGenericComponent, DropdownColorComponent, DropdownIconComponent],
    imports: [  CommonModule,
                FormsModule,
                DropdownModule,
                ColorPickerModule,
        ]


})

export class SfDropDownModule {

}
