
import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PlatformDetectorService } from 'src/app/core/services/platform-detector.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { ScUser } from 'src/app/pages/sc-user/sc-user.model';




@Component({
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SignUpComponent implements OnInit {

    loginForm: FormGroup;
    token: string;
    public tokenValido: boolean = false;
    public usuario: ScUser = {};
    public data: Date = new Date();
    public flExibir: boolean = false;


    public value: string[];


    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private notificationsService: NotificationsService,
        private loader: LoaderService,
        private _Activatedroute: ActivatedRoute
        ) { }


    ngOnInit(): void {

        this._Activatedroute.paramMap.subscribe(params => {
            this.token = params.get('token');
        });

        this.loginForm = this.formBuilder.group({
            password: ['', Validators.required],
            password2: ['', Validators.required],
            email: ['', Validators.required]
        });
    }

    forgotPwd() {
        this.router.navigate(['/login/forgot']);
    }



}


