import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';

import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { ScMenu, ScMenuItemTreeOutput } from '../sc-menu.model';
import { ScMenuService } from '../sc-menu.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { DropDown, DropDownLabel } from 'src/app/shared/model/base-model';






@Component({
    selector: 'sc-menu-tree',
    templateUrl: './sc-menu-tree.component.html',
    styleUrls: ['./sc-menu-tree.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})

export class ScMenuTreeComponent implements OnInit {

    public list: TreeNode[];
    public id: string;
    public item: ScMenu = {};
    public selectedItem!: TreeNode;
    public userId: string = null;
    public listDropDown: DropDownLabel[] = [];
    public selectedDropDown: DropDownLabel;
    public deleteItem: DropDownLabel;
    public listSelectedClaim: DropDown[] = [];

    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-warning p-m-1 mr-1";
    public lbActive: string = "Ativo";
    public message: string = "";
    public language: string = "";
    public isUpdate: boolean = false;
    public canUpdate: boolean = false;

    @ViewChild('tree') tree;

    constructor(  private MenuService: ScMenuService,
                    private messageService: MessageService,
                    private confirmationService: ConfirmationService,
                    private translateService: TranslateService,
                    private translateConfigService: TranslateConfigService,
                    private notificationsService: NotificationsService,
                    private breadcrumbService: BreadcrumbService,
                    private userTokenService: UserTokenService){ }

    ngOnInit(): void {

        this.language = this.translateConfigService.getLanguage();

        this.userId = this.userTokenService.getUserId();

        this.canUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN']);

        if (this.canUpdate == false)
        {
            this.classBtnUpd = "p-button p-button-secondary p-m-1 mr-1";
            this.classBtnDel = this.classBtnUpd;
        }

        this.getList();



    }

    getList() {

        this.MenuService.getTreeList(this.translateConfigService.getLanguage()).subscribe(
        {
            next: (res) => {
                if (res){
                    this.list = res.data.items;


                    // if (this.list.length > 0){
                    //     this.selectedItem = this.getNodeWithKey(this.list[0].key, this.list);
                    //     this.id = this.selectedItem.key;
                    //     this.isUpdate = true;
                    //     this.getItem();
                    // }
                }
                this.translate();

            },
            error: (err) => {
                console.log(`ScMenuTree - getList : ${err}.`);
            }
        });



    }

    getItem() {
        this.MenuService.getItem(this.id, this.language).subscribe(
        {
            next: (res) => {
                if (res){
                    this.item = res.data;
                    this.listSelectedClaim = this.item.claims;
                    this.geDropDownList();
                }
            },
            error: (err) => {
                console.log(`ScMenuTree - getItem : ${err}.`);
            }
        });
    }

    geDropDownList() {
        this.MenuService.getDropDownList().subscribe(
        {
            next: (res) => {
                if (res){
                    this.listDropDown = res.data;

                    if (this.listDropDown !== null){
                        this.selectedDropDown = this.listDropDown.find(x => x.id == this.item.scMenuParentId?.toString());
                        this.deleteItem = this.listDropDown.find(x => x.id == this.item.id?.toString());

                        if (this.deleteItem != null || this.deleteItem != undefined)
                        {
                            let index = this.listDropDown.findIndex(d => d.id === this.item.id?.toString());
                            this.listDropDown.splice(index, 1);
                        }
                    }
                }
            },
            error: (err) => {
                console.log(`ScMenuTree - geDropDownList : ${err}.`);
            }
        });
    }

    addItem()
    {
        this.geDropDownList();
        this.item = {};
        this.isUpdate = true;
    }

    delItem(item: ScMenu) {

        if (item != null && item != undefined && item.id != null) {
            this.confirmationService.confirm({
                message: this.translateService.instant('message.confirm_delete')+' ' + item.label + '?',
                header: this.translateService.instant('title.confirmation'),
                acceptLabel: this.translateService.instant('button.yes'),
                rejectLabel: this.translateService.instant('button.no'),
                acceptButtonStyleClass: 'p-button-success',
                rejectButtonStyleClass: 'p-button-danger',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.MenuService
                    .delItem(item.id)
                    .subscribe(
                    {
                        next: () =>
                        {
                            this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_deleted'));
                            this.getList();
                        },
                        error: (err) => {
                            this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.translateService.instant('api_response.ERROR'));
                            console.log(`ScMenuGrid - delItem : ${err}.`);
                        }
                    });
                }
        });
    }
    }

    cancel(){
        this.isUpdate = false;
    }

    saveItem() {



        if (this.item.label.trim()) {
            if (this.item.id) {
                this.id = this.item.id;

                this.MenuService
                .updItem(this.item, this.userId, this.language)
                .subscribe(
                    {
                    next: () => {
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_updated'));
                        this.getList();
                    },
                    error: (err) => {
                        console.log(err);
                        this.message = this.translateService.instant('api_response.'+err.error);
                        console.log(this.message);

                        if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                            this.message = this.translateService.instant('api_response.ERROR');

                        this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.message);
                }
            });

            } else {
                this.MenuService
                    .addItem(this.item, this.userId, this.language)
                    .subscribe(
                    {
                    next: () => {
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_inserted'));
                        this.id = this.item.id;
                        this.getList();
                    },
                    error: (err) => {
                        console.log(err);
                        this.message = this.translateService.instant('api_response.'+err.error);
                        if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.null")
                            this.message = this.translateService.instant('api_response.ERROR');

                        this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.message);
                    }
                    });
            }

            this.getItem();

            //this.list = [...this.list]
            this.isUpdate = false;
            this.item = {};
        }
    }

    translate()
    {
        const labAccessControl = this.translateService.instant('label.access_control')+" ";
        const labMenu = " "+this.translateService.instant('label.menu');
        this.breadcrumbService.setItems([{label: labAccessControl},{label: labMenu, routerLink: ['Menu']} ]);

        this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')

        this.lbActive = this.translateService.instant('label.active');
    }

    getNodeWithKey(key: string, nodes: TreeNode[]): TreeNode | undefined {
        for (let node of nodes) {
            if (node.key === key) {
               return node;
            }

            if (node.children) {
              let matchedNode = this.getNodeWithKey(key, node.children);
              if (matchedNode) {
                return matchedNode;
              }
            }
        }
        return undefined;
    }

    nodeSelect(event) {
        this.item = {};
        this.selectedItem = event.node;
        this.tree.filteredNodes = null;
        this.id = this.selectedItem.key;
        this.getItem();
        this.isUpdate = true;
//        this.collapseAll();
    }

    collapseAll(){
        this.list.forEach( node => {
            this.expandRecursive(node, false);
        });
    }

    private expandRecursive(node:TreeNode, isExpand:boolean){
        node.expanded = isExpand;
        if (node.children){
            node.children.forEach( childNode => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }

    public reciverFeedbackParent()
    {
        if (this.selectedDropDown.id === this.item.scMenuParentId) {
            this.messageService.add({severity: 'error', summary: this.translateService.instant('title.error'), detail: this.translateService.instant('message.RECORD_PARENT_INVALID'), life: 3000});
        }

        this.item.scMenuParentId = this.selectedDropDown.id;;
    }

    reciverFeedbackIcon(icon: DropDown) {
        if (icon == null || icon == undefined || icon.id == null || icon.id == undefined)
            this.item.icon = "";
        else
            this.item.icon = icon.id;
    }

    reciverFeedbackClaims(claims:DropDown[]){
        this.item.claims = claims;
    }

}
