import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DropDownLabelMeta, DropDownMeta } from 'src/app/shared/model/base-model';
import { ScMenu, ScMenuItem, ScMenuItemTreeOutput, ScMenuList, ScMenuUpdateInput } from './sc-menu.model';
import { TreeNode } from "primeng/api";

@Injectable({
    providedIn: 'any'
})

export class ScMenuService{

    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/Menu/';
    }

    getItem(id: string, lang: string): Observable<ScMenuItem> {
        let url = this.environmentUrl + id+"/?language="+lang;
        return this.httpClient.get<ScMenuItem>(url);
    }

    getTreeList(languageCode: string ): Observable<any> {
        let url = this.environmentUrl+"GetMenuTree/?language="+languageCode;
        return this.httpClient.get<TreeNode[]>(url);
    }

    addItem(model: ScMenu, userId: string, lang: string) {
        let input: ScMenuUpdateInput = this.adapt(model, userId, lang);
        return this.httpClient.post(this.environmentUrl, input);
    }

    updItem(model: ScMenu, userId: string, lang: string) {

        let url = this.environmentUrl + model.id;
        let input: ScMenuUpdateInput = this.adapt(model, userId, lang);

        return this.httpClient.put(url, input);
    }

    delItem(id: string) {
        return this.httpClient.delete(this.environmentUrl + id);
    }

    getDropDownList(): Observable<DropDownLabelMeta> {
        let url = this.environmentUrl + "DropDown";
        return this.httpClient.get<DropDownLabelMeta>(url);
    }

    adapt(model: ScMenu, userId: string, lang: string){

        var input: ScMenuUpdateInput = {};

        if (model.id != null && model.id != undefined && model.id != ""){
            input.id = model.id;
        }
        input.scMenuParentId = model.scMenuParentId;
        input.badgeStyleClass = model.badgeStyleClass;
        input.badge = model.badge;
        input.claims = model.claims;
        input.languageCode = lang;
        input.icon = model.icon;
        input.isActive = model.isActive;
        input.label = model.label;
        input.link = model.link;
        input.order = model.order;
        input.userId = userId;


        return input;
    }




}
