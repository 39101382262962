import { Component } from '@angular/core';

@Component({
  selector: 'app-sc-person-full-detail',
  templateUrl: './sc-person-full-detail.component.html',
  styleUrls: ['./sc-person-full-detail.component.scss']
})
export class ScPersonFullDetailComponent {

}
