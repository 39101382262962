import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './auth/request.interceptor';


import { AuthenticationGuard } from './guards/authentication.guard';


import { AuthorizationGuard } from './guards/authorization.guard';
import { AppFooterComponent } from '../layout/footer/app.footer.component';
import { AppProfileComponent } from '../layout/topbar/app.profile.component';
import { DialogModule } from 'primeng/dialog';

import { ConstantsService } from './services/constants.service';

@NgModule({
    declarations: [ AppFooterComponent,AppProfileComponent],
    exports:[AppFooterComponent,AppProfileComponent],
    imports: [CommonModule, RouterModule,DialogModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        },
        AuthenticationGuard,
        AuthenticationGuard.guards,
        AuthorizationGuard,
        ConstantsService
    ]
})
export class CoreModule{

}
