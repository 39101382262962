<sf-loader></sf-loader>
<sf-notification></sf-notification>
<div class="p-grid">
    <div class="p-col-12" style="margin-top: -22px;">
        <p-table #dt [value]="list" dataKey="id" [paginator]="true" [rows]="15"
            [showCurrentPageReport]="true"
            [currentPageReportTemplate]="pageMessage"
            [rowsPerPageOptions]="[15,50,100]"
            [globalFilterFields]="['code','name']"
            styleClass="p-datatable-responsive-demo p-datatable-striped"  [showCurrentPageReport]="true">

            <ng-template pTemplate="caption">
                <div class="flex">
                    <button pButton pRipple icon="pi pi-plus" [label]="'button.workflowNew' | translate" class="p-button-primary p-mr-2" style="width: 150px;"
                    (click)="addItem()" *ngIf="flUpdate"></button>

                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input #filter pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"  [placeholder]="'search.workflow' | translate" />
                        <button pButton [label]="'button.clear' | translate" class="p-button-warning mr-2 ml-2" icon="pi pi-filter-slash" (click)="clear(dt, filter); filter.value = '';"></button>
                    </span>
                    <p-button icon="pi pi-refresh" (click)="getList()"></p-button>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 105px; text-align: center;"><span translate>label.actions</span></th>
                    <th pSortableColumn="code"><span translate>label.code</span> <p-sortIcon field="code"></p-sortIcon></th>
                    <th pSortableColumn="name"><span translate>label.description</span><p-sortIcon field="name"></p-sortIcon></th>
                    <th><span translate>label.icon</span></th>
                    <th style="width: 50px;"><span translate>label.color</span></th>
                    <th><span translate>label.active</span></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>
                        <p-button icon="pi pi-pencil" [styleClass]="classBtnUpd" [pTooltip]="'button.edit' | translate" (click)="updItem(item.id)" ></p-button>
                        <p-button icon="pi pi-trash" [styleClass]="classBtnDel" [pTooltip]="'button.delete' | translate"   (click)="delItem(item.id)" [disabled]="!flUpdate"></p-button>
                    </td>
                    <td>{{item.code}}</td>
                    <td>{{item.name}}</td>
                    <td><i class={{item.icon}}></i> &nbsp; {{item.icon}}</td>
                    <td><span [class]="'status-badge'" [style]="{'background-color':''+item.color+'', 'color': '#FFFFFF'}">{{item.color}}</span> </td>
                    <td><input type="checkbox"  id="isActive" readonly [(ngModel)]="item.isActive"></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

