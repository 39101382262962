<div class="stepsdemo-content">
    <p-card>
        <ng-template pTemplate="title"> <span translate class="font-bold">label.confirmation</span> </ng-template>
        <ng-template pTemplate="content">
            <div class="formgrid grid text-lg">

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="code" class="block mb-1 font-bold">
                        <span translate>label.shortName</span>
                    </label>
                    {{ step.code }}
                </div>

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="ein" class="block mb-1 font-bold">
                        <span translate>label.scCompanyEin</span>
                    </label>
                    {{ step.ein }}
                </div>

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="name" class="mb-1 font-bold block ">
                        <span translate>label.companyName</span>
                    </label>
                    {{ step.name }}

                </div>

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="fantasyName" class="mb-1 font-bold block ">
                        <span translate>label.fantasyName</span>
                    </label>
                    {{ step.fantasyName }}
                </div>

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="licensingPlan" class="block mb-1 font-bold">
                        <span translate>label.licensingPlan</span>
                    </label>
                    {{ step.paPlanName }}
                </div>

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="type" class="block mb-1 font-bold">
                        <span translate>label.type</span>
                    </label>
                    {{ step.scCompanyTypeName }}
                </div>

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="moduleAvailable" class="block mb-1 font-bold">
                        <span translate>label.moduleAvailable</span>
                    </label>
                    {{ modules }}
                </div>

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="config" class="block mb-1 font-bold">
                        <span translate>label.config</span>
                    </label>
                    {{ configs }}
                </div>

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="email" class="block mb-1 font-bold">
                        <span translate>label.email</span>
                    </label>
                    {{ step.userEmail }}
                </div>
                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="name" class="block mb-1 font-bold">
                        <span translate>label.name</span>
                    </label>
                    {{ step.userName }}
                </div>

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="access_profile" class="block mb-1 font-bold">
                        <span translate>label.access_profile</span>
                    </label>
                    {{ step.scRoleName }}
                </div>

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="phone_number" class="block mb-1 font-bold">
                        <span translate>label.phone_number</span>
                    </label>
                    {{ step.phone }}
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="grid grid-nogutter justify-content-between">
                <p-button [label]="'label.back' | translate" (onClick)="prevPage()" icon="pi pi-angle-left"></p-button>

                    
                <p-button [label]="'button.confirm' | translate" (onClick)="save()" class="p-button-success"  icon="pi pi-check" iconPos="right"></p-button>                
    
            </div>
        </ng-template>
    </p-card>
</div>
