<sf-loader></sf-loader>
<sf-notification></sf-notification>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="p-grid mt-2">
    <div class="p-col-12" style="margin-top: -22px;">
        <p-table #dt [value]="list" dataKey="id" [paginator]="true" [rows]="15"
                 [showCurrentPageReport]="true"
                 [currentPageReportTemplate]="pageMessage"
                 [rowsPerPageOptions]="[15,50,100]"
                 [globalFilterFields]="['code','name','stageName','statusName']"
                 styleClass="p-datatable-responsive-demo p-datatable-striped"  [showCurrentPageReport]="true">

            <ng-template pTemplate="caption">
                <div class="flex">
                    <button pButton pRipple icon="pi pi-plus" [label]="'button.serviceNew' | translate" class="p-button-success p-mr-2" style="width: 170px;"
                            (click)="addItem()" *ngIf="flUpdate"></button>

                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input #filter pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"  [placeholder]="'search.filter' | translate" />
                        <button pButton [label]="'button.clear' | translate" class="p-button-warning mr-2 ml-2" icon="pi pi-filter-slash" (click)="clear(dt, filter); filter.value = '';"></button>
                    </span>
                    <p-button icon="pi pi-refresh" (click)="getList()"></p-button>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 120px; text-align: center;" pSortableColumn="code">Atendimento / Senha <p-sortIcon field="code"></p-sortIcon></th>
                    <th pSortableColumn="name"><span translate>label.description</span><p-sortIcon field="name"></p-sortIcon></th>
                    <th style="width: 140px; text-align: center;"><span translate>label.openDate</span></th>
                    <th style="text-align: center;"><span translate>label.status</span></th>
                    <th ><span translate>label.client</span></th>
                    <th style="width: 100px; text-align: center;"><span translate>label.clientRating</span></th>
                    <th style="width: 140px; text-align: center;"><span translate>label.dueDate</span></th>
                    <th style="width: 100px; text-align: center;"><span translate>label.waitingTime</span></th>
                    <th><span translate>label.employee</span></th>
                    <th style="width: 100px; text-align: center;"><span translate>label.actions</span></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td  style="text-align: center;">{{item.code}}<br>{{item.ticket}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.createdDate | date: 'dd/MM/yy HH:mm'}}</td>
                    <td><span [class]="'status-badge'" [style]="{'background-color':''+item.stageColor+'', 'color': '#FFFFFF'}">{{item.stageName}} - {{item.statusName}}</span> </td>                    
                    <td>{{item.clientName}}</td>                    
                    <td style="text-align: center;"><p-rating [ngModel]="item.clientRating" [readonly]="true" [cancel]="false" [iconOnStyle]="{'color':'#4743fb'}" ></p-rating></td>
                    <td  style="text-align: center;">{{item.deadlineTime | date: 'HH:mm'}}</td>
                    <td>{{item.excutionTime}}</td>
                    <td>{{item.userName}}</td>
                    <td style="text-align: center;">
                        <p-button icon="pi pi-search" styleClass="p-button p-button-success p-m-1 mr-1" [pTooltip]="'button.detail' | translate" (click)="updItem(item.id)"  ></p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

