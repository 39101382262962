import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Message } from 'primeng/api';

type Severities = 'success' | 'info' | 'warn' | 'error';

@Injectable()
export class NotificationsService {
    toastNotificationChange: Subject<Object> = new Subject<Object>();
    growlNotificationChange: Subject<Object> = new Subject<Object>();
    messageNotificationChange: Subject<Object> = new Subject<Object>();

    private message: Message[] = [];

    messageNotifyNow() {
        this.messageNotificationChange.next(this.message);
        this.message = [];
    }

    notificationClear() {
        this.message = [];
        this.messageNotifyNow();
    }

    toastNotify(severity: Severities, summary: string, detail: string) {
        this.toastNotificationChange.next({ severity, summary, detail });
    }

    messageNotify(severity: Severities, summary: string, detail: string) {
        this.messageNotificationChange.next([{ severity, summary, detail }]);
    }

    addNotification(severity: Severities, summary: string, detail: string) {
        this.message.push({ severity, summary, detail });
    }

}
