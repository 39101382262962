import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DropDown } from 'src/app/shared/model/base-model';

@Component({
    selector: 'sf-dropdown-icon',
    templateUrl: './dropdown-icon.component.html',
    styleUrls: ['./dropdown-icon.component.scss']
})

export class DropdownIconComponent implements OnInit, OnChanges{

    @Input() icon: string;
    @Input() placeHolderLanguage: string;
    @Output() chosedIcon = new EventEmitter();

    public list: DropDown[];
    public selectedIcon: DropDown;

    ngOnInit(): void {
        this.getList();
        this.getSelected();
    }

    ngOnChanges(): void {
        this.getSelected();

    }

    feedback() {
        this.chosedIcon.emit(this.selectedIcon);
    }

    getSelected(){
        if (this.list !== null && this.list != undefined &&this.icon !== null){
            this.selectedIcon = this.list.find(x => x.id == this.icon);
        }
    }

    getList(){
        this.list = [
            { name: 'align-center', id: 'pi pi-align-center' },
            { name: 'align-justify', id: 'pi pi-align-justify' },
            { name: 'align-left', id: 'pi pi-align-left' },
            { name: 'align-right', id: 'pi pi-align-right' },
            { name: 'amazon', id: 'pi pi-amazon' },
            { name: 'android', id: 'pi pi-android' },
            { name: 'angle-double-down', id: 'pi pi-angle-double-down' },
            { name: 'angle-double-left', id: 'pi pi-angle-double-left' },
            { name: 'angle-double-right', id: 'pi pi-angle-double-right' },
            { name: 'angle-double-up', id: 'pi pi-angle-double-up' },
            { name: 'angle-down', id: 'pi pi-angle-down' },
            { name: 'angle-left', id: 'pi pi-angle-left' },
            { name: 'angle-right', id: 'pi pi-angle-right' },
            { name: 'angle-up', id: 'pi pi-angle-up' },
            { name: 'apple', id: 'pi pi-apple' },
            { name: 'arrow-circle-down', id: 'pi pi-arrow-circle-down' },
            { name: 'arrow-circle-left', id: 'pi pi-arrow-circle-left' },
            { name: 'arrow-circle-right', id: 'pi pi-arrow-circle-right' },
            { name: 'arrow-circle-up', id: 'pi pi-arrow-circle-up' },
            { name: 'arrow-down', id: 'pi pi-arrow-down' },
            { name: 'arrow-down-left', id: 'pi pi-arrow-down-left' },
            { name: 'arrow-down-right', id: 'pi pi-arrow-down-right' },
            { name: 'arrow-left', id: 'pi pi-arrow-left' },
            { name: 'arrow-right', id: 'pi pi-arrow-right' },
            { name: 'arrow-right-arrow-left', id: 'pi pi-arrow-right-arrow-left' },
            { name: 'arrow-up', id: 'pi pi-arrow-up' },
            { name: 'arrow-up-left', id: 'pi pi-arrow-up-left' },
            { name: 'arrow-up-right', id: 'pi pi-arrow-up-right' },
            { name: 'arrows-alt', id: 'pi pi-arrows-alt' },
            { name: 'arrows-h', id: 'pi pi-arrows-h' },
            { name: 'arrows-v', id: 'pi pi-arrows-v' },
            { name: 'at', id: 'pi pi-at' },
            { name: 'backward', id: 'pi pi-backward' },
            { name: 'ban', id: 'pi pi-ban' },
            { name: 'bars', id: 'pi pi-bars' },
            { name: 'bell', id: 'pi pi-bell' },
            { name: 'bitcoin', id: 'pi pi-bitcoin' },
            { name: 'bolt', id: 'pi pi-bolt' },
            { name: 'book', id: 'pi pi-book' },
            { name: 'bookmark', id: 'pi pi-bookmark' },
            { name: 'bookmark-fill', id: 'pi pi-bookmark-fill' },
            { name: 'box', id: 'pi pi-box' },
            { name: 'briefcase', id: 'pi pi-briefcase' },
            { name: 'building', id: 'pi pi-building' },
            { name: 'calculator', id: 'pi pi-calculator' },
            { name: 'calendar', id: 'pi pi-calendar' },
            { name: 'calendar-minus', id: 'pi pi-calendar-minus' },
            { name: 'calendar-plus', id: 'pi pi-calendar-plus' },
            { name: 'calendar-times', id: 'pi pi-calendar-times' },
            { name: 'camera', id: 'pi pi-camera' },
            { name: 'car', id: 'pi pi-car' },
            { name: 'caret-down', id: 'pi pi-caret-down' },
            { name: 'caret-left', id: 'pi pi-caret-left' },
            { name: 'caret-right', id: 'pi pi-caret-right' },
            { name: 'caret-up', id: 'pi pi-caret-up' },
            { name: 'cart-plus', id: 'pi pi-cart-plus' },
            { name: 'chart-bar', id: 'pi pi-chart-bar' },
            { name: 'chart-line', id: 'pi pi-chart-line' },
            { name: 'chart-pie', id: 'pi pi-chart-pie' },
            { name: 'check', id: 'pi pi-check' },
            { name: 'check-circle', id: 'pi pi-check-circle' },
            { name: 'check-square', id: 'pi pi-check-square' },
            { name: 'chevron-circle-down', id: 'pi pi-chevron-circle-down' },
            { name: 'chevron-circle-left', id: 'pi pi-chevron-circle-left' },
            { name: 'chevron-circle-right', id: 'pi pi-chevron-circle-right' },
            { name: 'chevron-circle-up', id: 'pi pi-chevron-circle-up' },
            { name: 'chevron-down', id: 'pi pi-chevron-down' },
            { name: 'chevron-left', id: 'pi pi-chevron-left' },
            { name: 'chevron-right', id: 'pi pi-chevron-right' },
            { name: 'chevron-up', id: 'pi pi-chevron-up' },
            { name: 'circle', id: 'pi pi-circle' },
            { name: 'circle-fill', id: 'pi pi-circle-fill' },
            { name: 'clock', id: 'pi pi-clock' },
            { name: 'clone', id: 'pi pi-clone' },
            { name: 'cloud', id: 'pi pi-cloud' },
            { name: 'cloud-download', id: 'pi pi-cloud-download' },
            { name: 'cloud-upload', id: 'pi pi-cloud-upload' },
            { name: 'code', id: 'pi pi-code' },
            { name: 'cog', id: 'pi pi-cog' },
            { name: 'comment', id: 'pi pi-comment' },
            { name: 'comments', id: 'pi pi-comments' },
            { name: 'compass', id: 'pi pi-compass' },
            { name: 'copy', id: 'pi pi-copy' },
            { name: 'credit-card', id: 'pi pi-credit-card' },
            { name: 'database', id: 'pi pi-database' },
            { name: 'delete-left', id: 'pi pi-delete-left' },
            { name: 'desktop', id: 'pi pi-desktop' },
            { name: 'directions', id: 'pi pi-directions' },
            { name: 'directions-alt', id: 'pi pi-directions-alt' },
            { name: 'discord', id: 'pi pi-discord' },
            { name: 'dollar', id: 'pi pi-dollar' },
            { name: 'download', id: 'pi pi-download' },
            { name: 'eject', id: 'pi pi-eject' },
            { name: 'ellipsis-h', id: 'pi pi-ellipsis-h' },
            { name: 'ellipsis-v', id: 'pi pi-ellipsis-v' },
            { name: 'envelope', id: 'pi pi-envelope' },
            { name: 'eraser', id: 'pi pi-eraser' },
            { name: 'euro', id: 'pi pi-euro' },
            { name: 'exclamation-circle', id: 'pi pi-exclamation-circle' },
            { name: 'exclamation-triangle', id: 'pi pi-exclamation-triangle' },
            { name: 'external-link', id: 'pi pi-external-link' },
            { name: 'eye', id: 'pi pi-eye' },
            { name: 'eye-slash', id: 'pi pi-eye-slash' },
            { name: 'facebook', id: 'pi pi-facebook' },
            { name: 'fast-backward', id: 'pi pi-fast-backward' },
            { name: 'fast-forward', id: 'pi pi-fast-forward' },
            { name: 'file', id: 'pi pi-file' },
            { name: 'file-edit', id: 'pi pi-file-edit' },
            { name: 'file-excel', id: 'pi pi-file-excel' },
            { name: 'file-export', id: 'pi pi-file-export' },
            { name: 'file-import', id: 'pi pi-file-import' },
            { name: 'file-pdf', id: 'pi pi-file-pdf' },
            { name: 'file-word', id: 'pi pi-file-word' },
            { name: 'filter', id: 'pi pi-filter' },
            { name: 'filter-fill', id: 'pi pi-filter-fill' },
            { name: 'filter-slash', id: 'pi pi-filter-slash' },
            { name: 'flag', id: 'pi pi-flag' },
            { name: 'flag-fill', id: 'pi pi-flag-fill' },
            { name: 'folder', id: 'pi pi-folder' },
            { name: 'folder-open', id: 'pi pi-folder-open' },
            { name: 'forward', id: 'pi pi-forward' },
            { name: 'gift', id: 'pi pi-gift' },
            { name: 'github', id: 'pi pi-github' },
            { name: 'globe', id: 'pi pi-globe' },
            { name: 'google', id: 'pi pi-google' },
            { name: 'hashtag', id: 'pi pi-hashtag' },
            { name: 'heart', id: 'pi pi-heart' },
            { name: 'heart-fill', id: 'pi pi-heart-fill' },
            { name: 'history', id: 'pi pi-history' },
            { name: 'home', id: 'pi pi-home' },
            { name: 'hourglass', id: 'pi pi-hourglass' },
            { name: 'id-card', id: 'pi pi-id-card' },
            { name: 'image', id: 'pi pi-image' },
            { name: 'images', id: 'pi pi-images' },
            { name: 'inbox', id: 'pi pi-inbox' },
            { name: 'info', id: 'pi pi-info' },
            { name: 'info-circle', id: 'pi pi-info-circle' },
            { name: 'instagram', id: 'pi pi-instagram' },
            { name: 'key', id: 'pi pi-key' },
            { name: 'language', id: 'pi pi-language' },
            { name: 'link', id: 'pi pi-link' },
            { name: 'linkedin', id: 'pi pi-linkedin' },
            { name: 'list', id: 'pi pi-list' },
            { name: 'lock', id: 'pi pi-lock' },
            { name: 'lock-open', id: 'pi pi-lock-open' },
            { name: 'map', id: 'pi pi-map' },
            { name: 'map-marker', id: 'pi pi-map-marker' },
            { name: 'megaphone', id: 'pi pi-megaphone' },
            { name: 'microphone', id: 'pi pi-microphone' },
            { name: 'microsoft', id: 'pi pi-microsoft' },
            { name: 'minus', id: 'pi pi-minus' },
            { name: 'minus-circle', id: 'pi pi-minus-circle' },
            { name: 'mobile', id: 'pi pi-mobile' },
            { name: 'money-bill', id: 'pi pi-money-bill' },
            { name: 'moon', id: 'pi pi-moon' },
            { name: 'palette', id: 'pi pi-palette' },
            { name: 'paperclip', id: 'pi pi-paperclip' },
            { name: 'pause', id: 'pi pi-pause' },
            { name: 'paypal', id: 'pi pi-paypal' },
            { name: 'pencil', id: 'pi pi-pencil' },
            { name: 'percentage', id: 'pi pi-percentage' },
            { name: 'phone', id: 'pi pi-phone' },
            { name: 'play', id: 'pi pi-play' },
            { name: 'plus', id: 'pi pi-plus' },
            { name: 'plus-circle', id: 'pi pi-plus-circle' },
            { name: 'pound', id: 'pi pi-pound' },
            { name: 'power-off', id: 'pi pi-power-off' },
            { name: 'prime', id: 'pi pi-prime' },
            { name: 'print', id: 'pi pi-print' },
            { name: 'qrcode', id: 'pi pi-qrcode' },
            { name: 'question', id: 'pi pi-question' },
            { name: 'question-circle', id: 'pi pi-question-circle' },
            { name: 'reddit', id: 'pi pi-reddit' },
            { name: 'refresh', id: 'pi pi-refresh' },
            { name: 'replay', id: 'pi pi-replay' },
            { name: 'reply', id: 'pi pi-reply' },
            { name: 'save', id: 'pi pi-save' },
            { name: 'search', id: 'pi pi-search' },
            { name: 'search-minus', id: 'pi pi-search-minus' },
            { name: 'search-plus', id: 'pi pi-search-plus' },
            { name: 'send', id: 'pi pi-send' },
            { name: 'server', id: 'pi pi-server' },
            { name: 'share-alt', id: 'pi pi-share-alt' },
            { name: 'shield', id: 'pi pi-shield' },
            { name: 'shopping-bag', id: 'pi pi-shopping-bag' },
            { name: 'shopping-cart', id: 'pi pi-shopping-cart' },
            { name: 'sign-in', id: 'pi pi-sign-in' },
            { name: 'sign-out', id: 'pi pi-sign-out' },
            { name: 'sitemap', id: 'pi pi-sitemap' },
            { name: 'slack', id: 'pi pi-slack' },
            { name: 'sliders-h', id: 'pi pi-sliders-h' },
            { name: 'sliders-v', id: 'pi pi-sliders-v' },
            { name: 'sort', id: 'pi pi-sort' },
            { name: 'sort-alpha-down', id: 'pi pi-sort-alpha-down' },
            { name: 'sort-alpha-down-alt', id: 'pi pi-sort-alpha-down-alt' },
            { name: 'sort-alpha-up', id: 'pi pi-sort-alpha-up' },
            { name: 'sort-alpha-up-alt', id: 'pi pi-sort-alpha-up-alt' },
            { name: 'sort-alt', id: 'pi pi-sort-alt' },
            { name: 'sort-alt-slash', id: 'pi pi-sort-alt-slash' },
            { name: 'sort-amount-down', id: 'pi pi-sort-amount-down' },
            { name: 'sort-amount-down-alt', id: 'pi pi-sort-amount-down-alt' },
            { name: 'sort-amount-up', id: 'pi pi-sort-amount-up' },
            { name: 'sort-amount-up-alt', id: 'pi pi-sort-amount-up-alt' },
            { name: 'sort-down', id: 'pi pi-sort-down' },
            { name: 'sort-numeric-down', id: 'pi pi-sort-numeric-down' },
            { name: 'sort-numeric-down-alt', id: 'pi pi-sort-numeric-down-alt' },
            { name: 'sort-numeric-up', id: 'pi pi-sort-numeric-up' },
            { name: 'sort-numeric-up-alt', id: 'pi pi-sort-numeric-up-alt' },
            { name: 'sort-up', id: 'pi pi-sort-up' },
            { name: 'spinner', id: 'pi pi-spinner' },
            { name: 'star', id: 'pi pi-star' },
            { name: 'star-fill', id: 'pi pi-star-fill' },
            { name: 'step-backward', id: 'pi pi-step-backward' },
            { name: 'step-backward-alt', id: 'pi pi-step-backward-alt' },
            { name: 'step-forward', id: 'pi pi-step-forward' },
            { name: 'step-forward-alt', id: 'pi pi-step-forward-alt' },
            { name: 'stop', id: 'pi pi-stop' },
            { name: 'stop-circle', id: 'pi pi-stop-circle' },
            { name: 'stopwatch', id: 'pi pi-stopwatch' },
            { name: 'sun', id: 'pi pi-sun' },
            { name: 'sync', id: 'pi pi-sync' },
            { name: 'table', id: 'pi pi-table' },
            { name: 'tablet', id: 'pi pi-tablet' },
            { name: 'tag', id: 'pi pi-tag' },
            { name: 'tags', id: 'pi pi-tags' },
            { name: 'telegram', id: 'pi pi-telegram' },
            { name: 'th-large', id: 'pi pi-th-large' },
            { name: 'thumbs-down', id: 'pi pi-thumbs-down' },
            { name: 'thumbs-down-fill', id: 'pi pi-thumbs-down-fill' },
            { name: 'thumbs-up', id: 'pi pi-thumbs-up' },
            { name: 'thumbs-up-fill', id: 'pi pi-thumbs-up-fill' },
            { name: 'ticket', id: 'pi pi-ticket' },
            { name: 'times', id: 'pi pi-times' },
            { name: 'times-circle', id: 'pi pi-times-circle' },
            { name: 'trash', id: 'pi pi-trash' },
            { name: 'truck', id: 'pi pi-truck' },
            { name: 'twitter', id: 'pi pi-twitter' },
            { name: 'undo', id: 'pi pi-undo' },
            { name: 'unlock', id: 'pi pi-unlock' },
            { name: 'upload', id: 'pi pi-upload' },
            { name: 'user', id: 'pi pi-user' },
            { name: 'user-edit', id: 'pi pi-user-edit' },
            { name: 'user-minus', id: 'pi pi-user-minus' },
            { name: 'user-plus', id: 'pi pi-user-plus' },
            { name: 'users', id: 'pi pi-users' },
            { name: 'verified', id: 'pi pi-verified' },
            { name: 'video', id: 'pi pi-video' },
            { name: 'vimeo', id: 'pi pi-vimeo' },
            { name: 'volume-down', id: 'pi pi-volume-down' },
            { name: 'volume-off', id: 'pi pi-volume-off' },
            { name: 'volume-up', id: 'pi pi-volume-up' },
            { name: 'wallet', id: 'pi pi-wallet' },
            { name: 'whatsapp', id: 'pi pi-whatsapp' },
            { name: 'wifi', id: 'pi pi-wifi' },
            { name: 'window-maximize', id: 'pi pi-window-maximize' },
            { name: 'window-minimize', id: 'pi pi-window-minimize' },
            { name: 'wrench', id: 'pi pi-wrench' },
            { name: 'youtube', id: 'pi pi-youtube' }
        ];
    }
}
