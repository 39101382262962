import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

import { config } from 'src/configs/config';

const KEY = config.aplicacao + '-authToken';

@Injectable({providedIn:'root'})
export class TokenService{

    hasToken(){
        return !!this.getToken();
    }

    setToken(token: string){
        window.localStorage.setItem(KEY,token)
    }

    getToken(){
        return window.localStorage.getItem(KEY);
    }

    removeToken(){
        window.localStorage.removeItem(KEY);
    }

    getTokenExpirationDate(): Date {
        const decoded = jwtDecode(this.getToken());

        if (decoded.exp === undefined) return null;

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
      }

    isTokenExpired(token?: string): boolean {
        if(!token) token = this.getToken();
        if(!token) return true;

        const date = this.getTokenExpirationDate();
        if(date === undefined) return false;
        return !(date.valueOf() > new Date().valueOf());
      }
}
