<div class="layout-footer">
    <div class="grid">
        <div class="col-12 md:col-6 footer-left">

            <div class="grid">
                <div class="col11">
                    <img src="../../../assets/layout/images/sf-logoTabellio.png" height="50">
                </div>
                <div class="col-11 mt-3">
                    <span class="fontFooterTabellioWhite">Tabellio - A Plataforma Tecnológica para a Nova Era dos Cartórios</span>
                </div>
            </div>

        </div>
        <div class="col-12 md:col-6 footer-right">
            <div style="margin-top: -20px;">
                <a href="#" style="color: #00a2f5;">
                    <i class="pi pi-twitter"></i>
                </a>
                <a href="#" style="color: #ee3c76;">
                    <i class="pi pi-instagram"></i>
                </a>
                <a href="#" style="color: #0077b7;">
                    <i class="pi pi-linkedin"></i>
                </a>
            </div>
            <div>
                <a href="https://www.serviceflow.com.br">
                    <span   span class="fontMenuFooterWhite">powered by</span>
                    <span class="fontMenuFooterOrange">serviceflow.com.br</span>
                </a>
            </div>
        </div>
    </div>
</div>

