import { ScClaimList } from './../../sc-claim/sc-claim.model';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { ScRole, ScRoleOutput } from '../sc-role.model';
import { ScRoleService } from '../sc-role.service';
import { DropDown } from 'src/app/shared/model/base-model';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';






@Component({
    selector: 'sc-role-grid',
    templateUrl: './sc-role-grid.component.html',
    styleUrls: ['./sc-role-grid.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})

export class ScRoleGridComponent implements OnInit {

    public list: ScRole[] = [];
    public listRoleType: DropDown[] = [];
    public id: string;
    public item: ScRoleOutput;
    public userId: string = null;
    public language: string = "pt-BR";

    public selectedClaims: DropDown[] = [];

    public submitted: boolean;
    public displayMsg: boolean = false;
    public displayDialog: boolean;
    public dialogHeader: string;

    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-warning p-m-1 mr-1";

    public flUpdate: boolean = true;
    public isRoleTypeDisabled: boolean = true;

    constructor(  private roleService: ScRoleService,
                    private messageService: MessageService,
                    private confirmationService: ConfirmationService,
                    private translateService: TranslateService,
                    private translateConfigService: TranslateConfigService,
                    private notificationsService: NotificationsService,
                    private breadcrumbService: BreadcrumbService,
                    private userTokenService: UserTokenService)
        { }

    ngOnInit(): void {


        this.language = this.translateConfigService.getLanguage();
        this.userId = this.userTokenService.getUserId();

        this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN']);

        if (this.flUpdate == false)
        {
            this.classBtnUpd = "p-button p-button-secondary p-m-1 mr-1";
            this.classBtnDel = this.classBtnUpd;
        }

        this.getList();

        this.getRoleTypeDropDownList();
    }

    getList() {
        this.roleService.getList(this.language).subscribe(
            {
            next: (res) => {
                if (res){
                    this.list = res.data;
                }


                this.translate();
            },
            error: (err) => {
                console.log(`ScRoleGrid - getLista : ${err}.`);
            }
        });
    }

    getItem(id) {
        this.roleService.getItem(id).subscribe(
        {
            next: (res) =>
            {
                if (res) {
                    this.item = res.data;
                }
            },
            error: (err) => {
                console.log(`ScRoleGrid - getItem : ${err}.`);
            }
        });
    }

    getRoleTypeDropDownList(){
        this.roleService.getRoleTypeDropDownList().subscribe(
            {
                next: (res) =>
                {
                    if (res) {
                        this.listRoleType = res.data;
                    }
                },
                error: (err) => {
                    console.log(`ScRoleGrid - getRoleTypeDropDownList : ${err}.`);
                }
            });
    }





    addItem() {
        this.item = {};
        this.submitted = false;
        this.displayDialog = true;
        this.isRoleTypeDisabled = false;
    }

    updItem(item: ScRoleOutput) {
        this.item = {...item};
        // this.isRoleTypeDisabled = true;
        // if (this.item.scRoleTypeId == undefined || this.item.scRoleTypeId == null || this.item.scRoleTypeId.length == 0)
            this.isRoleTypeDisabled = false;

        if (this.item.claims == undefined || this.item.claims == null || this.item.claims.length == 0)
            this.selectedClaims = [];
        else
            this.selectedClaims = this.item.claims;


        this.displayDialog = true;
    }

    delItem(item: ScRoleOutput) {

        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_delete')+' ' + item.name + '?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.roleService
                .delItem(item.id)
                .subscribe(
                {
                    next: () =>
                    {
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.sc-role'), this.translateService.instant('message.record_deleted'));
                        this.getList();
                    },
                    error: (err) => {
                        this.notificationsService.toastNotify('warn', this.translateService.instant('title.sc-role'), this.translateService.instant('api_response.ERROR'));
                        console.log(`ScRoleGrid - delItem : ${err}.`);
                    }
                });
            }
      });
    }

    saveItem() {
        this.submitted = true;
        //console.log(this.item);
        if (this.item.code.trim()) {
            if (this.item.id) {

            this.roleService
            .updItem(this.item, this.userId, this.language)
            .subscribe(
                {
                next: () => {
                    this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_updated'), life: 3000});
                    this.getList();
                },
                error: (err) => console.log(err)
            });

            } else {
                this.roleService
                    .addItem(this.item, this.userId, this.language)
                    .subscribe(
                    {
                    next: () => {
                        this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_inserted'), life: 3000});
                    this.getList();
                    },
                    error: (err) => console.log(err)
                    });
            }

            this.list = [...this.list]
            this.displayDialog = false;
            this.item = {};
        }
    }

    translate()
    {
        const labAccessControl = this.translateService.instant('label.access_control')+" ";
        const labRole = " "+this.translateService.instant('label.role');
        this.breadcrumbService.setItems([{label: labAccessControl},{label: labRole, routerLink: ['role']} ]);

        this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')

        this.dialogHeader = this.translateService.instant('title.sc_role_edit');

    }

    hideDialog() {
        this.displayDialog = false;
        this.submitted = false;
    }



    clear(table: Table) {
        table.clear();
    }

    reciverFeedbackIcon(icon: DropDown) {
        this.item.icon = icon.id;
    }

    reciverFeedbackColor(color: string) {
        this.item.color = color;
    }

    reciverFeedbackRoleType(id: string){
        this.item.scRoleTypeId = id;
    }

    reciverFeedbackClaim(select: DropDown[])
    {
        this.selectedClaims = select;
        this.item.claims = this.selectedClaims;
    }
}
