import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateFormatPipe'
})
export class DateFormatPipe implements PipeTransform {
    transform(value:any):any {
        if (value) {
            var datePipe = new DatePipe("pt-BR");
            value = datePipe.transform(value, 'dd/MM/yyyy');
            return value;
        }
    }
}