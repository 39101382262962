import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { WoOrderAtendimentoImg, SfcWoOrderOrderServiceOutput, SfcWoOrderOrderServiceItem } from './wo-order-cartorio.model';




@Injectable({
    providedIn: 'root'
})

export class WoOrderCartorioService {

    private environmentUrl: string = '';
    public reciverFeedbackChanged = new EventEmitter<any>();
    public reciverFeedbackValueChanged = new EventEmitter<any>();
    public dataChanged = new EventEmitter<any>();


    constructor(private httpClient: HttpClient) {
        //this.environmentUrl = environment.ApiCore+'/Notorial/WoOrderService/';
        this.environmentUrl = environment.ApiCore + '/OrderService/OrderOrderService';
    }

    //send data to component
    sendData(data: any) {
        this.dataChanged.emit(data)
    }

    //recive data from component
    reciverFeedback(data: any) {
        this.reciverFeedbackChanged.emit(data)
    }

    //receve data from component
    reciverFeedbackValue(data: any) {
        this.reciverFeedbackValueChanged.emit(data)
    }




    getItem(id: string): Observable<SfcWoOrderOrderServiceOutput> {
        let url = this.environmentUrl + '/' + id;
        return this.httpClient.get<SfcWoOrderOrderServiceOutput>(url);
    }

    getList(): Observable<SfcWoOrderOrderServiceOutput> {
        let url = this.environmentUrl;
        return this.httpClient.get<SfcWoOrderOrderServiceOutput>(url);
    }

    addItem(model: SfcWoOrderOrderServiceOutput) : Observable<SfcWoOrderOrderServiceItem> {
        let url = this.environmentUrl;
        console.log(url);
        return this.httpClient.post<SfcWoOrderOrderServiceItem>(url, model);
    }

    updItem(model: SfcWoOrderOrderServiceOutput) {
        let url = this.environmentUrl + '/' + model.id;
        console.log(url);
        return this.httpClient.put(url, model);
    }

    delItem(id: string) {
        let url = this.environmentUrl + '/' + id;
        return this.httpClient.delete(url);
    }





    //======================================================
    //================== service ===========================
    //======================================================

    getServiceItem(id: string): Observable<WoOrderAtendimentoImg> {
        let url = this.environmentUrl + "Service/" + id;
        return this.httpClient.get<WoOrderAtendimentoImg>(url);
    }

    getServiceList(): Observable<WoOrderAtendimentoImg> {
        let url = this.environmentUrl + "Service/";
        return this.httpClient.get<WoOrderAtendimentoImg>(url);
    }

    addServiceItem(model: WoOrderAtendimentoImg, userId: string, language: string) {
        let url = this.environmentUrl + "Service/";
        return this.httpClient.post(url, model);
    }

    updServiceItem(model: WoOrderAtendimentoImg, userId: string, language: string) {
        let url = this.environmentUrl + "Service/" + model.id;
        return this.httpClient.put(url, model);
    }

    cancelServiceItem(id: string) {
        let url = this.environmentUrl + "Service/" + id;
        return this.httpClient.delete(url);
    }

    getServiceStatusTimeline(id: string, language: string): Observable<WoOrderAtendimentoImg> {
        let url = this.environmentUrl + 'status/timeline?language=' + language + '&search=' + id;


        return this.httpClient.get<WoOrderAtendimentoImg>(url);
    }

    updServiceStatus(id: string, woOrderStatusId: string, obs: string) {
        let url = this.environmentUrl + "Service/UpdateStatus/" + id;

        var input: WoOrderAtendimentoImg = {};
        input.id = id;
        //input.statusDate = statusDate;

        return this.httpClient.put(url, input);
    }

    //======================================================
    //======== WoOrderOrderServiceScPersonFile =============
    //======================================================
    addItemWoOrderOrderServiceScPersonFile(woOrderOrderServiceId: string, scPersonFileId: string) {
        const params = {
            woOrderOrderServiceId: woOrderOrderServiceId,
            scPersonFileId: scPersonFileId
        };

        let url = this.environmentUrl + "/ScPersonFile";
        return this.httpClient.post(url, params);
    }

    delItemWoOrderOrderServiceScPersonFile(woOrderOrderServiceId: string, scPersonFileId: string) {
        let url = this.environmentUrl + '/ScPersonFile/' + woOrderOrderServiceId + "/" + scPersonFileId;
        return this.httpClient.delete(url);
    }
}