import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ScConfigGridComponent } from "./sc-config-grid/sc-config-grid.component";
import { FormsModule } from "@angular/forms";
import { NotificationsModule } from "src/app/shared/notifications/notifications.module";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { TranslateModule } from "@ngx-translate/core";
import { MessageModule } from "primeng/message";
import { TableModule } from "primeng/table";
import { TooltipModule } from "primeng/tooltip";
import { InputMaskModule } from "primeng/inputmask";
import { SfDropDownModule } from "src/app/shared/components/dropdown/dropdown.module";
import { DialogModule } from "primeng/dialog";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DropdownModule } from "primeng/dropdown";
import { ColorPickerModule } from "primeng/colorpicker";
import { LoaderModule } from "src/app/shared/components/loader/loader.module";
import { ScConfigListMultiComponent } from "./sc-config-list-multi/sc-config-list-multi.component";
import { ScConfigDataViewComponent } from "./sc-config-data-view/sc-config-data-view.component";
import { ListboxModule } from "primeng/listbox";
import { DataViewModule } from 'primeng/dataview';
import { CheckboxModule } from 'primeng/checkbox';


@NgModule({
    declarations: [ScConfigGridComponent, ScConfigListMultiComponent, ScConfigDataViewComponent],
    exports: [ScConfigGridComponent, ScConfigListMultiComponent, ScConfigDataViewComponent],
    imports: [
        CommonModule,
        FormsModule,
        NotificationsModule,
        ButtonModule,
        InputTextModule,
        TranslateModule,
        MessageModule,
        TableModule,
        TooltipModule,
        InputMaskModule,
        SfDropDownModule,
        DialogModule,
        ConfirmDialogModule,
        DropdownModule,
        ColorPickerModule,
        LoaderModule,
        ListboxModule,
        DataViewModule,
        CheckboxModule,

    ]
})
export class ScConfigModule {}
