import { Table } from 'primeng/table';
import { BreadcrumbService } from './../../../../layout/topbar/app.breadcrumb.service';
import { NotificationsService } from './../../../../shared/notifications/notifications.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ConfirmationService, MessageService } from 'primeng/api';
import { TranslateConfigService } from  '../../../../core/services/translate-config.service';
import { UserTokenService } from '../../../../core/services/user-token.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConstantsService } from 'src/app/core/services/constants.service';
import { DropDown } from 'src/app/shared/model/base-model';
import { UserToken } from 'src/app/core/auth.model';
import { WoOrderServiceService } from '../wo-order-service.service';
import { Observable } from 'rxjs';
import { WoOrderServiceOutput } from '../wo-order-service.model';
import { WoOrderServiceTemplateService } from '../../wo-order-service-template/wo-order-service-template.service';

@Component({
    selector: 'wo-order-service-grid',
    templateUrl: './wo-order-service-grid.component.html',
    styleUrls: ['./wo-order-service-grid.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})
export class WoOrderServiceGridComponent implements OnInit {

    public user$: Observable<UserToken>;
    public user?: UserToken;
    public list: WoOrderServiceOutput[] = [];
    public item: WoOrderServiceOutput = {};
    public OrderServiceTemplateList: DropDown[] = [];

    public language: string = "pt-BR";
    public pageMessage: string = "";
    public displayDialog: boolean = false;
    public submitted: boolean = false;
    public canUpdate: boolean = false;
    public isLoading: boolean = true;
    public isCartorio: boolean = false;
    public dialogHeader: string = "";

    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-warning p-m-1 mr-1";
    public showValueB: boolean = false;
    public showTaxA: boolean = true;
    public showTaxB: boolean = false;
    public showRange: boolean = true;
    public labelUnitValueA: string = "";
    public labelUnitValueB: string = "";
    public labelUnitValueTaxA: string = "";
    public labelUnitValueTaxB: string = "";
    public TotalRangeValueFrom: string = "";
    public TotalRangeValueTo: string = "";



    constructor(private orderServiceService: WoOrderServiceService,
                private orderServiceTemplateService: WoOrderServiceTemplateService,
                private confirmationService: ConfirmationService,
                private constantsService: ConstantsService,
                private messageService: MessageService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService,
                private breadcrumbService: BreadcrumbService,
                private router: Router,
                private userTokenService: UserTokenService) { }

    ngOnInit(): void {
        this.isLoading = true;
        this.language = this.translateConfigService.getLanguage();
        this.user$ = this.userTokenService.getUser();

        this.user$.subscribe({
            next: (data) => { this.user = data; }
            });""

        this.isCartorio =  this.user.ScCompanyConfig.includes("M_SFC_NOTAS");
        this.canUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN', 'SF_SELLER', 'WO_ORDER_SERVICE_U']);

        this.getOrderServiceTemplateList()
        this.getList();
        this.translate();

        if (this.canUpdate == false)
        {
            this.classBtnDel = "p-button p-button-secondary p-m-1 mr-1";
        }

    }


    getList() {
        this.orderServiceService.getList().subscribe(
        {
            next: (res) => {
                if (res)
                    this.list = res.data;
                this.isLoading = false;
            },
            error: (err) => {
                this.messageError(this.translateService.instant('api_response.ERROR'));
                console.log(`WoOrderServiceGrid - getList : ${err}.`);
            }
        });

    }

    getOrderServiceTemplateList() {
        this.orderServiceTemplateService.getDropDownList().subscribe(
        {
            next: (res) => {
                if (res)
                    this.OrderServiceTemplateList = res.data;

            },
            error: (err) => {
                this.messageError(this.translateService.instant('api_response.ERROR'));
                console.log(`WoOrderServiceGrid - getOrderServiceTemplateList : ${err}.`);
            }
        });

    }

    addItem() {
        this.item = {};
        this.displayDialog = true;
        this.submitted = false;
        console.log(this.canUpdate);
    }

    updItem(item: WoOrderServiceOutput) {
        this.item = {...item};
        this.displayDialog = true;
        this.submitted = false;
        console.log(this.canUpdate);
    }

    delItem(item: WoOrderServiceOutput) {
        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_delete')+' ' + item.name + '?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.orderServiceService
                .delItem(item.id)
                .subscribe(
                {
                    next: () =>
                    {
                        this.messageSuccess(this.translateService.instant('message.record_deleted'));
                        this.getList();
                    },
                    error: (err) => {
                        this.messageError(this.translateService.instant('api_response.ERROR'));
                        console.log(`WoOrderServiceGrid - delItem : ${err}.`);
                    }
                });
            }
      });
    }

    saveItem()
    {
        this.submitted = true;
        if (this.item.code.trim()) {
            if (this.item.id) {

            this.orderServiceService
            .updItem(this.item)
            .subscribe(
                {
                next: () => {
                    this.messageSuccess(this.translateService.instant('message.record_updated'));
                    this.getList();
                },
                error: (err) => {
                    this.messageError(this.translateService.instant('api_response.ERROR'));
                    console.log(`WoOrderServiceTemplateGrid - updItem : ${err}.`);
                }
            });

            } else {
                this.orderServiceService
                    .addItem(this.item)
                    .subscribe(
                    {
                    next: () => {
                        this.messageSuccess(this.translateService.instant('message.record_inserted'));
                        this.getList();
                    },
                    error: (err) => {
                        this.messageError(this.translateService.instant('api_response.ERROR'));
                        console.log(`WoOrderServiceTemplateGrid - updItem : ${err}.`);
                    }
                    });
            }

            this.list = [...this.list]
            this.displayDialog = false;
            this.submitted = false;
            this.item = {};
        }
    }

    reciverFeedbackTemplate(event){
        {
            if (event != null && event !== undefined) {
                this.item.woOrderServiceTemplateId = event;
            }
        }
    }

    hideDialog() {
        this.displayDialog = false;
        this.submitted = false;
    }

    translate(){
        const sleep = (ms) => new Promise(r => setTimeout(r, ms));

        (async () => {
            await sleep(100);
            const labA = this.translateService.instant('label.services')+" ";
            const labB = " "+this.translateService.instant('label.service');
            this.breadcrumbService.setItems([{label: labA}, {label: labB, routerLink: ['order-service']} ]);
            this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries');
            this.dialogHeader = this.translateService.instant('title.woOrderServiceEdit');

            if (this.isCartorio){
                this.showValueB = this.showTaxA = this.showTaxB = this.showRange = true;
                this.labelUnitValueA = "Recompe";
                this.labelUnitValueB = "Emolumentos";
                this.labelUnitValueTaxA = "Fiscalização";
                this.labelUnitValueTaxB = "ISS";
            }
            else{
                this.showValueB = (this.user.ScCompanyConfig.includes("UNIT_VALUE_B"));
                this.showTaxA = (this.user.ScCompanyConfig.includes("UNIT_VALUE_TAX_A"));
                this.showTaxB = (this.user.ScCompanyConfig.includes("UNIT_VALUE_TAX_B"));
                this.showRange = (this.user.ScCompanyConfig.includes("RANGE_VALUE"));

                this.labelUnitValueA = this.translateService.instant('label.unitValue');
                this.labelUnitValueB = this.translateService.instant('label.unitValueAux');
                this.labelUnitValueTaxA = this.translateService.instant('label.unitTaxValue');
                this.labelUnitValueTaxB = this.translateService.instant('label.unitTaxValueAux');
            }

        })();
    }

    clear(table: Table, filter: any) {
        table.clear();
        filter.Text = "";
    }

    messageSuccess(message: string){
        this.messageService.add({severity: this.constantsService.SEVERITY_SUCCESS, summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 3000});
    }

    messageError(message: string){
        this.messageService.add({severity: this.constantsService.SEVERITY_WARNING, summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 3000});
    }
}


