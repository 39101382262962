import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import localePT from '@angular/common/locales/pt';
import localeExtraPT from '@angular/common/locales/extra/pt';


import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { RatingModule } from 'primeng/rating';


import { AuthService } from 'src/app/core/auth/auth.service';
import { AuthorizationGuard } from 'src/app/core/guards/authorization.guard';

import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';

import { SfDropDownModule } from 'src/app/shared/components/dropdown/dropdown.module';
import { WoOrderPaymentComponent } from './wo-order-payment.component';
import { WoOrderService } from '../../wo-order.service';
import { WoOrderModule } from '../../wo-order.module';
import { WoOrderPaymentSummaryComponent } from './wo-order-payment-summary/wo-order-payment-summary.component';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { WoOrderPaymentService } from './wo-order-payment.service';
import { WoOrderServiceService } from 'src/app/pages/wo-order-service/wo-order-service/wo-order-service.service';


registerLocaleData(localePT, 'pt', localeExtraPT);

@NgModule({
    declarations: [WoOrderPaymentComponent, WoOrderPaymentSummaryComponent ],
    exports: [WoOrderPaymentComponent, WoOrderPaymentSummaryComponent ],
    imports: [FormsModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        ButtonModule,
        InputTextModule,
        TranslateModule,
        TableModule,
        TooltipModule,
        ConfirmDialogModule,
        LoaderModule,
        DialogModule,
        NotificationsModule,
        DropdownModule,
        InputNumberModule,
        InputTextModule,
        RatingModule,
        SfDropDownModule,
        WoOrderModule,
        TabViewModule,
        AccordionModule
        ],
    providers: [NotificationsService, AuthService, AuthorizationGuard, WoOrderService, WoOrderServiceService, WoOrderPaymentService, {provide: LOCALE_ID, useValue: 'pt'}]
})
export class WoOrderPaymentModule { }


