import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ScCompanyService } from '../../../sc-company.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ScCompanyInfoInput } from '../../../sc-company.model';
import { ScRoleService } from 'src/app/pages/sc-role/sc-role.service';
import { DropDown } from 'src/app/shared/model/base-model';
import { ScUserService } from 'src/app/pages/sc-user/sc-user.service';
import { Subject } from 'rxjs';



@Component({
    selector: 'sc-company-add-step-user',
    templateUrl: './sc-company-add-step-user.component.html',
    //styleUrls: ['./sc-company-add.component.scss'],
    //providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})


export class ScCompanyAddStepUserComponent implements OnInit {

    public step: ScCompanyInfoInput = {};
    public submitted: boolean = false;
    public validLogin: boolean = true;
    public checkedLogin: boolean = false;
    public scRoleId: string;
    public scRoleName: string;
    public roleList: DropDown[];
    public language: string = 'pt-BR';
    public isEmail: boolean = false;

    private dataLoaded: Subject<void> = new Subject<void>()

    constructor(private companyService: ScCompanyService,
                private roleService: ScRoleService,
                private userService: ScUserService,
                private translateConfigService: TranslateConfigService
            ) {}


    async ngOnInit() {
        this.language = this.translateConfigService.getLanguage();
        this.getRoles();
        this.step = this.companyService.getAddSteps();
    }

    getRoles(){
        this.roleService.getDropDownList(this.language).subscribe(
            {
                next: (res) => {
                    if (res)
                        this.roleList = res.data;                    
                },
                error: (err) => {
                    console.log(`ScCompanyAddStepUserComponent - getRoles : ${err}.`);
                }
            });
    }

    async isLoginValid(){

        this.checkAdminLogin(this.step.userEmail);

        // this.dataLoaded.subscribe(() => {
        //     if (this.validLogin == false){
        //         const r = Math.floor(10 + Math.random() * 90);
        //         this.step.userEmail = this.step.code+"_Admin_"+r;
        //         this.checkAdminLogin(this.step.userEmail);
        //     }
        //   });
        // return;

    }




    checkAdminLogin(login: string){
        this.userService.getItemByLogin(login,login).subscribe(
            {
                next: (res) => {
                    if (res){
                        if (res == null || res.data == null)
                            this.validLogin = true;
                        else
                            this.validLogin = false;

                            console.log(`ScCompanyAddStepUserComponent - checkAdminLogin : ${this.validLogin}.`);
                        // this.dataLoaded.next();  // Signal that data is loaded
                        // this.dataLoaded.complete();  // Complete the Subject to avoid memory leaks
                    }
                },
                error: (err) => {
                    console.log(`ScCompanyAddStepUserComponent - checkAdminLogin : ${err}.`);
                },
                // complete: () => {
                //         this.checkedLogin = true;

                // }
            });
    }

    reciverFeedbackRole(id: string){
        this.scRoleId = id;
        console.log(`ScCompanyAddStepUserComponent - reciverFeedbackRole : ${id}.`);
        if (id !== undefined && id !== null && id !== ""){
            this.step.scRoleId = id;
            this.step.scRoleName = this.roleList.find(x => x.id == id).name;
            console.log(`ScCompanyAddStepUserComponent - reciverFeedbackRole : ${this.step}.`);
        }
    }

    nextPage() {
        //this.checkAdminLogin(this.step.userEmail);
        this.isEmail = this.userService.isEmail(this.step.userEmail);

        if (this.step.userName  
                && this.step.userEmail 
                && this.step.scRoleId 
                //&& this.validLogin
                && this.isEmail){
            this.companyService.addSteps = this.step;
            this.companyService.dialogCompanyAddNext.emit();
            return;
        }

      this.submitted = true;

    }

    prevPage() {
        this.companyService.dialogCompanyAddBack.emit();
    }
}
