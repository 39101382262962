import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { CashOpeningResponse, PaCashier, PaCashierTransactionRegister, PaymentType, TransactionType } from '../pa-payment.model';
import { PaCashierService } from '../pa-payment.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-pa-payment-home',
  templateUrl: './pa-payment-home.component.html',
  styleUrls: ['./pa-payment-home.component.scss']
})
export class PaPaymentHomeComponent implements OnInit {

    public item: PaCashier;
    public paymentType: PaymentType = PaymentType.Cash;
    public cashTransaction: PaCashierTransactionRegister = {};

    public pointOfSaleId: string = "9d7dcd0b-1876-4d27-9196-a7da77985531";
    public language: string = "pt-BR";
    public selectedCashControl: string = '';
    public cashId: string;
    public buttonConfirmOpening: string = '';
    public buttonConfirmClosing: string = '';
    public dialogHeader: string = '';
    public buttonInflow: string ='p-button p-button-success m-1 mr-1';
    public buttonOutflow: string = 'p-button p-button-gray m-1 mr-1';

    public flUpdate: boolean = true;
    public submitted: boolean;
    public open_button: boolean = false;
    public inflowOutflow_button: boolean = true;
    public close_button: boolean = true;
    public statement_button: boolean = true;
    public cashInflow: boolean = true;
    public cashOutflow:boolean = false;
    public displayCashControlConfirm: boolean = true
    public displayCashOpening: boolean = false;
    public displayCashInflowOutflow: boolean = false;
    public displayCashClosing: boolean = false;
    public displayStatement: boolean = false;

    public cashData = [
        {treatment: 'Atendimento 08 - balcao', client: "Cliente teste",  amount: -200.00, date: new Date()},
        {treatment: 'Atendimento 09 - balcao', client: "Cliente teste 2",  amount: +600.00, date: new Date()}
    ];

    constructor(
        private translateService: TranslateService,
        private translateConfigService: TranslateConfigService,
        private cashierService: PaCashierService,
        private messageService: MessageService,

    ){}

    ngOnInit(): void {
        this.language = this.translateConfigService.getLanguage();
        this.item = {};

        this.checkCashStaus();
        this.translateService.get('button.confirm').subscribe((res: string) => {
            this.updateButtonLabel();
        });
    }

    showDialog(action: string) {
        switch(action) {
          case 'cashOpening':
            this.displayCashControlConfirm = true;
            this.dialogHeader = this.translateService.instant('title.cashOpening');
            this.displayCashOpening = true;
            break;
          case 'cashInflowOutflow':
            this.dialogHeader = this.translateService.instant('title.cashInflowOutflow');
            this.displayCashInflowOutflow = true;
            break;
          case 'cashClosing':
            this.dialogHeader = this.translateService.instant('title.cashClosing');
            this.displayCashClosing = true;
            break;
          case 'statement':
            this.dialogHeader = this.translateService.instant('title.statement');
            this.displayStatement = true;
            break;
        }
    }

    showCashControl(){
        this.displayCashControlConfirm = true;
    }

    openCash() {
        this.submitted = true;

        this.cashierService.openCash(this.item, this.pointOfSaleId).subscribe({
            next: (response: CashOpeningResponse) => {
                this.cashId = response.data.id
                localStorage.setItem('cashId', this.cashId.toString());
                this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.cashier_open_sucess'), life: 3000});
                console.log('Caixa aberto com sucesso:');
                this.checkCashStaus();
            },
            error: (err)=> {
                this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.cashier_open_failure'), life: 3000});
                console.log(err)
            }
        });

        this.displayCashOpening = false;
        this.item = {};
    }


    cashTransactionRegister(transactionType: TransactionType, paymentType?: PaymentType){
        this.submitted = true;
        this.cashId = localStorage.getItem('cashId');

        if(!this.cashId || this.cashId === undefined){
            console.log('Caixa nao aberto');
            return false;
        }

        if (transactionType === TransactionType.Payment) {
            if (!paymentType) {
                this.messageService.add({severity: 'error',summary: this.translateService.instant('title.error'),detail: this.translateService.instant('message.select_payment_type'),life: 3000});
                return;
            }
            this.cashTransaction.paymentType = paymentType;
        }

        this.cashTransaction.transactionType = transactionType;
        this.cashierService.cashTransaction(this.cashTransaction, this.cashId).subscribe({
            next: () => {
                this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_inserted'), life: 3000});
                console.log('Caixa aberto com sucesso:');
            },
            error: (err)=> console.log(err)
        });
        this.displayCashInflowOutflow = false;

        this.item = {};
        this.cashTransaction = {}
    }

    closeCash(){
        this.submitted = true;
        this.cashierService.closeCash(this.item, this.cashId).subscribe({
            next: () => {
                localStorage.removeItem('cashId');
                this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_inserted'), life: 3000});
                this.checkCashStaus();
                console.log('Caixa fechado com sucesso:');
            },
            error: (err)=> console.log(err)
        });

        this.displayCashClosing = false;
        this.item = {};
    }

    updateButtonLabel(): void {
        this.translateService.get('button.confirm').subscribe((res: string) => {
            this.buttonConfirmOpening = this.item.amount
            ? `${res} R$ ${this.item.amount}`
            : `${res} R$ 0,00`;

            this.buttonConfirmClosing = this.item.amount
            ? `${res} R$ ${this.item.amount}`
            : `${res} R$ 0,00`;
        });
    }

    selectCashInflow() {
        this.selectedCashControl = 'inflow';
        this.buttonInflow = 'p-button p-button-success m-1 mr-1';
        this.buttonOutflow = 'p-button p-button-gray m-1 mr-1';
        this.cashInflow = true;
        this.cashOutflow = false;
    }

    selectCashOutflow() {
        this.selectedCashControl = 'outflow';
        this.buttonInflow = 'p-button p-button-gray m-1 mr-1';
        this.buttonOutflow = 'p-button p-button-success m-1 mr-1';
        this.cashOutflow = true;
        this.cashInflow = false;
    }

    checkCashStaus(){
        if(localStorage.getItem('cashId')){
            this.open_button = true;
            this.inflowOutflow_button= false
            this.close_button= false
            this.statement_button= false
        }else{
            this.open_button = false;
            this.inflowOutflow_button= true
            this.close_button= true
            this.statement_button= true
        }
    }
}
