import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ScConfigList, ScConfigOutput } from "./sc-config.model";
import { Observable } from "rxjs";
import { DropDown, DropDownMeta } from "src/app/shared/model/base-model";

@Injectable({
    providedIn: 'any'
})
export class ScConfigService {
    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient) {
        this.environmentUrl = environment.ApiCore + '/Config/';
    }

    getItem(id: string): Observable<ScConfigOutput> {
        let url = this.environmentUrl + id;
        return this.httpClient.get<ScConfigOutput>(url);
    }

    getList(scCompanyTypeId: string, language: string): Observable<ScConfigList> {
        let url = this.environmentUrl + "?search="+scCompanyTypeId+"&language="+language;
        return this.httpClient.get<ScConfigList>(url);
    }

    addItem(model: ScConfigOutput) {
        return this.httpClient.post(this.environmentUrl, model);
    }

    updItem(id: string, model: ScConfigOutput) {
        let url = this.environmentUrl + id;
        return this.httpClient.put(url, model);
    }

    delItem(id: string) {
        return this.httpClient.delete(this.environmentUrl + id);
    }

    getDropDownList(scCompanyTypeId : string, language: string): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown?search="+scCompanyTypeId+"&language="+language;
        return this.httpClient.get<DropDownMeta>(url);
    }


}
