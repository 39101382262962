<sf-loader></sf-loader>
<sf-notification></sf-notification>
<div class="p-grid">
    <div class="p-col-12" >
        <p-table #dt [value]="list" dataKey="id" [paginator]="true" [rows]="15"
            [showCurrentPageReport]="true"
            [currentPageReportTemplate]="pageMessage"
            [rowsPerPageOptions]="[15,50,100]"
            styleClass="p-datatable-responsive-demo p-datatable-striped"
            [globalFilterFields]="['code','name']"
            [showCurrentPageReport]="true"
            >

            <ng-template pTemplate="caption">
                <div class="flex">
                    <button pButton pRipple icon="pi pi-plus"
                        [label]="'button.module_new' | translate"
                        class="p-button-primary p-mr-2"
                        style="width: 150px;"
                        (click)="addItem()"
                        *ngIf="flUpdate">
                    </button>

                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input #filter pInputText 
                            type="text"
                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                            [placeholder]="'search.module' | translate"/>
                        <button pButton [label]="'button.clear' | translate" 
                            class="p-button-warning mr-2 ml-2"
                            icon="pi pi-filter-slash"
                            (click)="clear(dt, filter); filter.value='';">
                        </button>
                    </span>
                    <p-button icon="pi pi-refresh" (click)="getList()"></p-button>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 140px; text-align: center;"><span translate>label.code</span></th>
                    <th style="width: 140px; text-align: center;"><span translate>label.name</span></th>
                    <th style="width: 140px; text-align: center;"><span translate>label.color</span></th>
                    <th style="width: 140px; text-align: center;"><span translate>label.icon</span></th>
                    <th style="width: 140px; text-align: center;"><span translate>label.active</span></th>
                    <th style="width: 140px; text-align: center;"><span translate>label.companies</span></th>
                    <th style="width: 140px; text-align: center;"><span translate>label.actions</span></th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr>
                    <td style="width: 140px; text-align: center;">{{item.code}}</td>
                    <td style="width: 140px; text-align: center;">{{item.name}}</td>
                    <td style="width: 140px; text-align: center;">
                        <span
                            class="status-badge"
                            [style.background-color]="item.color ? item.color : 'transparent'"
                            [style.color]="item.color ? item.color : '#000000'">
                            {{ item.color ? item.color : '-' }}
                        </span>
                    </td>
                    <td style="width: 140px; text-align: center;"><i class="{{item.icon}}"></i>&nbsp; {{item.icon ? item.icon : '-'}}</td>
                    <td style="width: 140px; text-align: center;"><input type="checkbox" id="isActive" [(ngModel)]="item.isActive"></td>
                    <td style="width: 140px; text-align: center;"> 
                        <span *ngFor="let company of item.companies; let last = last">
                          {{ company.name }}<ng-container *ngIf="!last">, </ng-container>
                        </span>
                    </td>
                    <td style="width: 140px; text-align: center;">
                        <p-button icon="pi pi-pencil" [styleClass]="classBtnUpd" [pTooltip]="'button.edit' | translate" (click)="updItem(item)"  [disabled]="!flUpdate"></p-button>
                        <p-button icon="pi pi-trash" [styleClass]="classBtnDel" [pTooltip]="'button.delete' | translate"   (click)="delItem(item)" [disabled]="!flUpdate"></p-button>
                    </td>
                </tr>                
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog [(visible)]="displayDialog" [style]="{width:'500px'}" [modal]="true" styleClass="p-fluid" [header]="dialogHeader">
    <ng-template pTemplate="content">
        <div style="margin-top: 1px;" class="p-grid p-d-flex dialog-page">
            <div class="p-md-12 p-lg-6 p-xl-6 p-order-1 p-order-1 p-order-lg-1 flex flex-column gap-2">
                <div class="p-field">
                    <label for="code"><span translate>label.code</span></label>
                    <input type="text" pInputText id="code" [(ngModel)]="item.code" required />
                    <small class="p-invalid" *ngIf="submitted && !item.code"><span translate>label.field_code_required</span></small>
                </div>
                <div class="p-field">
                    <label for="name"><span translate>label.name</span></label>
                    <input type="text" pInputText id="name" [(ngModel)]="item.name" required />
                    <small class="p-invalid" *ngIf="submitted && !item.name"><span translate>label.field_name_required</span></small>
                </div>
                <div class="p-field">
                    <label for="icon"><span translate>label.icon</span></label>
                    <sf-dropdown-icon [icon]="item.icon" (chosedIcon)="reciverFeedbackIcon($event)" [placeHolderLanguage]="'label.select_icon' | translate"> </sf-dropdown-icon>
                </div>
                <div class="p-field">
                    <label for="company"><span translate>label.companies</span></label>
                    <sf-sc-company-dropdown-multi
                        InputId="company"
                        [selectedItems]="selectedCompanies"
                        [placeHolderLanguage]="'label.select_company' | translate"
                        (chosedItem)="reciverFeedbackCompany($event)">

                    </sf-sc-company-dropdown-multi>
                </div>                
                <div class="p-field flex gap-2 align-items-end">
                    <div class="flex-grow-1">
                        <label for="txtcolor"><span translate>label.color</span></label>
                        <input type="text" pInputText id="txtcolor" [(ngModel)]="item.color"/>
                    </div>
                    <div class="block mt-2 w-min">
                        <sf-dropdown-color [color]="item.color" (chosedColor)="reciverFeedbackColor($event)" [isInLine]="false" parentToAppend="body"></sf-dropdown-color>
                    </div>
                </div>
                <div class="p-field">
                    <label for="claim"><span translate>label.claims</span></label>
                    <sf-sc-claim-dropdown-multi InputId="claim" [selectedItems]="selectedClaims" [placeHolderLanguage]="'label.select_claim' | translate" (chosedItem)="reciverFeedbackClaim($event)"></sf-sc-claim-dropdown-multi>
                </div>
                <div class="p-field">
                    <input type="checkbox"  id="isActive" [(ngModel)]="item.isActive">
                    <label for="isActive"><span translate>label.active</span></label>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div style="float: right; display: flex; align-items: flex-end; gap: 8px;">
            <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="hideDialog()"></button>
            <div *ngIf="flUpdate">
                <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="saveItem()"></button>
            </div>
        </div>
    </ng-template>
</p-dialog>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
