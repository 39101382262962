<sf-notification></sf-notification>
<p-confirmDialog header="Confirmação" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="p-grid">
    <div class="p-col-12" style="margin-top: -15px;">
        <p-table #dt [value]="list" dataKey="id" [paginator]="false"
            styleClass="p-datatable-gridlines p-datatable-striped" >
            <ng-template pTemplate="caption">
                <div class="flex">
                    <button pButton pRipple icon="pi pi-plus" label="Adicionar Serviço" class="p-button-success p-mr-2" style="width: 200px;"
                    (click)="addItem()" *ngIf="canExecuteService"></button>

                    <span class="p-input-icon-left ml-auto">
                    <!--
                        <i class="pi pi-search"></i>
                        <input #filter pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"  [placeholder]="'search.service' | translate" />
                        <button pButton [label]="'button.clear' | translate" class="p-button-warning mr-2 ml-2" icon="pi pi-filter-slash" (click)="clear(dt, filter); filter.value = '';"></button>
                    -->
                    </span>
                    <p-button icon="pi pi-refresh" (click)="getList()"></p-button>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center;" pSortableColumn="name" rowspan="2">Descrição<p-sortIcon field="name"></p-sortIcon></th>
                    <th style="text-align: center;" style="width: 10px;" rowspan="2">Qtde</th>
                    <!-- <th colspan="5" style="text-align: center;">Valor Unitário R$</th>
                    <th colspan="5" style="text-align: center;">Valor Total R$</th> -->

                    <th style="text-align: center;" colspan="2">Recompe</th>
                    <th style="text-align: center;" colspan="2">Emolumentos</th>
                    <th style="text-align: center;" colspan="2">Fiscalização</th>
                    <th style="text-align: center;" colspan="2">ISS</th>
                    <th style="background-color: #f4f2f2; text-align: center;" colspan="2">Total</th>

                    <th style="width: 110px; text-align: center;"  rowspan="2" *ngIf="canExecuteService"><span translate>label.actions</span></th>
                </tr>
                <tr>
                    <!-- <th style="width: 10px;">Recompe</th>
                    <th style="width: 30px;">Emolumentos</th>
                    <th style="width: 30px;">Fiscalização</th>
                    <th style="width: 30px;">ISS</th>
                    <th style="width: 30px; background-color: #eeeeee;">Total</th>
                    <th style="width: 30px;">Recompe</th>
                    <th style="width: 30px;">Emolumentos</th>
                    <th style="width: 30px;">Fiscalização</th>
                    <th style="width: 30px;">ISS</th>
                    <th style="width: 30px; background-color: #eeeeee;">Total</th> -->

                    <th style="text-align: center;">Unit.</th>
                    <th style="text-align: center;">Total</th>
                    <th style="text-align: center;">Unit.</th>
                    <th style="text-align: center;">Total</th>
                    <th style="text-align: center;">Unit.</th>
                    <th style="text-align: center;">Total</th>
                    <th style="text-align: center;">Unit.</th>
                    <th style="text-align: center;">Total</th>
                    <th style="text-align: center; background-color: #f4f2f2;">Unit.</th>
                    <th style="text-align: center;background-color: #f4f2f2;">Total</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{item.code}} - {{item.name}}</td>
                    <td style="width: 50px;">{{item.quantity | number:'1.2-2'}}</td>
                    <!-- <td style="text-align: right;">R$ {{item.unitValueA | number:'1.2-2'}}</td>
                    <td style="text-align: right;">R$ {{item.unitValueB | number:'1.2-2'}}</td>
                    <td style="text-align: right;">R$ {{item.unitValueTaxA | number:'1.2-2'}}</td>
                    <td style="text-align: right;">R$ {{item.unitValueTaxB | number:'1.2-2'}}</td>
                    <td style="text-align: right; background-color: #eeeeee;">R$ {{(item.unitValueA + item.unitValueB + item.unitValueTaxA + item.unitValueTaxB) | number:'1.2-2'}}</td>
                    <td style="text-align: right;">R$ {{item.quantity * item.unitValueA | number:'1.2-2'}}</td>
                    <td style="text-align: right;">R$ {{item.quantity * item.unitValueB | number:'1.2-2'}}</td>
                    <td style="text-align: right;">R$ {{item.quantity * item.unitValueTaxA | number:'1.2-2'}}</td>
                    <td style="text-align: right;">R$ {{item.quantity * item.unitValueTaxB | number:'1.2-2'}}</td>
                    <td style="text-align: right; background-color: #eeeeee;">{{item.quantity * (item.unitValueA + item.unitValueB + item.unitValueTaxA + item.unitValueTaxB) | number:'1.2-2'}}</td> -->

                    <td style="text-align: right;">{{item.unitValueA | number:'1.2-2'}}</td>
                    <td style="text-align: right; font-weight: 700;">{{item.quantity * item.unitValueA | number:'1.2-2'}}</td>

                    <td style="text-align: right;">{{item.unitValueB | number:'1.2-2'}}</td>
                    <td style="text-align: right; font-weight: 700;">{{item.quantity * item.unitValueB | number:'1.2-2'}}</td>

                    <td style="text-align: right;">{{item.unitValueTaxA | number:'1.2-2'}}</td>
                    <td style="text-align: right; font-weight: 700;">{{item.quantity * item.unitValueTaxA | number:'1.2-2'}}</td>

                    <td style="text-align: right;">{{item.unitValueTaxB | number:'1.2-2'}}</td>
                    <td style="text-align: right; font-weight: 700;">{{item.quantity * item.unitValueTaxB | number:'1.2-2'}}</td>

                    <td style="text-align: right; background-color: #f4f2f2;">{{(item.unitValueA + item.unitValueB + item.unitValueTaxA + item.unitValueTaxB) | number:'1.2-2'}}</td>
                    <td style="text-align: right; background-color: #f4f2f2; font-weight: 700;">{{item.quantity * (item.unitValueA + item.unitValueB + item.unitValueTaxA + item.unitValueTaxB) | number:'1.2-2'}}</td>





                    <td *ngIf="canExecuteService">
                        <p-button icon="pi pi-times" [styleClass]="classBtnDel" [pTooltip]="'button.delete' | translate"   (click)="delItem(item)" class="mr-1" [disabled]="!canExecuteService"></p-button>
                        <p-button icon="pi pi-pencil" [styleClass]="classBtnUpd" [pTooltip]="'button.edit' | translate" (click)="updItem(item)" [disabled]="!canExecuteService"></p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<!--
<p-dialog [(visible)]="displayDialog" [style]="{width: '500px'}" [modal]="true" styleClass="p-fluid" header="Adicionar novo serviço">
    <ng-template pTemplate="content">

        <ng-container *ngIf="lazyComponent">
            <ng-container *ngComponentOutlet="lazyComponent"></ng-container>
        </ng-container>

    </ng-template>
</p-dialog> -->


<p-dialog *ngIf="displayDialogNew" [(visible)]="displayDialogNew" [style]="{width: '80vw'}" [maximizable]="true" [modal]="true" styleClass="p-fluid" header="Adicionar novo serviço">
    <ng-template pTemplate="content">
        <div class="p-col-12" style="margin-top: -22px;">

            <ng-container *ngIf="lazyComponent">
                <ng-container *ngComponentOutlet="lazyComponent; ngModuleFactory: moduleFactory;  inputs: {scPerson: scPerson, canExecuteService: canExecuteService};"></ng-container>
            </ng-container>
            <div *ngIf="!lazyComponent">

                <div class="px-4 py-8 md:px-6 lg:px-8 grid" *ngIf="!showSteps">
                    <!-- <div class="col-12 md:col-4 mb-4 px-5">
                        <div class="icons-new-order">
                            <div class="image-new-order">
                                <div class="icon-new-order"  (click)="addService('24CDB64E-3501-4817-9C80-3179769D6311','2','Abertura de Firma')">
                                    <div style=" background-color:orange;">
                                        <img src="../../../../../assets/images/images-order/abertura-firma.png" class="new-order">
                                    </div>
                                    <div style="padding: 16px;">
                                        <h6 class="new-order-title">Abertura de Firma</h6>
                                        <span class="new-order-description">A abertura de firma é o registro da assinatura de uma pessoa física.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 md:col-4 mb-4 px-5">
                        <div class="icons-new-order">
                            <div class="image-new-order">
                                <div class="icon-new-order" (click)="addService('3A51F16D-1787-4D15-9B75-E6904D80DFAE','1','Autenticação')">
                                    <div style="background-color:orange;">
                                        <img src="../../../../../assets/images/images-order/auteticacao.png" class="new-order">
                                    </div>
                                    <div style="padding: 16px;">
                                        <h6 class="new-order-title">Autenticação</h6>
                                        <span class="new-order-description">Autenticação de documentos para atestar a veracidade.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 md:col-4 mb-4 px-5">
                        <div class="icons-new-order">
                            <div class="image-new-order">
                                <div class="icon-new-order"  (click)="newService('Alteração Contratual')">
                                    <div style="background-color:orange;">
                                        <img src="../../../../../assets/images/images-order/auteracao-contratual.png" class="new-order">
                                    </div>
                                    <div style="padding: 16px;">
                                        <h6 class="new-order-title">Alteração Contratual</h6>
                                        <span class="new-order-description">Modificação oficial de termos em um contrato, ajustando cláusulas.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 md:col-4 mb-4 px-5">
                        <div class="icons-new-order">
                            <div class="image-new-order">
                                <div class="icon-new-order"  (click)="newService('Escritura')">
                                    <div style="background-color:orange;">
                                        <img src="../../../../../assets/images/images-order/escritura.png" class="new-order">
                                    </div>
                                    <div style="padding: 16px;">
                                        <h6 class="new-order-title">Escritura</h6>
                                        <span class="new-order-description">Documento legal, feito em cartório, que comprova atos jurídicos.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12 md:col-4 mb-4 px-5">
                        <div class="icons-new-order">
                            <div class="image-new-order">
                                <div class="icon-new-order"  (click)="newService('Inventário')">
                                    <div style="background-color:orange;">
                                        <img src="../../../../../assets/images/images-order/inventario.png" class="new-order">
                                    </div>
                                    <div style="padding: 16px;">
                                        <h6 class="new-order-title">Inventário</h6>
                                        <span class="new-order-description">Inventário é o processo para apuração de bens, direitos e dívidas.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12 md:col-4 mb-4 px-5">
                        <div class="icons-new-order">
                            <div class="image-new-order">
                                <div class="icon-new-order"  (click)="newService('Procuração')">
                                    <div style="background-color:orange;">
                                        <img src="../../../../../assets/images/images-order/procuracao.png" class="new-order">
                                    </div>
                                    <div style="padding: 16px;">
                                        <h6 class="new-order-title">Procuração</h6>
                                        <span class="new-order-description">Documento pelo qual uma pessoa concede poderes a outra pessoa.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 md:col-4 mb-4 px-5">
                        <div class="icons-new-order">
                            <div class="image-new-order">
                                <div class="icon-new-order"  (click)="newService('Reconhecimento de Firma')">
                                    <div style="background-color:orange;">
                                        <img src="../../../../../assets/images/images-order/reconhecimento-firma.png" class="new-order">
                                    </div>
                                    <div style="padding: 16px;">
                                        <h6 class="new-order-title">Reconhecimento de Firma</h6>
                                        <span class="new-order-description">Confirmação a autenticidade da assinatura em um documento.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12 md:col-4 mb-4 px-5">
                        <div class="icons-new-order">
                            <div class="image-new-order">
                                <div class="icon-new-order"  (click)="newService('Separação/Divórcio')">
                                    <div style="background-color:orange;">
                                        <img src="../../../../../assets/images/images-order/separacao-divorcio.png" class="new-order">
                                    </div>
                                    <div style="padding: 16px;">
                                        <h6 class="new-order-title">Separação/Divórcio</h6>
                                        <span class="new-order-description">Processo para dissolver o casamento, de forma consensual ou litigioso.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12 md:col-4 mb-4 px-5">
                        <div class="icons-new-order">
                            <div class="image-new-order">
                                <div class="icon-new-order"  (click)="newService('Testamento')">
                                    <div style="background-color:orange;">
                                        <img src="../../../../../assets/images/images-order/testamento.png" class="new-order">
                                    </div>
                                    <div style="padding: 16px;">
                                        <h6 class="new-order-title">Testamento</h6>
                                        <span class="new-order-description">Documento que uma pessoa determina a destinação de bens após sua morte.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div *ngFor="let item of listServiceByUser; let i = index;" class="col-12 md:col-4 mb-4 px-5">
                        <div class="icons-new-order">
                            <div class="image-new-order">
                                <div class="icon-new-order" (click)="addService(item.id, item.code, item.name)">
                                    <div style=" background-color:orange;">
                                        <img src="{{item.imageUrl}}" class="new-order">
                                    </div>
                                    <div style="padding: 16px;">
                                        <h6 class="new-order-title">{{item.name}}</h6>
                                        <span class="new-order-description">{{item.description}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </ng-template>

    <!-- <ng-template pTemplate="footer">
        <div style="float: right;">
            <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="hideDialog()"></button>
            <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="saveItem()"></button>
        </div>
    </ng-template> -->

</p-dialog>


