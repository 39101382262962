<div class="p-grid">
    <div class="p-col-12" style="margin-top: -15px;">
        <p-table #dt [value]="list" dataKey="id" [paginator]="false"
            styleClass="p-datatable-gridlines p-datatable-striped" >
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center;"><span translate>label.description</span></th>
                    <th style="text-align: center;" style="width: 50px;" ><span translate>label.quantity</span></th>
                    <th style="text-align: center;" ><span translate>label.unitValue</span></th>
                    <th style="text-align: center;" ><span translate>label.totalValue</span></th>                    
                </tr>                
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{item.code}} - {{item.name}}</td>
                    <td>{{item.quantity | number:'1.2-2'}}</td>
                    <td style="text-align: right;">{{(item.unitValueA + item.unitValueB + item.unitValueTaxA +item.unitValueTaxB) | number:'1.2-2'}}</td>
                    <td style="text-align: right; background-color: #f4f2f2; font-weight: 700;">{{(item.unitValueA + item.unitValueB + item.unitValueTaxA +item.unitValueTaxB) * item.quantity | number:'1.2-2'}}</td>
                </tr>
            </ng-template>
        </p-table>

        <div class="formgrid grid mt-3">
            <div class="field col">
                <span class="font-bold"><span translate>label.totalQuantity</span></span>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-list"></i>
                    </span>
                    <input type="text" pInputText readonly [value]="totalValue.quantity">
                </div>
            </div>
            <div class="field col">
                <span class="font-bold"><span translate>label.totalUnitValue</span></span>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <span translate>label.moneySymbol</span>
                    </span>
                    <input type="text" pInputText disabled [value]="totalValue.valueA | number:'1.2-2'">
                </div>
            </div>
            <div class="field col">
                <span class="font-bold"><span translate>label.totalValue</span></span>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <span translate>label.moneySymbol</span>
                    </span>
                    <input type="text" pInputText disabled  [value]="totalValue.total | number:'1.2-2'">
                </div>
            </div>
        </div>

    </div>
</div>
