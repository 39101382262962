<sf-loader></sf-loader>
<sf-notification></sf-notification>
<div class="p-grid">
    <p-table #dt [value]="list" dataKey="id" [paginator]="true" [rows]="15"
        [showCurrentPageReport]="true"
        [currentPageReportTemplate]="pageMessage"
        [rowsPerPageOptions]="[15,50,100]"
        [globalFilterFields]="['code','name','fatasyName','ein','scCompanyType','scCompanyStatus']"
        styleClass="p-datatable-striped" [showCurrentPageReport]="true">

        <ng-template pTemplate="caption">
            <div class="flex">
                <button pButton pRipple icon="pi pi-plus" [label]="'button.company_new' | translate" class="p-button-success p-mr-2" style="width: 150px;"
                (click)="addItem()" ></button>

                <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input #filter pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" [placeholder]="'search.company' | translate" />
                    <button pButton [label]="'button.clear' | translate" class="p-button-warning mr-2 ml-2" icon="pi pi-filter-slash" (click)="clear(dt, filter)"></button>
                </span>
                <p-button icon="pi pi-refresh" (click)="getList()"></p-button>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>                
                <!-- <th style="width: 60px;"><span translate>label.logotype</span></th> -->
                <th pSortableColumn="name"><span translate>label.name</span><p-sortIcon field="name"></p-sortIcon></th>
                <th pSortableColumn="paPlanName"><span translate>label.plan</span><p-sortIcon field="paPlanName"></p-sortIcon></th>
                <th pSortableColumn="scCompanyTypeName"><span translate>label.type</span><p-sortIcon field="scCompanyTypeName"></p-sortIcon></th>
                <th pSortableColumn="scCompanyStatusName"><span translate>label.status</span><p-sortIcon field="scCompanyStatusName"></p-sortIcon></th>
                <th pSortableColumn="scUserResponsibleName"><span translate>label.responsible</span><p-sortIcon field="scUserResponsibleName"></p-sortIcon></th>
                <th style="width: 130px; text-align: center;"><span translate>label.actions</span></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>                
                <!-- <td>
                    <div class="avatar-container">
                        <div class="image-container" [ngStyle]="{backgroundImage: 'url(' + item.pictureUri+ ')'}" *ngIf="hasPicture"></div>                        
                    </div>
                </td> -->
                <td>{{item.name}}</td>                                     
                <td>{{item.paPlanName}}</td>
                <td>{{item.scCompanyTypeName}}</td>
                <td><span [class]="'status-badge'" [style]="{'background-color':''+item.scCompanyStatusColor+'', 'color': '#ffffff'}">{{item.scCompanyStatusName}}</span></td>
                <td>{{item.scUserResponsibleName}}</td>
                <td>                    
                    <div *ngIf="item.code == 'SF' && !flUpdate">
                        <p-button icon="pi pi-pencil" [styleClass]="classBtnUpd" [pTooltip]="'button.edit' | translate" (click)="updItem(item)"  [disabled]="true"></p-button>
                        <p-button icon="pi pi-times" [styleClass]="classBtnDel" [pTooltip]="'button.delete' | translate"   (click)="delItem(item)" [disabled]="true"></p-button>                    
                    </div>
                    <div *ngIf="item.code != 'SF' || flUpdate">
                        <p-button icon="pi pi-pencil" [styleClass]="classBtnUpd" [pTooltip]="'button.edit' | translate" (click)="updItem(item)" ></p-button>
                        <p-button icon="pi pi-times" [styleClass]="classBtnDel" [pTooltip]="'button.delete' | translate"   (click)="delItem(item)" [disabled]="!flUpdate"></p-button>                    
                    </div>                        
                    
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="displayDialog" [style]="{width: '65vw', height: '65hw', 'max-height': '80hw'}"
    [modal]="true" styleClass="p-fluid" [header]="dialogHeader" #dialog >
    <sc-company-add *ngIf="displayDialog"></sc-company-add>
</p-dialog>
