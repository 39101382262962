

import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';
import { InputMaskModule } from 'primeng/inputmask';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { AuthService } from 'src/app/core/auth/auth.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { AuthorizationGuard } from 'src/app/core/guards/authorization.guard';
import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';

import { ScUserService } from './sc-user.service';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';
import { ScUserDetailComponent } from './sc-user/sc-user-detail/sc-user-detail.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ScRoleModule } from '../sc-role/sc-role.module';
import { TabViewModule } from 'primeng/tabview';

import { TimelineModule } from 'primeng/timeline';

import { ImageCropperModule } from 'ngx-image-cropper';

import localePT from '@angular/common/locales/pt';
import localeExtraPT from '@angular/common/locales/extra/pt';

import { ScUserGridComponent } from './sc-user/sc-user-grid.component';
import { ScOrganizationStructureModule } from '../sc-organization-structure/sc-organization-structure.module';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { WoSkillModule } from '../wo-skill/wo-skill.module';
import { SfcDropDownClerkModule } from 'src/app/modules/cartorio/component/sfc-dropdown-clerk.component/sfc-dropdown-clerk.module';
import { ScUserProfileComponent } from './sc-user/sc-user-profile/sc-user-profile.component';


registerLocaleData(localePT, 'pt', localeExtraPT);

@NgModule({
    declarations: [ScUserGridComponent, ScUserDetailComponent, ScUserProfileComponent],
    exports: [ScUserGridComponent, ScUserDetailComponent, ScUserProfileComponent],
    imports: [FormsModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        ButtonModule,
        InputTextModule,
        InputMaskModule,
        CheckboxModule,
        TranslateModule,
        MessageModule,
        MessagesModule,
        TableModule,
        TooltipModule,
        ConfirmDialogModule,
        LoaderModule,
        ScRoleModule,
        TabViewModule,
        TimelineModule ,
        CardModule,
        DialogModule,

        ImageCropperModule,

        NotificationsModule,
        ScOrganizationStructureModule,
        WoSkillModule,
        SfcDropDownClerkModule
        ],
    providers: [NotificationsService, AuthService, AuthorizationGuard, ScUserService, {provide: LOCALE_ID, useValue: 'pt'}]
})
export class ScUserModule { }
