import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AppConfig, Config } from 'src/app/layout/app.config';


@Component({
    selector: 'cria-carimbo',
    templateUrl: './carimbo.component.html',
    styleUrls:['./carimbo.component.css']
})
export class CarimboComponent implements OnInit{
    config$: Observable<Config>;

    constructor(config: AppConfig){
        this.config$ = config.getConfig();
    }

    @Input() env: string ='DEV';
    ngOnInit(): void {
        this.env = environment.env;
    }
   


}