

import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DropDown } from 'src/app/shared/model/base-model';





@Component({
    selector: 'sf-dropdown-generic',
    templateUrl: './dropdown-generic.component.html',
    styleUrls: ['./dropdown-generic.component.scss']
})

export class DropDownGenericComponent implements OnInit {


    public selectedItem: DropDown;
    public placeholderText: "";

    @Input() list : DropDown[] = [];
    @Input() id?: string;
    @Input() disabled: boolean;
    @Input() filter: boolean = false;
    @Input() inputId: string = '';

    @Output() selectedItemOutput = new EventEmitter();


    constructor(private translateService: TranslateService){ }

    ngOnInit(): void {

        if (this.disabled == null)
            this.disabled = false;
        this.getList();

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));
        (async () => {
            await sleep(50);
            this.placeholderText = this.translateService.instant('components.dropdown_select');
        })();
    }

    ngOnChanges(): void {
        this.getList();
    }

    getList() {
        if (this.list !== null && this.list !== undefined && this.list.length > 0){
            if (this.id != null && this.id !== undefined && this.id != "")
                this.selectedItem = this.list.find(x => x.id == this.id?.toString());
            else{
                this.selectedItem = this.list[0];
                this.feedback();
            }
        }
    }

    feedback(){
        if (this.selectedItem != null && this.selectedItem !== undefined && this.selectedItem.id != null && this.selectedItem.id !== undefined)
            this.selectedItemOutput.emit(this.selectedItem.id);
    }

}
