<form [formGroup]="addressForm" (submit)="save()">
    <div class="formgrid grid">
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">
            <div class="field mb-2">
                <label for="zip" class="mb-1 font-bold text-lg"><span translate>label.zip_code</span>*</label>
                <p-inputMask inputId="zip" formControlName="postalCode" [mask]="zipCodeMask" [placeholder]="zipCodeMask"
                    autoWidth="false" (ngModelChange)="changeZipCode($event)" [style]="{'width':'100%'}" ></p-inputMask>

            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-6 xl:col-6 vertical-center">
            <div class="field mb-2 search-button">
                <p-button icon="pi pi-search" severity="info" (click)="consultaCEP(addressForm.get('postalCode').value)"></p-button>
            </div>
        </div>
        <!-- <div class="field col-12 md:col-3 lg:col-3 xl:col-3">
    <div class="field mb-2">
        <label for="country" class="mb-1 font-bold text-lg"><span translate>label.country</span></label>
        <sf-dropdown-generic InputId="country" [list]="companyStatusList" [id]="item.scCompanyStatusId" (selectedItemOutput)="reciverFeedbackCompanyStatus($event)" [disabled]="true" ></sf-dropdown-generic>
    </div>
</div> -->
        <div class="field col-12 md:col-6 lg:col-6 xl:col-6 ">
            <div class="field mb-2">
                <label for="state" class="mb-1 font-bold text-lg"><span translate>label.state</span>*</label>
                <sf-dropdown-generic inputId="state" [list]="UfList" [id]="item.state"
                    (selectedItemOutput)="reciverFeedbackUf($event)"
                    [disabled]="UfList.length == 0 ? true : false || cepWasSubmitted"
                    [style]="{'width':'100%'}"></sf-dropdown-generic>
            </div>
        </div>
        <div class="field col-12 md:col-6 lg:col-6 xl:col-6 ">
            <div class="field mb-2">
                <label for="city" class="mb-1 font-bold text-lg"><span translate>label.city</span>*</label>
                <sf-dropdown-generic inputId="city" [list]="CitiesList" [id]="item.city"
                    (selectedItemOutput)="reciverFeedbackCity($event)"
                    [disabled]="CitiesList.length == 0 ? true : false || cepWasSubmitted"></sf-dropdown-generic>
            </div>
        </div>
        <div class="field col-12 md:col-9 lg:col-9 xl:col-9">
            <div class="field mb-2">
                <label for="route" class="mb-1 font-bold text-lg"><span translate>label.street</span>*</label>
                <input type="text" formControlName="route" id="route" pInputText style="width: 100%;">
            </div>
        </div>
        <div class="field col-12 md:col-3 lg:col-3 xl:col-3">
            <div class="field mb-2">
                <label for="street_number" class="mb-1 font-bold text-lg"><span translate>label.street_number</span>*</label>
                <input formControlName="streetNumber" type="text" id="street_number" pInputText style="width: 100%;">
            </div>
        </div>
        <div class="field col-12 md:col-6 lg:col-6 xl:col-6">
            <div class="field mb-2">
                <label for="neighborhood" class="mb-1 font-bold text-lg"><span translate>label.neighborhood</span>*</label>
                <input type="text" formControlName="neighborhood" id="neighborhood" pInputText
                    style="width: 100%;">
            </div>
        </div>
        <div class="field col-12 md:col-6 lg:col-6 xl:col-6">
            <div class="field mb-2">
                <label for="street_complement" class="mb-1 font-bold text-lg"><span
                        translate>label.street_complement</span></label>
                <input type="text" formControlName="streetComplement" id="street_complement" pInputText
                    
                    style="width: 100%;">
            </div>
        </div>
        <div class="field col-12 md:col-9 lg:col-9 xl:col-9">
            <div class="field mb-2">
                <label for="address" class="mb-1 font-bold text-lg"><span translate>label.address</span></label>
                <input type="text" name ="route" id="address" pInputText style="width: 100%;" disabled="true">
            </div>
        </div>
        <div class="field col-12 md:col-3 lg:col-3 xl:col-3">
            <div style="float: right;" class="field mb-2 search-button">
                <p-button [label]="'button.get_coordinate' | translate" severity="info" disabled="true"></p-button>
            </div>
        </div>
        <div class="field col-12 md:col-6 lg:col-3 xl:col-4 ">
            <div class="field mb-2">
                <label for="lat" class="mb-1 font-bold text-lg"><span translate>label.latitude</span></label>
                <input type="text" id="lat" pInputText 
                    style="width: 100%;" disabled="true">
            </div>
        </div>
        <div class="field col-12 md:col-6 lg:col-3 xl:col-4 ">
            <div class="field mb-2">
                <label for="lng" class="mb-1 font-bold text-lg"><span translate>label.longitude</span></label>
                <input type="text" id="lng" pInputText
                    style="width: 100%;" disabled="true">
            </div>
        </div>
        <div class="field col-12 md:col-6 lg:col-6 xl:col-4">
            <div style="float: right;">
                <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;"
                    class="p-button-primary p-mr-2 mt-3" [label]="'button.confirm' | translate"
                    disabled="true"></button>
            </div>
        </div>
        <div class="field col-12">
            <div style="float: right;">
                <button pButton type="submit" pRipple icon="pi pi-check" style="width: 110px; margin: 3px;"
                    class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" [disabled]="!addressForm.valid"></button>
            </div>
        </div>
    </div>

</form>