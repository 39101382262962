import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DropDownMeta } from 'src/app/shared/model/base-model';
import { ScModuleItem, ScModuleList, ScModuleOutput, ScModuleUpdateInput } from './sc-module.model';

@Injectable({
    providedIn: 'any'
})

export class ScModuleService{

    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/Module/';
    }

    getItem(id: string): Observable<ScModuleItem> {
        let url = this.environmentUrl + id;
        return this.httpClient.get<ScModuleItem>(url);
    }

    getItemByUserId(userId: string, language: string): Observable<ScModuleItem> {
        let url = this.environmentUrl + userId+'?language='+language;
        return this.httpClient.get<ScModuleItem>(url);
    }

    getList(language: string): Observable<ScModuleList> {
        let url = this.environmentUrl+ "?language="+language;
        return this.httpClient.get<ScModuleList>(url);
    }

    addItem(model: ScModuleOutput, userId: string, language: string) {
        let modelUpd: ScModuleUpdateInput = {};

        modelUpd.code = model.code;
        modelUpd.name = model.name;
        modelUpd.icon = model.icon;
        modelUpd.color = model.color;
        modelUpd.isActive = model.isActive;
        modelUpd.languageCode = language;
        modelUpd.companyId = model.companyId;
        modelUpd.userId = userId;
        modelUpd.claims = model.claims;
        modelUpd.companies = model.companies;

        return this.httpClient.post(this.environmentUrl, modelUpd);
    }

    updItem(model: ScModuleOutput, userId: string, language: string) {

        let url = this.environmentUrl + model.id;
        let modelUpd: ScModuleUpdateInput = {};

        modelUpd.code = model.code;
        modelUpd.name = model.name;
        modelUpd.icon = model.icon;
        modelUpd.color = model.color;
        modelUpd.isActive = model.isActive;
        modelUpd.languageCode = language;
        modelUpd.companyId = model.companyId;
        modelUpd.userId = userId;
        modelUpd.claims = model.claims;
        modelUpd.companies = model.companies;

        return this.httpClient.put(url, modelUpd);
    }

    delItem(id: string) {
        return this.httpClient.delete(this.environmentUrl + id);
    }

    getDropDownList(scCompanyTypeId: string, language: string): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown/?scCompanyTypeId="+scCompanyTypeId+"&language="+language;
        return this.httpClient.get<DropDownMeta>(url);
    }

}
