<sf-loader></sf-loader>
<sf-notification></sf-notification>
<div class="p-grid">
    <div class="p-col-12">
        <p-table #dt [value]="list" dataKey="id" [paginator]="true" [rows]="15" [showCurrentPageReport]="true"
            [currentPageReportTemplate]="pageMessage" [rowsPerPageOptions]="[15,50,100]"
            [globalFilterFields]="['cpf','name','email']" styleClass="p-datatable-responsive-demo p-datatable-striped"
            [showCurrentPageReport]="true">

            <ng-template pTemplate="caption">
                <div class="flex">
                    <button pButton pRipple icon="pi pi-plus" [label]="'button.person_new' | translate"
                        class="p-button-success p-mr-2" style="width: 150px;" (click)="addItem()"
                        *ngIf="flUpdate"></button>

                    <p-autoComplete class="ml-2" [placeholder]="'label.search_person_by_ssn_name' | translate" [(ngModel)]="selectedItem" [suggestions]="suggestionsPerson" (completeMethod)="searchPerson($event)" (onSelect)="onSelectPerson($event)" field="name"></p-autoComplete>

                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input #filterPerson pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                            [placeholder]="'label.search_person' | translate" />
                        <button pButton [label]="'button.clear' | translate" class="p-button-warning mr-2 ml-2"
                            icon="pi pi-filter-slash" (click)="clear(dt, filterPerson); filterPerson.value = '';"></button>
                    </span>
                    <p-button icon="pi pi-refresh" (click)="getList()"></p-button>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="cpf"><span translate>label.person_ssn</span> <p-sortIcon
                            field="cpf"></p-sortIcon></th>
                    <th pSortableColumn="name"><span translate>label.name</span><p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="email"><span translate>label.email</span><p-sortIcon
                            field="email"></p-sortIcon></th>
                    <th pSortableColumn="phone"><span translate>label.phone_number</span><p-sortIcon
                            field="phone"></p-sortIcon></th>
                    <th><span translate>label.active</span></th>
                    <th style="width: 105px; text-align: center;"><span translate>label.actions</span></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{item.cpf}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.email}}</td>
                    <td>({{item.telephone === undefined || item.telephone.ddd === undefined ? '' : item.telephone.ddd}}){{item.telephone === undefined || item.telephone.phone === undefined ? '' : item.telephone.phone}}</td>
                    <td><input type="checkbox" readonly [(ngModel)]="item.isActive" onclick="return false;"></td>
                    <td>
                        <p-button icon="pi pi-pencil" [styleClass]="classBtnUpd" [pTooltip]="'button.edit' | translate"
                            (click)="updItem(item)" [disabled]="!flUpdate"></p-button>
                        <p-button icon="pi pi-times" [styleClass]="classBtnDel" [pTooltip]="'button.delete' | translate"
                            (click)="delItem(item)" [disabled]="!flUpdate"></p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog *ngIf="displayDialog" [(visible)]="displayDialog" [style]="{width: '80vw', height: '80hw', 'max-height': '80hw'}" [modal]="true"
    styleClass="p-fluid" [header]="dialogHeader" #dialog >
    <ng-template pTemplate="content">
        <div style="margin-top:1px;" class="p-grid p-d-flex dialog-page">
            <div class="p-md-12 p-lg-6 p-xl-6 p-order-1 p-order-lg-1 flex flex-column gap-2">
                <div class="formgrid grid">
                    <div class="p-field col-2">
                        <label htmlFor="ssn"><span translate>label.person_ssn</span></label>
                        <p-inputMask mask="999.999.999-99" [(ngModel)]="item.cpf" placeholder="999.999.999-99"
                            id="ssn" inputId="ssn" styleClass="mask" [disabled]="!canUpdate"
                            [style]="{'width':'100%'}" (onComplete)="onCompleteCpf()"></p-inputMask>
                        <small class="p-invalid" *ngIf="getFieldValidation(!item.cpf)"><span translate>label.field_ssn_required</span></small>
                    </div>
                    <div class="p-field col-10 text-right">
                        <p-rating [(ngModel)]="item.rating" [readonly]="true" [cancel]="false"></p-rating>
                    </div>
                </div>
                <div class="formgrid grid">
                    <div class="p-field col-6">
                        <label for="name"><span translate>label.name</span></label>
                        <input type="text" pInputText id="name" [(ngModel)]="item.name" required />
                        <small class="p-invalid" *ngIf="getFieldValidation(!item.name)"><span
                                translate>label.field_name_required</span></small>
                    </div>
                    <div class="p-field col-6">
                        <label for="email"><span translate>label.email</span></label>
                        <input type="text" pInputText id="email" [(ngModel)]="item.email" required />
                        <small class="p-invalid" *ngIf="getFieldValidation(!item.email)"><span
                                translate>label.field_email_required</span></small>
                    </div>
                </div>
                <div class="formgrid grid">
                    <div class="p-field col-6">
                        <label><span translate>label.spouse</span></label>
                        <sf-sc-person-dropdown componentType="basic" [id]="item.spouseId"
                            (chosedItem)="reciverFeedbackPersonSpouse($event)"
                            [placeHolderLanguage]="'label.spouse' | translate" [flagPersonType]="flagPersonType"></sf-sc-person-dropdown>
                        <!-- <small class="p-invalid" *ngIf="getFieldValidation(!item.spouseId)"><span
                                translate>label.field_spouse_required</span></small> -->
                    </div>
                    <div class="p-field col-6">
                        <label><span translate>label.marital_status</span></label>
                        <sf-sc-person-marital-status-dropdown [id]="item.scPersonMaritalStatusId"
                            (chosedItem)="reciverFeedbackPersonMaritalStatus($event)"
                            [placeHolderLanguage]="'label.marital_status' | translate"></sf-sc-person-marital-status-dropdown>

                        <small class="p-invalid" *ngIf="getFieldValidation(!item.scPersonMaritalStatusId)"><span
                                translate>label.field_marital_status_required</span></small>
                    </div>

                </div>
                <div class="formgrid grid">
                    <div class="p-field col-6">
                        <label for="mother_name"><span translate>label.mother_name</span></label>
                        <input type="text" pInputText id="mother_name" [(ngModel)]="item.motherName" required />
                        <small class="p-invalid" *ngIf="getFieldValidation(!item.motherName)"><span
                                translate>label.field_mother_name_required</span></small>
                    </div>
                    <div class="p-field col-6">
                        <label for="father_name"><span translate>label.father_name</span></label>
                        <input type="text" pInputText id="father_name" [(ngModel)]="item.fatherName" required />
                        <small class="p-invalid" *ngIf="getFieldValidation(!item.fatherName)"><span
                                translate>label.field_father_name_required</span></small>
                    </div>
                </div>
                <div class="formgrid grid">
                    <div class="p-field col-6">
                        <label for="addressid"><span translate>label.address</span></label>
                        <input style="font-size: 12px;" type="text" pInputText id="addressid" [ngModel]="formattedAddress" required />
                        <!-- <small class="p-invalid" *ngIf="getFieldValidation(!item.addressid)"><span
                                translate>label.field_address_required</span></small> -->
                    </div>
                    <div class="p-field col-2 address-button-dialog">
                        <p-button (click)="showDialogNewAddress()" icon="pi pi-external-link" [disabled]="!item.id"
                            [label]="'label.addresses' | translate"></p-button>
                    </div>

                    <div class="p-field col-4">
                        <label for="date_of_birth"><span translate>label.date_of_birth</span></label>
                        <input type="date" pInputText id="date_of_birth" [(ngModel)]="item.dateOfBirth" required />
                        <small class="p-invalid" *ngIf="getFieldValidation(!item.dateOfBirth)"><span
                                translate>label.field_date_of_birth_required</span></small>
                    </div>
                </div>
                <div class="formgrid grid">

                    <div class="p-field col-6">
                        <label for="profession"><span translate>label.profession</span></label>
                        <input type="text" pInputText id="profession" [(ngModel)]="item.profession" required />
                        <!-- <small class="p-invalid" *ngIf="getFieldValidation(!item.profession)"><span
                                translate>label.field_profession_required</span></small> -->
                    </div>

                    <div class="p-field col-6">
                        <label for="observation"><span translate>label.observation</span></label>
                        <input type="text" pInputText id="observation" [(ngModel)]="item.observation" required />
                        <!-- <small class="p-invalid" *ngIf="getFieldValidation(!item.observation)"><span
                                translate>label.field_observation_required</span></small> -->
                    </div>
                </div>

                <div class="formgrid grid">
                    <div class="p-field col-4">
                        <label><span translate>label.type_nationality</span></label>
                        <sf-sc-person-type-nationality-dropdown [id]="item.scPersonTypeNationalityId"
                            (chosedItem)="reciverFeedbackPersonTypeNationality($event)"
                            [placeHolderLanguage]="'label.type_nationality' | translate"></sf-sc-person-type-nationality-dropdown>
                        <!-- <small class="p-invalid" *ngIf="getFieldValidation(!item.scPersonTypeNationalityId)"><span
                                translate>label.field_type_nationality_required</span></small> -->
                    </div>
                    <div class="p-field col-4">
                        <label><span translate>label.matrimonial_property</span></label>

                        <sf-sc-person-matrimonial-property-dropdown [id]="item.scPersonMatrimonialPropertyId"
                            (chosedItem)="reciverFeedbackPersonMatrimonialProperty($event)"
                            [placeHolderLanguage]="'label.matrimonial_property' | translate"></sf-sc-person-matrimonial-property-dropdown>

                        <!-- <small class="p-invalid" *ngIf="getFieldValidation(!item.scPersonMatrimonialPropertyId)"><span
                                translate>label.field_matrimonial_property_required</span></small> -->
                    </div>

                    <div class="p-field col-4">
                        <label><span translate>label.gender</span></label>
                        <sf-sc-person-gender-dropdown [id]="item.scPersonGenderId"
                            (chosedItem)="reciverFeedbackPersonGender($event)"
                            [placeHolderLanguage]="'label.gender' | translate"></sf-sc-person-gender-dropdown>

                        <small class="p-invalid" *ngIf="getFieldValidation(!item.scPersonGenderId)"><span
                                translate>label.field_gender_required</span></small>
                    </div>

                </div>
                <div class="formgrid grid">

                    <div class="p-field col-1">
                        <label for="ddd_number"><span translate>DDD</span></label>
                        <p-inputMask mask="99" [(ngModel)]="item.telephone.ddd" placeholder="99"
                            inputId="ddd_number" styleClass="mask" [disabled]="!canUpdate"
                            [style]="{'width':'100%'}"></p-inputMask>
                        <small class="p-invalid" *ngIf="getFieldValidation(!(item.telephone.ddd > 0))"><span
                                translate>*</span></small>
                    </div>
                    <div class="p-field col-2">
                        <label for="phone_number"><span translate>label.phone_number</span></label>
                        <p-inputMask mask="99999999" [(ngModel)]="item.telephone.phone" placeholder="99999999"
                            inputId="phone_number" styleClass="mask" [disabled]="!canUpdate"
                            [style]="{'width':'100%'}"></p-inputMask>
                        <small class="p-invalid" *ngIf="getFieldValidation(!(item.telephone.phone > 0))"><span
                                translate>*</span></small>
                    </div>
                    <div class="p-field col-1">
                        <label for="mobile_ddd"><span translate>DDD</span></label>
                        <p-inputMask mask="99" [(ngModel)]="item.mobile.ddd" placeholder="99"
                            inputId="mobile_ddd" styleClass="mask" [disabled]="!canUpdate"
                            [style]="{'width':'100%'}"></p-inputMask>
                        <small class="p-invalid" *ngIf="getFieldValidation(!(item.mobile.ddd > 0))"><span
                                translate>*</span></small>
                    </div>
                    <div class="p-field col-2">
                        <label for="mobile_number"><span translate>label.mobile_number</span></label>
                        <p-inputMask mask="999999999" [(ngModel)]="item.mobile.phone" placeholder="999999999"
                            inputId="mobile_number" styleClass="mask" [disabled]="!canUpdate"
                            [style]="{'width':'100%'}"></p-inputMask>
                        <small class="p-invalid" *ngIf="getFieldValidation(!(item.mobile.phone > 0))"><span
                                translate>*</span></small>
                    </div>

                    <div class="p-field col-2">
                        <label><span translate>label.nationality</span></label>
                        <!-- <input type="text" pInputText id="nationality" [(ngModel)]="item.nationality" required /> -->
                        <sf-dropdown-generic *ngIf="loadListCountry" InputId="nationality" [list]="listCountry" [id]="item.scCountryId"
                            (selectedItemOutput)="reciverFeedbackCountry($event)" [filter]="true"
                            [style]="{'width':'100%'}"></sf-dropdown-generic>
                        <small class="p-invalid" *ngIf="getFieldValidation(!item.scCountryId)"><span
                                translate>label.field_nationality_required</span></small>
                    </div>

                    <div class="p-field col-2">
                        <label><span translate>label.birth_state</span></label>
                        <!-- <input type="text" pInputText id="birthState" [(ngModel)]="item.birthState" required /> -->
                        <sf-dropdown-generic *ngIf="loadListState" InputId="birthState" [list]="listState" [id]="item.scStateId"
                            (selectedItemOutput)="reciverFeedbackState($event)" [filter]="true"
                            [style]="{'width':'100%'}"></sf-dropdown-generic>
                        <small class="p-invalid" *ngIf="getFieldValidation(!item.scStateId)"><span
                                translate>label.field_birth_state_required</span></small>
                    </div>

                    <div class="p-field col-2">
                        <label><span translate>label.birth_city</span></label>
                        <!-- <input type="text" pInputText id="birthCity" [(ngModel)]="item.birthCity" required /> -->
                        <sf-dropdown-generic *ngIf="loadListCity" InputId="birthCity" [list]="listCity" [id]="item.scCityId"
                            (selectedItemOutput)="reciverFeedbackCity($event)" [filter]="true"
                            [style]="{'width':'100%'}"></sf-dropdown-generic>
                        <small class="p-invalid" *ngIf="getFieldValidation(!item.scCityId)"><span
                                translate>label.field_birth_city_required</span></small>
                    </div>

                </div>
                <div class="formgrid grid">
                    <div class="field-checkbox p-field col-3">
                        <p-checkbox [(ngModel)]="item.isActive" binary="true" inputId="isActive" ngDefaultControl
                            class="p-d-inline"></p-checkbox>
                        <label for="isActive" class="mb1 font-bold"><span translate>label.active</span></label>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <div style="float: right; display: flex; align-items: flex-end; gap: 8px;">

            <button pButton pRipple icon="pi pi-cloud-upload" style="width: 130px; margin: 3px;"
                class="p-button p-button-info p-mr-2 mt-3" [label]="'label.document_inserted' | translate"
                (click)="uploadFiles()"></button>

            <button pButton pRipple icon="pi pi-times" style="width: 130px; margin: 3px;"
                class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate"
                (click)="hideDialog()"></button>
            <div *ngIf="flUpdate">
                <button pButton pRipple icon="pi pi-check" style="width: 130px; margin: 3px;"
                    class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate"
                    (click)="saveItem(true)" [disabled]="getAllFieldValidation()"></button>
            </div>
        </div>
    </ng-template>
</p-dialog>

<p-dialog *ngIf="dialogPersonAddressVisible" [(visible)]="dialogPersonAddressVisible" [style]="{width: '50vw', height:'70vh'}">
    <p-header> {{ 'label.address' | translate }} </p-header>
    <!-- <app-address (formattedAddressEmitter)="formattedAddressSubmitted($event)" (newAddressEmitter)="addressSubmitted($event)" [addressId]="item.addressId"></app-address> -->

    <div class="col-12 md:col-12 lg:col-12 xl:col-12">

        <div class="card">

            <p-table #dtAddress [value]="listAddress" dataKey="id" [paginator]="true" [rows]="15" [showCurrentPageReport]="true"
                [currentPageReportTemplate]="pageMessage" [rowsPerPageOptions]="[15,50,100]"
                [globalFilterFields]="['route', 'city', 'state', 'postalCode']"
                styleClass="p-datatable-responsive-demo p-datatable-striped" [showCurrentPageReport]="true">

                <ng-template pTemplate="caption">
                    <div class="flex">
                        <p-button (click)="showDialogAddress()" icon="pi pi-external-link"
                            [label]="'button.new_address' | translate"></p-button>

                        <span class="p-input-icon-left ml-auto">
                            <i class="pi pi-search"></i>
                            <input #filter pInputText type="text"
                                (input)="dtAddress.filterGlobal($event.target.value, 'contains')"
                                [placeholder]="'label.address' | translate" />
                            <button pButton [label]="'button.clear' | translate" class="p-button-warning mr-2 ml-2"
                                icon="pi pi-filter-slash" (click)="clear(dtAddress, filter); filter.value = '';"></button>
                        </span>
                        <p-button icon="pi pi-refresh" (click)="getListAddress()"></p-button>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 15%"><span translate>label.main</span></th>
                        <th style="width: 70%" pSortableColumn="route"><span translate>label.address</span> <p-sortIcon
                                field="route"></p-sortIcon></th>
                        <!-- <th pSortableColumn="city"><span translate>label.city</span><p-sortIcon
                                field="city"></p-sortIcon>
                        </th>
                        <th pSortableColumn="state"><span translate>label.state</span><p-sortIcon
                                field="state"></p-sortIcon></th>
                        <th pSortableColumn="postalCode"><span translate>label.postalCode</span><p-sortIcon
                                field="postalCode"></p-sortIcon></th>                             -->
                        <th style="width: 15%; text-align: center;"><span translate>label.actions</span></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-itemAddress>
                    <tr>
                        <td><p-checkbox (click)="updItemAddress(itemAddress)" [(ngModel)]="itemAddress.checked" [binary]="true" inputId="binary_{{itemAddress.id}}"></p-checkbox></td>                        
                        <td>
                            {{itemAddress.route}},{{itemAddress.streetNumber == null ? ' SN' : itemAddress.streetNumber}} - {{itemAddress.streetComplement == null ? '' : itemAddress.streetComplement}} - {{itemAddress.neighborhood}} - {{itemAddress.city}}/{{itemAddress.state}} - {{itemAddress.postalCode}}
                        </td>
                        <!-- <td>{{item.city}}</td>
                        <td>{{item.state}}</td>
                        <td>{{item.postalCode}}</td> -->
                        <td class="text-center">
                            <!-- <p-button icon="pi pi-pencil" [styleClass]="classBtnUpd"
                                [pTooltip]="'button.edit' | translate" (click)="updItemAddress(item)"
                                [disabled]="!flUpdate"></p-button> -->
                            <p-button icon="pi pi-times" [styleClass]="classBtnDel"
                                [pTooltip]="'button.delete' | translate" (click)="delItemAddress(itemAddress)"
                                [disabled]="!flUpdate"></p-button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

    </div>

</p-dialog>

<p-dialog *ngIf="uploadFilesModal" [(visible)]="uploadFilesModal" [style]="{width: '50vw', height:'80vh'}">
    <p-header> {{ 'label.document_inserted' | translate }} </p-header>
    <div class="col-12 md:col-12 lg:col-12 xl:col-12">
        <div class="card">
            <sf-sc-person-file [scPersonId]="item.id"></sf-sc-person-file>            
        </div>
    </div>
</p-dialog>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
