import { DropDown, DropDownLabel } from 'src/app/shared/model/base-model';
import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { ScMenuService } from '../sc-menu.service';


@Component({
    selector: 'sf-menu-dropdown',
    templateUrl: './sc-menu-dropdown.component.html',
    styleUrls: ['./sc-menu-dropdown.component.scss']
})

export class ScMenuDropdownComponent implements OnInit{

    public list : DropDownLabel[];
    public selectedItem: DropDownLabel;
    public deleteItem: DropDownLabel;

    @Input() placeHolderLanguage: string;
    @Input() id: string;
    @Input() idDelete: string;
    @Output() chosedItem = new EventEmitter();

    constructor(  private scMenuService: ScMenuService){ }

    ngOnInit(): void {
        console.log("menu");
        this.getList();
    }

    ngOnChanges(): void {
        this.getList();
    }

    getList() {
        this.scMenuService.getDropDownList().subscribe(
          {
            next: (res) => {
                if (res)
                    this.list = res.data;


                if (this.list !== null){
                    this.selectedItem = this.list.find(x => x.id == this.id?.toString());
                    this.deleteItem = this.list.find(x => x.id == this.idDelete?.toString());

                    if (this.deleteItem != null || this.deleteItem != undefined)
                    {
                        let index = this.list.findIndex(d => d.id === this.deleteItem.id);
                        this.list.splice(index, 1);
                    }
                }
            },
            error: (err) => {
                console.log(`MenuDropDown - getList : ${err}.`);
            }
        });
      }

    feedback(){
        this.chosedItem.emit(this.selectedItem)
    }

}
