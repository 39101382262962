import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { MessageModule } from 'primeng/message';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { NotificationsModule } from '../../notifications/notifications.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ScGridGenericBasicComponent } from './grid-generic-basic/grid-generic-basic.component';
import { TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { SfDropDownModule } from '../dropdown/dropdown.module';

@NgModule({

    declarations: [ScGridGenericBasicComponent],
    exports: [ScGridGenericBasicComponent],
    imports: [  FormsModule,
        FormsModule,
        CommonModule,
        NotificationsModule,
        ButtonModule,
        InputTextModule,
        TranslateModule,
        MessageModule,
        TableModule,
        TooltipModule,
        InputMaskModule,
        SfDropDownModule,
        DialogModule,
        ConfirmDialogModule,
        DropdownModule,
        ColorPickerModule

    ]


})

export class SfGridGenericModule {

}
