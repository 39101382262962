import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';

import { DropDown } from 'src/app/shared/model/base-model';

import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ScCompanyService } from '../../../sc-company.service';
import { PaPlanService } from 'src/app/pages/pa-plan/pa-plan.service';
import { ScModuleService } from 'src/app/pages/sc-module/sc-module.service';
import { ScConfigService } from 'src/app/pages/sc-config/sc-config.service';
import { ScCompanyInfoInput } from '../../../sc-company.model';


@Component({
    selector: 'sc-company-add-step-type',
    templateUrl: './sc-company-add-step-type.component.html',
    //styleUrls: ['./sc-company-add.component.scss'],
    //providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})


export class ScCompanyAddStepTypeComponent implements OnInit, AfterViewInit {

    public step: ScCompanyInfoInput = {
        code: '',
        name: '',
        fantasyName: '',
        ein: '',
        logoUri:'',
        scCompanyTypeId:'',
        scCompanyTypeName:'',
        paPlanId:'',
        paPlanName:'',
        address:{},
        modules:[],
        configs:[],
        userEmail: '',
        userName: '',
        phone: '',
        scRoleId: '',
        scRoleName: ''
    };
    public submitted: boolean = false;
    public showPlan: boolean = false;
    public showType: boolean = false;


    public companyPlanList: DropDown[] = [];
    public paPlanId: string;

    public companyTypeList: DropDown[] = [];
    public scCompanyTypeId: string;

    public selectModuleAll: boolean = false;
    public selectedModules: DropDown[] = [];
    public moduleList: DropDown[] = [];
    
    public selectConfigAll: boolean = false;
    public selectedConfigs: DropDown[] = [];
    public configList: DropDown[] = [];

    public language: string = 'pt-BR';

    constructor(private companyService: ScCompanyService,
                private planService: PaPlanService,
                private moduleService: ScModuleService,
                private configService: ScConfigService,
                private translateConfigService: TranslateConfigService
            ) {}


    ngOnInit() {
        this.step = this.companyService.getAddSteps();
        this.language = this.translateConfigService.getLanguage();
        this.getTypes();
        this.getPlans();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.step = this.companyService.getAddSteps();
        }, 0);
      }

    reciverFeedbackCompanyPlan(id: string){
        this.paPlanId = id;
        if (id !== undefined && id !== null && id !== ""){
            this.step.paPlanId = id;
            this.step.paPlanName = this.companyPlanList.find(x => x.id == id).name;
        }
    }

    reciverFeedbackCompanyType(id: string){
        this.selectedModules = [];
        this.selectedConfigs = [];
        this.scCompanyTypeId = id;
        if (id !== undefined && id !== null && id !== ""){
            this.step.scCompanyTypeId = id;
            this.step.scCompanyTypeName = this.companyTypeList.find(x => x.id == id).name;
            this.getModules();
            this.getConfigs();
        }
    }


    onSelectModuleAllChange(event) {
        console.log('onSelectModuleAllChange');
        this.selectedModules = event.checked ? [...this.moduleList] : [];
        this.selectModuleAll = event.checked;
        event.target.updateModel(this.selectedModules, event.originalEvent);
    }

    onModuleChange(event) {
        const { originalEvent, value } = event
        if (this.moduleList != null && this.moduleList != undefined)
            if(value) this.selectModuleAll = value.length === this.moduleList.length;
        this.step.modules = this.selectedModules;
    }

    onSelectConfigAllChange(event) {
        this.selectedConfigs = event.checked ? [...this.configList] : [];
        this.selectConfigAll = event.checked;
        event.updateModel(this.selectedConfigs, event.originalEvent)
    }

    onConfigChange(event) {
        const { originalEvent, value } = event
        if (this.configList != null && this.configList != undefined)
            if(value) this.selectConfigAll = value.length === this.configList.length;
        this.step.configs = this.selectedConfigs;        
    }


    getPlans()
    {
        this.planService.getDropDownList(this.language).subscribe(
                {
                    next: (res) => {
                        if (res)
                            this.companyPlanList = res.data;
                    },
                    error: (err) => {
                        console.log(`ScCompanyAddStepTypeComponent - getPlans : ${err}.`);
                    }
                });
    }

    getTypes()
    {
        this.companyService.getListTypeDropDown().subscribe(
                {
                    next: (res) => {
                        if (res)
                            this.companyTypeList = res.data;
                    },
                    error: (err) => {
                        console.log(`ScCompanyAddStepTypeComponent - getTypes : ${err}.`);
                    }
                });
    }

    getModules()
    {
        if (this.step.scCompanyTypeId != null  && this.step.scCompanyTypeId !== undefined)
            this.moduleService.getDropDownList(this.step.scCompanyTypeId, this.language).subscribe(
                {
                    next: (res) => {
                        if (res)
                            this.moduleList = res.data;
                    },
                    error: (err) => {
                        console.log(`ScCompanyAddStepTypeComponent - getModuleList : ${err}.`);
                    }
                });
    }

    getConfigs()
    {
        if (this.step.scCompanyTypeId != null  && this.step.scCompanyTypeId !== undefined)
            this.configService.getDropDownList(this.step.scCompanyTypeId, this.language).subscribe(
                {
                    next: (res) => {
                        if (res)
                            this.configList = res.data;
                    },
                    error: (err) => {
                        console.log(`ScCompanyAddStepTypeComponent - getConfigList : ${err}.`);
                    }
                });
    }

    nextPage() {
        if (this.step.scCompanyTypeId && this.step.paPlanId && this.selectedModules.length > 0) {
            this.step.modules = this.selectedModules;
            this.step.configs = this.selectedConfigs;
            this.companyService.addSteps = this.step;
            this.companyService.dialogCompanyAddNext.emit();
            return;
        }
      this.submitted = true;



    }

    prevPage() {
        this.companyService.dialogCompanyAddBack.emit();
    }
}
