import { DropDownMeta, GenericItem, GenericListMeta } from '../../shared/model/base-model';
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, firstValueFrom } from "rxjs";

@Injectable({
    providedIn: 'any'
})

export class ScCompanyStatusService {

    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient) {
        this.environmentUrl = environment.ApiCore + '/Company/Status/';
    }

    getItem(id: string): Observable<GenericItem> {
        let url = this.environmentUrl + id;
        return this.httpClient.get<GenericItem>(url);
    }

    getList(): Observable<GenericListMeta> {
        let url = this.environmentUrl;
        return this.httpClient.get<GenericListMeta>(url);
    }

    addItem(model: GenericItem) {
        return this.httpClient.post(this.environmentUrl, model);
    }

    updItem(id: string, model: GenericItem) {
        let url = this.environmentUrl + id;
        return this.httpClient.put(url, model);
    }

    delItem(id: string) {
        return this.httpClient.delete(this.environmentUrl + id);
    }

    getDropDown(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown/";
        return this.httpClient.get<DropDownMeta>(url);
    }
}
