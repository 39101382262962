import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { HttpRequest } from "@angular/common/http";
import { HttpHandler } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { HttpSentEvent } from "@angular/common/http";
import { HttpHeaderResponse } from "@angular/common/http";
import { HttpProgressEvent } from "@angular/common/http";
import { HttpResponse } from "@angular/common/http";
import { HttpUserEvent } from "@angular/common/http";
import { retry, catchError, tap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

import { TokenService } from '../services/token.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { TranslateService } from "@ngx-translate/core";
import { TranslateConfigService } from "../services/translate-config.service";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private tokenService: TokenService,
              private injector: Injector,
              private notificationsService: NotificationsService,
              private loader: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent
    | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {


      /*Não mostrar o loader em certa url */
      // if (req.url.indexOf('/api/maxline/eventos') === -1) {
         this.loader.show();
      // }


    const router = this.injector.get(Router);

    if (this.tokenService.hasToken()) {
      const token = this.tokenService.getToken();
      req = req.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + token,
          'Accept-Language': 'pt-BR'
        }
      });
    }
    return next.handle(req).pipe(

      retry(0),

      catchError((error: HttpErrorResponse) => {
        console.error(error.error);
        if (error.status === 401 ) {
          //  if (this.tokenService.hasToken() && this.tokenService.isTokenExpired())
          //  {
          this.tokenService.removeToken();
          router.navigate(['/login']);
          //}
          return throwError(error);
        } else if (error.status === 403 ) {
          //  if (this.tokenService.hasToken() && this.tokenService.isTokenExpired())
          //  {
            this.notificationsService.toastNotify('error', 'Erro!', 'Access Denied - No permission.');

            //this.notificationsService.toastNotify('error', 'Erro!', this.translateService.instant('message.no_permission'));

          //}
          return throwError(error);
        } else if (error.status != 404) {
          if(error.error.objects){
            this.notificationsService.toastNotify('error',error.error.title, error.error.objects[0].userMessage);
          }else
          if(error.error.detail){
            this.notificationsService.toastNotify('error',error.error.title, error.error.detail);
          }else{

            if (typeof error.error === "string")
              this.notificationsService.toastNotify('error', 'Erro!', error.error);
            else
              this.notificationsService.toastNotify('error', 'Erro!', error.message + ": " + error.status);
          }
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    ).pipe(finalize(() => this.loader.hide()));

  }
}
