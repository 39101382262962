
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router} from "@angular/router";
import {Injectable} from "@angular/core";


import { TokenService } from '../services/token.service';
import { UserTokenService } from "../services/user-token.service";

@Injectable()
export class AuthorizationGuard implements  CanActivate {

    private allowedRoles:string[];

    constructor(private userTokenService: UserTokenService, private tokenService: TokenService, private router: Router ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        this.allowedRoles = route.data["roles"];

        const allowed:boolean = this.userTokenService.getRoles().filter(
            role=>this.allowedRoles.includes(role)).length > 0;

            if(allowed){
                return allowed;
            }else{
                return this.router.parseUrl('/accessdenied');
            }

    }

}
