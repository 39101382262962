
<div class="login-body">
    <div class="card login-panel p-fluid">
        <div class="login-panel-content">
            <div class="p-grid">
                <div class="col-12 login-icon-container">
                    <a href="#">
                        <img src="assets/layout/images/sf-logoTabellioFull150.png" style="margin-right: 10px; width: 40px;" alt="california-layout" />
                        <span class="fontLoginCria" >Service</span><span class="fontLoginService" >Flow</span>
                    </a>
                </div>
                <div class="col-12 lg:col-12 md:col-12 logo-container">
                    <h2 class="titulo"><span translate>button.recover_pwd</span></h2>

                    <div *ngIf="tokenValido">
                        <form class="form-login nao-validar" [formGroup]="loginForm" (submit)="alterarSenha()">

                            <h5 class="titulo">{{usuario}}</h5>
                            <cria-vmessage [hidden]="valid" [text]="'label.required_fields' | translate"></cria-vmessage>

                            <div class="field">
                                <label for="pwd" class="block"><span translate>label.pwd</span></label>
                                <input #senha1Input type="password" formControlName="password" aria-describedby="pwd-help"
                                    pInputText />

                                <small id="pwd-help" class="p-error block">
                                    <div [hidden]="valid"><span translate>validation_errors.required_pwd</span></div>
                                </small>
                            </div>

                            <div class="field">
                                <label for="pwd" class="block"><span translate>label.pwd_confirm</span></label>
                                <input #senha2Input type="password" formControlName="password2" aria-describedby="pwd-help"
                                    pInputText />

                                <small id="pwd-help" class="p-error block">
                                    <div [hidden]="valid"><span translate>validation_errors.required_pwd</span></div>
                                </small>
                            </div>

                            <button [disabled]="loginForm.invalid" [disabled]="!valid" type="submit" class="btn btn-primary button-login"><span translate>button.pwd_update</span></button>
                        </form>
                    </div>

                    <div *ngIf="!tokenValido">

                        <p-message severity="warn" [text]="msg | translate" styleClass="p-mr-2"></p-message>

                        <div style="margin-top: 15px;">
                            <p-message severity="success" [text]="'label.msg_token_pwd_reset' | translate" styleClass="p-mr-2" ></p-message>
                        </div>


                        <button type="submit" class="btn btn-primary button-login" (click)="novaSenha()" style="margin-top: 15px;"><span translate>button.recover_pwd</span></button>

                    </div>




                    <div class="p-grid" style="margin-top: 200px;">
                        <div class="p-col-12">
                            <span translate style="margin-left: 150px;">menu.choose_language</span>
                        </div>
                        <div class="col-12 lg:col-12 md:col-12">
                            <div style="margin-left: 120px;">
                                <span class="flag flag-br" style="width: 30px; height: 20px;" (click)="changeLanguage('pt-BR')"
                                    style="margin-left: 5px; margin-top: 10px;"></span>
                                <span class="flag flag-gb" style="width: 30px; height: 20px" (click)="changeLanguage('en-US')"
                                    style="margin-left: 5px; margin-top: 10px;"></span>
                                <span class="flag flag-pt" style="width: 30px; height: 20px" (click)="changeLanguage('pt-PT')"
                                    style="margin-left: 5px; margin-top: 10px;"></span>
                            </div>
                        </div>
                    </div>
    </div>
</div>

