import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { WoSkill } from '../wo-skill.model';
import { WoSkillService } from '../wo-skill.service';
import { DropDown } from 'src/app/shared/model/base-model';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';






@Component({
    selector: 'wo-skill-grid',
    templateUrl: './wo-skill-grid.component.html',
    styleUrls: ['./wo-skill-grid.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})

export class WoSkillGridComponent implements OnInit {

    public list: WoSkill[] = [];
    public id: string;
    public item: WoSkill;
    public userId: string = null;

    public submitted: boolean;
    public displayMsg: boolean = false;
    public displayDialog: boolean;
    public dialogHeader: string;
    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-warning p-m-1 mr-1";

    public flUpdate: boolean = true;

    constructor(  private claimService: WoSkillService,
                    private messageService: MessageService,
                    private confirmationService: ConfirmationService,
                    private translateService: TranslateService,
                    private translateConfigService: TranslateConfigService,
                    private notificationsService: NotificationsService,
                    private breadcrumbService: BreadcrumbService,
                    private userTokenService: UserTokenService)
        { }

    ngOnInit(): void {

        this.userId = this.userTokenService.getUserId();

        this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN']);

        if (this.flUpdate == false)
        {
            this.classBtnUpd = "p-button p-button-secondary p-m-1 mr-1";
            this.classBtnDel = this.classBtnUpd;
        }

        this.getList();
    }

    getList() {
        this.claimService.getList().subscribe(
            {
            next: (res) => {
                if (res)
                    this.list = res.data;
                this.translate();
            },
            error: (err) => {
                console.log(`WoSkillGrid - getLista : ${err}.`);
            }
        });
    }

    getItem(id) {
        this.claimService.getItem(id).subscribe(
        {
            next: (res) =>
            {
                if (res) {
                    this.item = res.data;
                }
            },
            error: (err) => {
                console.log(`WoSkillGrid - getItem : ${err}.`);
            }
        });
    }


    addItem() {
        this.item = {};
        this.submitted = false;
        this.displayDialog = true;
    }

    updItem(item: WoSkill) {
        this.item = {...item};
        this.displayDialog = true;
    }

    delItem(item: WoSkill) {

        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_delete')+' ' + item.name + '?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.claimService
                .delItem(item.id)
                .subscribe(
                {
                    next: () =>
                    {
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.wo-skill'), this.translateService.instant('message.record_deleted'));
                        this.getList();
                    },
                    error: (err) => {
                        this.notificationsService.toastNotify('warn', this.translateService.instant('title.wo-skill'), this.translateService.instant('api_response.ERROR'));
                        console.log(`WoSkillGrid - delItem : ${err}.`);
                    }
                });
            }
      });
    }

    saveItem() {
        this.submitted = true;
        if (this.item.code.trim()) {
            if (this.item.id) {

            this.claimService
            .updItem(this.item, this.userId)
            .subscribe(
                {
                next: () => {
                    this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_updated'), life: 3000});
                    this.getList();
                },
                error: (err) => console.log(err)
            });

            } else {
                this.claimService
                    .addItem(this.item)
                    .subscribe(
                    {
                    next: () => {
                        this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_inserted'), life: 3000});
                    this.getList();
                    },
                    error: (err) => console.log(err)
                    });
            }

            this.list = [...this.list]
            this.displayDialog = false;
            this.item = {};
        }
    }

    translate()
    {
        const labAccessControl = this.translateService.instant('label.access_control')+" ";
        const labClaim = " "+this.translateService.instant('label.claims');
        this.breadcrumbService.setItems([{label: labAccessControl},{label: labClaim, routerLink: ['claim']} ]);

        this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')

        this.dialogHeader = this.translateService.instant('title.sc_claim_edit');

    }

    hideDialog() {
        this.displayDialog = false;
        this.submitted = false;
    }



    clear(table: Table) {
        table.clear();
    }

    reciverFeedbackIcon(icon: DropDown) {
        this.item.icon = icon.id;
    }

    reciverFeedbackColor(color: string) {
        console.log(color);
        this.item.color = color;
    }
}
