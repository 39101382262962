
import { ScConfigService } from '../sc-config.service';
import { DropDown } from '../../../shared/model/base-model';
import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { ScConfigOutput } from '../sc-config.model';

@Component({
    selector: 'sf-sc-config-data-view',
    templateUrl: './sc-config-data-view.component.html',
    styleUrls: ['./sc-config-data-view.component.scss']
})

export class ScConfigDataViewComponent implements OnInit{

    public list : ScConfigOutput[];
    public item: ScConfigOutput;
    public selectedList: DropDown[];

    @Input() language: string;
    @Input() scCompanyTypeId: string = "";
    @Input() disabled: boolean;
    @Output() chosedItem = new EventEmitter();

    constructor(  private scConfigService: ScConfigService){ }

    ngOnInit(): void {

        if (this.disabled == null)
            this.disabled = false;

        this.getList();
    }

    ngOnChanges(): void {
        this.getList();
    }

    getList() {
        if (this.scCompanyTypeId == null  || this.scCompanyTypeId === undefined) 
            this.scCompanyTypeId = "";

        this.scConfigService.getList(this.scCompanyTypeId, this.language).subscribe(
          {
            next: (res) => {
                if (res)
                    this.list = res.data;

                this.selectedList = this.list.filter(x => x.isChecked === true).map(x => ({ id: x.id, name: x.name }));
                this.feedback();
            },
            error: (err) => {
                console.log(`ScConfigDataView - getList : ${err}.`);
            }
        });
      }

    check(id: string)
    {
        this.selectedList = this.list.filter(x => x.isChecked === true).map(x => ({ id: x.id, name: x.name }));
        const item = this.list.find((x) => x.id === id);
        item.isChecked = true;        
        this.selectedList.push({id: id, name: item.name})        
        this.feedback();      
        //console.log(this.selectedList) ;
    }

    unCheck(id: string)
    {
        this.selectedList = this.list.filter(x => x.isChecked === true).map(x => ({ id: x.id, name: x.name }));
        const item = this.list.find((x) => x.id === id);
        item.isChecked = false;
        this.selectedList = this.selectedList.filter(x => x.id !== id); 
        this.feedback();       
        //console.log(this.selectedList) ;
    }

    feedback(){
        this.chosedItem.emit(this.selectedList);
    }
}
