<sf-notification></sf-notification>
<div class="p-grid">
    <div class="p-col-12" style="margin-top: -22px;">
        <p-table #dt [value]="list" dataKey="id" [paginator]="true" [rows]="15"
            [showCurrentPageReport]="true"
            [currentPageReportTemplate]="pageMessage"
            [rowsPerPageOptions]="[15,50,100]"
            [globalFilterFields]="['code','name']"
            styleClass="p-datatable-responsive-demo p-datatable-striped"  [showCurrentPageReport]="true">

            <ng-template pTemplate="caption">
                <div class="flex">
                    <button pButton pRipple icon="pi pi-plus" [label]="'button.organizarion_structure_new' | translate" class="p-button-success p-mr-2" style="width: 150px;"
                    (click)="addItem()" *ngIf="flUpdate"></button>

                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input #filter pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"  [placeholder]="'search.select_organization_structure' | translate" />
                        <button pButton [label]="'button.clear' | translate" class="p-button-warning mr-2 ml-2" icon="pi pi-filter-slash" (click)="clear(dt, filter); filter.value = '';"></button>
                    </span>
                    <p-button icon="pi pi-refresh" (click)="getList()"></p-button>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 105px; text-align: center;"><span translate>label.actions</span></th>
                    <th pSortableColumn="name"><span translate>label.name</span><p-sortIcon field="name"></p-sortIcon></th>
                    <th pSortableColumn="hierarchyLabel"><span translate>label.hierarchy</span> <p-sortIcon field="hierarchyLabel"></p-sortIcon></th>
                    <th pSortableColumn="level"><span translate>label.level</span> <p-sortIcon field="level"></p-sortIcon></th>
                    <th pSortableColumn="city"><span translate>label.city</span> <p-sortIcon field="city"></p-sortIcon></th>
                    <th><span translate>label.active</span></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>
                        <p-button icon="pi pi-pencil" [styleClass]="classBtnUpd" [pTooltip]="'button.edit' | translate" (click)="updItem(item)"  [disabled]="!flUpdate"></p-button>
                        <p-button icon="pi pi-times" [styleClass]="classBtnDel" [pTooltip]="'button.delete' | translate"   (click)="delItem(item)" [disabled]="!flUpdate"></p-button>
                    </td>
                    <td>{{item.name}}</td>
                    <td>{{item.hierarchyLabel}}</td>
                    <td>{{item.level}}</td>
                    <td>{{item.ScCityName}}</td>
                    <td><input type="checkbox"  id="isActive" [(ngModel)]="item.isActive"></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog [(visible)]="displayDialog" [style]="{width: '500px'}" [modal]="true" styleClass="p-fluid" [header]="dialogHeader">
    <ng-template pTemplate="content">
        <div style="margin-top:1px;" class="p-grid p-d-flex dialog-page">
            <div class="p-md-12  p-lg-6 p-xl-6 p-order-1 p-order-lg-1">

                <div class="p-field">
                    <label for="name"><span translate>label.name</span></label>
                    <input type="text" pInputText id="name" [(ngModel)]="item.name" required />
                    <small class="p-invalid" *ngIf="submitted && !item.name"><span translate>label.field_name_required</span></small>
                </div>

                <div class="field">
                    <label for="parent"><span class="font-bold"  translate>label.organizarion_structure_parent</span></label>
                    <sf-sc-menu-dropdown [id]="item.scMenuParentId" [idDelete]="item.id" (chosedItem)="reciverFeedbackParent($event)" [placeHolderLanguage]="'label.select_organizarion_structure' | translate"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary w-full">
                    </sf-sc-menu-dropdown>
                </div>

                <div class="field">
                    <label for="claim"><span class="font-bold"  translate>label.country</span></label>
                    <sf-sc-claim-dropdown-multi [selectedItems]="listSelectedClaim" (chosedItem)="reciverFeedbackClaims($event)" [placeHolderLanguage]="'label.select_claim' | translate"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary w-full">
                    </sf-sc-claim-dropdown-multi>
                </div>

                <div class="field">
                    <label for="claim"><span class="font-bold"  translate>label.state</span></label>
                    <sf-sc-claim-dropdown-multi [selectedItems]="listSelectedClaim" (chosedItem)="reciverFeedbackClaims($event)" [placeHolderLanguage]="'label.select_claim' | translate"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary w-full">
                    </sf-sc-claim-dropdown-multi>
                </div>

                <div class="field">
                    <label for="claim"><span class="font-bold"  translate>label.city</span></label>
                    <sf-sc-claim-dropdown-multi [selectedItems]="listSelectedClaim" (chosedItem)="reciverFeedbackClaims($event)" [placeHolderLanguage]="'label.select_claim' | translate"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary w-full">
                    </sf-sc-claim-dropdown-multi>
                </div>

                <div class="p-field">
                    <label for="name"><span translate>label.address</span></label>
                    <sf-sc-claim-dropdown-multi [selectedItems]="listSelectedClaim" (chosedItem)="reciverFeedbackClaims($event)" [placeHolderLanguage]="'label.select_claim' | translate"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary w-full">
                    </sf-sc-claim-dropdown-multi>
                </div>



                <div class="p-field">
                    <input type="checkbox"  id="isActive" [(ngModel)]="item.isActive">
                    <label for="isActive"><span translate>label.active</span></label>
                </div>

            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <div style="float: right;">
            <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="saveItem()"></button>
            <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="hideDialog()"></button>
        </div>
    </ng-template>
</p-dialog>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
