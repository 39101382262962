import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';



import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { ScCompany, ScCompanyOutput } from '../../sc-company.model';
import { ScCompanyService } from '../../sc-company.service';
import { DropDown } from 'src/app/shared/model/base-model';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScModuleService } from 'src/app/pages/sc-module/sc-module.service';
import { ConstantsService } from 'src/app/core/services/constants.service';
import { ScConfigService } from 'src/app/pages/sc-config/sc-config.service';



@Component({
    selector: 'sc-company-detail',
    templateUrl: './sc-company-detail.component.html',
    styleUrls: ['./sc-company-detail.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})

export class ScCompanyDetailComponent implements OnInit {

    public item: ScCompanyOutput = { scCompanyStatusId: "", scCompanyTypeId: ""};
    public id: string;
    public visible: boolean = false;
    public formattedAddress: string = "";
    public zipCodeMask: String = "";
    public UfList: DropDown[] = [];
    public citiesList: DropDown[] = [];

    public companyStatusList: DropDown[] = [];    
    public companyTypeList: DropDown[] = [];    
    public planList: DropDown[] = [];
    
    public moduleList: DropDown[] = [];
    public selectedModules: DropDown[] = [];
    public selectModuleAll: boolean = false;

    public configList: DropDown[] = [];
    public selectedConfigs: DropDown[] = [];
    public selectConfigAll: boolean = false;
    

    public hasPicture: boolean = false;

    public flUpdate: boolean = true;
    public submitted: boolean = false;
    public language: string = "pt-BR";
    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-warning p-m-1 mr-1";
    


    constructor(private companyService: ScCompanyService,
                private moduleService: ScModuleService,
                private configService: ScConfigService,
                private router: Router,
                private activateRoute: ActivatedRoute,
                private notificationsService: NotificationsService,
                private constantsService: ConstantsService,
                private messageService: MessageService,
                private translateService: TranslateService,
                private userTokenService: UserTokenService,
                private translateConfigService: TranslateConfigService,
                private breadcrumbService: BreadcrumbService) {



    }

    ngOnInit(): void {

        this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN','SF_SELLER']);
        this.language = this.translateConfigService.getLanguage();
        this.translate();
        //this.item = this.activateRoute.snapshot.data['ScCompany'].company;

        this.activateRoute.paramMap.subscribe(params => {
            if (params.has('id')) {                
                this.id = params.get('id');
                this.getItem();
                if (this.item.modules == null)
                    this.item.modules = [];
                if (this.item.configs == null)
                    this.item.configs = [];

            }
        });

      


    }

    getItem() {
        this.companyService.getItem(this.id).subscribe(
            {
                next: (res) => {
                    if (res) {
                        this.item = res.data;

                        if (this.item == null || this.item == undefined) {
                            console.log(`Erro : ${this.item}.`);
                            this.messageError(this.translateService.instant('api_response.RECORD_NOT_FOUND'));
                            this.router.navigate(['company']);
                        }
                        else {                        
                            this.getCompanyStatus();
                            this.getCompanyTypes();
                            this.getModules();
                            this.getConfigs();
                        }
                    }
                },
                error: (err) => {
                    console.log(`ScCompanyDetailComponent - getItem : ${err}.`);
                }
            });
    }

    getCompanyStatus() {
        this.companyService.getListStatusDropDown().subscribe(
            {
                next: (res) => {
                    if (res)
                        this.companyStatusList = res.data;
                },
                error: (err) => {
                    console.log(`ScCompanyDetailComponent - getCompanyStatusList : ${err}.`);
                }
            });
    }

    getCompanyTypes() {
        this.companyService.getListTypeDropDown().subscribe(
            {
                next: (res) => {
                    if (res)
                        this.companyTypeList = res.data;
                },
                error: (err) => {
                    console.log(`ScCompanyDetailComponent - getCompanyTypeList : ${err}.`);
                }
            });
    }

    getModules()
    {
        var id = '';

        if (this.item.scCompanyTypeId != null  || this.item.scCompanyTypeId !== undefined)
            id = this.item.scCompanyTypeId

        this.moduleService.getDropDownList(id, this.language).subscribe(
            {
                next: (res) => {
                    if (res){
                        this.moduleList = res.data;
                        this.selectedModules = this.item.modules;
                    }
                },
                error: (err) => {
                    console.log(`ScCompanyDetailComponent - getModuleList : ${err}.`);
                }
            });
    }

    getConfigs()
    {
        var id = '';

        if (this.item.scCompanyTypeId != null  || this.item.scCompanyTypeId !== undefined)
            id = this.item.scCompanyTypeId
        this.configService.getDropDownList(id, this.language).subscribe(
            {
                next: (res) => {
                    if (res){
                        this.configList = res.data;
                        this.selectedConfigs = this.item.configs;                        
                    }
                },
                error: (err) => {
                    console.log(`ScCompanyDetailComponent - getConfigList : ${err}.`);
                }
            });
    }

    onSelectModuleAllChange(event) {
        this.selectedModules = event.checked ? [...this.moduleList] : [];
        this.selectModuleAll = event.checked;
        event.target.updateModel(this.selectedModules, event.originalEvent);
    }

    onModuleChange(event) {
        const { originalEvent, value } = event
        if (this.moduleList != null && this.moduleList != undefined)
            if(value) this.selectModuleAll = value.length === this.moduleList.length;
        this.item.modules = this.selectedModules;
    }

    onSelectConfigAllChange(event) {
        this.selectedConfigs = event.checked ? [...this.configList] : [];
        this.selectConfigAll = event.checked;
        event.updateModel(this.selectedConfigs, event.originalEvent)
    }

    onConfigChange(event) {
        const { originalEvent, value } = event
        if (this.configList != null && this.configList != undefined)
            if(value) this.selectConfigAll = value.length === this.configList.length;
        this.item.configs = this.selectedConfigs;        
        console.log(this.item.configs);
        console.log(`configs ${this.item.configs}`);
                        console.log(`list ${this.configList}`);
    }

    reciverFeedbackCompanyPlan(id: string) {
        if (id == null || id == "" || id == undefined) {
            this.item.paPlanId = null;
        }
        else {
            this.item.paPlanId = id;
        }
    }
 
    reciverFeedbackCompanyStatus(id: string) {
        if (id == null || id == "" || id == undefined) {
            this.item.scCompanyStatusId = null;
        }
        else {
            this.item.scCompanyStatusId = id;
        }
    }

    reciverFeedbackCompanyType(id: string) {
        if (id == null || id == "" || id == undefined) {
            this.item.scCompanyTypeId = null;
        } else {
            this.item.scCompanyTypeId = id;
        }
    }
 
    addressSubmitted(addressId) {
        this.visible = false;

        if (addressId == null || addressId == "" || addressId == undefined) {
            this.item.scAddressId = null;
        }
        else {
            this.item.scAddressId = addressId;
        }

    }

    showDialog() {
        this.visible = true;
    }

    formattedAddressSubmitted(formattedAddress) {
        this.formattedAddress = formattedAddress;
    }

    fileChangeEvent(event){

    }

   
    close(){
        this.router.navigate(['company']);
    }

    save() {
        if (this.item.id) {
            this.companyService
            .updItem(this.item.id, this.item)
            .subscribe({
                next: () => {
                    this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_updated'));
                    this.router.navigate(['company']);
                },
                error: (err) => {
                    this.messageError(this.translateService.instant('api_response.ERROR'));
                    console.log(`CompanyDetail - updItem : ${err}.`);
                }
            });
        }        
    }

    translate() {

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));

        //Need 50ms to get and set de language by cookie
        (async () => {
            await sleep(100);
            const labCompanies = this.translateService.instant('label.companies') + " ";
            const labCompany = " " + this.translateService.instant('label.company');
            const labCompanyDetail = " " + this.translateService.instant('label.scCompanyDetail');
            this.breadcrumbService.setItems([{ label: labCompanies }, { label: labCompany, routerLink: ['company'] }, { label: labCompanyDetail, routerLink: ['company-detail/' + this.item.id] }]);
            })();
    }

    messageSuccess(message: string){
        this.messageService.add({severity: this.constantsService.SEVERITY_SUCCESS, summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 3000});
    }

    messageError(message: string){
        this.messageService.add({severity: this.constantsService.SEVERITY_WARNING, summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 3000});
    }


}
