import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WoNotarialSealService } from "../wo-notarial-seal.service";
import { WoNotarialSealOutput } from "../wo-notarial-seal.model";
import { UserTokenService } from "src/app/core/services/user-token.service";

@Component({
    selector: 'wo-notarial-seal-reserve',
    templateUrl: './wo-notarial-seal-reserve.component.html'
})
export class WoNotarialSealReserveComponent implements OnInit {
    
    @Output() selectedItemOutput = new EventEmitter();
    listWoNotarialSealOutput: WoNotarialSealOutput[] = [];
    selectedItem: WoNotarialSealOutput = {};
    @Input() woNotarialSealId: string;
    @Input() cssClass: string;

    constructor(private woNotarialSealService: WoNotarialSealService,
                private userTokenService: UserTokenService
    ) {}

    ngOnInit(): void {
        this.getListNotarialSeal();        
    }

    getListNotarialSeal() {
        this.woNotarialSealService.getList().subscribe(
            {
              next: (res) => {
                  if (res) {
                      this.listWoNotarialSealOutput = res.data;

                    if (this.listWoNotarialSealOutput.length > 0 && (this.woNotarialSealId === undefined || this.woNotarialSealId === null)) {
                        this.reserveNotarialSeal(this.listWoNotarialSealOutput.shift());
                    } else {
                        this.selectedItem.code = this.woNotarialSealId;
                    }
                  }           
              },
              error: (err) => {
                  console.log(`WoNotorialSealReserveComponent - getListNotarialSeal : ${err}.`);
              }
          });
    }

    reserveNotarialSeal(woNotarialSealOutput: WoNotarialSealOutput) {
        this.woNotarialSealService.reserve(woNotarialSealOutput.id, this.userTokenService.getUserId()).subscribe(
            {
              next: () => {
                if (woNotarialSealOutput != null) {
                    this.selectedItem = woNotarialSealOutput;
                    this.selectedItemOutput.emit(woNotarialSealOutput);
                }
              },
              error: (err) => {
                  console.log(`WoNotorialSealReserveComponent - getListNotarialSeal : ${err}.`);
              }
          });
    }
}