import { DropDown } from 'src/app/shared/model/base-model';
import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { WoSkillService } from '../wo-skill.service';


@Component({
    selector: 'sf-wo-skill-dropdown',
    templateUrl: './wo-skill-dropdown.component.html',
    styleUrls: ['./wo-skill-dropdown.component.scss']
})

export class WoSkillDropdownComponent implements OnInit{

    public list : DropDown[];
    @Input() placeHolderLanguage: string;
    public selectedItem: DropDown;

    @Input() id: string;
    @Output() chosedItem = new EventEmitter();

    constructor(  private WoSkillService: WoSkillService){ }

    ngOnInit(): void {
        this.getList();
    }

    getList() {
        this.WoSkillService.getDropDownList().subscribe(
          {
            next: (res) => {
                if (res)
                    this.list = res.data;
                    if (this.list !== null)
                        this.selectedItem = this.list.find(x => x.id == this.id?.toString());
            },
            error: (err) => {
                console.log(`SkillDropDown - getList : ${err}.`);
            }
        });
      }

    feedback(){
        this.chosedItem.emit(this.selectedItem.id)
    }

}
