import { NgModule, OnInit } from '@angular/core';
import { EnvComponent } from './env.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [EnvComponent],
    exports: [EnvComponent],
    imports: [ CommonModule]
})
export class EnvModule {

}