import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import localePT from '@angular/common/locales/pt';
import localeExtraPT from '@angular/common/locales/extra/pt';


import { TableModule } from 'primeng/table';
import { InputMaskModule } from 'primeng/inputmask';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { WoOrderService } from './wo-order.service';
import { StepsModule } from 'primeng/steps';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TimelineModule } from 'primeng/timeline';
import { GalleriaModule } from 'primeng/galleria';

import { AuthService } from 'src/app/core/auth/auth.service';
import { AuthorizationGuard } from 'src/app/core/guards/authorization.guard';

import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';
import { DarkenOnHoverModule } from 'src/app/shared/directives/darken-on-hover/darken-on-hover.module';

import { ScOrganizationStructureModule } from '../sc-organization-structure/sc-organization-structure.module';


import { SfDropDownModule } from 'src/app/shared/components/dropdown/dropdown.module';
import { WoOrderDetailComponent } from './wo-order-service/wo-order-detail/wo-order-detail.component';
import { WoOrderGridComponent } from './wo-order-service/wo-order-grid/wo-order-grid.component';


registerLocaleData(localePT, 'pt', localeExtraPT);

@NgModule({
    declarations: [WoOrderGridComponent, WoOrderDetailComponent],
    exports: [WoOrderGridComponent, WoOrderDetailComponent],
    imports: [FormsModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        ButtonModule,
        InputTextModule,
        InputMaskModule,
        CheckboxModule,
        TranslateModule,
        MessageModule,
        MessagesModule,
        TableModule,
        TooltipModule,
        ConfirmDialogModule,
        LoaderModule,
        TabViewModule,
        CardModule,
        DialogModule,
        NotificationsModule,
        ScOrganizationStructureModule,
        StepsModule,
        DropdownModule,
        DarkenOnHoverModule,
        InputNumberModule,
        CalendarModule,
        InputTextModule,
        RatingModule,
        ScrollPanelModule,
        TimelineModule,
        GalleriaModule,
        SfDropDownModule,
        //WoOrderPaymentModule
        ],
    providers: [NotificationsService, AuthService, AuthorizationGuard, WoOrderService, {provide: LOCALE_ID, useValue: 'pt'}]
})
export class WoOrderModule { }


