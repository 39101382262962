
export interface PaCashier {
    amount?: number;
    note?: string;
}

export interface PaCashierOpen {
    data?: PaCashier
}

export interface PaCashierClose {
    data?: PaCashier
}

export interface PaCashierTransactionRegister extends PaCashier{
    paymentType?: PaymentType,
    transactionType?: TransactionType
}

export interface CashOpeningResponse {
    data: {
        id: string;
        pointOfSaleId: string;
        status: number;
        openingDate: string;
        openingBalance: number;
        note: string;
        userId: string;
        createdAt: string;
        userIdCreated: string;
    };

}

export enum PaymentType {
    Cash = 1,
    CreditCard = 2,
    DebitCard = 3,
    Pix = 4
}

export enum TransactionType {
    Payment  = 1,
    Withdrawal = 2,
}

