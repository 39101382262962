<ng-container pContainer="content">
<div style="margin-top:1px;" class="p-grid p-d-flex dialog-page">
    <div class="p-md-12  p-lg-6 p-xl-6 p-order-1 p-order-lg-1">

        <div class="p-field">
            <label for="ssn" class="mb1 font-bold"><span translate>label.person_ssn</span></label>
            <p-inputMask #inputSsn mask="999.999.999-99" [(ngModel)]="ssn" placeholder="999.999.999-99" id="ssn"
                styleClass="mask" [style]="{'width':'100%'}" (onComplete)="onCompleteCpf()"></p-inputMask>
        </div>
        <div class="p-field">
            <label for="namePersonBasic"><span translate>label.name</span></label>
            <input #inputName type="text" pInputText id="namePersonBasic" [(ngModel)]="name" maxlength="255" required />
            <small class="p-invalid" *ngIf="!name"><span translate>label.field_name_required</span></small>
        </div>
    </div>
</div>
</ng-container>
<ng-container pContainer="footer">
<div style="float: right;">
    <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3"
        [label]="'button.cancel' | translate" (click)="hideDialog()"></button>
    <button *ngIf="flUpdate" pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;"
        class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="savePerson()"
        [disabled]="!name || !ssn"></button>    
</div>
</ng-container>