
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';
import { AuthService } from 'src/app/core/auth/auth.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { AuthorizationGuard } from 'src/app/core/guards/authorization.guard';
import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { VMessageModule } from 'src/app/shared/components/vmessage/vmessage.module';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MultiSelectModule } from 'primeng/multiselect';

import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { SfcReconhecimentoFirmaComponent } from './sfc-reconhecimento-firma.component';
import { SfcReconhecimentoFirmaService } from './sfc-reconhecimento-firma.service';
import { SfDropDownModule } from 'src/app/shared/components/dropdown/dropdown.module';
import { InputNumberModule } from 'primeng/inputnumber';
import { GalleriaModule } from 'primeng/galleria';
import { SfcDropDownClerkModule } from '../sfc-dropdown-clerk.component/sfc-dropdown-clerk.module';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { WoNotarialSealModule } from 'src/app/pages/wo-notarial-seal/wo-notarial-seal.module';
import { SCPersonModule } from 'src/app/features/sc-person/sc-person.module';
import { CheckboxModule } from 'primeng/checkbox';


@NgModule({
    declarations: [SfcReconhecimentoFirmaComponent],
    exports: [SfcReconhecimentoFirmaComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        VMessageModule,
        RouterModule,
        NotificationsModule,
        ButtonModule,
        InputTextModule,
        TranslateModule,
        MessageModule,
        MessagesModule,
        TableModule,
        TooltipModule,
        DialogModule,
        ConfirmDialogModule,
        DropdownModule,
        MultiSelectModule,
        InputNumberModule,
        SfDropDownModule,
        SCPersonModule,
        GalleriaModule,
        SfcDropDownClerkModule,
        FileUploadModule,
        ImageModule,
        WoNotarialSealModule,
        CheckboxModule
    ],
    providers: [NotificationsService, AuthService, AuthorizationGuard, SfcReconhecimentoFirmaService]
})
export class SfcReconhecimentoFirmaModule { }
