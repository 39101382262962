<sf-loader></sf-loader>
<sf-notification></sf-notification>
<div class="p-grid">
    <div class="p-col-12" style="margin-top: -22px;">
        <p-table #dt [value]="list" dataKey="id" [paginator]="true" [rows]="15"
            [showCurrentPageReport]="true"
            [currentPageReportTemplate]="pageMessage"
            [rowsPerPageOptions]="[15,50,100]"
            [globalFilterFields]="['code','name']"
            styleClass="p-datatable-responsive-demo p-datatable-striped"  [showCurrentPageReport]="true">

            <ng-template pTemplate="caption">
                <div class="flex">
                    <button pButton pRipple icon="pi pi-plus" [label]="'button.user_new' | translate" class="p-button-primary p-mr-2" style="width: 150px;"
                    (click)="addItem()" *ngIf="flUpdate"></button>

                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <!-- <input #filter pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"  [placeholder]="'search.role' | translate" /> -->
                        <button pButton [label]="'button.clear' | translate" class="p-button-warning mr-2 ml-2" icon="pi pi-filter-slash" (click)="clear(dt, filter); filter.value = '';"></button>
                    </span>
                    <p-button icon="pi pi-refresh" (click)="getList()"></p-button>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 40px; text-align: center;">&nbsp;</th>
                    <th style="text-align: center;"><span translate>label.login</span></th>
                    <th style="text-align: center;"><span translate>label.name</span></th>
                    <th style="text-align: center;"><span translate>label.status</span></th>
                    <th style="text-align: center;"><span translate>label.organizational_structure</span></th>
                    <!-- <th style="text-align: center;"><span translate>label.commission_type</span></th> -->
                    <th style="text-align: center;"><span translate>label.role</span></th>
                    <th style="text-align: center;"><span translate>label.mobile_version</span></th>
                    <th style="width: 260px; text-align: center;"><span translate>label.actions</span></th>
                </tr>
                <tr>
                    <th></th>

                    <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'login', 'contains')" class="w-full"/></th>
                    <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'name', 'contains')" class="w-full"/></th>
                    <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'scUserStatusName', 'contains')" class="w-full"/></th>
                    <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'scOrganizationStructureName', 'contains')" class="w-full"/></th>
                    <!-- <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'slCommissionName', 'contains')" class="w-full"/></th> -->
                    <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'scRoleName', 'contains')" class="w-full"/></th>
                    <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'mobileVersion', 'contains')" class="w-full"/></th>
                    <th></th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr>
                    <td style="text-align: right;">
                        <div class="personal-image">
                            <figure class="personal-figure-grid">
                                <div class="avatar-container">
                                    <div class="image-container" [ngStyle]="{backgroundImage: 'url(' + item.pictureUri+ ')'}" *ngIf="item.pictureUri"></div>
                                    <div class="image-container" [ngStyle]="{backgroundImage: 'url(assets/layout/images/perfil.svg)'}" *ngIf="!item.pictureUri"></div>
                                </div>
                            </figure>
                        </div>
                    </td>
                    <td style="text-align: left;">{{item.login}}</td>
                    <td>{{item.name}}</td>
                    <td style="text-align: center;"><span [class]="'status-badge'" [style]="{'background-color':''+item.scUserStatusColor+'', 'color': '#FFFFFF'}">{{item.scUserStatusName}}</span> </td>
                    <td style="text-align: left;">{{item.scOrganizationStructureName}}</td>
                    <!-- <td style="text-align: center;">{{item.slCommissionTypeName}}</td> -->
                    <td style="text-align: left;">{{item.scRoleName}}</td>
                    <td style="text-align: center;">{{item.mobileVersion}}</td>
                    <td style="text-align: center;">
                        <p-button icon="pi pi-pencil" [styleClass]="classBtnUpd" [pTooltip]="'button.edit' | translate" (click)="updItem(item.id)"></p-button>
                        <p-button icon="pi pi-key" [styleClass]="classBtnReset" [pTooltip]="'button.reset_pwd' | translate" (click)="resetPwd(usuario)" [disabled]="!flUpdate"></p-button>

                        <!-- <p-button icon="pi pi-lock-open" [styleClass]="'item.isBlocked == false' ? 'p-button-secondary' : 'p-button-info'" [pTooltip]="'button.unlock_user' | translate"  (click)="unblockUser(usuario)" [disabled]="flUnblock"></p-button> -->

                        <p-button *ngIf="item.isBlocked" icon="pi pi-lock-open" styleClass="p-button-info p-m-1 mr-1" [pTooltip]="'button.unlock_user' | translate"  (click)="unblockUser(usuario)" [disabled]="!flUpdate"></p-button>
                        <p-button *ngIf="!item.isBlocked" icon="pi pi-lock-open" styleClass="p-button-secondary p-m-1 mr-1" [pTooltip]="'button.unlock_user' | translate" [disabled]="true"></p-button>

                        <p-button *ngIf="!item.isEmailConfirmed" icon="pi pi-envelope" styleClass="p-button-info p-m-1 mr-1" [pTooltip]="'button.emalConfirm' | translate"  (click)="confirmEmail(item)" [disabled]="!flUpdate"></p-button>
                        <p-button *ngIf="item.isEmailConfirmed" icon="pi pi-envelope" styleClass="p-button-secondary p-m-1 mr-1" [pTooltip]="'button.emalConfirm' | translate"  (click)="confirmEmail(item)" [disabled]="true"></p-button>

                        <p-button *ngIf="item.isFirstAccess" icon="pi pi-check-square" styleClass="p-button-info p-m-1 mr-1" [pTooltip]="'button.firstAccess' | translate"  (click)="unsetFirstAceess(item)" [disabled]="!flUpdate"></p-button>
                        <p-button *ngIf="!item.isFirstAccess" icon="pi pi-check-square" styleClass="p-button-secondary p-m-1 mr-1" [pTooltip]="'button.firstAccess' | translate"  (click)="unsetFirstAceess(item)" [disabled]="true"></p-button>

                        <p-button icon="pi pi-trash" [styleClass]="classBtnDel" [pTooltip]="'button.delete' | translate"   (click)="delItem(item)" [disabled]="!flUpdate"></p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

