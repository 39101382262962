<p-listbox
    [options]="list"
    [(ngModel)]="selectedList"
    optionLabel="name"
    [style]="{ width: '100%' }"
    [filter]="true"
    [multiple]="true"
    [checkbox]="true"
    [metaKeySelection]="false"
    [showToggleAll]="true"
    (onChange)="feedback($event)"
    [listStyle]="{ 'max-height': '220px' }"
></p-listbox>
