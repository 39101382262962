
<div class="card">
    <p-steps [model]="items" [readonly]="false"  [activeIndex]="activeIndex" (activeIndexChange)="onActiveIndexChange($event)"></p-steps>

    <div class="" *ngIf="activeIndex == 0">
        <sc-company-add-step-info></sc-company-add-step-info>
    </div>
    <div class="" *ngIf="activeIndex == 1">
        <sc-company-add-step-type></sc-company-add-step-type>
    </div>
    <div class="" *ngIf="activeIndex == 2">
        <sc-company-add-step-user></sc-company-add-step-user>
    </div>
    <div class="" *ngIf="activeIndex == 3">
        <sc-company-add-step-confirm></sc-company-add-step-confirm>
    </div>


</div>



<!--
<div class="formgrid grid surface-ground">
    <div class="field col-12 md:col-8 lg:col-8 xl:col-8 mt-2">
        <div class="card">
            <div class="formgrid grid text-lg">
                <div class="field col-4">
                    <div class="avatar-container">
                        <div class="image-container" [ngStyle]="{backgroundImage: 'url(' + item.pictureUri+ ')'}" *ngIf="hasPicture"></div>
                        <div class="image-container vertical-align-baseline text-center"  *ngIf="!hasPicture"> <span class="fontOrangeBig" translate>label.companyLogo</span></div>
                        <label for="avatar-input-file">
                            <div class="icon-wrapper">
                                <input type="file" id="avatar-input-file" (change)="fileChangeEvent($event)" accept="image/*"/>
                                <i class="pi pi-upload icon"></i>
                            </div>
                        </label>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <label for="name" class="mb-1 font-bold text-lg"><span translate>label.shortName</span>*</label>
                    <input type="text" id="code" pInputText [(ngModel)]="item.code" class="w-full" [disabled]="!flUpdate" required>
                    <small class="p-invalid" *ngIf="flSubmitted && !item.name" translate>validation.required_field</small>

                </div>
                <div class="field col-12 md:col-4">
                    <label for="ein" class=" block mb-2 font-bold text-lg"><span translate>label.scCompanyEin</span></label>
                    <p-inputMask id="ein" mask="99.999.999/9999-99" [(ngModel)]="item.ein" placeholder="99.999.999/9999-99" class=" w-full" [disabled]="!flUpdate" ></p-inputMask>
                </div>
            </div>

            <div class="formgrid grid text-lg">
                <div class="field col-12 md:col-6">
                    <label for="name" class="mb-1 font-bold text-lg"><span translate>label.name</span>*</label>
                    <input type="text" id="name" pInputText [(ngModel)]="item.name" class="w-full"  [disabled]="!flUpdate" required>
                    <small class="p-invalid" *ngIf="flSubmitted && !item.name" translate>validation.required_field</small>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="fatasyName" class="mb-1 font-bold text-lg"><span translate>label.scCompanyFantasyName</span></label>
                    <input type="text" id="fatasyName" [(ngModel)]="item.fantasyName" pInputText class="w-full" [disabled]="!flUpdate">
                </div>
            </div>
            <div class="formgrid grid text-lg">
                <div class="field col-12 md:col-10">
                    <label for="name" class="mb-1 font-bold text-lg"><span translate>label.address</span>*</label>
                    <input type="text" id="name" pInputText [ngModel]="formattedAddress" [ngModelOptions]="{standalone: true}" class="w-full">
                    <small class="p-invalid" *ngIf="flSubmitted && !formattedAddress" translate>validation.required_field</small>
                </div>
                <div class="field col-12 md:col-2" *ngIf="flUpdate">
                    <div class="mt-2">
                    <p-button (click)="showDialog()" icon="pi pi-external-link" [label]="'button.addressUpd' | translate" ></p-button>
                </div>
                </div>
            </div>


        </div>


        <div class="card" >
            <div class="p-col-12 p-md-12">
                <div class="formgrid grid text-lg">
                    <div class="field col-4">
                        <label for="status" class="mb-1 font-bold text-lg"><span translate>label.type</span>*</label>
                        <sf-dropdown-generic [list]="companyTypeList" [id]="item.scCompanyTypeId"
                            (selectedItemOutput)="reciverFeedbackCompanyType($event)" [disabled]="true"></sf-dropdown-generic>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="status" class="mb-1 font-bold text-lg"><span translate>label.licensingPlan</span>*</label>
                        <sf-dropdown-generic [list]="companyStatusList" [id]="item.scCompanyStatusId"
                            (selectedItemOutput)="reciverFeedbackCompanyStatus($event)" [disabled]="true"></sf-dropdown-generic>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="status" class="mb-1 font-bold text-lg"><span translate>label.status</span>*</label>
                            <sf-dropdown-generic [list]="companyStatusList" [id]="item.scCompanyStatusId"
                                (selectedItemOutput)="reciverFeedbackCompanyStatus($event)" [disabled]="true"></sf-dropdown-generic>
                    </div>
                </div>




            </div>
        </div>
    </div>
    <div class="col-12 md:col-3 lg:col-3 xl:col-3 p-order-3 p-order-lg-2">

        <div class="card">

            <h5 class="text-center font-bold"><span translate>label.config</span> </h5>
            <p-scrollPanel [style]="{ width: '100%', height: '450px' }">
                <sf-sc-config-data-view [language]="language" [scCompanyTypeId]="scCompanyTypeId"
                (selectedItemOutput)="reciverFeedbackCompanyConfig($event)" [disabled]="!flUpdate"></sf-sc-config-data-view>
        </p-scrollPanel>
        </div>
        <div class="card text-center ">
            <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="hideDialog()"></button>
            <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="save()" [disabled]="!flUpdate"></button>
    </div>

    </div>
</div> -->

<!-- <p-dialog [(visible)]="visible" [style]="{width: '50vw', height:'70vh'}">
    <p-header> {{ 'label.address' | translate }} </p-header>
   <app-address (formattedAddressEmitter)="formattedAddressSubmitted($event)" (newAddressEmitter)="addressSubmitted($event)" [addressId]="item.scAddressId"></app-address>
</p-dialog> -->





