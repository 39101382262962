import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'dashboard-home',
    templateUrl: './dashboard-home.component.html',
    styleUrls: ['./dashboard-home.component.scss']
  })

  export class DashboardHomeComponent implements OnInit {



    constructor(  )
      { }

    ngOnInit(): void {

    }
}
