import { DropDown } from 'src/app/shared/model/base-model';
import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { ScModuleService } from '../sc-module.service';



@Component({
    selector: 'sf-sc-module-dropdown-multi',
    templateUrl: './sc-module-dropdown-multi.component.html',
    styleUrls: ['./sc-module-dropdown-multi.component.scss']
})

export class ScModuleDropdownMultiComponent implements OnInit{

    public list : DropDown[];
    public item: DropDown;
    public selectedList: DropDown[];

    @Input() placeHolderLanguage: string;
    @Input() scCompanyType: string = "";
    @Input() selectedItems: DropDown[] = [];
    @Output() chosedItem = new EventEmitter();



    constructor(  private scModuleService: ScModuleService){ }

    ngOnInit(): void {
        this.getList();

    }

    ngOnChanges(): void {
        this.selectedList = this.selectedItems;
    }


    getList() {
        this.scModuleService.getDropDownList(this.scCompanyType, this.placeHolderLanguage).subscribe(
          {
            next: (res) => {
                if (res)
                    this.list = res.data;
                this.selectedList = this.selectedItems;

            },
            error: (err) => {
                console.log(`ModuleDropDownMulti - getList : ${err}.`);
            }
        });
      }

    feedback(){
        this.chosedItem.emit(this.selectedList)
    }

}
