import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent} from 'ngx-image-cropper';

import { MessageService, ConfirmationService, TreeNode } from "primeng/api";

import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ScUserDetailOutput, ScUserOutput, ScUserStatusHist } from '../../sc-user.model';
import { DropDown } from 'src/app/shared/model/base-model';
import { ScUserService } from '../../sc-user.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { ScCompanyService } from '../../../sc-company/sc-company.service';
import { ScRoleService } from '../../../sc-role/sc-role.service';


class ImageSnippet {
    constructor(public src: string, public file: File) {}
}

@Component({
    selector: 'sc-user-detail',
    templateUrl: './sc-user-detail.component.html',
    styleUrls: ['./sc-user-detail.component.scss'],
    providers: [ConfirmationService, MessageService]
})
export class ScUserDetailComponent implements OnInit {

    public userId: string = null;
    public language: string = "pt-BR";
    public item: ScUserOutput = {};
    public id: string;
    public detailItem: ScUserDetailOutput = {};
    public userStatusHist: ScUserStatusHist[] = [];

    public treeItem!: TreeNode;
    public roleItem: DropDown;

    public userSignForList: DropDown[] = [];

    public canChangeLogin: boolean = true;
    public canUpdate: boolean = true;
    public invalidEmail: boolean = false;
    public hasPicture: boolean = false;

    


    public message: string = "";
    public pageMessage: string = "";
    public errorMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-danger p-m-1 mr-1";
    public classBtnReset: string = "p-button p-button-warning p-m-1 mr-1";

    public labelAccessPermission: string = "";
    public labelUserRegistration: string = "";
    public labelUserHistory: string = "";
    public labelUserDetails: string = "";


    public listCommissionType: DropDown[] = [];
    public listStatusType: DropDown[] = [];
    public listOrganiztionType: DropDown[] = [];
    public selectedSkills: DropDown[] = [];

    public userPicDialog: boolean = false;
    public imageChangedEvent: any = '';
    public croppedImage: any;
    public showCropper = false;
    public fileList: any[] = [];
    public imageBlob: any;
    public imageUrl: string;

    public isCommissionTypeDisabled: boolean = true;


    //access
    public hasClerk: boolean = true;


    constructor(private userTokenService: UserTokenService,
                private userService: ScUserService,
                private roleService: ScRoleService,
                private companyService: ScCompanyService,
                private confirmationService: ConfirmationService,
                private activateRoute: ActivatedRoute,
                private notificationsService: NotificationsService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService,
                private breadcrumbService: BreadcrumbService,
                private router: Router) { }

    ngOnInit(): void {

        this.language = this.translateConfigService.getLanguage();
        this.userId = this.userTokenService.getUserId();
        this.canUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN', 'SF_SELLER', 'SC_MANAGER_U']);
        this.canChangeLogin = this.companyService.canChangeLogin(this.userTokenService.getCompanyId());
        this.hasClerk = this.userTokenService.getCompanyConfig('CLERK');

        this.activateRoute.paramMap.subscribe(params => {
            if (params.has('id')) {
                this.id = params.get('id');
                if (this.id != "new"){
                    this.getItem();
                }
                else{
                    this.item.isActive = true;
                    this.item.isBlocked = false;
                    this.item.isEmailConfirmed = true;
                    this.item.isFirstAccess = true;
                    this.item.languageCode = this.language;
                }
            }
        });


        if (this.canUpdate == false)
        {
            this.classBtnDel = this.classBtnReset = "p-button p-button-secondary p-m-1 mr-1";
        }
    }

    getItem() {
        this.userService.getItem(this.id).subscribe(
        {
        next: (res) => {
            if (res)
            {
                this.item = res.data;
                if (this.item != null){
                    this.hasPicture = !(this.item.pictureUri == undefined || this.item.pictureUri == null || this.item.pictureUri == "");
                    this.getItemDetail();
                    this.getTimeLine();

                }
            }
            this.translate();
        },
        error: (err) => console.log(`UserDetail - getItem : ${err}.`)
        });
    }

    getItemDetail() {
        
        this.userService.getItemDetail(this.id).subscribe(
        {
        next: (res) => {
            if (res)
            {
                this.detailItem = res.data;
                this.item.scOrganizationStructureId = this.detailItem.scOrganizationStructureId;                
                this.selectedSkills = this.detailItem.skills;
                this.item.skills = this.detailItem.skills;
            }
        },
        error: (err) => console.log(`UserDetail - getItemDetail : ${err}.`)
        });
    }




    getRole() {
        this.roleService.getItemByUserId(this.item.id, this.language).subscribe(
        {
        next: (res) => {
            if (res)
            {
                this.item.scRoleId = res.data.id;
            }
        },
        error: (err) => console.log(`UserDetail - getRole : ${err}.`)
        });
    }

    getTimeLine() {

        this.userService.geTimeLine(this.id, this.language).subscribe(
        {
            next: (res) =>
            {
                this.userStatusHist = res.data;
          
            },
            error: (err) => {
                console.log(`UsuarioGrid - getTimeLine`)
                console.log(err)
            }
        });
    }

   

    translate(){
        const labAccessControl = this.translateService.instant('label.access_control')+" ";
        const labUser = " "+this.translateService.instant('label.users');
        this.breadcrumbService.setItems([{label: labAccessControl},{label: labUser, routerLink: ['user']} ]);

        this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')

        //this.dialogHeader = this.translateService.instant('title.sc_user_edit');

    }

    fileChangeEvent(event: any): void {

      //  console.log("fileChangeEvent");
        this.userPicDialog = true;
        this.imageChangedEvent = event;

        // this.tipoFoto = 'png';

        // if (event.target.files && event.target.files[0]) {
        // let file =  event.target.files[0]
        // if ((file.name.includes('jpg')) || (file.name.includes('jpeg')))
        //     this.tipoFoto = 'jpeg';

        // //    console.log(file);

        //     var selectedFile = event.target.files[0];



            //this.fileList.push(selectedFile);


        // var reader = new FileReader();
        // reader.readAsDataURL(event.target.files[0]); // read file as data url
        // }


    }

    imageCropped(event: ImageCroppedEvent) {
        //  console.log('Cropped Image Event:', event);
        this.croppedImage = event.base64;
        this.fileList.push(this.croppedImage);

        this.imageBlob = event.blob;
        this.imageUrl = event.objectUrl;
        this.fileList = [];

        fetch(this.imageUrl)
            .then((res) => res.blob())
            .then((myBlob) => {
                const myFile = new File([myBlob], 'image.png', {type: myBlob.type});
                this.fileList.push(myFile);
            });
    }

    imageLoaded() {
        this.showCropper = true;
        // console.log('cropper load');
    }
    cropperReady() {
        // cropper ready
        // console.log('cropper ready');
    }
    loadImageFailed() {
        // show message
        // console.log('cload fail');
    }

    hidePicDialog(): void {
        this.userPicDialog = false;
    }

    saveItem() {

        if (this.item.scOrganizationStructureId == null || this.item.scOrganizationStructureId == undefined) {
            this.notificationsService.toastNotify('warn', this.translateService.instant('message.structure_required'), this.errorMessage);
            return;
        }

        if (this.item.email.trim()) {
            if (this.item.id) {
                this.userService
                .updItem(this.item, this.fileList, this.userId, this.language)
                .subscribe(
                    {
                        next: () => {
                            this.savePic();
                            this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_updated'));
                            this.router.navigate(['user']);
                            this.getTimeLine();
                        },
                        error: (err) => {
                            error: (err) => console.log(`UsuarioDetail - saveItem - Upd : ${err}.`)
                            this.message = this.translateService.instant('api_response.'+err.error);
                            console.log(this.message);

                            if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                                this.message = this.translateService.instant('api_response.ERROR');

                            this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.message);
                        }
                    });
            }
            else {
                this.userService
                .addItem(this.item, this.userId, this.language)
                .subscribe({
                    next: () => {
                        this.savePic();
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_updated'));
                        this.router.navigate(['user']);
                    },
                    error: (err) => {
                        error: (err) => console.log(`UsuarioDetail - saveItem - Add : ${err}.`)
                        this.message = this.translateService.instant('api_response.'+err.error);
                        console.log(this.message);

                        if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                            this.message = this.translateService.instant('api_response.ERROR');

                        this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.message);
                    }
                });
            }
        }
        else{
            this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.errorMessage);
        }
    }

    savePic()
    {
        if (this.item.id) {
            this.userService.uploadItem(this.fileList, this.item.id)
            .subscribe(
                {
                    next: () =>
                    {
                        this.item.pictureUri = this.imageUrl;
                        this.hasPicture = true;
                        this.userPicDialog = false;
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.picture_updated'));
                    },
                    error: (err) => {
                        this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.errorMessage);
                        console.log(`UsuarioGrid - uploadItem`)
                        console.log(err)
                    }
                });
        }
        else {
            this.item.pictureUri = this.imageUrl;
            this.hasPicture = true;
            this.userPicDialog = false;
        }
    }

    cancel()
    {
        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_cancel'),
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.getItem();
            }
        });
    }

    close()
    {
        this.getItem();
        this.router.navigate(['user']);
    }



    checkEmail(email: string)
    {


    }

    checkLogin(login: string)
    {


    }
    reciverFeedbackRole(event)
    {
        this.roleItem = event;
        if (this.roleItem != null && this.roleItem.id != undefined)
        {
            this.item.scRoleId = this.roleItem.id;
        }
    }

    reciverFeedbackStructure(event)
    {
        this.treeItem = event;
        if (this.treeItem != null && this.treeItem.key != undefined && this.treeItem.key != "")
        {
            this.item.scOrganizationStructureId = this.treeItem.key;
        }
    }

    reciverFeedbackSkill(select: DropDown[])
    {
        this.selectedSkills = select;
        this.item.skills = this.selectedSkills;
    }

    reciverFeedbackUserClerk(event)
    {        
        this.item.scUserSignatureId = event;                
    }
}
