import { DropDown } from 'src/app/shared/model/base-model';
import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { WoSkillService } from '../wo-skill.service';


@Component({
    selector: 'sf-wo-skill-dropdown-multi',
    templateUrl: './wo-skill-dropdown-multi.component.html',
    styleUrls: ['./wo-skill-dropdown-multi.component.scss']
})

export class WoSkillDropdownMultiComponent implements OnInit{

    public list : DropDown[];
    public item: DropDown;
    public selectedList: DropDown[];

    @Input() placeHolderLanguage: string;
    @Input() selectedItems: DropDown[] = [];
    @Output() chosedItem = new EventEmitter();



    constructor(  private WoSkillService: WoSkillService){ }

    ngOnInit(): void {
        this.getList();

    }

    ngOnChanges(): void {
        this.selectedList = this.selectedItems;
    }


    getList() {
        this.WoSkillService.getDropDownList().subscribe(
          {
            next: (res) => {
                if (res)
                    this.list = res.data;
                this.selectedList = this.selectedItems;

            },
            error: (err) => {
                console.log(`SkillDropDownMulti - getList : ${err}.`);
            }
        });
      }

    feedback(){
        this.chosedItem.emit(this.selectedList)
    }

}
