<sf-loader></sf-loader>
<sf-notification></sf-notification>
<div class="p-grid">
    <div class="p-col-12" style="margin-top: -18px;">
        <div class="flex align-items-center justify-content-center z-2" style="margin-top: 50px;" *ngIf="isLoading">
            <p-progressSpinner styleClass="w-4rem h-4rem margin-top: 25px;" strokeWidth="8" />
        </div>

        <p-table #dt [value]="list" dataKey="id" [paginator]="true" [rows]="25"
            [showCurrentPageReport]="true"
            [currentPageReportTemplate]="pageMessage"
            [rowsPerPageOptions]="[25,50,100]"
            [globalFilterFields]="['code','name']"
            styleClass="p-datatable-responsive-demo p-datatable-striped"
            responsiveLayout="stack"
            [breakpoint]="'450px'"
            [showCurrentPageReport]="true"[tableStyle]="{'min-width': '50rem'}">

            <ng-template pTemplate="caption">
                <div class="flex">
                    <button pButton pRipple icon="pi pi-plus" [label]="'button.serviceTemplateNew' | translate" class="p-button-primary p-mr-2" style="width: 250px;"
                    (click)="addItem()"></button>

                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input #filter pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"  [placeholder]="'search.role' | translate" />
                        <button pButton [label]="'button.clear' | translate" class="p-button-warning mr-2 ml-2" icon="pi pi-filter-slash" (click)="clear(dt, filter); filter.value = '';"></button>
                    </span>
                    <p-button icon="pi pi-refresh" (click)="getList()"></p-button>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="code"><span translate>label.code</span> <p-sortIcon field="code"></p-sortIcon></th>
                    <th pSortableColumn="name"><span translate>label.name</span><p-sortIcon field="name" ></p-sortIcon></th>
                    <th><span translate>label.PathWeb</span></th>
                    <th><span translate>label.pathMob</span></th>
                    <th style="width: 150px; text-align: center;"><span translate>label.actions</span></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{item.code}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.pathWeb}}</td>
                    <td>{{item.pathMob}}</td>
                    <td>
                        <p-button icon="pi pi-pencil" styleClass="p-button p-button-success p-m-1 mr-1" [pTooltip]="'button.edit' | translate" (click)="updItem(item)"></p-button>
                        <p-button icon="pi pi-trash" styleClass="p-button p-button-danger p-m-1 mr-1" [pTooltip]="'button.delete' | translate"   (click)="delItem(item)" [disabled]="item.isActive ? true : false"></p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog [(visible)]="displayDialog" [style]="{width: '500px'}" [modal]="true" styleClass="p-fluid" [header]="dialogHeader">
    <ng-template pTemplate="content">
        <div style="margin-top:1px;" class="p-grid p-d-flex dialog-page">
            <div class="p-md-12 p-lg-6 p-xl-6 p-order-1 p-order-lg-1 flex flex-column gap-2">

                <div class="p-field">
                    <label for="code"><span translate>label.code</span></label>
                    <input type="text" pInputText id="code" [(ngModel)]="item.code" required [disabled]="isUpdate" />
                    <small class="p-invalid" *ngIf="submitted && !item.code"><span translate>label.field_code_required</span></small>
                </div>

                <div class="p-field">
                    <label for="name"><span translate>label.name</span></label>
                    <input type="text" pInputText id="name" [(ngModel)]="item.name" required />
                    <small class="p-invalid" *ngIf="submitted && !item.name"><span translate>label.field_name_required</span></small>
                </div>

                <div class="p-field">
                    <label for="pathWeb"><span translate>label.PathWeb</span></label>
                    <input type="text" pInputText id="pathWeb" [(ngModel)]="item.pathWeb" />
                </div>

                <div class="p-field">
                    <label for="pathMob"><span translate>label.PathMob</span></label>
                    <input type="text" pInputText id="pathMob" [(ngModel)]="item.pathMob" />
                </div>

                <div class="p-field">
                    <label for="module"><span translate>label.modules</span></label>
                    <sf-sc-module-dropdown-multi appendTo="body" InputId="module" [selectedItems]="selectedModules" [placeHolderLanguage]="'label.selectModule' | translate" (chosedItem)="reciverFeedbackModule($event)"></sf-sc-module-dropdown-multi>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <div style="float: right; display: flex; align-items: flex-end; gap: 8px;">
            <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="hideDialog()"></button>
            <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="saveItem()"></button>

        </div>
    </ng-template>
</p-dialog>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

