import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DropDownMeta } from 'src/app/shared/model/base-model';
import { WoOrderTypeItem, WoOrderTypeList, WoOrderTypeOutput } from './wo-order-type.model';




@Injectable({
    providedIn: 'root'
  })

export class WoOrderTypeService{

    private environmentUrl: string = '';


    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/Order/Type/';
    }



//======================================================
//================== ORDER SERVICE =====================
//======================================================

    getItem(id: string): Observable<WoOrderTypeItem> {
        let url = this.environmentUrl+ id;
        return this.httpClient.get<WoOrderTypeItem>(url);
    }

    getList() : Observable<WoOrderTypeList>{
        let url = this.environmentUrl;
        return this.httpClient.get<WoOrderTypeList>(url);
    }

    getListByUser(woOrderClass: string) : Observable<WoOrderTypeList>{
        let url = this.environmentUrl + 'User/Class/'+woOrderClass;
        return this.httpClient.get<WoOrderTypeList>(url);
    }

    addItem(model: WoOrderTypeOutput, userId: string, language: string){
        let url = this.environmentUrl;
        return this.httpClient.post(url, model);
    }

    updItem(model: WoOrderTypeOutput, userId: string, language: string){
        let url = this.environmentUrl+ model.id;
        return this.httpClient.put(url, model);
    }

    delItem(id: string) {
        let url = this.environmentUrl+id;
        return this.httpClient.delete(url);
    }

    getOrderTypeDropDownList(language: string): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown?language=" + language;
        return this.httpClient.get<DropDownMeta>(url);
    }




}


