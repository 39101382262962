import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MessageService } from "primeng/api";
import { MessagesModule } from "primeng/messages";
import { ToastModule } from "primeng/toast";
import { ScPersonFileComponent } from "./components/sc-person-file/sc-person-file.component";
import { FileUploadModule } from "primeng/fileupload";
import { GalleriaModule } from "primeng/galleria";
import { DropDownGenericComponent } from "./components/dropdown/dropdown-generic/dropdown-generic.component";
import { SfDropDownModule } from "./components/dropdown/dropdown.module";
import { DialogModule } from "primeng/dialog";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { TranslateModule } from "@ngx-translate/core";

const resources = [    
    ScPersonFileComponent
];

@NgModule({
    imports: [
        CommonModule,
        ToastModule,
        MessagesModule,
        FormsModule,
        FileUploadModule,
        GalleriaModule,
        SfDropDownModule,
        DialogModule,
        ConfirmDialogModule,
        TranslateModule
    ],
    declarations: [...resources],
    providers: [MessageService],
    exports: [...resources],
})
export class SharedModule { }