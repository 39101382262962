import { DropDown } from 'src/app/shared/model/base-model';
import { OnInit, Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, Injector, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { Subject, debounceTime } from 'rxjs';
import { ScPersonBasicComponent } from '../sc-person-basic/sc-person-basic.component';
import { ScPersonService } from 'src/app/core/services/sc-person/sc-person.service';

@Component({
    selector: 'sf-sc-person-dropdown',
    templateUrl: './sc-person-dropdown.component.html',
    styleUrls: ['./sc-person-dropdown.component.scss'],
    providers: [ConfirmationService]
})

export class ScPersonDropdownComponent implements OnInit, OnDestroy, OnChanges {

    public list : DropDown[];
    @Input() placeHolderLanguage: string;
    public selectedItem: DropDown;

    @Input() id: string;
    @Output() chosedItem = new EventEmitter();
    displayDialog: boolean = false;
    @Input() name: string = '';
    @Input() ssn: string = '';
    @ViewChild('inputName') inputName: ElementRef; 
    @ViewChild('inputSsn') inputSsn: ElementRef; 

    public dialogHeader: string;
    public pageMessage: string = "";
    flRead: boolean = false;
    flUpdate: boolean = false;
    
    debounce: Subject<any> = new Subject<any>();

    @Input() outlet: any;
    @Input() componentType: string = 'basic';
    public classBtnUpd: string = "p-button p-button-primary p-mr-2 mt-3 font-medium";
    @Input() isDisabled: string;
    @Input() flagPersonType: string = '';

    constructor(private scPersonService: ScPersonService,
                private userTokenService: UserTokenService,
                private translateService: TranslateService){ }
    
    ngOnInit(): void {
        if (this.componentType == 'basic') {
            this.outlet = ScPersonBasicComponent;
        }

        this.flRead = this.userTokenService.hasPermissionArray(['SF_ADMIN', 'SF_SELLER', 'SC_PERSON_R', 'SC_PERSON_U']);
        this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN', 'SF_SELLER', 'SC_PERSON_U']);
        this.dialogHeader = this.translateService.instant('title.sc_person_edit');
        //this.getList();

        this.debounce
            .pipe(debounceTime(1000))
            .subscribe(filter => 
                this.onFilter(filter)
            );

        this.scPersonService.scPersonBasicConfirmEmitter
            .subscribe(data => {
                    this.getList();
                    if (data.data !== undefined) {
                        this.selectedItem = { id: data.data.id, name: data.data.cpf + '-' + data.data.name };
                        this.feedback();
                    }
                }
            );

        this.scPersonService.scPersonBasicDisplayDialogEmitter
            .subscribe(data => {
                    this.name = null;
                    this.ssn = null;
                    this.flagPersonType = null;
                    this.displayDialog = false;
                }
            );            
    }

    ngOnDestroy(): void {
        this.debounce.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getList();
    }
    
    getList() {
        this.scPersonService.getDropDownListPerson()
            .subscribe(
            {
                next: (res) => {
                    if (res)
                        this.list = res.data;
                    if (this.list !== null) {
                        if (this.list.length == 1) {
                            this.selectedItem = this.list[0];    
                        } else {
                            this.selectedItem = this.list.find(x => x.id == this.id);
                        }
                    }                    
                },
                error: (err) => {
                    console.log(`PersonDropDown - getList : ${err}.`);                    
                }
        });
    }

    feedback() {
        if (this.selectedItem != null) {
            this.chosedItem.emit(this.selectedItem);           
        }
    }

    insertPerson() {
        this.displayDialog = true;
        this.name = null;
        this.ssn = null;           
    }

    savePerson() {
        //const name = document.getElementById('name') as HTMLInputElement;
        //alert(this.inputName.nativeElement.value);
        this.displayDialog = false;
    }

    hideDialog() {
        this.displayDialog = false;
    }

    filter(event) {
        if (event.filter != null) {
            this.debounce
                .pipe(debounceTime(300))
                .subscribe(filter => 
                    this.onFilter(filter)
                );        

            // alert(event.filter);
            // alert(this.list.length);
        }
    }

    onFilter(event) {
        if (event.filter != null) {
            let filteredPersonList = this.list.filter(person =>
                person.name.toLowerCase().includes(event.filter.toLowerCase())
            );

            //alert(filteredPersonList.length);

            if (filteredPersonList.length == 0) {
                var numsStr = event.filter.replace(/[^0-9]/g,'');
                this.ssn = numsStr;
                var reg = event.filter.replace(/[^a-zA-Záéíóú]/g,'');
                this.name = reg; 
                this.displayDialog = true;
            }
        }
    }    
}