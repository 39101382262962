import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { WoNotarialSeal, WoNotarialSealItem, WoNotarialSealList } from './wo-notarial-seal.model';
import { WoNotarialSealService } from './wo-notarial-seal.service';

@Component({
    selector: 'wo-notarial-seal-grid',
    templateUrl: './wo-notarial-seal-grid.component.html',
    styleUrls: ['./wo-notarial-seal-grid.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})

export class  WoNotarialSealGridComponent implements OnInit {
    public list: WoNotarialSeal[] = [];
    public id: string;

    public userId: string = null;
    public language: string = "pt-BR";
    public item: WoNotarialSeal;
    public submitted: boolean;
    public displayMsg: boolean = false;
    public displayDialog: boolean;
    public dialogHeader: string;
    public pageMessage: string = '';
    public classBtnUpd: string = 'p-button p-button-success p-m-1 mr-1';
    public classBtnDel: string = 'p-button p-button-warning p-m-1 mr-1';

    public flUpdate: boolean = true;

    public listStatus: ["Disponivel","Reservado"]

    constructor(  private woNotarialSealService: WoNotarialSealService,
                  private translateService: TranslateService,
                  private translateConfigService: TranslateConfigService,
                  private breadcrumbService: BreadcrumbService,
                  private messageService: MessageService,
                  private confirmationService: ConfirmationService,
                  private notificationsService: NotificationsService,
                  private userTokenService: UserTokenService)
    { }

    ngOnInit(): void {

        this.language = this.translateConfigService.getLanguage();
        this.userId = this.userTokenService.getUserId();

        this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN']);

        if (this.flUpdate == false)
        {
            this.classBtnUpd = 'p-button p-button-secondary p-m-1 mr-1';
            this.classBtnDel = this.classBtnUpd;
        }

        this.getList();
    }
    addItem() {
        this.item = {};
        this.submitted = false;
        this.displayDialog = true;
    }

    getList() {
        this.woNotarialSealService.getList().subscribe(
            {
                next: (res) => {
                    if (res)
                       this.list = res.data;
                    this.translate();
                },
                error: (err) => {
                    console.log(`WoNotarialSealGrid - getLista : ${err}.`);
                }
            });
    }

    getItem(id) {
        this.woNotarialSealService.getItem(id).subscribe(
            {
                next: (res) =>
                {
                    if (res) {
                        this.item = res.data;
                    }
                },
                error: (err) => {
                    console.log(`WoNotarialSealGrid - getItem : ${err}.`);
                }
            });
    }

    saveItem() {
        this.submitted = true;
        if (this.item.code.trim()) {
            if (this.item.id) {

                this.woNotarialSealService
                    .updItem(this.item, this.userId, this.userId, this.language)
                    .subscribe(
                        {
                            next: () => {
                                this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_updated'), life: 3000});
                                this.getList();
                            },
                            error: (err) => console.log(err)
                        });

            } else {
                this.woNotarialSealService
                    .addItem(this.item, this.userId, this.language)
                    .subscribe(
                        {
                            next: () => {
                                this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_inserted'), life: 3000});
                                this.getList();
                            },
                            error: (err) => console.log(err)
                        });
            }

            this.list = [...this.list];
            this.displayDialog = false;
            this.item = {};
        }
    }

    delItem(item: WoNotarialSeal) {
        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_delete') + ' ' + item.id + '?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.woNotarialSealService
                    .delItem(item.id)
                    .subscribe(
                        {
                            next: () =>
                            {
                                this.notificationsService.toastNotify('success', this.translateService.instant('title.sc-company-status'), this.translateService.instant('message.record_deleted'));
                                this.getList();
                            },
                            error: (err) => {
                                this.notificationsService.toastNotify('warn', this.translateService.instant('title.sc-company-status'), this.translateService.instant('api_response.ERROR'));
                                console.log(`WoNotarialSealGrid - delItem : ${err}.`);
                            }
                        });
            }
        });
    }


    translate()
    {
        const labAccessControl = this.translateService.instant('label.access_control') + ' ';
        const labCompanyStatus = ' ' + this.translateService.instant('label.wo_notarial_seal');
        this.breadcrumbService.setItems([{label: labAccessControl},{label: labCompanyStatus, routerLink: ['companyStatus']} ]);

        this.pageMessage = this.translateService.instant('grid.showing') + ' {first} ' + this.translateService.instant('grid.to') + ' {last} ' + this.translateService.instant('grid.of') + ' {totalRecords} ' + this.translateService.instant('grid.entries');

        this.dialogHeader = this.translateService.instant('title.wo_notarial_seal');
    }

    hideDialog() {
        this.displayDialog = false;
        this.submitted = false;
    }

    clear(table: Table) {
        table.clear();
    }

    getStatusDesc(status: number): string {
        return status === 2 ? 'Reservado' : 'Disponivel';
    }
}
