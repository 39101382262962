import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserTokenService } from 'src/app/core/services/user-token.service';

@Component({
    templateUrl:'./app-middleware.component.html',
    styleUrls:  ['./app-middleware.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppMiddlewareComponent implements OnInit{

    constructor(
        private userTokenService: UserTokenService,
        private activateRoute: ActivatedRoute,
        private router: Router    
    ){}

    public action: string;

    ngOnInit(): void {

        this.activateRoute.paramMap.subscribe(params => {
            if (params.has('action')) {                
                this.action = params.get('action');
            }
        });        
           
        if (this.action == 'logout'){
            this.logout();
        }
        else 
            this.router.navigate(['/']);               
    

        
    }

    logout(){
        this.userTokenService.logout();
        this.router.navigate(['/login']);        
    }
}
