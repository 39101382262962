import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';

import { ScCompanyService } from '../../sc-company.service';
import { ScCompanyOutput } from '../../sc-company.model';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { Table } from 'primeng/table';
import { UserTokenService } from 'src/app/core/services/user-token.service';




@Component({
  selector: 'sc-company-grid',
  templateUrl: './sc-company-grid.component.html',
  styleUrls: ['./sc-company-grid.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None
})

export class ScCompanyGridComponent implements OnInit {

  public list: ScCompanyOutput[] = [];
  public flUpdate: boolean = true;
  public displayDialog: boolean = false;
  public dialogHeader: string = "Cadastro de Empresa"
  public pageMessage: string = "";
  public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
  public classBtnDel: string = "p-button p-button-danger p-m-1 mr-1";


  constructor(  private companyService: ScCompanyService,
                private router: Router,
                private notificationsService: NotificationsService,
                private confirmationService: ConfirmationService,
                private translateService: TranslateService,
                private breadcrumbService: BreadcrumbService,
                private userTokenService: UserTokenService) {}


    ngOnInit(): void {

        this.companyService.dialogCompanyAdd
        .subscribe(
          () => this.hideDialog(),
          err => { }
        );
        
        if (!this.userTokenService.hasPermission('SF_ADMIN')){
            this.flUpdate = false;
           // this.classBtnDel = "p-button p-button-secondary p-m-1 mr-1";            

            if (!this.userTokenService.hasPermission('SF_SELLER'))
                this.router.navigate(['company-detail/', this.userTokenService.getCompanyId()]);
        }
                
        this.translate();
        this.getList();
    }

    hideDialog(){
        this.displayDialog = false;
        this.getList();
    }

    clear(table: Table, filter: any) {
        table.clear();
        filter.Text = "";
    }


    translate()
    {
        const sleep = (ms) => new Promise(r => setTimeout(r, ms));
        (async () => {
             await sleep(100);
             const labCompanies = this.translateService.instant('label.companies')+" ";
             const labCompany = " "+this.translateService.instant('label.company');
             this.breadcrumbService.setItems([{label: labCompanies},{label: labCompany, routerLink: ['company']} ]);
             this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')
             this.dialogHeader = this.translateService.instant('button.company_new');
         })();
    }

    getList() {
        this.companyService.getList().subscribe(
        {
            next: (res) => {
                if (res)
                    this.list = res.data;
            },
            error: (err) => {
                this.notificationsService.toastNotify('warn', this.translateService.instant('title.sc_company'), this.translateService.instant('api_response.ERROR'));
                console.log(`ScCompanyGrid - getList : ${err}.`);
            }
        });
    }


    addItem() {
        this.displayDialog = true;
    }


    updItem(item: ScCompanyOutput) {
        if (this.flUpdate || item.code != 'SF')
            this.router.navigate(['company-detail/', item.id]);
    }


    delItem(item: ScCompanyOutput) {
        if (this.flUpdate){
            this.confirmationService.confirm({
                    message: this.translateService.instant('message.confirm_delete')+' ' + item.name + '?',
                    header: this.translateService.instant('title.confirmation'),
                    acceptLabel: this.translateService.instant('button.yes'),
                    rejectLabel: this.translateService.instant('button.no'),
                    acceptButtonStyleClass: 'p-button-success',
                    rejectButtonStyleClass: 'p-button-danger',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        this.companyService
                        .delItem(item.id)
                        .subscribe(
                        {
                            next: () =>
                            {
                                this.notificationsService.toastNotify('success', this.translateService.instant('title.sc-company'), this.translateService.instant('message.record_deleted'));
                                this.getList();
                            },
                            error: (err) => {
                                this.notificationsService.toastNotify('warn', this.translateService.instant('title.sc-company'), this.translateService.instant('api_response.ERROR'));
                                console.log(`ScCompanyGrid - delItem : ${err}.`);
                            }
                        });
                    }
            });
        }   
    }
}
