<p-dataView #dv [value]="list">
    <ng-template let-item pTemplate="listItem">
        <div class="col-12">
            <div class="flex flex-column xl:flex-row xl:align-items-start p-1 gap-2">
                <div>
                    <i class={{item.icon}}  [style]="{'color':''+item.color == null ? '#000000' : item.color}"></i>
                </div>
                <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 ">
                    <div class="flex flex-column align-items-center sm:align-items-start gap-2">
                        <span class="font-medium  text-600">{{ item.name }}</span>
                    </div>
                    <div class="formgrid grid">                        
                        <div class="field col" *ngIf="!item.isChecked">
                            <p-button icon="pi pi-times" (click)="check(item.id)" styleClass="p-button-rounded p-button-danger p-button-text" [disabled] = "disabled"/>
                        </div>
                        <div class="field col" *ngIf="item.isChecked">    
                            <p-button icon="pi pi-check" styleClass="p-button-rounded p-button-success p-button-text" (click)="unCheck(item.id)" [disabled] = "disabled" />
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-dataView>
