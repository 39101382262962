import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl:'./forgot.component.html',
    styleUrls:  ['./forgot.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ForgotComponent implements OnInit{

    forgotForm: FormGroup;
    public valid: boolean = true;
    public lang: string;

    personalInformation: any;

    submitted: boolean = false;


    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private notificationsService: NotificationsService,
        private translateConfigService: TranslateConfigService,
        private translateService: TranslateService
    ){}


    ngOnInit(): void {

        this.forgotForm = this.formBuilder.group({
            userName:['', Validators.required, Validators.email]
         } );

         this.lang = this.translateConfigService.getLanguage();
    }

    changeLanguage(type: string) {
        this.translateConfigService.changeLanguage(type);
        this.lang = type;
    }


    login()
    {
        this.router.navigate(['/login']);
    }

    reset(){
        if (this.forgotForm.invalid)
        {
            this.valid = false;
        }
        else
        {
            const userName = this.forgotForm.get('userName').value;            

            this.authService
                .resetPwd(userName, this.lang, null, null, null)
                .subscribe(
                    {
                        next: (res) =>{
                            if (res){
                                this.notificationsService.toastNotify('success',this.translateService.instant('title.reset_password'), this.translateService.instant('message.change_pwd_email_sent'));
                                this.router.navigate(['/'])
                                console.log(res);
                            }
                            else{
                                this.forgotForm.reset();
                                this.notificationsService.toastNotify('error',this.translateService.instant('title.reset_password'), this.translateService.instant('message.email_invalid'));
                            }
                        },
                        error: (err) => {
                            console.log(err);
                            this.forgotForm.reset();
                            this.notificationsService.toastNotify('error',this.translateService.instant('title.reset_password'), this.translateService.instant('message.email_invalid'));
                        }
                    }
                );
        }
    }
}
