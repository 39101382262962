import { NgModule } from '@angular/core';

import { FloorPipe } from './floor.pipe';
import { CeilPipe } from './ceil.pipe';
import { SimNaoPipe } from './yes-no.pipe';
import { DateFormatPipe } from './date-pt.pipe';
import { SafePipe } from './safe.pipe';


@NgModule({
    declarations: [ FloorPipe, CeilPipe, SimNaoPipe,DateFormatPipe,SafePipe ],
    exports: [ FloorPipe,CeilPipe, SimNaoPipe,DateFormatPipe,SafePipe],
    providers:[SimNaoPipe,SafePipe]
})
export class PipesModule {

}
