import { DropDown } from 'src/app/shared/model/base-model';
import { OnInit, Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ScRoleService } from '../sc-role.service';


@Component({
    selector: 'sc-role-dropdown',
    templateUrl: './sc-role-dropdown.component.html',
    styleUrls: ['./sc-role-dropdown.component.scss']
})

export class ScRoleDropdownComponent implements OnInit, OnChanges{

    public list : DropDown[];
    @Input() placeHolderLanguage: string;
    public selectedItem: DropDown;

    @Input() id: string;
    @Input() languageCode: string = "pt-BR";
    @Input() disabled: boolean = false;
    @Output() chosedItem = new EventEmitter();

    constructor(  private scRoleService: ScRoleService){ }

    ngOnInit(): void {
        this.getList();
    }

    ngOnChanges(): void {
        this.getList();
    }

    getList() {
        this.scRoleService.getDropDownList(this.languageCode).subscribe(
          {
            next: (res) => {
                if (res)
                    this.list = res.data;
                    if (this.list !== null){
                        this.selectedItem = this.list.find(x => x.id == this.id);
                        if (this.selectedItem == null || this.selectedItem.id == undefined)
                        {
                            this.selectedItem = this.list[0];
                            this.feedback();
                        }


                    }

            },
            error: (err) => {
                console.log(`RoleDropDown - getList : ${err}.`);
            }
        });
      }

    feedback(){
        this.chosedItem.emit(this.selectedItem)
    }

}
