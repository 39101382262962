import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from '../../layout/topbar/app.breadcrumb.service';
import { UserTokenService } from '../../core/services/user-token.service';
import { TranslateConfigService } from '../../core/services/translate-config.service';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { WoWorkFlowService } from './wo-workflow.service';
import { WoWorkFlow, WoWorkFlowOutput } from './wo-workflow.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';


@Component({
    selector: 'wo-workflow-grid',
    templateUrl: './wo-workflow-grid.component.html',
    styleUrls: ['./wo-workflow-grid.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})

export class WoWorkFlowGridComponent implements OnInit {

    public workFlowType: string = "service";
    public list: WoWorkFlow[] = [];
    public id: string;
    public userId: string = "";
    public language: string = "pt-BR";

    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-warning p-m-1 mr-1";
    public flUpdate: boolean = true;


    constructor(  private workflowService: WoWorkFlowService,
                    private confirmationService: ConfirmationService,
                    private activateRoute: ActivatedRoute,
                    private translateService: TranslateService,
                    private translateConfigService: TranslateConfigService,
                    private notificationsService: NotificationsService,
                    private breadcrumbService: BreadcrumbService,
                    private router: Router,
                    private userTokenService: UserTokenService)
        { }

    ngOnInit(): void {

        this.activateRoute.paramMap.subscribe(params => {
            if (params.has('service')) {
                this.workFlowType = params.get('service');
            }
        });

        this.language = this.translateConfigService.getLanguage();
        this.userId = this.userTokenService.getUserId();

        this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN', 'SF_SELLER', 'WO_WORKFLOW_U']);

        if (this.flUpdate == false)
        {
            this.classBtnDel = "p-button p-button-secondary p-m-1 mr-1";
        }

        this.getList();
    }

    translate()
    {

        this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')
        this.breadcrumbService.setItems([
            {label: this.translateService.instant('label.workflow')+" "},
            {label: this.translateService.instant('label.workflow'+this.workFlowType), routerLink: ['workflow'+this.workFlowType]}
        ]);
    }


    clear(table: Table) {
        table.clear();
    }

    getList() {
        this.workflowService.getList().subscribe(
            {
            next: (res) => {
                if (res){
                    this.list = res.data;
                }
                this.translate();
            },
            error: (err) => {
                console.log(`WoWorkFlowGrid - getLista : ${err}.`);
            }
        });
    }

    addItem() {
        this.router.navigate(['workflow/'+this.workFlowType+'/new']);
    }

    updItem(id: string) {
        console.log('order-service/'+this.workFlowType+'/'+id);
        this.router.navigate(['workflow/'+this.workFlowType+'/'+id]);
    }

    delItem(item: WoWorkFlowOutput) {

        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_delete')+' ' + item.name + '?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.workflowService
                .delItem(item.id)
                .subscribe(
                {
                    next: () =>
                    {
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.wo-workflow'), this.translateService.instant('message.record_deleted'));
                        this.getList();
                    },
                    error: (err) => {
                        this.notificationsService.toastNotify('warn', this.translateService.instant('title.wo-workflow'), this.translateService.instant('api_response.ERROR'));
                        console.log(`WoWorkFlowGrid - delItem : ${err}.`);
                    }
                });
            }
      });
    }
}
