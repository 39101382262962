<p-multiSelect 
    [options]="list"
    [(ngModel)]="selectedList"
    optionLabel="name"
    scrollHeight="280px"
    (onChange)="feedback()"
    [style]="{'width': '100%'}"
    name="companies"
    defaultLabel="{{ 'label.select_company' | translate }}"
    appendTo="body">
</p-multiSelect>