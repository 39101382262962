import { Message } from 'primeng/api';
import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from 'src/app/core/auth/auth.service';
import { PlatformDetectorService } from 'src/app/core/services/platform-detector.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';

@Component({
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class SignInComponent implements OnInit {

    public loginForm: FormGroup;
    public registerForm: FormGroup;
    public valid: boolean = true;
    public remember: boolean = false;
    public message: string = "";
    public value: string[];
    public loading: boolean = false;

    @ViewChild('userNameInput', { static: false }) userNameInput: ElementRef<HTMLInputElement>;
    fromUrl: string;
    
    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private platformDetectorService: PlatformDetectorService,
        private notificationService: NotificationsService,
        private translateConfigService: TranslateConfigService,
        private translateService: TranslateService,
        private activatedRoute: ActivatedRoute
    ) { }


    ngOnInit(): void {

        this.activatedRoute
            .queryParams
            .subscribe(params => this.fromUrl = params['fromUrl']);


        this.loginForm = this.formBuilder.group({
            userName: ['', Validators.required],
            password: ['', Validators.required],
            remember: ['']
        });

        this.registerForm = this.formBuilder.group({
            userName: ['', Validators.required],
            password: ['', Validators.required],
            email: ['', Validators.required],
            remember: ['']
        });
    }

    changeLanguage(type: string) {
        this.translateConfigService.changeLanguage(type);
    }

    login() {

        if (this.loginForm.invalid){
            this.valid = false;
        }
        else
        {
            this.loading=true;
            this.valid = true;
            const userName = this.loginForm.get('userName').value;
            const password = this.loginForm.get('password').value;
            const lang = this.translateConfigService.getLanguage();

            if (this.loginForm.get('remember').value === undefined || this.loginForm.get('remember').value === null || this.loginForm.get('remember').value === "")
                this.remember = false;
            else
                this.remember = this.loginForm.get('remember').value;

            this.authService
            .authenticate(userName, password, this.remember, "WEB", lang)
            .subscribe(
            {
                next: (res) => {
                    this.fromUrl
                        ? this.router.navigateByUrl(this.fromUrl)
                        : this.router.navigate(['']);
                },
                error: (err) => {
                    console.error('teste');
                    console.log(err);
                    this.loginForm.reset();
                    this.loading = false;
                    const message = err.error.Message.substring(1, err.error.Message.indexOf("-") -  2);

                    this.message = this.translateService.instant('api_response.'+message);

                    if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                        this.message = this.translateService.instant('api_response.ERROR');
                    else if (this.message == "api_response.undefined")
                        this.message = this.translateService.instant('api_response.ERROR_TOKEN');

                    this.notificationService.toastNotify('error', 'Login', this.message)

                    this.platformDetectorService.isPlatformBrowser() &&
                        this.userNameInput.nativeElement.focus();
                }
            });
        }
    }

    signup() {
        this.router.navigate(['/login/signup']);
    }

}
