import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ScCompanyService } from '../../../sc-company.service';
import { Router } from '@angular/router';
import { ScCompanyInfoInput } from '../../../sc-company.model';

@Component({
    selector: 'sc-company-add-step-info',
    templateUrl: './sc-company-add-step-info.component.html',
    //styleUrls: ['./sc-company-add.component.scss'],
    //providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})


export class ScCompanyAddStepInfoComponent implements OnInit {

    public step: ScCompanyInfoInput = {};
    submitted: boolean = false;

    constructor(public companyService: ScCompanyService) {}

    ngOnInit() {
        this.step = this.companyService.getAddSteps();
    }


    nextPage() {
        if (this.step.code && this.step.name) {
            this.companyService.addSteps = this.step;
            this.companyService.dialogCompanyAddNext.emit();

            return;
        }

        this.submitted = true;
    }




}
