import { OnInit, Component, Input, Output, EventEmitter, OnChanges, NgModuleFactory, Compiler } from '@angular/core';

import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { WoOrderCartorioService } from '../../wo-order-cartorio/wo-order-cartorio.service';
import { SfcWoOrderOrderServiceOutput, SfcWoOrderOrderServiceValue } from '../../wo-order-cartorio/wo-order-cartorio.model';
import { SfcReconhecimentoFirmaModule } from '../sfc-reconhecimento-firma/sfc-reconhecimento-firma.module';
import { WoOrderServiceService } from 'src/app/pages/wo-order-service/wo-order-service/wo-order-service.service';





@Component({
    selector: 'sfc-service-grid',
    templateUrl: './sfc-service-grid.component.html',
    styleUrls: ['./sfc-service-grid.component.scss']
})

export class SfcServiceGridComponent implements OnInit, OnChanges{

    public list : SfcWoOrderOrderServiceOutput[];
    public item: SfcWoOrderOrderServiceOutput;
    public totalValue: SfcWoOrderOrderServiceValue = {id:"", code:"", codeExternal:"", name:"", valueA: 0, valueB:0, valueTaxA: 0,valueTaxB: 0, quantity:0, total: 0};
    public lazyComponent: any;


    public recompe: number = 0;
    public emolumentos: number = 0;
    public tax: number = 0;
    public iss: number = 0;
    public qty: number = 0;
    public total: number = 0;

    public displayDialog: boolean = false;
    public displayDialogNew: boolean = false;
    public popupSize: string = "1200px";
    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-danger p-m-1 mr-1";
    public languageCode: string = 'pt-BR';

    @Input() woOrderId: string;
    @Input() isDisabled: boolean = false;
    @Input() canExecuteService: boolean = false;
    @Input() woOrderTypeId: string;
    @Output() chosedItem = new EventEmitter();

    listServiceByUser: SfcWoOrderOrderServiceOutput[];

    private moduleFactory: NgModuleFactory<any>;

    @Input() scPerson: string;

    constructor(private woOrderServiceService: WoOrderServiceService,
                private messageService: MessageService,
                private confirmationService: ConfirmationService,
                private translateService: TranslateService,
                private notificationsService: NotificationsService,
                private orderCartorioService: WoOrderCartorioService,
                private compiler: Compiler
            ){ }

    ngOnInit(): void {
        if (this.isDisabled == true)
            {
                this.classBtnUpd = this.classBtnDel = "p-button p-button-secondary p-mr-2 mt-3 font-medium";
            }
        this.getList();
        this.translate();
    }

    ngOnChanges(): void {
        this.reciverFeedbackService();
        //this.getList();
    }

    getList() {
        this.woOrderServiceService.getOrderOrderServiceList(this.woOrderId).subscribe(
          {
            next: (res) => {
                if (res)
                    this.list = res.data;

                    this.recompe = 0;
                    this.emolumentos = 0;
                    this.tax = 0;
                    this.iss = 0;
                    this.total = 0;
                    this.qty = 0;

                    this.list.forEach((item: SfcWoOrderOrderServiceOutput) => {
                        this.totalValue.id = item.id;
                        this.totalValue.code = item.code;
                        this.totalValue.codeExternal = item.codeExternal;
                        this.totalValue.quantity += item.quantity;
                        this.totalValue.valueA += item.unitValueA * item.quantity;
                        this.totalValue.valueB += item.unitValueB * item.quantity;
                        this.totalValue.valueTaxA += item.unitValueTaxA * item.quantity;
                        this.totalValue.valueTaxB += item.unitValueTaxB * item.quantity;
                        this.totalValue.total += (item.unitValueA + item.unitValueB + item.unitValueTaxA + item.unitValueTaxB) * item.quantity;



                    });

                    this.feedback();
                    // if (this.list !== null){
                    //     this.selectedItem = this.list.find(x => x.id == this.id);
                    // }
                    //console.log(this.list);

            },
            error: (err) => {
                console.log(`SfcServiceGrid - getOrderOrderServiceList : ${err}.`);
            }
        });
      }

    translate(){
        const sleep = (ms) => new Promise(r => setTimeout(r, ms));

        (async () => {
            await sleep(100);
            this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')
        })();
    }

    addItem()
    {
        this.getListServiceByUser();
        this.lazyComponent = null;
        this.changePopupSize("1200");
        this.displayDialogNew = true;
    }

    async addService(orderServiceId: string, orderServiceCode: string, orderServiceName: string, item: SfcWoOrderOrderServiceOutput){

        this.changePopupSize("900");
        this.lazyComponent = null;


        switch ( orderServiceCode ) {
            case "1":{
                const module = await import('../sfc-service-autenticacao/sfc-service-autenticacao.component');
                this.lazyComponent = module.SfcServiceAutenticacaoComponent;
                break;
            }

            case "2":{
                const module = await import('../../component/sfc-reconhecimento-firma/sfc-reconhecimento-firma.component');
                this.lazyComponent = module.SfcReconhecimentoFirmaComponent;
                this.moduleFactory = this.compiler.compileModuleSync(SfcReconhecimentoFirmaModule);
                break
            }


        }

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));

        (async () => {
            await sleep(1000);
            this.orderCartorioService.sendData({ woOrderServiceId: orderServiceId, woOrderId : this.woOrderId, woOrderServiceName: orderServiceName, item: item  });
        })();

        this.displayDialogNew = true;
    }

    delItem(item: SfcWoOrderOrderServiceOutput) {

        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_delete') + ' ' + item.name + '?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.orderCartorioService
                    .delItem(item.id)
                    .subscribe(
                    {
                    next: () => {
                        this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant(this.translateService.instant('message.record_deleted')), life: 3000});
                        this.getList();
                    },
                    error: (err) =>
                    {
                        this.messageService.add({severity: 'warn', summary: this.translateService.instant('title.error'), detail: this.translateService.instant('api_response.ERROR', `SfcReconhecimentoFirmaComponent - saveItem : ${err}.`), life: 3000});
                        console.log(err)
                    }
                    });
            }
      });
    }

    updItem(item: SfcWoOrderOrderServiceOutput){
        this.lazyComponent = null;
        this.addService(item.woOrderServiceId, item.code, item.name, item);
        this.displayDialog = true;
        this.item = { ...item };
    }

    saveItem() {
        // if (item.quantity > 0) {
        //     delete this.clonedList[item.id as string];
        //     this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Product is updated' });
        // } else {
        //     this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Price' });
        // }
    }

    close() {
        this.displayDialogNew = false;
        this.getList();
    }

    changePopupSize(size: string) {
        this.popupSize = size+"px";
    }

    feedback(){
        this.chosedItem.emit(this.totalValue);
    }

    reciverFeedbackService() {
        this.orderCartorioService.reciverFeedbackChanged.subscribe((data: any) => {
            if (data.action == 'cancel'){
                this.close();
            }
            else if (data.action == 'save'){
                this.close();
            }
          });
    }

    getData(){


    }

    getListServiceByUser() {
        this.woOrderServiceService.getListByUser(this.woOrderTypeId).subscribe(
        {
            next: (res) => {
                if (res)
                    this.listServiceByUser = res.data;
            },
            error: (err) => {
                this.notificationsService.toastNotify('warn', this.translateService.instant('title.WoOrderService'), this.translateService.instant('api_response.ERROR'));
                console.log(`SfcServiceGrid - getListServiceByUser : ${err}.`);
            }
        });
    }

}
