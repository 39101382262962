import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScPersonService } from 'src/app/core/services/sc-person/sc-person.service';
import { DropDown } from 'src/app/shared/model/base-model';

@Component({
  selector: 'sf-sc-person-type-nationality-dropdown',
  templateUrl: './sc-person-type-nationality-dropdown.component.html',
  styleUrls: ['./sc-person-type-nationality-dropdown.component.scss']
})
export class ScPersonTypeNationalityDropdownComponent {
  public list: DropDown[];
  @Input() placeHolderLanguage: string;
  public selectedItem: DropDown;

  @Input() id: string;
  @Output() chosedItem = new EventEmitter();

  constructor(private scPersonService: ScPersonService) { }

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this.scPersonService.getDropDownTypeNationality().subscribe(
      {
        next: (res) => {
          if (res)
            this.list = res.data;
          if (this.list !== null)
            this.selectedItem = this.list.find(x => x.id == this.id?.toString());
        },
        error: (err) => {
          console.log(`ScPersonTypeNationalityDropDown - getList : ${err}.`);
        }
      });
  }

  feedback() {
    this.chosedItem.emit(this.selectedItem.id)
  }
}