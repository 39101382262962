import { NgModule, OnInit } from '@angular/core';
import { CarimboComponent } from './carimbo.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [CarimboComponent],
    exports: [CarimboComponent],
    imports: [ CommonModule]
})
export class CarimboModule {

}