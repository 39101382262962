import { ScConfigService } from '../sc-config.service';
import { DropDown } from './../../../shared/model/base-model';
import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'sf-sc-config-list-multi',
    templateUrl: './sc-config-list-multi.component.html',
    styleUrls: ['./sc-config-list-multi.component.scss']
})

export class ScConfigListMultiComponent implements OnInit{

    public list : DropDown[];
    public item: DropDown;
    public selectedList: DropDown[];

    @Input() placeHolderLanguage: string;
    @Input() selectedItems: DropDown[] = [];
    @Input() language: string = window.localStorage.getItem("_LANG");
    @Output() chosedItem = new EventEmitter();

    constructor(  private scConfigService: ScConfigService){ }

    ngOnInit(): void {
        this.getList();
    }

    ngOnChanges(): void {
        this.selectedList = this.selectedItems;
    }

    getList() {
        this.scConfigService.getDropDownList('', this.language).subscribe(
          {
            next: (res) => {
                if (res)
                    this.list = res.data;
                this.selectedList = this.selectedItems;

            },
            error: (err) => {
                console.log(`ConfigListMulti - getList : ${err}.`);
            }
        });
      }

    feedback(event){
        this.chosedItem.emit(this.selectedList);
    }
}
