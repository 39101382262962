import { LoaderModule } from './../../shared/components/loader/loader.module';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';

import { AuthService } from 'src/app/core/auth/auth.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { AuthorizationGuard } from 'src/app/core/guards/authorization.guard';

import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';
import { ScCompanyGridComponent } from './sc-company/sc-company-grid/sc-company-grid.component';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { VMessageModule } from 'src/app/shared/components/vmessage/vmessage.module';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';

import { InputMaskModule } from 'primeng/inputmask';
import { CardModule } from 'primeng/card';
import { addressModule } from 'src/app/shared/components/address/address.module';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { PickListModule } from 'primeng/picklist';
import { StepsModule } from 'primeng/steps';
import { ListboxModule } from 'primeng/listbox';
import { ScrollPanelModule } from 'primeng/scrollpanel';

import { SfDropDownModule } from 'src/app/shared/components/dropdown/dropdown.module';
import { SfGridGenericModule } from 'src/app/shared/components/grid-generic/grid-generic.module';


import { ScCompanyService } from './sc-company.service';
import { ScCompanyTypeService } from './sc-company-type.service';
import { ScCompanyStatusService } from './sc-company-status.service';
import { ScConfigModule } from '../sc-config/sc-config.module';


import { ScCompanyStatusGridComponent } from './sc-company-status/sc-company-status-grid.component';
import { ScCompanyTypeGridComponent } from './sc-company-type/sc-company-type-grid.component';
import { ScCompanyDropdownMultiComponent } from './sc-company-dropdown-multi/sc-company-dropdown-multi.component';
import { ScCompanyDetailComponent } from './sc-company/sc_company-detail/sc-company-detail.component';
import { ScCompanyAddComponent } from './sc-company/sc-company-add/sc-company-add.component';
import { ScCompanyAddStepInfoComponent } from './sc-company/sc-company-add/steps/sc-company-add-step-info.component';
import { ScCompanyAddStepTypeComponent } from './sc-company/sc-company-add/steps/sc-company-add-step-type.component';
import { ScCompanyAddStepUserComponent } from './sc-company/sc-company-add/steps/sc-company-add-step-user.component';
import { ScCompanyAddStepConfirmComponent } from './sc-company/sc-company-add/steps/sc-company-add-step-confirm.component';




@NgModule({
    declarations: [ScCompanyGridComponent,
                   ScCompanyDetailComponent,
                   ScCompanyAddComponent,
                   ScCompanyStatusGridComponent,
                   ScCompanyTypeGridComponent,
                   ScCompanyDropdownMultiComponent,
                   ScCompanyAddStepInfoComponent,
                   ScCompanyAddStepTypeComponent,
                   ScCompanyAddStepUserComponent,
                   ScCompanyAddStepConfirmComponent,
                ],
    exports: [ScCompanyGridComponent,
            ScCompanyDetailComponent,
            ScCompanyAddComponent,
            ScCompanyStatusGridComponent,
            ScCompanyTypeGridComponent,
            ScCompanyDropdownMultiComponent,
            ScCompanyAddStepInfoComponent,
            ScCompanyAddStepTypeComponent,
            ScCompanyAddStepUserComponent,
            ScCompanyAddStepConfirmComponent,
            ],

    imports: [FormsModule,
        ReactiveFormsModule,
        CommonModule,
        VMessageModule,
        RouterModule,
        NotificationsModule,
        ButtonModule,
        InputTextModule,
        TranslateModule,
        MessageModule,
        MessagesModule,
        TableModule,
        TooltipModule,
        InputMaskModule,
        CardModule,
        DialogModule,
        LoaderModule,
        MultiSelectModule,
        PickListModule,
        ScrollPanelModule,
        SfGridGenericModule,
        SfDropDownModule,
        addressModule,
        ScConfigModule,
        StepsModule,
        ListboxModule

        ],
    providers: [NotificationsService, AuthService, AuthorizationGuard, ScCompanyService, ScCompanyStatusService, ScCompanyTypeService],
})
export class ScCompanyModule { }
