<div class="stepsdemo-content">
    <p-card>
        <ng-template pTemplate="title"> <span translate class="font-bold">label.companyInfo</span> </ng-template>
        <ng-template pTemplate="subtitle"> <span class="text-lg font-bold" translate>label.enterCompanyInfo</span> </ng-template>
        <ng-template pTemplate="content">
            <div class="formgrid grid text-lg">
                <div class="field col-12 sm:col-12 md:col-12 lg:col-3">
                    <div class="avatar-container">
                        <div class="image-container" [ngStyle]="{backgroundImage: 'url(' + item.pictureUri+ ')'}" *ngIf="hasPicture"></div>
                        <div class="image-container vertical-align-baseline text-center"  *ngIf="!hasPicture"> <span class="fontOrangeBig" translate>label.companyLogo</span></div>
                        <label for="avatar-input-file">
                            <div class="icon-wrapper">
                                <input type="file" id="avatar-input-file" (change)="fileChangeEvent($event)" accept="image/*"/>
                                <i class="pi pi-upload icon"></i>
                            </div>
                        </label>
                    </div>
                </div>
                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="code"><span translate>label.shortName</span></label>
                    <input #code="ngModel" id="code" type="text" required pInputText [(ngModel)]="step.code"
                        [ngClass]="{ 'ng-dirty': (!step.code && submitted) }"/>
                    <small *ngIf="(!step.code && submitted)" class="p-error"><span translate>label.required_fields</span></small>
                </div>
                <div class="field col-12 sm:col-12 md:col-12 lg:col-3">
                    <label for="ein"><span translate>label.scCompanyEin</span></label>
                    <p-inputMask inputId="ein" mask="99.999.999/9999-99" [(ngModel)]="step.ein"></p-inputMask>
                </div>
                <div class="field col-12">
                    <label for="name"><span translate>label.name</span></label>
                    <input #shortName="ngModel" id="name" type="text" required pInputText [(ngModel)]="step.name"
                        [ngClass]="{ 'ng-dirty': (!step.name && submitted) }"/>
                    <small *ngIf="(!step.name && submitted)" class="p-error"><span translate>label.required_fields</span></small>
                </div>
                <div class="field col-12">
                    <label for="fantasyName"><span translate>label.fantasyName</span></label>
                    <input #shortName="ngModel" id="fantasyName" type="text" pInputText [(ngModel)]="step.fantasyName"/>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="grid grid-nogutter justify-content-end">
                <p-button [label]="'label.next' | translate" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
            </div>
        </ng-template>
    </p-card>
</div>
