
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';

import { AuthService } from 'src/app/core/auth/auth.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { AuthorizationGuard } from 'src/app/core/guards/authorization.guard';
import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { VMessageModule } from 'src/app/shared/components/vmessage/vmessage.module';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MultiSelectModule } from 'primeng/multiselect';

import { InputMaskModule } from 'primeng/inputmask';
import { CardModule } from 'primeng/card';




import { SfDropDownModule } from 'src/app/shared/components/dropdown/dropdown.module';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { WoSkillService } from './wo-skill.service';
import { WoSkillGridComponent } from './wo-skill/wo-skill-grid.component';
import { WoSkillDropdownMultiComponent } from './wo-skill-dropdown-multi/wo-skill-dropdown-multi.component';
import { WoSkillDropdownComponent } from './wo-skill-dropdown/wo-skill-dropdown.component';


@NgModule({
    declarations: [WoSkillGridComponent, WoSkillDropdownMultiComponent, WoSkillDropdownComponent],
    exports: [WoSkillGridComponent, WoSkillDropdownMultiComponent, WoSkillDropdownComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        VMessageModule,
        RouterModule,
        NotificationsModule,
        ButtonModule,
        InputTextModule,
        TranslateModule,
        MessageModule,
        MessagesModule,
        TableModule,
        TooltipModule,
        InputMaskModule,
        CardModule,
        SfDropDownModule,
        DialogModule,
        ConfirmDialogModule,
        DropdownModule,
        MultiSelectModule

    ],
    providers: [NotificationsService, AuthService, AuthorizationGuard, WoSkillService]
})
export class WoSkillModule { }
