import {Component} from '@angular/core';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import {AppMainComponent} from '../../app.main.component';

@Component({
    selector: 'app-rightpanel',
    templateUrl: './app.rightpanel.component.html'
})
export class AppRightPanelComponent {

    constructor(public app: AppMainComponent, private translateConfigService: TranslateConfigService) { }

    changeLanguage(type: string) {
        this.translateConfigService.changeLanguage(type);
    }
}
