import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserTokenService } from '../services/user-token.service';



@Injectable({providedIn:'root'})
export class AuthGuard implements CanActivate{
    constructor(private userTokenService: UserTokenService, private router:Router){}


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        // console.log('guard');
        // console.log(this.userService.isLogged());
        if(this.userTokenService.isLogged()){
            this.router.navigate(['']);
            return false;
        }
        return true;
    }
}
