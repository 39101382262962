import { Table } from 'primeng/table';
import { BreadcrumbService } from './../../../../layout/topbar/app.breadcrumb.service';
import { NotificationsService } from './../../../../shared/notifications/notifications.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ConfirmationService, MessageService } from 'primeng/api';
import { TranslateConfigService } from  '../../../../core/services/translate-config.service';
import { UserTokenService } from '../../../../core/services/user-token.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WoOrderServiceTemplateOutput } from '../wo-order-service-template.model';
import { WoOrderServiceTemplateService } from '../wo-order-service-template.service';
import { ConstantsService } from 'src/app/core/services/constants.service';
import { DropDown } from 'src/app/shared/model/base-model';

@Component({
    selector: 'wo-order-service-template-grid',
    templateUrl: './wo-order-service-template-grid.component.html',
    styleUrls: ['./wo-order-service-template-grid.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})
export class WoOrderServiceTemplateGridComponent implements OnInit {

    public language: string = "pt-BR";
    public pageMessage: string = "";

    public displayDialog: boolean = false;
    public submitted: boolean = false;
    public isUpdate: boolean = false;
    public dialogHeader: string = "";
    public list: WoOrderServiceTemplateOutput[] = [];
    public item: WoOrderServiceTemplateOutput = {};
    public selectedModules: DropDown[] = [];
    public isLoading: boolean = true;


    constructor(private orderServiceTemplateService: WoOrderServiceTemplateService,
                private confirmationService: ConfirmationService,
                private constantsService: ConstantsService,
                private messageService: MessageService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService,
                private breadcrumbService: BreadcrumbService,
                private userTokenService: UserTokenService) { }

    ngOnInit(): void {
        this.isLoading = true;
        this.language = this.translateConfigService.getLanguage();

        if (!this.item || !this.selectedModules || !this.submitted) {
            this.item = {};
            this.selectedModules = [];            
            this.submitted = false;
        }

        this.getList();
        this.translate();
    }


    getList() {
        this.orderServiceTemplateService.getList().subscribe(
        {
            next: (res) => {
                if (res)
                    this.list = res.data;
                this.isLoading = false;   
            },
            error: (err) => {
                this.messageError(this.translateService.instant('api_response.ERROR'));
                console.log(`WoOrderServiceTemplateGrid - getList : ${err}.`);
            }
        });
       
    }

    addItem() {
        this.item = {};
        this.displayDialog = true;
        this.submitted = false;
        this.isUpdate = false;
    }

    updItem(item: WoOrderServiceTemplateOutput) {
        this.item = {...item};
        this.displayDialog = true;
        this.submitted = false;
        this.isUpdate = true;
    }
    
    delItem(item: WoOrderServiceTemplateOutput) {
        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_delete')+' ' + item.name + '?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.orderServiceTemplateService
                .delItem(item.id)
                .subscribe(
                {
                    next: () =>
                    {
                        this.messageSuccess(this.translateService.instant('message.record_deleted'));
                        this.getList();
                    },
                    error: (err) => {
                        this.messageError(this.translateService.instant('api_response.ERROR'));
                        console.log(`WoOrderServiceTemplateGrid - delItem : ${err}.`);
                    }
                });
            }
      });
    }

    saveItem()
    {
        this.submitted = true;
        if (this.item.code.trim()) {
            if (this.item.id) {

            this.orderServiceTemplateService
            .updItem(this.item)
            .subscribe(
                {
                next: () => {
                    this.messageSuccess(this.translateService.instant('message.record_updated'));
                    this.getList();
                },
                error: (err) => {
                    this.messageError(this.translateService.instant('api_response.ERROR'));
                    console.log(`WoOrderServiceTemplateGrid - updItem : ${err}.`);
                }
            });

            } else {
                this.orderServiceTemplateService
                    .addItem(this.item)
                    .subscribe(
                    {
                    next: () => {
                        this.messageSuccess(this.translateService.instant('message.record_inserted'));
                        this.getList();
                    },
                    error: (err) => {
                        this.messageError(this.translateService.instant('api_response.ERROR'));
                        console.log(`WoOrderServiceTemplateGrid - updItem : ${err}.`);
                    }
                    });
            }

            this.list = [...this.list]
            this.displayDialog = false;
            this.submitted = false;
            this.item = {};
        }
    }


    hideDialog() {
        this.displayDialog = false;
        this.submitted = false;
    }


    translate(){
        const sleep = (ms) => new Promise(r => setTimeout(r, ms));

        (async () => {
            await sleep(100);

            const labService = this.translateService.instant('label.order_service')+" ";
            const labServiceTemplate = " "+this.translateService.instant('label.orderServiceTemplate');
            this.breadcrumbService.setItems([{label: labService}, {label: labServiceTemplate, routerLink: ['order-service-template']} ]);
            this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')
            this.dialogHeader = this.translateService.instant('title.woOrderServiceTemplateEdit');
        })();
    }

    clear(table: Table, filter: any) {
        table.clear();
        filter.Text = "";
    }

    messageSuccess(message: string){
        this.messageService.add({severity: this.constantsService.SEVERITY_SUCCESS, summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 3000});
    }

    messageError(message: string){
        this.messageService.add({severity: this.constantsService.SEVERITY_WARNING, summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 3000});
    }

    reciverFeedbackModule(select: DropDown[])
    {
        this.selectedModules = select;
        this.item.modules = this.selectedModules;
        console.log(this.selectedModules);
    }

}


