import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


import { ConfirmationService, MessageService } from 'primeng/api';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { WoOrderStatus, WoOrderStatusOutput } from './wo-order-status.model';
import { WoOrderStatusService } from './wo-order-status.service';
import { DropDown, GenericItem } from 'src/app/shared/model/base-model';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { Table } from 'primeng/table';


@Component({
    selector: 'wo-order-status-grid',
    templateUrl: './wo-order-status-grid.component.html',
    styleUrls: ['./wo-order-status-grid.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})

export class WoOrderStatusGridComponent implements OnInit {

    public language: string = "pt-BR";
    public flUpdate: boolean = true;
    public dialogHeader: string = "";
    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-warning p-m-1 mr-1";


    public list: WoOrderStatusOutput[] = [];
    public item: WoOrderStatus = {};
    public listStage: DropDown[] = [];

    public displayMsg: boolean = false;
    public displayDialog: boolean;
    public submitted: boolean;


    constructor(private userTokenService: UserTokenService,
                private messageService: MessageService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService,
                private breadcrumbService: BreadcrumbService,
                private orderStatusService: WoOrderStatusService)
    { }

    ngOnInit(): void {
        this.getList();
        this.getListStage();
        this.translate();
    }

    translate()
    {
        this.language = this.translateConfigService.getLanguage();

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));
        (async () => {
             await sleep(50);
             const lab1 = this.translateService.instant('label.order') + ' ';
             const lab2 = ' ' + this.translateService.instant('label.orderStatus');
             this.breadcrumbService.setItems([{label: lab1},{label: lab2, routerLink: ['order-status']} ]);
             this.dialogHeader = this.translateService.instant('title.WoOrderStatus');
             this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')
         })();

         this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN','SF_SELLER','WO_WORKFLOW_U']);
         if (this.flUpdate == false)
         {
             this.classBtnUpd = "p-button p-button-secondary p-m-1 mr-1";
             this.classBtnDel = this.classBtnUpd;
         }
    }

    getList() {
        this.orderStatusService.getList().subscribe(
            {
                next: (res) => {
                    if (res)
                        this.list = res.data;
                },
                error: (err) => {
                    console.log(`WoOrderStatusGrid - getList : ${err}.`);
                }
            });
    }

    getListStage() {
        this.orderStatusService.getOrderStatusStageDropDownList(this.language).subscribe(
            {
                next: (res) => {
                    if (res)
                        this.listStage = res.data;
                },
                error: (err) => {
                    console.log(`WoOrderStatusGrid - getOrderStatusStageList : ${err}.`);
                }
            });
    }

    addItem() {
        this.item = {};
        this.submitted = false;
        this.displayDialog = true;
    }

    updItem(item: GenericItem) {
        this.item = {...item};
        this.displayDialog = true;
    }

    delete() {
        this.orderStatusService
            .delItem(this.item.id)
            .subscribe(
                {
                    next: () =>
                    {
                        this.messageSuccess('message.record_deleted');
                        this.getList();
                    },
                    error: (err) => {
                        this.messageError('api_response.ERROR');
                        console.log(`WoOrderStatusGrid - delItem : ${err}.`);
                    }
                });
    }

    save() {
        if (this.item.code) {
            if (this.item.id) {
                this.orderStatusService
                    .updItem(this.item)
                    .subscribe(
                        {
                            next: () => {
                                this.messageSuccess('message.record_updated');
                                this.getList();
                            },
                            error: (err) => {
                                this.messageError('api_response.ERROR');
                                console.log(`WoOrderStatusGrid - UpdItem : ${err}.`);
                            }
                        });

            } else {
                this.orderStatusService
                    .addItem(this.item)
                    .subscribe(
                        {
                            next: () => {
                                this.messageSuccess('message.record_inserted');
                                this.getList();
                            },
                            error: (err) => {
                                this.messageError('api_response.ERROR');
                                console.log(`WoOrderStatusGrid - InsItem : ${err}.`);
                            }
                        });
            }

            this.list = [...this.list];
            this.item = {};
            this.hideDialog();
        }
    }

    hideDialog() {
        this.displayDialog = false;
        this.submitted = false;
    }

    clear(table: Table) {
        table.clear();
    }


    messageSuccess(message: string){
        this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 3000});
    }

    messageError(message: string){
        this.messageService.add({severity: 'warn', summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 3000});
    }

    reciverFeedbackStage(id: string){
        this.item.woOrderStatusStageId = id;
    }
}
