

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DropDownMeta } from 'src/app/shared/model/base-model';
import { WoSkill, WoSkillItem, WoSkillList, WoSkillUpdateInput } from './wo-skill.model';

@Injectable({
    providedIn: 'any'
})

export class WoSkillService{

    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/Skill/';
    }

    getItem(id: string): Observable<WoSkillItem> {
        let url = this.environmentUrl + id;
        return this.httpClient.get<WoSkillItem>(url);
    }

    getList(): Observable<WoSkillList> {
        let url = this.environmentUrl;
        return this.httpClient.get<WoSkillList>(url);
    }

    addItem(model: WoSkill) {
        return this.httpClient.post(this.environmentUrl, model);
    }

    updItem(model: WoSkill, userId: string) {

        let url = this.environmentUrl + model.id;
        let modelUpd: WoSkillUpdateInput = {};

        modelUpd.id = model.id;
        modelUpd.code = model.code;
        modelUpd.name = model.name;
        modelUpd.icon = model.icon;
        modelUpd.color = model.color;
        modelUpd.isActive = model.isActive;
        modelUpd.userId = userId;

        return this.httpClient.put(url, modelUpd);
    }

    delItem(id: string) {
        return this.httpClient.delete(this.environmentUrl + id);
    }

    getDropDownList(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown";
        return this.httpClient.get<DropDownMeta>(url);
    }

}
