import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { LoaderService } from './loader.service';
import { ProgressBarModule } from 'primeng/progressbar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@NgModule({
    declarations: [LoaderComponent],
    exports: [LoaderComponent],
    imports: [ CommonModule, ProgressBarModule, ProgressSpinnerModule],
    providers:[LoaderService]
})
export class LoaderModule {

}