<div class="col-12 mt-5">
    <div class="formgrid grid">
        <div class="field col">
            <label for="name" class="mb1 font-bold">Serviço</label>
            <input type="text" pInputText id="name" [(ngModel)]="item.name" class="mt-1" disabled class="w-full"/>
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col">
            <label class="mb1 font-bold">Escrevente</label>
            <sf-dropdown-generic  [list]="userClerkList" [id]="item.scUserClerkId"
                (selectedItemOutput)="reciverFeedbackUserClerk($event)" [disabled]="isDisabled"></sf-dropdown-generic>
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col">
            <label class="mb1 font-bold">Tributação</label>
            <sf-dropdown-generic  [list]="taxTypeList" [id]="item.woOrderServiceTaxTypeId"
                (selectedItemOutput)="reciverFeedbackTaxType($event)" [disabled]="isDisabled"></sf-dropdown-generic>
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col">
            <label for="description" class="mb1 font-bold">Descrição</label>
            <input type="text" pInputText [(ngModel)]="item.description" id="description"  class="w-full"  [disabled]="isDisabled"/>
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col">
            <label class="mb1 font-bold">Tipo de Documento</label>
            <sf-dropdown-generic  [list]="docTypeList" [id]="item.scArchiveTypeId"
                (selectedItemOutput)="reciverFeedbackDocType($event)" [disabled]="isDisabled"></sf-dropdown-generic>
        </div>

        <div class="field col">
            <label for="documentDescription" class="mb1 font-bold">Descrição do Documento</label>
            <input type="text" pInputText [(ngModel)]="item.documentDescription" id="documentDescription"  class="w-full"  [disabled]="isDisabled"/>
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col">
            <label class="mb1 font-bold">Quantidade de Cópias</label>
            <p-inputNumber [(ngModel)]="item.copyQty"  [showButtons]="true" inputId="minmax-buttons-copyQty" [min]="1" [max]="100" [disabled]="isDisabled" class="w-full"> </p-inputNumber>
        </div>
        <div class="field col">
            <label class="mb1 font-bold">Quantidade de Folhas</label>
            <p-inputNumber [(ngModel)]="item.pageQty"  [showButtons]="true" inputId="minmax-buttons-pageQty" [min]="1" [max]="100" [disabled]="isDisabled" class="w-full"> </p-inputNumber>
        </div>
    </div>

    <div class="formgrid grid mt-1">
        <div class="field col">
            <div class="field-checkbox">
                <p-checkbox [(ngModel)]="isDisabled" binary="true" id="isStampPrinted" name="isStampPrinted" ngDefaultControl class="p-d-inline" [disabled]="isDisabled"> </p-checkbox>
                <label class="mb1 font-bold">Imprimir Selo Único</label>
            </div>
        </div>

        <!-- <div class="field col">
            <label for="mobileCode" class="mb1 font-bold">Selos Disponíveis: <span class="font-bold">{{stampQty}}</span></label>
        </div>

        <div class="field col">
            <label for="mobileCode" class="mb1 font-bold">Selo Atual: <span class="font-bold">{{item.stamp}}</span></label>
        </div> -->
        <div class="field col">
            <wo-notarial-seal-reserve *ngIf="load" [woNotarialSealId]="item.woNotorialSealId" [cssClass]="'field col'" (selectedItemOutput)="reserveNotarialSeal($event)"></wo-notarial-seal-reserve>
        </div>
    </div>

    <div class="col-12 mt-3">
        <div style="float: right;">
            <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="feedback('cancel')"></button>
            <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="saveItem()"></button>
        </div>
    </div>

</div>
