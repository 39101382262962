import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthorizationGuard } from "src/app/core/guards/authorization.guard";
import { AuthenticationGuard } from "src/app/core/guards/authentication.guard";
import { ScPersonFullGridComponent } from "./pages/sc-person-full/sc-person-full-grid/sc-person-grid/sc-person-full-grid.component";

const routes: Routes = [
    {
        path: ':flagPersonType', component: ScPersonFullGridComponent,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data:{
            roles:['SF_ADMIN','SF_SELLER','SC_PERSON_U','SC_PERSON_R']
        }
    }
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class ScPersonRoutingModule { }