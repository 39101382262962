<div [class.loader-hidden]="!show">
  <div>
    <div class="loader-overlay">
            <div>
                <p-progressBar class ="loader-bar" mode="indeterminate"  [style]="{ height: '6px' }" *ngIf="show"></p-progressBar>
            </div>
    </div>
</div>



<!--<div  *ngIf="show" class="overlay" [style.background-color]="'#00000088'"
  [style.position]="'fixed'">

  <div>
        <div class="loader-overlay">
            <div>
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#00000000" animationDuration=".5s"></p-progressSpinner>

            </div>
        </div>
    </div>

</div>
-->
