<div class="route-bar">
    <div class="route-bar-breadcrumb">
        <!-- <i class="pi pi-home"></i> -->
        Home
        <span>/ </span>
        <ng-template ngFor let-item let-last="last" [ngForOf]="items">
            <a [routerLink]="item.routerLink" *ngIf="item.routerLink">{{item.label}}</a>
            <ng-container *ngIf="!item.routerLink">{{item.label}}</ng-container>
            <span *ngIf="!last">/</span>
        </ng-template>
    </div>

    <ul class="route-bar-menu">
        <!-- <li class="search-item">
            <i class="pi pi-search" ></i>
            <input type="text" placeholder="Search..."/>
        </li> -->

        <!-- TODO Notifications  -->
        <li>
            <a href="#" [pTooltip]="'button.notifications' | translate"  tooltipPosition="bottom">
                <i class="pi pi-globe" ></i>
            </a>
        </li>

        <!-- TODO Help  -->
        <li>
            <a href="#" [pTooltip]="'button.help' | translate" tooltipPosition="bottom">
                <i class="pi pi-question-circle" ></i>
            </a>
        </li>
    </ul>
</div>
