
<div class="p-col-12" style="margin-top: -5px;">
    <div class="formgrid grid">

        <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
            <div class="card">

                <div class="formgrid grid text-lg">
                    <div class="field col-2">
                        <label for="firstname2" class="font-bold"><span translate>label.order</span></label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-users"></i>
                            </span>
                            <input pInputText [value]="order.code" readonly/>
                        </div>
                    </div>
                    <div class="field col-4">
                        <label for="person" class="font-bold mr-2"><span translate>label.client</span></label><p-rating [ngModel]="order.clientRating" [readonly]="true" [cancel]="false" [iconOnStyle]="{'color':'#4743fb'}" ></p-rating>                        
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-users"></i>
                            </span>
                            <input pInputText [value]="order.clientName" readonly/>
                        </div>                        
                    </div>                    
                    <div class="field col-2">
                        <label for="firstname2" class="font-bold"><span translate>label.createdDate</span></label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-calendar"></i>
                            </span>
                            <input pInputText  [value]="order.createdDate | date: 'dd/MM/yy HH:mm'" readonly />
                        </div>
                    </div>
                    <div class="field col-2">
                        <label for="firstname2" class="font-bold"><span translate>label.orderResponsableUser</span></label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-users"></i>
                            </span>
                            <input pInputText  [value]="order.userName" readonly/>
                        </div>
                    </div>
                    <div class="field col-2">
                        <label for="firstname2" class="font-bold"><span translate>label.orderPaymentUser</span></label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-users"></i>
                            </span>
                            <input pInputText [value]="order.userName" readonly/>
                        </div>
                    </div>                    
                </div>
             </div>  <!--Main Card -->
        </div>

        <div class="field col-6 md:col-6 lg:col-6 xl:col-6">
            <div class="card">
                <h4 class="text-center mb-5"><span translate>label.paymentSummary</span></h4>

                    <sf-wo-order-payment-summary [WoOrderId]="order.id" (totalOutput)="reciverFeedbackTotalValue($event)"></sf-wo-order-payment-summary> 
               
            </div>
        </div>

        <div class="field col-6 md:col-6 lg:col-6 xl:col-6">
            <div class="card">
                <h4 class="text-center"><span translate>label.paymentMethod</span></h4>

                <div class="grid">
                    <div class="col-6">              
                    
                        <p-accordion class="w-full"  [activeIndex]="activeIndex">                        
                            <p-accordionTab>
                                <ng-template pTemplate="header">
                                    <div class="flex align-items-center">
                                        <i class="pi pi-paypal text-green-400"  style="font-size: 2rem;"></i>
                                        <span class="ml-2 paymentType text-green-400">PIX</span>                                     
                                    </div>                                
                                    <div *ngIf="hasPix" >
                                        <i class="pi pi-check text-green-600 "></i>
                                    </div>
                                </ng-template>
                                
                                <ng-template pTemplate="content">
                                    <div class="field">
                                        <label for="firstname2" class="font-bold"><span translate>label.value</span></label>
                                        <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <span translate>label.moneySymbol</span>
                                            </span>
                                            <p-inputNumber [(ngModel)]="item.pixValue" inputId="card" mode="currency" [currency]="moneyType" [locale]="language"   (onInput)="updateReceived()" [disabled]="!flUpdate" > </p-inputNumber>
                                        </div>
                                    </div>         
                                    <div class="field">
                                        <label for="firstname2" class="font-bold"><span translate>label.code</span> - <span translate>label.optional</span></label>
                                        <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <i class="pi pi-print"></i>
                                            </span>
                                            <input pInputText [value]="pixCode"/>
                                        </div>
                                    </div>                                     
                                </ng-template>                            
                            </p-accordionTab>
                        
                            <p-accordionTab>
                                <ng-template pTemplate="header">
                                    <div class="flex align-items-center">
                                        <i class="pi pi-credit-card text-blue-600"  style="font-size: 2rem;"></i>
                                        <span class="ml-2 paymentType text-blue-600"><span translate>label.card</span></span>
                                    </div>
                                    <div *ngIf="hasCard" >
                                        <i class="pi pi-check text-green-600 "></i>
                                    </div>
                                </ng-template>

                                <ng-template pTemplate="content">
                                    <div class="field">
                                        <label for="firstname2" class="font-bold"><span translate>label.value</span></label>
                                        <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <span translate>label.moneySymbol</span>
                                            </span>
                                            <p-inputNumber [(ngModel)]="item.cardValue" inputId="card"  mode="currency" [currency]="moneyType" [locale]="language"   (onInput)="updateReceived()" [disabled]="!flUpdate" > </p-inputNumber>
                                        </div>
                                    </div>         
                                    <div class="field">
                                        <label for="firstname2" class="font-bold"><span translate>label.code</span></label>
                                        <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <i class="pi pi-print"></i>
                                            </span>
                                            <input pInputText [value]="cardCode"/>
                                        </div>
                                    </div>                                     
                                </ng-template>         
                            </p-accordionTab>
                            
                            <p-accordionTab>
                                <ng-template pTemplate="header">
                                    <div class="flex align-items-center">
                                        <i class="pi pi-money-bill text-green-700"  style="font-size: 2rem;"></i>
                                        <span class="ml-2 paymentType text-green-700" ><span translate>label.money</span></span>  
                                    </div>
                                    <div *ngIf="hasMoney" >
                                        <i class="pi pi-check text-green-600 "></i>
                                    </div>
                                </ng-template>

                                <ng-template pTemplate="content">
                                    <div class="field">
                                        <label for="firstname2" class="font-bold"><span translate>label.value</span></label>
                                        <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <span translate>label.moneySymbol</span>
                                            </span>
                                            <p-inputNumber [(ngModel)]="item.moneyValue" inputId="card" mode="currency" [currency]="moneyType" [locale]="language"   (onInput)="updateReceived()" [disabled]="!flUpdate" > </p-inputNumber>
                                        </div>
                                    </div>         
                                    <div class="field">
                                        <label for="firstname2" class="font-bold"><span translate>label.code</span></label>
                                        <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <i class="pi pi-print"></i>
                                            </span>
                                            <input pInputText [value]="moneyCode"/>
                                        </div>
                                    </div>                                     
                                </ng-template>       
                            </p-accordionTab>

                        </p-accordion>                
                    </div>

                    <div class="col-6">                    
                        <div class="grid col-12">
                            <div class="field col-6">
                                
                                <span class="font-bold"><span translate>label.discountValue</span></span>                                
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <span translate>label.moneySymbol</span>
                                    </span>
                                    <p-inputNumber [(ngModel)]="item.discountValue" inputId="discount" mode="currency" [currency]="moneyType" [locale]="language" (onInput)="updateTotal(true)" class="w-full"> </p-inputNumber>                                 
                                </div>
                            </div>
                            <div class="field col-6">
                                <span class="font-bold"><span translate>label.discountPercent</span></span>
                                <div class="p-inputgroup">                        
                                    <p-inputNumber [(ngModel)]="discountPercent" [showButtons]="true" buttonLayout="horizontal" inputId="dioscountPercent" spinnerMode="horizontal" [step]="0.25" prefix="%"
                                        decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" (onInput)="updateTotal(false)" class="w-full" >
                                    </p-inputNumber> 
                                </div>
                            </div>                        
                        </div>

                        <div class="grid col-12">
                            <div class="field col-6">
                                <label for="firstname2" class="font-bold"><span translate>label.pixValue</span></label>                        
                                <div class="p-inputgroup">    
                                    <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                                    <input type="text" pInputText disabled  [value]="item.pixValue | number:'1.2-2'" class="w-full">                            
                                </div>
                            </div>
                            <div class="field col-6">
                                <span class="font-bold"><span translate>label.totalValue</span></span>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                                    <input type="text" pInputText disabled  [value]="totalValue | number:'1.2-2'" class="w-full font-bold" style="color: black;">                            
                                </div>
                            </div>
                        </div>  

                        <div class="grid col-12">
                            <div class="field col-6">
                                <label for="firstname2" class="font-bold"><span translate>label.cardValue</span></label>                        
                                <div class="p-inputgroup">    
                                    <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                                    <input type="text" pInputText disabled  [value]="item.cardValue | number:'1.2-2'" class="w-full">                                                            
                                </div>
                            </div>
                            <div class="field col-6">
                                <span class="font-bold"><span translate>label.totalDiscountValue</span></span>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                                    <input type="text" pInputText disabled  [value]="totalDiscountValue | number:'1.2-2'" class="w-full font-bold" style="color: black;">                            
                                </div>
                            </div>
                        </div>      

                        <div class="grid col-12">
                            <div class="field col-6">
                                <label for="firstname2" class="font-bold"><span translate>label.moneyValue</span></label>                        
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                                    <input type="text" pInputText disabled  [value]="item.moneyValue | number:'1.2-2'" >                            
                                </div>
                            </div>
                            <div class="field col-6">
                                <label for="firstname2" class="font-bold"><span translate>label.totalReceiviedValue</span></label>                                                
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                                    <input type="text" pInputText disabled  [value]="totalReceivedValue | number:'1.2-2'" class="font-bold" [ngStyle]="{'color': totalReceivedValue == totalValue ? 'green' : 'red' }" >                            
                                </div>
                            </div>
                        </div>   
                        
                        <div class="grid col-12">
                            <div class="field col-6">
                                <label for="firstname2" class="font-bold"><span translate>label.totalMissingValue</span></label>                                                
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                                    <input type="text" pInputText disabled  [value]="missingValue | number:'1.2-2'"  class="w-full font-bold" [ngStyle]="{'color': missingValue == 0 ? 'green' : 'black' }">                            
                                </div>
                            </div>
                            <div class="field col-6">
                                <label for="firstname2" class="font-bold"><span translate>label.change</span></label>                                                
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                                    <input type="text" pInputText disabled  [value]="changeValue | number:'1.2-2'"  class="w-full font-bold" [ngStyle]="{'color': changeValue > 0 ? 'green' : 'black' }">                            
                                </div>
                            </div>
                        </div>   
                        <div class="grid col-12" >
                            <div class="flex justify-content-end">
                                <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px; " class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="close(false)"></button>
                                <div *ngIf="flUpdate">
                                    <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px; " class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="save()" [disabled]="!canSave"></button>
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


     <!-- <div class='credit-info'>
                    <div class='credit-info-content'>
                    <table class='half-input-table'>
                        <tr>
                        <td>Selecione a forma de pagamento: </td>
                        <td>
                            <sf-dropdown-generic InputId="state" [list]="paymentTypeList" [style]="{'width':'100%'}"
                            [id]="paymentSelected" (selectedItemOutput)="paymentSelected = $event"></sf-dropdown-generic>
        
                        </td>
                        </tr>
                    </table>
        
                    <app-money totalValue="10.35" *ngIf="paymentSelected == '1'"></app-money>
                    <app-card *ngIf="paymentSelected == '2'" [cardForm]="cardForm"></app-card>
                    <app-pix *ngIf="paymentSelected == '3'"></app-pix>
                    <button pButton type="submit" class='pay-btn'  [disabled]="!cardForm.valid">Finalizar</button>
        
                    </div>
        
                </div> -->

<!--  
 
 <div class="formgrid grid">
    <div class="col mt-5">&nbsp;&nbsp;&nbsp;&nbsp;</div>
    <div class="col-fixed mt-5" style="width:800px">
        <div class='window'>
        <div class='order-info'>
            <div class='order-info-content'>
            <h2>Resumo</h2>
            <div class='line'></div>
            <table class='order-table'>
                <tbody>
                <tr>

                    <td>
                    <br> <span class='thin'>E-Assina</span>
                    <br><span class='thin small'> Reconhecimento de Firma<br><br></span>
                    </td>

                </tr>
                <tr>
                    <td>

                    <div class='price'>R$ </div>
                    </td>
                </tr>
                </tbody>

            </table>
            <div class='line'></div>
            <div class='total'>
                <span style='float:left;'>
                <div class='thin dense'>Recompe </div>
                <div class="thin dense">Emolumentos</div>
                <div class='thin dense'>Taxa fisc.</div>
                <div class='thin dense'>ISS</div>

                TOTAL
                </span>
                <span style='float:right; text-align:right;'>
                <div class='thin dense'>R$ 0,44</div>
                <div class='thin dense'>R$ 7,36</div>
                <div class='thin dense'>R$ 2,42</div>
                <div class='thin dense'>R$ 0,37</div>
                R$ 10,59
                </span>
            </div>
            </div>
        </div>
        <div class='credit-info'>
            <div class='credit-info-content'>
            <table class='half-input-table'>
                <tr>
                <td>Selecione a forma de pagamento: </td>
                <td>
                    <sf-dropdown-generic InputId="state" [list]="paymentTypeList" [style]="{'width':'100%'}"
                    [id]="paymentSelected" (selectedItemOutput)="paymentSelected = $event"></sf-dropdown-generic>

                </td>
                </tr>
            </table>

            <app-money totalValue="10.35" *ngIf="paymentSelected == '1'"></app-money>
            <app-card *ngIf="paymentSelected == '2'" [cardForm]="cardForm"></app-card>
            <app-pix *ngIf="paymentSelected == '3'"></app-pix>
            <button pButton type="submit" class='pay-btn'  [disabled]="!cardForm.valid">Finalizar</button>

            </div>

        </div>
        </div>
    </div>
    <div class="col mt-5">&nbsp;&nbsp;&nbsp;&nbsp;</div>
</div> -->
