import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AuthorizationGuard } from 'src/app/core/guards/authorization.guard';
import { WoOrderService } from 'src/app/pages/wo-order/wo-order.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';

import { KeyFilterModule } from 'primeng/keyfilter';
import { SfcServiceGridComponent } from './sfc-service-grid.component';



@NgModule({
    declarations: [SfcServiceGridComponent],
    exports: [SfcServiceGridComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        ButtonModule,
        TranslateModule,
        MessageModule,
        MessagesModule,
        TableModule,
        TooltipModule,
        CardModule,
        DialogModule,
        ConfirmDialogModule,
        InputNumberModule,
        InputTextModule,
        NotificationsModule,
        KeyFilterModule
        ],
    providers: [NotificationsService, AuthService, AuthorizationGuard, WoOrderService]
})

export class SfcServiceGridModule { }
