import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MessageService, ConfirmationService, Message } from "primeng/api";

import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import { AuthService } from 'src/app/core/auth/auth.service';
import { ScUser, ScUserOutput } from '../sc-user.model';
import { DropDown } from 'src/app/shared/model/base-model';
import { ScUserService } from '../sc-user.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';

import 'hammerjs';

class ImageSnippet {
    constructor(public src: string, public file: File) {}
}
@Component({
    selector: 'sc-user-grid',
    templateUrl: './sc-user-grid.component.html',
    styleUrls: ['./sc-user-grid.component.scss'],
    providers: [ConfirmationService, MessageService]
})
export class ScUserGridComponent implements OnInit {

    public list: ScUserOutput [] = [];
    public userId: string = null;
    public language: string = "pt-BR";
    public item: ScUser;
    public id: string;


    public flUpdate: boolean = true;

    public message: string = "";
    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-danger p-m-1 mr-1";
    public classBtnReset: string = "p-button p-button-warning p-m-1 mr-1";


    constructor(private userService: ScUserService,
                private userTokenService: UserTokenService,
                private notificationsService: NotificationsService,
                private confirmationService: ConfirmationService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService,
                private breadcrumbService: BreadcrumbService,
                private router: Router) { }

    ngOnInit(): void {

        this.language = this.translateConfigService.getLanguage();
        this.userId = this.userTokenService.getUserId();
        this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN','SC_MANAGER_U']);

        if (this.flUpdate == false)
        {
            this.classBtnDel = this.classBtnReset = "p-button p-button-secondary p-m-1 mr-1";
        }

        this.getList();
    }

    getList() {
        this.userService.getList().subscribe(
        {
        next: (res) => {
            if (res)
                this.list = res.data;
            this.translate();
        },
        error: (err) => console.log(`UserGrid - getList : ${err}.`)
        });
    }

    unBlock(user: ScUserOutput) {
        this.confirmationService.confirm({
        message: this.translateService.instant('message.confirm_unblock_user')+' ' + user.login + '?',
        header: this.translateService.instant('title.confirmation'),
        acceptLabel: this.translateService.instant('button.yes'),
        rejectLabel: this.translateService.instant('button.no'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.userService
            .unBlock(user.id, this.userId, this.language)
            .subscribe({
                next: () => {
                this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_inserted'));
                this.getList();
                },
                error: (err) => {
                    console.log(`UserGrid - getList : ${err}.`)
                    this.message = this.translateService.instant('api_response.'+err.error);
                    if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.null")
                        this.message = this.translateService.instant('api_response.ERROR');

                    this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.message);
                }
            });
        }
        });
    }

    resetPwd(user: ScUserOutput) {
        this.confirmationService.confirm({
        message: this.translateService.instant('message.confirm_forgot')+' ' + user.login + '?',
        header: this.translateService.instant('title.confirmation'),
        acceptLabel: this.translateService.instant('button.yes'),
        rejectLabel: this.translateService.instant('button.no'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.userService
            .resetPwd(user.id, user.email, this.userId, this.language)
            .subscribe(
                {
                    next: (res) =>
                    {
                        console.log(res);
                        if (res){
                            this.notificationsService.toastNotify('success', this.translateService.instant('title.user_control'), this.translateService.instant('message.change_pwd_email_sent'));
                        }
                        else
                            this.notificationsService.toastNotify('warn',  this.translateService.instant('title.user_control'), this.translateService.instant('message.change_pwd_error'));

                    },
                    error: (err) => console.log(`UsuarioGrid - emailForgot : ${err}.`)
                });
        }
        });
    }

    emailValidation(user: ScUserOutput) {
        this.confirmationService.confirm({
        message: this.translateService.instant('message.confirm_validation_email'),
        header: this.translateService.instant('title.confirmation'),
        acceptLabel: this.translateService.instant('button.yes'),
        rejectLabel: this.translateService.instant('button.no'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
        this.userService
            .setEmailValidation(user.id, this.userId, this.language)
            .subscribe(
            {
                next: (res) =>
                {
                    console.log(res);
                    if (res){
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.user_control'), this.translateService.instant('message.validation_email_sent'));
                    }
                    else
                        this.notificationsService.toastNotify('warn',  this.translateService.instant('title.user_control'), this.translateService.instant('message.email_invalid'));

                },
                error: (err) =>
                {
                    console.log(`UsuarioGrid - emailForgot : ${err}.`);
                    this.message = this.translateService.instant('api_response.'+err.error);
                    if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.null")
                    this.message = this.translateService.instant('api_response.ERROR');
                    this.notificationsService.toastNotify('warn',  this.translateService.instant('title.user_control'), this.message);
                }
            });
        }});
        }

    unsetFirstAceess(user: ScUserOutput) {
        this.confirmationService.confirm({
        message: this.translateService.instant('message.unsetFirstAceess'),
        header: this.translateService.instant('title.confirmation'),
        acceptLabel: this.translateService.instant('button.yes'),
        rejectLabel: this.translateService.instant('button.no'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.userService
            .unsetFirstAccess(user.id, this.userId, this.language)
            .subscribe(
                {
                    next: (res) =>
                    {
                        console.log(res);
                        if (res){
                            this.notificationsService.toastNotify('success', this.translateService.instant('title.user_control'), this.translateService.instant('message.update'));
                        }
                        else
                            this.notificationsService.toastNotify('warn',  this.translateService.instant('title.user_control'), this.translateService.instant('message.user_not_found'));

                    },
                    error: (err) =>
                    {
                    console.log(`UsuarioGrid - emailForgot : ${err}.`);
                    this.message = this.translateService.instant('api_response.'+err.error);
                    if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.null")
                        this.message = this.translateService.instant('api_response.ERROR');
                    this.notificationsService.toastNotify('warn',  this.translateService.instant('title.user_control'), this.message);
                    }
                });
        }});
    }

    translate(){
        const labAccessControl = this.translateService.instant('label.access_control')+" ";
        const labUser = " "+this.translateService.instant('label.users');
        this.breadcrumbService.setItems([{label: labAccessControl},{label: labUser, routerLink: ['user']} ]);

        this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')
    }

    addItem() {
        this.router.navigate(['user-detail/', "new"]);
      }

      updItem(id: string) {
        this.router.navigate(['user-detail/', id]);
      }

    delItem(user: ScUserOutput) {
        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_delete')+' ' + user.login + '?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.userService
                .delItem(user.id)
                .subscribe(
                    {
                        next: (res) =>
                        {
                            console.log(res);
                            if (res){
                                this.notificationsService.toastNotify('success', this.translateService.instant('title.user_control'), this.translateService.instant('message.delete'));
                            }
                            else
                                this.notificationsService.toastNotify('warn',  this.translateService.instant('title.user_control'), this.translateService.instant('message.user_not_found'));

                        },
                        error: (err) =>
                        {
                            console.log(`UsuarioGrid - delete : ${err}.`);
                            this.message = this.translateService.instant('api_response.'+err.error);
                            if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.null")
                                this.message = this.translateService.instant('api_response.ERROR');
                            this.notificationsService.toastNotify('warn',  this.translateService.instant('title.user_control'), this.message);
                        }
                    });
        }});
    }
}

