import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaPaymentHomeComponent } from './pa-payment-home/pa-payment-home.component';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';
import { ButtonModule } from 'primeng/button';
import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';



@NgModule({
  declarations: [PaPaymentHomeComponent],
  exports:[PaPaymentHomeComponent],
  imports: [
    CommonModule,
    ButtonModule,
    LoaderModule,
    NotificationsModule,
    TranslateModule,
    DialogModule,
    FormsModule,
    RadioButtonModule
  ]
})
export class PaPaymentModule { }
