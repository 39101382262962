import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';


import { TableModule } from 'primeng/table';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';

import { InputNumberModule } from 'primeng/inputnumber';


import { AuthService } from 'src/app/core/auth/auth.service';
import { AuthorizationGuard } from 'src/app/core/guards/authorization.guard';

import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';
import { WoOrderTypeService } from './wo-order-type.service';



@NgModule({
    declarations: [],
    exports: [],
    imports: [
        FormsModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        ButtonModule,
        InputTextModule,
        TranslateModule,
        MessageModule,
        MessagesModule,
        TableModule,
        TooltipModule,
        ConfirmDialogModule,
        CardModule,
        DialogModule,
        NotificationsModule,
        InputNumberModule,
        ],
    providers: [NotificationsService, AuthService, AuthorizationGuard, WoOrderTypeService]
})
export class WoOrderTypeModule { }


