<!-- <div *ngIf="flRead" class="formgrid grid">
    <div class="field col-11">
        <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
                <i class="pi pi-user"></i>
            </span>
            <p-dropdown [options]="list" [(ngModel)]="selectedItem" optionLabel="name" [filter]="true" filterBy="name"
                [showClear]="true" [placeholder]="placeHolderLanguage" (onChange)="feedback()" (onFilter)="debounce.next($event)" [style]="{'width': '100%'}">
            </p-dropdown>
        </div>
    </div>
    <div class="field col-1" style="display: flex; justify-content: space-between; flex-direction: column-reverse;">
        <div style="float: right;">
            <button *ngIf="flUpdate" pButton pRipple icon="pi pi-plus" class="p-button-primary" (click)="insertPerson()"
                [disabled]="false"></button>
        </div>
    </div>
</div> -->

<div *ngIf="true" class="formgrid grid text-lg">
    <div class="field col">        
        <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
                <i class="pi pi-user"></i>
            </span>
            <p-dropdown [options]="list" [(ngModel)]="selectedItem" optionLabel="name" [filter]="true" filterBy="name"
                [showClear]="true" [(placeholder)]="placeHolderLanguage" (onChange)="feedback()" (onFilter)="debounce.next($event)" [style]="{'width': '100%'}"
                [disabled]="!flRead || isDisabled">
            </p-dropdown>
            <div  style="margin-left: 10px; margin-top: -13px;">
                <button *ngIf="flRead || flUpdate" pButton pRipple icon="pi pi-plus" [class]="classBtnUpd" (click)="insertPerson()"
                    [disabled]="!flRead || isDisabled">
                </button>
            </div>
        </div>      
    </div>
</div>

<p-dialog [(visible)]="displayDialog" [style]="{width: '500px'}" [modal]="true" styleClass="p-fluid"
    [header]="dialogHeader">
    <!-- <sf-sc-person-basic [name]="name" [ssn]="ssn"></sf-sc-person-basic> -->
    <ng-container *ngComponentOutlet="outlet; inputs: {name: name, ssn: ssn, flagPersonType: flagPersonType};"></ng-container>
</p-dialog>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
