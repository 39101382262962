
<div class="login-body">
    <div class="card login-panel p-fluid">
        <div class="login-panel-content">
            <div class="p-grid">
                <div class="col-12 login-icon-container">
                    <a href="#">
                        <img src="assets/layout/images/icon-login.png" style="margin-right: 10px; width: 40px;" alt="ServiceFlowforgot" />
                        <span class="fontLoginCria" >Service</span><span class="fontLoginService" >Flow</span>
                    </a>
                </div>
                <div class="col-12 lg:col-12 md:col-12 logo-container">
                    <form class="form-login nao-validar" [formGroup]="forgotForm" (submit)="reset()">

                    <div class="field">
                        <label for="username" class="block"><span translate>label.email</span></label>
                        <input #userNameInput type="username" formControlName="userName" pInputText />
                        <small id="username-help" class="p-error block" ><div [hidden]="valid"><span translate>validation_errors.required_user_email</span></div></small>
                    </div>



                    <button  type="submit" class="btn btn-primary button-login" ><span translate>button.recover_pwd</span></button>


                    <div class="col-12 lg:col-12 md:col-12 ">
                        <div class="flex flex-row flex-wrap card-container blue-container" style="margin: 20px;">
                            <div class="label-acesso">
                                <span translate>label.still_have_no_access</span>&nbsp;
                        </div>
                            <div>
                                <a [routerLink]="['signup']"> <span translate>label.sign_up_now</span>!!!</a></div>
                        </div>

                    </div>

                </form>
                </div>
            </div>
        </div>

        <div class="p-grid" style="margin-top: 200px;">
            <div class="p-col-12">
                <span translate style="margin-left: 150px;">menu.choose_language</span>
            </div>
            <div class="col-12 lg:col-12 md:col-12">
                <div style="margin-left: 120px;">
                    <span class="flag flag-br" style="width: 30px; height: 20px;" (click)="changeLanguage('pt-BR')"
                        style="margin-left: 5px; margin-top: 10px;"></span>
                    <span class="flag flag-gb" style="width: 30px; height: 20px" (click)="changeLanguage('en-US')"
                        style="margin-left: 5px; margin-top: 10px;"></span>
                    <span class="flag flag-pt" style="width: 30px; height: 20px" (click)="changeLanguage('pt-PT')"
                        style="margin-left: 5px; margin-top: 10px;"></span>
                </div>
            </div>
        </div>

    </div>
</div>
