import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';


import { AuthService } from 'src/app/core/auth/auth.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { AuthorizationGuard } from 'src/app/core/guards/authorization.guard';
import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { VMessageModule } from 'src/app/shared/components/vmessage/vmessage.module';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CardModule } from 'primeng/card';
import { TreeSelectModule } from 'primeng/treeselect';
import { ToolbarModule } from 'primeng/toolbar';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressBarModule } from 'primeng/progressbar';


import { SfDropDownModule } from 'src/app/shared/components/dropdown/dropdown.module';
import { ScOrganizationStructureService } from './sc-organization-structure.service';
import { ScOrganizationStructureGridComponent } from './sc-organization-structure/sc-organization-structure-grid.component';
import { ScOrganizationStructureDropdownComponent } from './sc-organization-structure-dropdown/sc-organization-structure-dropdown.component';
import { ScOrganizationStructureTreeComponent } from './sc-organization-structure-tree/sc-organization-structure-tree.component';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';




@NgModule({
    declarations: [ScOrganizationStructureGridComponent, ScOrganizationStructureDropdownComponent, ScOrganizationStructureTreeComponent],
    exports: [ScOrganizationStructureGridComponent, ScOrganizationStructureDropdownComponent,ScOrganizationStructureTreeComponent ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        VMessageModule,
        RouterModule,
        NotificationsModule,
        ButtonModule,
        InputTextModule,
        InputNumberModule,
        TranslateModule,
        MessageModule,
        MessagesModule,
        CardModule,
        ConfirmDialogModule,
        TooltipModule,
        TreeSelectModule,
        ToolbarModule,
        DropdownModule,
        SfDropDownModule,
        CheckboxModule,
        ProgressBarModule,
        DialogModule,
        TableModule


    ],
    providers: [NotificationsService, AuthService, AuthorizationGuard, ScOrganizationStructureService]
})
export class ScOrganizationStructureModule { }
