import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';


@Injectable({
  providedIn: 'root',
})
export class TranslateConfigService {

  private lang: string = 'pt-BR';

  constructor(private translateService: TranslateService, private config: PrimeNGConfig) {

    this.lang  = window.localStorage.getItem("_LANG");

    if (this.lang == null) {
        this.lang = 'pt-BR';
    }

    this.translateService.use(this.lang);
    //this.translateService.use('en');
    this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
  }

  changeLanguage(type: string) {
    this.translateService.use(type);
    window.localStorage.setItem("_LANG",type);
  }

  getLanguage(){
    this.lang  = window.localStorage.getItem("_LANG");

    if (this.lang == null) {
        this.lang = 'pt-BR';
    }

    return this.lang;
  }
}
