import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';



import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
//import { BaseResponseModel } from 'src/app/shared/model/base-model';
import { UserTokenService } from '../services/user-token.service';
import { DataPwdForgot, PwdForgot, Token } from '../auth.model';
import { ScUserItem, ScUserOutput, ScUserSignUpItem, ScUserSignUpOutput } from 'src/app/pages/sc-user/sc-user.model';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

    enviromentUrl = '';

    constructor(private http: HttpClient, private userTokenService: UserTokenService){
        this.enviromentUrl = environment.ApiCore;
    }

    authenticate(userName: string, password: string, remember: boolean, version: string, language: string) {

        console.log(userName);

        return this.http
        .post(
            this.enviromentUrl + '/User/Auth/Login',
            { Login: userName, Password: password, IsRemember: remember, Version: version, LanguageCode: language },
            { observe: 'response' }
        )
        .pipe(tap(res => {
            const authToken = (res.body as Token).data.token;
            this.userTokenService.setToken(authToken);
        }));

    }

    authenticateCompanyChange(userName: string, scCompanyId: string,language: string) {

        console.log(this.enviromentUrl + '/User/Auth/CompanyChange');

        return this.http
        .post(
            this.enviromentUrl + '/User/Auth/CompanyChange',
            { Login: userName, ScCompanyId: scCompanyId, LanguageCode: language },
            { observe: 'response' }
        )
        .pipe(tap(res => {
            this.userTokenService.logout();
            const authToken = (res.body as Token).data.token;
            this.userTokenService.setToken(authToken);
        }));

    }

    resetPwd(email: string, accessCode: string, password: string, passwordConfirmation: string, option: string): Observable<PwdForgot>{
        var url = this.enviromentUrl + "/User/Auth/Forgot";

        console.log(`code: ${accessCode} - op: ${option} - ${url}`);

        if (option.toUpperCase() == "FORGOT"){
            return this.http.post<PwdForgot> (url, {email});
        }
        else if (option.toUpperCase() == "CODE"){
            url = url +"/code";
            return this.http.post<PwdForgot> (url,{email,accessCode});
        }
        else if (option.toUpperCase() == "PWD")
        {
            url = url +"/pwd";
            return this.http.post<PwdForgot> (url,{email,PwdCode: accessCode, password, passwordConfirmation});
        }
        else
            return this.http.post<PwdForgot> (url, {email});

    }

    signUpClient(email: string, name: string, password, languageCode: string): Observable<ScUserSignUpItem>{
        var url = this.enviromentUrl + "/User/Auth/SignUp/Client";
        console.log(email+' - '+name+' '+password)
        return this.http.post<ScUserSignUpItem> (url, {email, name, password, languageCode});
    }

    signUpCode(email: string, accessCode: string): Observable<PwdForgot>{
        var url = this.enviromentUrl + "/User/Auth/SignUp/Code";
        return this.http.post<PwdForgot> (url, {email, accessCode});
    }

    signUpSendMail(email: string): Observable<ScUserSignUpItem>{
        var url = this.enviromentUrl + "/User/Auth/SignUp/SendEmail";
        var resendEmail = true;
        return this.http.post<ScUserSignUpItem> (url, {email});
    }


}
