import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ScModule, ScModuleOutput } from '../sc-module.model';
import { DropDown } from 'src/app/shared/model/base-model';
import { ScModuleService } from '../sc-module.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { Table } from 'primeng/table';
import { ScCompanyService } from '../../sc-company/sc-company.service';
import { ScCompanyOutput } from '../../sc-company/sc-company.model';


@Component({
  selector: 'sc-module-grid',
  templateUrl: './sc-module-grid.component.html',
  styleUrls: ['./sc-module-grid.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None
})
export class ScModuleComponent implements OnInit{

    public companiesList: DropDown[] = [];
    public list: ScModule[] = [];
    public id: string;
    public item: ScModuleOutput;
    public userId: string = null;
    public language: string = "pt-BR";

    public selectedClaims: DropDown[] = [];
    public selectedCompanies: DropDown[] = [];

    public submitted: boolean;
    public displayMsg: boolean = false;
    public displayDialog: boolean;
    public dialogHeader: string;

    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-warning p-m-1 mr-1";

    public flUpdate: boolean = true;

    constructor(  
                private companyService: ScCompanyService,
                private moduleService: ScModuleService,
                private messageService: MessageService,
                private confirmationService: ConfirmationService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService,
                private notificationsService: NotificationsService,
                private breadcrumbService: BreadcrumbService,
                private userTokenService: UserTokenService){ }

    ngOnInit(): void {

        if (!this.item || !this.selectedCompanies || !this.submitted) {
            this.item = {};
            this.selectedCompanies = [];
            this.selectedClaims = [];
            this.submitted = false;
        }

        this.language = this.translateConfigService.getLanguage();
        this.userId = this.userTokenService.getUserId();

        this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN']);

        if (this.flUpdate == false)
        {
            this.classBtnUpd = "p-button p-button-secondary p-m-1 mr-1";
            this.classBtnDel = this.classBtnUpd;
        }

        this.getList();
    }

    getList() {
        this.moduleService.getList(this.language).subscribe(
            {
            next: (res) => {
                if (res){
                    this.list = res.data
                }else {
                    this.list = [];  
                }
                this.translate();
            },
            error: (err) => {
                console.log(`ScModuleGrid - getLista : ${err}.`);
            }
        });
    }

    getItem(id: string) {
        this.moduleService.getItem(id).subscribe(
        {
            next: (res) =>
            {
                if (res) {
                    this.item = res.data;
                }
            },
            error: (err) => {
                console.log(`ScModuleGrid - getItem : ${err}.`);
            }
        });
    }

    addItem() {
        this.item = {};
        this.selectedCompanies = [];
        this.selectedClaims = [];
        this.submitted = false;
        this.displayDialog = true;
    }

    updItem(item: ScModuleOutput) {

        this.item = {...item};
        if (this.item.claims == undefined || this.item.claims == null || this.item.claims.length == 0)
            this.selectedClaims = [];
        else 
            this.selectedClaims = this.item.claims;

        if (this.item.companies == undefined || this.item.companies == null || this.item.companies.length == 0) 
            this.selectedCompanies = [];
        else
            this.selectedCompanies = this.item.companies;
       
        this.displayDialog = true;
    }

    delItem(item: ScModuleOutput) {

        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_delete')+' ' + item.name + '?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.moduleService
                .delItem(item.id)
                .subscribe(
                {
                    next: () =>
                    {
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.sc_module'), this.translateService.instant('message.record_deleted'));
                        this.getList();
                    },
                    error: (err) => {
                        this.notificationsService.toastNotify('warn', this.translateService.instant('title.sc_module'), this.translateService.instant('api_response.ERROR'));
                        console.log(`ScModuleGrid - delItem : ${err}.`);
                    }
                });
            }
      });
    }

    saveItem() {
        this.submitted = true;

        this.item.companies = this.item.companies.map((company: any) => ({
            id: company.id,
            name: company.name
        }))

        if (this.item.code.trim()) {
            if (this.item.id) {

                this.moduleService
                .updItem(this.item, this.userId, this.language)
                .subscribe(
                    {
                    next: () => {
                        this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_updated'), life: 3000});
                        this.getList();
                    },
                    error: (err) => console.log(err)
                });
            } else {
                this.moduleService
                    .addItem(this.item, this.userId, this.language)
                    .subscribe(
                    {
                        next: () => {
                            this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_inserted'), life: 3000});
                            this.getList();
                        },
                        error: (err) => console.log(err)
                    });
            }

            this.list = [...this.list]
            this.displayDialog = false;
            this.item = {};
        }
    }

    translate()
    {
        const labAccessControl = this.translateService.instant('label.access_control')+" ";
        const labModule = " "+this.translateService.instant('label.module');
        this.breadcrumbService.setItems([{label: labAccessControl},{label: labModule, routerLink: ['module']} ]);

        this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')

        this.dialogHeader = this.translateService.instant('title.sc_module_edit');

    }

    hideDialog() {
        this.displayDialog = false;
        this.submitted = false;
    }

    clear(table: Table) {
        table.clear();
    }

    reciverFeedbackIcon(icon: DropDown) {
        this.item.icon = icon.id;
    }

    reciverFeedbackColor(color: string) {
        this.item.color = color;
    }

    reciverFeedbackClaim(select: DropDown[])
    {
        this.selectedClaims = select;
        this.item.claims = this.selectedClaims;
    }

    reciverFeedbackCompany(select: DropDown[])
    {
        this.selectedCompanies = select;
        this.item.companies = this.selectedCompanies;
    }
}
