import { Routes, RouterModule } from "@angular/router";
import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { AuthGuard } from '../core/auth/auth.guard';
import { SignInComponent } from './signin/signin.component';
import { SignUpComponent } from './signup/signup.component';
import { ForgotComponent } from './forgot/forgot.component';
import { PwdComponent } from "./pwd/pwd.component";
import { ForgotStepComponent } from "./forgot-step/forgot-step.component";





const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: SignInComponent,
            },
            {
                path: 'signup',
                component: SignUpComponent,
            },
            {
              path: 'signup/:token',
              component: SignUpComponent,
            },
            {
                path: 'forgot-step',
                component: ForgotStepComponent,
            },
            {
                path: 'forgot',
                component: ForgotComponent,
            },
            {
                path: 'pwd',
                component: PwdComponent,
            },
            {
                path: 'code',
                component: PwdComponent,
            },
            {
                path: 'confirmation',
                component: PwdComponent,
            },
            {
                path: 'newpwd/:token',
                component: PwdComponent,
            },

        ]
    }
];


@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [ RouterModule ]
})
export class LoginRoutingModule{}
