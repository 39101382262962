<p-dropdown appendTo="body" [options]="list" [(ngModel)]="selectedIcon" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" [placeholder]="placeHolderLanguage" (onChange)="feedback()">
    <ng-template pTemplate="selectedItem">
        <div class="country-item country-item-value" *ngIf="selectedIcon">
            <div><i [class]="selectedIcon.id"></i> {{selectedIcon.name}}</div>
        </div>
    </ng-template>
    <ng-template let-item pTemplate="item">
        <div class="country-item">
            <div><i [class]="item.id"></i> {{item.name}}</div>
        </div>
    </ng-template>
</p-dropdown>

