<sf-loader></sf-loader>
<sf-notification></sf-notification>
<div class="button-stack">
    <button pButton pRipple
        icon="pi pi-wallet"
        [label]="'button.cash_opening' | translate"
        class="p-button-secondary"
        (click)="showDialog('cashOpening')" *ngIf="flUpdate"
        [disabled]="open_button"
        >
    </button>

    <button pButton pRipple
        icon="pi pi-arrow-right-arrow-left"
        [label]="'button.cash_inflow_outflow' | translate"
        class="p-button-secondary"
        (click)="showDialog('cashInflowOutflow')" *ngIf="flUpdate"
        [disabled]="inflowOutflow_button"
        >
    </button>

    <button pButton pRipple
        icon="pi pi-lock"
        [label]="'button.cash_closing' | translate"
        class="p-button-secondary"
        (click)="showDialog('cashClosing')" *ngIf="flUpdate"
        [disabled]="close_button"
        >
    </button>

    <button pButton pRipple
        icon="pi pi-dollar"
        [label]="'button.statement' | translate"
        class="p-button-secondary"
        (click)="showDialog('statement')" *ngIf="flUpdate"
        [disabled]="statement_button"
        >
    </button>
</div>

<p-dialog
    [header]="dialogHeader"
    [(visible)]="displayCashOpening"
    [modal]="true"
    [closable]="false"
    [blockScroll]="true"
    styleClass="custom-dialog-container"
    [style]="{ width: '450px'}">

    <div>
        <!-- <div *ngIf="!displayCashControlConfirm"  class="p-field">
            <label for="user" class="bold-text"><span translate>label.user</span></label>
            <div id="user" class="text-display">{{ userName }}</div>
        </div>

        <div *ngIf="!displayCashControlConfirm"  class="p-field">
            <label for="closing" class="bold-text"><span translate>label.closing</span></label>
            <div id="closing" class="text-display">{{ closingDate | date: 'dd/MM/yyyy HH:mm:ss'  }}</div>
        </div>

        <div *ngIf="!displayCashControlConfirm" class="p-field">
            <label for="balance" class="bold-text"><span translate>label.balance</span></label>
            <div id="balance" class="text-display">{{ openingValue | currency:'BRL':'symbol':'1.2-2':'pt-BR' }}</div>
        </div> -->

        <div *ngIf="displayCashControlConfirm" class="p-field">
            <label for="openingValue" class="bold-text" style="width: 50%; white-space: nowrap;">
              <span translate>label.opening_value</span>
            </label>
            <input
              type="number"
              inputmode="numeric"
              class="text-input"
              pInputText
              id="openingValue"
              placeholder="R$ 0,00"
              [(ngModel)]="item.amount"
            />
        </div>

        <div *ngIf="displayCashControlConfirm" class="p-field vertical-field">
            <label for="observationOpening" class="bold-text">
              <span translate>label.observation</span>
            </label>
            <textarea
              class="observation"
              id="observationOpening"
              [(ngModel)]="item.note"
            ></textarea>
        </div>

    </div>
    <ng-template pTemplate="footer">
        <hr />
        <div style="float: right;">
            <button pButton pRipple icon="pi pi-times" style="width: 130px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="displayCashOpening = false; displayCashControlConfirm=false"></button>
            <button pButton pRipple *ngIf="!displayCashControlConfirm"  icon="pi pi-check" style="width: 150px; margin: 3px;" class="p-button-success p-mr-2 mt-3"[label]="'button.open_cash' | translate" (click)="showCashControl()"></button>
            <button pButton pRipple *ngIf="displayCashControlConfirm" icon="pi pi-check" style="width: 150px; margin: 3px;" class="p-button-success p-mr-2 mt-3"[label]="'button.confirm' | translate" (click)="openCash()"></button>
        </div>
    </ng-template>

</p-dialog>


<p-dialog
    [header]="dialogHeader"
    [(visible)]="displayCashInflowOutflow"
    [modal]="true"
    [closable]="false"
    [blockScroll]="true"
    styleClass="custom-dialog-container"
    [style]="{ width: '500px'}">

    <div>
        <div class="p-field" style="display: flex; justify-content: center; align-items: center;">
            <p-button pRipple
                [styleClass]="buttonInflow"
                (click)="selectCashInflow()"
                [label]="'button.cash_inflow' | translate"   >

            </p-button>
            <p-button pRipple
                [styleClass]="buttonOutflow"
                (click)="selectCashOutflow()"
                [label]="'button.cash_outflow' | translate">
            </p-button>
        </div>

        <div  class="p-field">
            <label *ngIf="cashInflow" for="inflowOutflow" class="bold-text" style="width: 50%; white-space: nowrap;"><span translate>label.opening_value</span></label>
            <label *ngIf="cashOutflow" for="inflowOutflow" class="bold-text" style="width: 50%; white-space: nowrap;"><span translate>label.withdrawal_value</span></label>
            <input type="number" inputmode="numeric" class="text-input" pInputText id="inflowOutflow" [(ngModel)]="item.amount" placeholder="R$ 0,00" (ngModelChange)="updateButtonLabel()"  />
        </div>

        <div *ngIf="cashInflow" style="margin: auto;">
            <div class="p-field-radiobutton">
                <p-radioButton name="paymentType" value="1" [(ngModel)]="paymentType" inputId="cash"></p-radioButton>
                <label for="cash"><span translate>label.cash</span></label>
            </div>
            <div class="p-field-radiobutton">
                <p-radioButton name="paymentType" value="2" [(ngModel)]="paymentType" inputId="creditCard"></p-radioButton>
                <label for="creditCard"><span translate>label.credit_card</span></label>
            </div>
            <div class="p-field-radiobutton">
                <p-radioButton name="paymentType" value="3" [(ngModel)]="paymentType" inputId="debitCard"></p-radioButton>
                <label for="debitCard"><span translate>label.debit_card</span></label>
            </div>
            <div class="p-field-radiobutton">
                <p-radioButton name="paymentType" value="4" [(ngModel)]="paymentType" inputId="pix"></p-radioButton>
                <label for="pix"><span translate>label.pix</span></label>
            </div>

        </div>

        <div class="p-field vertical-field">
            <label for="observationInOutFlow" class="bold-text"><span translate>label.observation</span></label>
            <textarea class="observation" id="observationInOutFlow"  [(ngModel)]="item.note"></textarea>
        </div>

    </div>
    <ng-template pTemplate="footer">
        <hr />
        <div style="float: right;">
            <button pButton pRipple icon="pi pi-times" style="width: 130px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="displayCashInflowOutflow = false;"></button>
            <button pButton pRipple *ngIf="cashOutflow" icon="pi pi-check" style="width: 220px; max-height: 35px; margin: 3px;" class="p-button-gray p-mr-2 mt-3" [label]="buttonConfirmOpening" (click)="cashTransactionRegister('Withdrawal')"></button>
            <button pButton pRipple *ngIf="cashInflow"  icon="pi pi-check"  style="width: 220px; max-height: 35px; margin: 3px;"  class="p-button-success p-mr-2 mt-3" [label]="buttonConfirmClosing" (click)="cashTransactionRegister('Payment', paymentType)"></button>
        </div>
    </ng-template>

</p-dialog>


<p-dialog
    [header]="dialogHeader"
    [(visible)]="displayCashClosing"
    [modal]="true"
    [closable]="false"
    [blockScroll]="true"
    [style]="{ width: '500px'}">

    <div>
        <div class="p-field">
            <label for="closingValue" class="bold-text" style="width: 50%; white-space: nowrap;">
              <span translate>label.closing_value</span>
            </label>
            <input
              type="number"
              inputmode="numeric"
              class="text-input"
              pInputText
              id="closingValue"
              placeholder="R$ 0,00"
              [(ngModel)]="item.amount"
            />
        </div>

        <div class="p-field vertical-field">
            <label for="observationClose" class="bold-text">
              <span translate>label.observation</span>
            </label>
            <textarea
              class="observation"
              id="observationClose"
              [(ngModel)]="item.note"
            ></textarea>
        </div>

    </div>

    <ng-template pTemplate="footer">
        <hr />
        <div style="float: right;">
            <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="displayCashClosing = false; displayCashControlConfirm=false"></button>
            <button pButton pRipple icon="pi pi-check" style="width: 140px; margin: 3px;" class="p-button-success p-mr-2 mt-3"[label]="'button.close_cash' | translate" (click)="closeCash()"></button>
        </div>
    </ng-template>

</p-dialog>

<p-dialog
    [header]="dialogHeader"
    [(visible)]="displayStatement"
    [modal]="true"
    [closable]="false"
    [blockScroll]="true"
    [style]="{ width: '600px'}">

    <div>
        <div style="display: flex; justify-content: space-between;">
            <div style="width: 100%;">
                <div class="p-field">
                    <table style="width: 100%; border-collapse: collapse;">
                        <thead>
                            <tr>
                                <th class="cash-key"> <span translate> label.order_service </span></th>
                                <th class="cash-key"> <span translate> label.client </span></th>
                                <th class="cash-key"> <span translate> label.value </span></th>
                                <th class="cash-key"> <span translate> label.date </span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of cashData">
                                <td class="cash-value">{{ data.treatment }}</td>
                                <td class="cash-value">{{ data.client }}</td>
                                <td class="cash-value">{{ data.amount | currency:'BRL' }}</td>
                                <td class="cash-value">{{ data.date | date:'dd/MM/yyyy' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div style="float: right;">
            <button pButton pRipple icon="pi pi-times" style="width: 150px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.close_back' | translate" (click)="displayStatement = false;"></button>
        </div>
    </ng-template>

</p-dialog>

