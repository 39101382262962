<div class="layout-topbar">
    <a class="logo" href="/" >
        <div *ngIf="!hasLogo">
            <span class="fontLogoWhite" >{{companyName}} </span>
        </div>
        <div *ngIf="hasLogo">
            <img [src]="logo" class="personal-avatar"  >
        </div>

    </a>
    <a id="menu-button" href="#" (click)="appMain.onMenuButtonClick($event)">
        <i class="pi pi-align-left"></i>
    </a>

    <a id="layout-megamenu-button" href="#" (click)="appMain.onMegaMenuButtonClick($event)">
        <i class="pi pi-angle-down"></i>
        <span class="mega-menu-title" translate>menu.quick_acess</span>
    </a>
    <a id="topbar-menu-button" href="#" (click)="appMain.onTopbarMenuButtonClick($event)" *ngIf="app.profileMode==='top' || appMain.isHorizontal()">
        <!-- <img src="assets/layout/images/avatar.png" alt="california-layout"> -->

        <!-- <img [src]="foto | safe" *ngIf="flFoto" style="width: 36px; height: inherit; box-sizing: border-box; border-radius: 100%; border: 2px solid transparent; box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); transition: all ease-in-out .3s;">
        <img src="assets/layout/images/perfil.svg" width="50px"
            *ngIf="!flFoto"> -->

        <span>{{user.given_name}}</span>
        <span>{{user.funcao}}</span>



    </a>

    <ul  id="topbar-usermenu" class="usermenu usermenu-overlay fadeInDown" [ngClass]="{'usermenu-active': appMain.topbarMenuActive}">
        <li #profile [ngClass]="{'menuitem-active':appMain.activeTopbarItem === profile}">
            <a href="#" (click)="appMain.onTopbarItemClick($event,profile)">
                <i class="pi pi-fw pi-user"></i>
                <span class="topbar-item-name">Profile</span>
            </a>
        </li>
        <!-- <li #settings [ngClass]="{'menuitem-active':appMain.activeTopbarItem === settings}">
            <a href="#" (click)="appMain.onTopbarItemClick($event,settings)">
                <i class="pi pi-fw pi-cog"></i>
                <span class="topbar-item-name">Settings</span>
                <i class="layout-menuitem-toggler pi pi-fw pi-angle-down"></i>
            </a>
            <ul class="fadeInDown">
                <li role="menuitem">
                    <a href="#" (click)="appMain.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-palette"></i>
                        <span>Change Theme</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="appMain.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-star"></i>
                        <span>Favorites</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="appMain.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-lock"></i>
                        <span>Lock Screen</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="appMain.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-image"></i>
                        <span>Wallpaper</span>
                    </a>
                </li>
            </ul>
        </li>
        <li #messages [ngClass]="{'menuitem-active':appMain.activeTopbarItem === messages}">
            <a href="#" (click)="appMain.onTopbarItemClick($event,messages)">
                <i class="pi pi-fw pi-envelope"></i>
                <span class="topbar-item-name">Messages</span>
                <i class="layout-menuitem-toggler pi pi-fw pi-angle-down"></i>
            </a>
            <ul class="fadeInDown">
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="appMain.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar1.png" width="25"/>
                        <span>Give me a call</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="appMain.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar2.png" width="25"/>
                        <span>Sales reports attached</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="appMain.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar3.png" width="25"/>
                        <span>About your invoice</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="appMain.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar2.png" width="25"/>
                        <span>Meeting today at 10pm</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="appMain.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar4.png" width="25"/>
                        <span>Out of office</span>
                    </a>
                </li>
            </ul>
        </li>



        <li #notifications [ngClass]="{'menuitem-active':appMain.activeTopbarItem === notifications}">
            <a href="#" (click)="appMain.onTopbarItemClick($event,notifications)">
                <i class="pi pi-fw pi-bell"></i>
                <span class="topbar-item-name">Notifications</span>
                <i class="layout-menuitem-toggler pi pi-fw pi-angle-down"></i>
            </a>
            <ul class="fadeInDown">
                <li role="menuitem">
                    <a href="#" (click)="appMain.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-sliders-h"></i>
                        <span>Pending tasks</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="appMain.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-calendar"></i>
                        <span>Meeting today at 3pm</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="appMain.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-download"></i>
                        <span>Download documents</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="appMain.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-ticket"></i>
                        <span>Book flight</span>
                    </a>
                </li>
            </ul>
        </li> -->
    </ul>

   <a id="right-sidebar-button" href="#" (click)="appMain.onRightPanelButtonClick($event)">
        <img src="../../../assets/layout/images/sf-logoTabellio.png" height="40" style="margin-top: 10px;" >
    </a>
</div>
