import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropDown } from 'src/app/shared/model/base-model';
import { ScCompanyService } from '../sc-company.service';

@Component({
  selector: 'sf-sc-company-dropdown-multi',
  templateUrl: './sc-company-dropdown-multi.component.html',
  styleUrls: ['./sc-company-dropdown-multi.component.scss']
})

export class ScCompanyDropdownMultiComponent implements OnInit{

  public list : DropDown[];
  public item: DropDown;
  public selectedList: DropDown[];

  @Input() placeHolderLanguage: string;
  @Input() selectedItems: DropDown[] = [];
  @Output() chosedItem = new EventEmitter();

  constructor(  private scCompanyService: ScCompanyService,
  ){ }

  ngOnInit(): void {
    this.getList();
  }

  ngOnChanges(): void {
    this.selectedList = this.selectedItems;
  }

  getList() {
    this.scCompanyService.getDropDownList().subscribe(
      {
        next: (res) => {
            if (res)
                this.list = res.data;
            this.selectedList = this.selectedItems;

        },
        error: (err) => {
            console.log(`CompanyDropDownMulti - getList : ${err}.`);
        }
    });
  }

  feedback(){
      this.chosedItem.emit(this.selectedList)
  }
}
