import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { Message } from 'primeng/api';
import {  MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';

import { NotificationsService } from './notifications.service';


@Component({
    selector: 'sf-notification',
    template: ` <p-messages [(value)]="message" *ngIf="showMessage"></p-messages>
                <p-toast ></p-toast>`
})
export class NotificationsComponent implements OnInit, OnDestroy {

    message: Message[] = [];
    toast: Message[] = [];
    messageSubscription: Subscription;
    toastSubscription: Subscription;
    showMessage: boolean=false;
    showToast: boolean=false;

    constructor(private notificationsService: NotificationsService, private messageService: MessageService) { }

    ngOnInit() {
        this.subscribeToMessageNotifications();
        this.subscribeToToastlNotifications();
    }

    subscribeToToastlNotifications() {
        this.toastSubscription = this.notificationsService.toastNotificationChange
        .subscribe(( notification: Message)  => {
            this.showMessage  =false;
            this.messageService.add({
                severity: notification.severity,
                summary: notification.summary,
                detail: notification.detail,
                life: 8000
            });
        });
    }


    subscribeToMessageNotifications() {

        this.messageSubscription = this.notificationsService.messageNotificationChange
        .subscribe(( notification: Message[])  => {
            this.showMessage  =true;
            if ( notification !== undefined) {
                notification.forEach(element => {
                    this.message.push(element);
                    console.log(this.message);
                });

                setTimeout(() => {
                    this.message.shift();
                }, 8000);
            }
        });
    }

    ngOnDestroy() {
        this.messageSubscription.unsubscribe();
        this.toastSubscription.unsubscribe();
    }
}
