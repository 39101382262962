import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent} from 'ngx-image-cropper';

import { MessageService, ConfirmationService} from "primeng/api";

import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ScUserDetailOutput, ScUserOutput, ScUserStatusHist } from '../../sc-user.model';
import { DropDown } from 'src/app/shared/model/base-model';
import { ScUserService } from '../../sc-user.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';



class ImageSnippet {
    constructor(public src: string, public file: File) {}
}

@Component({
    selector: 'sc-user-profile',
    templateUrl: './sc-user-profile.component.html',
    styleUrls: ['./sc-user-profile.component.scss'],
    providers: [ConfirmationService, MessageService]
})
export class ScUserProfileComponent implements OnInit {

    public language: string = "pt-BR";
    public item: ScUserOutput = {};
    public detailItem: ScUserDetailOutput = {};
    public userStatusHist: ScUserStatusHist[] = [];

    public userSignForList: DropDown[] = [];

    public hasPicture: boolean = false;
    public hasClerk: boolean = true;
    


    public message: string = "";
    public pageMessage: string = "";
    public errorMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    
    public labelAccessPermission: string = "";
    public labelUserRegistration: string = "";
    public labelUserHistory: string = "";
    public labelUserDetails: string = "";

    public userPicDialog: boolean = false;
    public imageChangedEvent: any = '';
    public croppedImage: any;
    public showCropper = false;
    public fileList: any[] = [];
    public imageBlob: any;
    public imageUrl: string;



    //access
    

    @Input() userId: string;
    @Output() closeEvent = new EventEmitter();


    constructor(private userTokenService: UserTokenService,
                private userService: ScUserService,
                private notificationsService: NotificationsService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService
                ) { }

    ngOnInit(): void {
        this.language = this.translateConfigService.getLanguage();
        this.hasClerk = this.userTokenService.getCompanyConfig('CLERK');
    }

    getItem() {
        this.userService.getItem(this.userId).subscribe(
        {
        next: (res) => {
            if (res)
            {
                this.item = res.data;
                if (this.item != null){
                    this.hasPicture = !(this.item.pictureUri == undefined || this.item.pictureUri == null || this.item.pictureUri == "");
                    this.getItemDetail();
                    this.getTimeLine();

                }
            }
        },
        error: (err) => console.log(`UserProfile - getItem : ${err}.`)
        });
    }

    getItemDetail() {
        
        this.userService.getItemDetail(this.userId).subscribe(
        {
        next: (res) => {
            if (res)
            {
                this.detailItem = res.data;
                this.item.skills = this.detailItem.skills;
            }
        },
        error: (err) => console.log(`UserProfile - getItemDetail : ${err}.`)
        });
    }

    
    getTimeLine() {

        this.userService.geTimeLine(this.userId, this.language).subscribe(
        {
            next: (res) =>
            {
                this.userStatusHist = res.data;
          
            },
            error: (err) => console.log(`UserProfile - getTimeLine : ${err}.`)
        });
    }

   

    
    fileChangeEvent(event: any): void {
        this.userPicDialog = true;
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        //  console.log('Cropped Image Event:', event);
        this.croppedImage = event.base64;
        this.fileList.push(this.croppedImage);

        this.imageBlob = event.blob;
        this.imageUrl = event.objectUrl;
        this.fileList = [];

        fetch(this.imageUrl)
            .then((res) => res.blob())
            .then((myBlob) => {
                const myFile = new File([myBlob], 'image.png', {type: myBlob.type});
                this.fileList.push(myFile);
            });
    }

    imageLoaded() {
        this.showCropper = true;
        // console.log('cropper load');
    }
    cropperReady() {
        // cropper ready
        // console.log('cropper ready');
    }
    loadImageFailed() {
        // show message
        // console.log('cload fail');
    }

    hidePicDialog(): void {
        this.userPicDialog = false;
    }

    saveItem() {

        this.userService
        .updItem(this.item, this.fileList, this.userId, this.language)
        .subscribe(
            {
                next: () => {
                    this.savePic();
                    this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_updated'));
                    this.getTimeLine();
                },
                error: (err) => {
                    console.log(`UserProfile - saveItem - Upd : ${err}.`)
                    this.message = this.translateService.instant('api_response.'+err.error);
                    
                    if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                        this.message = this.translateService.instant('api_response.ERROR');

                    this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.message);
                }
            });
    }

    savePic()
    {
        if (this.item.id) {
            this.userService.uploadItem(this.fileList, this.item.id)
            .subscribe(
                {
                    next: () =>
                    {
                        this.item.pictureUri = this.imageUrl;
                        this.hasPicture = true;
                        this.userPicDialog = false;
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.picture_updated'));
                    },
                    error: (err) => {
                        this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.errorMessage);
                        console.log(`UserProfile - savePic : ${err}.`)
                    }
                });
        }
        else {
            this.item.pictureUri = this.imageUrl;
            this.hasPicture = true;
            this.userPicDialog = false;
        }
    }

    
    close()
    {
        this.feedback();
    }


    reciverFeedbackUserClerk(event)
    {        
        this.item.scUserSignatureId = event;                
    }

    feedback(){
        this.closeEvent.emit(true);
    }
}
