

<div id="layout-megamenu" class="col-12 layout-megamenu fadeInDown" [ngClass]="{'layout-megamenu-active': appMain.megaMenuActive,'layout-megamenu-dark': app.megaMenuMode !== 'light',
         'layout-megamenu-gradient':  app.megaMenuMode === 'gradient'}" (click)="appMain.onMegaMenuClick()">
    <div class="grid">
        <div class="col-12 md:col-6 lg:col-3 layout-megamenu-submenu">
            <h6>System Reports</h6>
            <ul>
                <li>
                    <a href="#">
                        <i class="pi pi-fw pi-list"></i>
                        <span>System Logs</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="pi pi-fw pi-save"></i>
                        <span>Back-up Server</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="pi pi-fw pi-users"></i>
                        <span>Conversions</span>
                    </a>

                </li>
                <li>
                    <a href="#">
                        <i class="pi pi-fw pi-th-large"></i>
                        <span>Storage Logs</span>
                    </a>

                </li>
            </ul>
        </div>
        <div class="col-12 md:col-6 lg:col-3 layout-megamenu-submenu">
            <h6>Finance Reports</h6>
            <ul>
                <li>
                    <a href="#">
                        <i class="pi pi-fw pi-file"></i>
                        <span>Annual Reports</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="pi pi-fw pi-dollar"></i>
                        <span>Revenue Reports</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="pi pi-fw pi-user"></i>
                        <span>HR Reports</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="pi pi-fw pi-chart-bar"></i>
                        <span>Finance Margin</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-12 md:col-6 lg:col-3 layout-megamenu-submenu">
            <h6>Settings</h6>
            <ul>
                <li>
                    <a href="#">
                        <i class="pi pi-fw pi-envelope"></i>
                        <span>E-mail Notifications</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="pi pi-fw pi-mobile"></i>
                        <span>Sms Alerts</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="pi pi-fw pi-globe"></i>
                        <span>Site Tracking</span>
                    </a>
                </li>
                <li>

                    <i class="pi pi-fw pi-language"></i>
                    <span translate styleClass="p-m-1 mr-1">menu.choose_language</span>
                    <span class="flag flag-br" style="width: 30px; height: 20px" (click)="changeLanguage('pt-BR')" style="margin-left: 5px; margin-top: 10px;" ></span>
                    <span class="flag flag-gb" style="width: 30px; height: 20px" (click)="changeLanguage('en-US')" style="margin-left: 5px; margin-top: 10px;"></span>
                    <span class="flag flag-pt" style="width: 30px; height: 20px" (click)="changeLanguage('pt-PT')" style="margin-left: 5px; margin-top: 10px;"></span>

                </li>
            </ul>
        </div>        
    </div>
</div>
