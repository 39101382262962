import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ScMenuCompanyUserItem } from 'src/app/pages/sc-menu/sc-menu.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class MenuService {

    private menuSource = new Subject<string>();
    private resetSource = new Subject();
    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/Menu/GetUserMenu';
    }

    menuSource$ = this.menuSource.asObservable();
    resetSource$ = this.resetSource.asObservable();

    onMenuStateChange(key: string) {
        this.menuSource.next(key);
    }

    reset() {
        this.resetSource.next(true);
    }

    getMenu(id: string): Observable<ScMenuCompanyUserItem> {
        let url = this.environmentUrl+"/"+id;
        return this.httpClient.get<ScMenuCompanyUserItem>(url);
    }

    getMenuUser(companyId: string, userId: string, languageCode: string): Observable<ScMenuCompanyUserItem> {
        let url = this.environmentUrl+"/"+companyId+"/"+userId+"/"+languageCode;
        return this.httpClient.get<ScMenuCompanyUserItem>(url);
    }
}
