import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PlatformDetectorService } from 'src/app/core/services/platform-detector.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';



@Component({
    templateUrl: './pwd.component.html',
    styleUrls: ['./pwd.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PwdComponent implements OnInit {

    loginForm: FormGroup;
    public token: string;
    public tokenValido: boolean = false;

    public msg:  string = "api_response.PWD_TOKEN_NOT_FOUND";
    public usuario: string = "";
    public value: string[];
    public valid: boolean = true;


    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private platformDetectorService: PlatformDetectorService,
        private notificationsService: NotificationsService,
        private loader: LoaderService,
        private _Activatedroute: ActivatedRoute,
        private translateConfigService: TranslateConfigService

    ) { }


    ngOnInit(): void {


        this._Activatedroute.paramMap.subscribe(params => {

            this.token = params.get('token');

            console.log(`token `+this.token);
            console.log(this.token);

            if (this.token !== null) {
                this.validaToken(this.token);
            }

        });

        this.loginForm = this.formBuilder.group({
            password: ['', Validators.required],
            password2: ['', Validators.required]
        });
    }

    changeLanguage(type: string) {
        this.translateConfigService.changeLanguage(type);
    }

    novaSenha()
    {
        this.router.navigate(['/login/forgot']);
    }

     validaToken(token: string)
     {
        //  if (this.token){
        //      this.authService.getUserToken(token).subscribe(
        //     {
        //         next: (res) =>{
        //            if (res) {
        //                 this.result = res;

        //                 if (this.result.status == "OK")
        //                 {
        //                     this.tokenValido = true;
        //                     this.usuario = this.result.usuario;
        //                 }
        //                 this.msg = "api_response."+this.result.mensagem;
        //            }
        //          },
        //          error: (err) => {
        //             this.result.mensagem = "api_response.PWD_TOKEN_NOT_FOUND";
        //             console.log(`Pwd - validaToken : ${err}.`);
        //          }
        //     });
        //  }
        //  else{
        //      this.tokenValido = false;
        //  }
     }




    alterarSenha() {


        // this.valid = true;
        // if (this.loginForm.invalid)
        // {
        //     this.valid = false;
        // }
        // else
        // {
        //     const password = this.loginForm.get('password').value;
        //     const password2 = this.loginForm.get('password2').value;

        //     if (password != password2) {
        //         this.notificationsService.toastNotify('error', 'Erro!', 'Senhas devem ser idênticas!');
        //         return
        //     }

        //     if (this.validaToken) {
        //         this.authService
        //             .changePwdToken(password, this.token)
        //             .subscribe(
        //                 () =>{
        //                     this.notificationsService.toastNotify('success','Senha', 'Senha foi alterada com sucesso!');

        //                     this.router.navigate(['/'])
        //                 } ,
        //                 error => {
        //                     console.log(error);
        //                     this.loginForm.reset();

        //                     this.platformDetectorService.isPlatformBrowser()
        //                     if(error.status==404)
        //                     if(error.error.objects){
        //                         this.notificationsService.toastNotify('error',error.error.title, error.error.objects[0].userMessage);
        //                     }else
        //                     if(error.error.detail){
        //                         this.notificationsService.toastNotify('error',error.error.title, error.error.detail);
        //                     }else{

        //                         if (typeof error.error === "string")
        //                         this.notificationsService.toastNotify('error', 'Erro!', error.error);
        //                         else
        //                         this.notificationsService.toastNotify('error', 'Erro!', error.message + ": " + error.status);
        //                     }

        //                 }
        //             );
        //     }
        // }
    }

}
