
<div class="user-profile" *ngIf="(user$ | async) as user">
    <a href="#" (click)="onProfileClick($event)" id="sidebar-profile-button">
        <div class="mt-2 mb-3">
            <span class="fontToolBarTabellioWhite" >Tabellio </span><span class="fontToolBarTabellioOrange" >Notas</span>
        </div>

        <div class="image-container px-auto" [ngStyle]="{backgroundImage: 'url(' + user.PictureUri+ ')'}" *ngIf="hasPicture"></div>
        <div class="image-container" [ngStyle]="{backgroundImage: 'url(assets/layout/images/perfil.svg)'}" *ngIf="!hasPicture"></div>


        <span class="sidebar-profile-name mt-2">{{user.given_name}}</span>
        <span class="sidebar-profile-role mt-2">{{user.rofile}}</span>
        <div *ngIf="hasClerk">
            <span class="sidebar-profile-role mt-2">Escrevente: {{user.ScUserSignatureName}}</span>
        </div>

    </a>

    <ul id="sidebar-usermenu" class="usermenu" [ngClass]="{'usermenu-active':app.usermenuActive}"
        [@menu]="app.isSlim()? app.usermenuActive ? 'visible' : 'hidden' :
        app.usermenuActive ? 'visibleAnimated' : 'hiddenAnimated'">
        <!-- <li #profile [ngClass]="{'menuitem-active':app.activeProfileItem === profile}">
            <a href="#" (click)="onProfileItemClick($event,profile)">
                <i class="pi pi-fw pi-user"></i>
                <span class="topbar-item-name">{{lblProfile}}</span>
            </a>
        </li> -->
        <!-- <li #settings [ngClass]="{'menuitem-active':app.activeProfileItem === settings}">
            <a href="#" (click)="onProfileItemClick($event,settings)">
                <i class="pi pi-fw pi-cog"></i>
                <span class="topbar-item-name">Settings</span>
                <i class="layout-menuitem-toggler pi pi-fw pi-angle-down"></i>
            </a>
            <ul>
                <li role="menuitem">
                    <a href="#" (click)="onProfileSubItemClick($event)">
                        <i class="pi pi-fw pi-palette"></i>
                        <span>Change Theme</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="onProfileSubItemClick($event)">
                        <i class="pi pi-fw pi-star"></i>
                        <span>Favorites</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="onProfileSubItemClick($event)">
                        <i class="pi pi-fw pi-lock"></i>
                        <span>Lock Screen</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="onProfileSubItemClick($event)">
                        <i class="pi pi-fw pi-image"></i>
                        <span>Wallpaper</span>
                    </a>
                </li>

            </ul>
        </li>-->
        <li  #messages [ngClass]="{'menuitem-active':app.activeProfileItem === messages}" *ngIf="companyList != null && companyList.length > 1">
            
                <a href="#" (click)="onProfileItemClick($event,messages)">
                    <i class="pi pi-fw pi-building"></i>
                    <span class="topbar-item-name">{{lblCompanies}}</span>
                    <i class="layout-menuitem-toggler pi pi-fw pi-angle-down"></i>
                </a>
                <ul>
                    <li *ngFor="let company of companyList" role="menuitem">
                        <a href="#" class="topbar-message" (click)="onChangeCompany(company.id)">                            
                            <span>{{company.name}}</span>
                        </a>
                    </li>                    
                </ul>
            
            <!-- <ul>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="onProfileSubItemClick($event)">
                        <img src="assets/layout/images/avatar1.png" width="25"/>
                        <span>Give me a call</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="onProfileSubItemClick($event)">
                        <img src="assets/layout/images/avatar2.png" width="25"/>
                        <span>Sales reports attached</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="onProfileSubItemClick($event)">
                        <img src="assets/layout/images/avatar3.png" width="25"/>
                        <span>About your invoice</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="onProfileSubItemClick($event)">
                        <img src="assets/layout/images/avatar2.png" width="25"/>
                        <span>Meeting today at 10pm</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="onProfileSubItemClick($event)">
                        <img src="assets/layout/images/avatar4.png" width="25"/>
                        <span>Out of office</span>
                    </a>
                </li>
            </ul> -->
        </li>
        <!-- <li #notifications [ngClass]="{'menuitem-active':app.activeProfileItem === notifications}">
            <a href="#" (click)="onProfileItemClick($event,notifications)">
                <i class="pi pi-fw pi-bell"></i>
                <span class="topbar-item-name">Notifications</span>
                <i class="layout-menuitem-toggler pi pi-fw pi-angle-down"></i>
            </a>
            <ul>
                <li role="menuitem">
                    <a href="#" (click)="onProfileSubItemClick($event)">
                        <i class="pi pi-fw pi-sliders-h"></i>
                        <span>Pending tasks</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="onProfileSubItemClick($event)">
                        <i class="pi pi-fw pi-calendar"></i>
                        <span>Meeting today at 3pm</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="onProfileSubItemClick($event)">
                        <i class="pi pi-fw pi-download"></i>
                        <span>Download documents</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="onProfileSubItemClick($event)">
                        <i class="pi pi-fw pi-ticket"></i>
                        <span>Book flight</span>
                    </a>
                </li>
            </ul>
        </li> -->
        <!-- <li #profile [ngClass]="{'menuitem-active':app.activeProfileItem === profile}">
            <a href="#" (click)="onProfileLogoutClick()">
                <i class="pi pi-sign-out"></i>
                <span class="topbar-item-name">{{lblLogout}}</span>

            </a>
        </li> -->
    </ul>
</div>


<p-dialog [(visible)]="showProfile" [style]="{width: '500px'}" [modal]="true" styleClass="p-fluid" [header]="dialogHeader">
    <ng-template pTemplate="content">
        <div style="margin-top:1px;" class="p-grid p-d-flex dialog-page">
            <sc-user-profile [userId]="user.id"></sc-user-profile>
        </div>
    </ng-template>
</p-dialog>
