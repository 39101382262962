import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { TranslateService } from '@ngx-translate/core';
import { InputMask } from 'primeng/inputmask';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ScPersonService } from 'src/app/core/services/sc-person/sc-person.service';

@Component({
    selector: 'sf-sc-person-basic',
    templateUrl: './sc-person-basic.component.html',
    styleUrls: ['./sc-person-basic.component.scss'],
    providers: []
})
export class ScPersonBasicComponent implements OnInit, OnDestroy, OnChanges {
    
    @Input() name: string = '';
    @Input() ssn: string = '';
    @ViewChild('inputName') inputName: ElementRef;
    @ViewChild('inputSsn') inputSsn: InputMask; 
    
    flRead: boolean = false;
    flUpdate: boolean = false;
    
    @Input() flagPersonType: string = '';
    public language: string = "pt-BR";
    public logComponent: string = "ScPersonBasic";
    flagItemExists: boolean = false;

    constructor(private scPersonService: ScPersonService,
                private userTokenService: UserTokenService,
                private translateService: TranslateService,
                private notificationsService: NotificationsService,
                private translateConfigService: TranslateConfigService){ }
        
    ngOnInit(): void {
        if (this.ssn !== undefined && this.ssn != null && this.ssn != '') {
            this.getPersonSearchByCpf(this.ssn.replace(/\./g, '').replace(/\-/g, ''));
        }
        this.language = this.translateConfigService.getLanguage();
        this.flRead = this.userTokenService.hasPermissionArray(['SF_ADMIN', 'SF_SELLER', 'SC_PERSON_R', 'SC_PERSON_U']);
        this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN', 'SF_SELLER', 'SC_PERSON_U']);     
    }

    ngOnDestroy(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.ssn !== undefined && this.ssn != null && this.ssn != '') {
            this.getPersonSearchByCpf(this.ssn.replace(/\./g, '').replace(/\-/g, ''));
        }
    }
    
    savePerson() {
        
        //const name = document.getElementById('name') as HTMLInputElement;
        //alert(this.inputName.nativeElement.value);
        // if(this.inputSsn.filled) {
        //     alert(this.inputSsn.value);
        // }
        let model = {            
            name: this.inputName.nativeElement.value,
            cpf: this.inputSsn.value.replace(/\./g, '').replace(/\-/g, ''),
            gender: 1,
            roleTypesId: null,
            motherName: 'MÃE',
            fatherName: 'PAI',
            telephone: {ddd: 1, phone: 0},
            mobile: {ddd: 1, phone: 0},
            email: 'email@EmailValidator.com',
            maritalStatus: 0,
            dateOfBirth: new Date(),
            spouse: null,
            flagClient: this.flagPersonType == 'client' ? true : false,
            flagSupplier: this.flagPersonType == 'supplier' ? true : false,
            flagCollaborator: this.flagPersonType == 'collaborator' ? true : false
        };
        this.scPersonService.addItemPerson(model)
            .subscribe(
            {
                next: (result) => {
                    if (this.flagItemExists) {
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_updated'));
                    } else {
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_inserted'));                        
                    }

                    //alert(JSON.stringify(result));
                    this.inputName.nativeElement.value = '';
                    this.inputSsn.value = '';
                    this.scPersonService.scPersonBasicConfirmEmitter.emit(result);
                    this.scPersonService.scPersonBasicDisplayDialogEmitter.emit();
                },
                error: (err) => console.log(err)
            });        
    }

    hideDialog() {
        this.name = null;
        this.ssn = null;
        this.scPersonService.scPersonBasicDisplayDialogEmitter.emit();
    }

    onCompleteCpf() {
        this.getPersonSearchByCpf(this.inputSsn.value.replace(/\./g, '').replace(/\-/g, ''));    
    }

    getPersonSearchByCpf(cpf: string) {        
        this.scPersonService.getListPerson(this.language, cpf, '', null).subscribe(
          {
            next: (res) => {
              if (res && res.data.length > 0) {
                this.flagItemExists = true;
                this.name = res.data[0].name;
                this.ssn  = res.data[0].cpf;
                this.scPersonService.scPersonBasicConfirmEmitter.emit(res.data);
              }
            },
            error: (err) => {
              console.log(`${this.logComponent} - getPersonSearchByCpf : ${err}.`);
            }
          });
      }
}