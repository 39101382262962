import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { WoOrderOutput } from '../../wo-order.model';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { WoOrderPaymentService } from './wo-order-payment.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ScPayment, ScPaymentOutput } from './wo-order-payment.model';
@Component({
    selector: 'sf-wo-order-payment',
    templateUrl:'./wo-order-payment.component.html',
    styleUrls: ['./wo-order-payment.component.scss']
})
export class WoOrderPaymentComponent implements OnInit {


    public woOrderPaymentTypeId: string;
    public item: ScPaymentOutput  = {id:"", amount:0, status:"", paymentType:"", details: "", orderId:"", pixValue:0, cardValue:0, moneyValue:0};



    public totalValue: number = 0;
    public totalDiscountValue: number = 0;
    public totalReceivedValue: number = 0;

    public discountPercent: number  = 0;
    public changeValue: number = 0;
    public missingValue: number = 0;

    public hasPix: boolean = true;
    public pixCode: string = "";
    public pixValue: number;

    public hasCard: boolean = true;
    public cardCode: string = "";
    public cardValue: number;

    public hasMoney: boolean = true;
    public moneyCode: string = "";
    public moneyValue: number;

    public activeIndex: number = 0;
    public moneyType: string = "BRL";
    public language: string = "pt-BR";
    public flUpdate: boolean = true;
    public canSave: boolean = false;
    public userId: string;





    @Input() public order: WoOrderOutput = {};
    @Output() closePopup = new EventEmitter();




    constructor(private paymentService: WoOrderPaymentService,
                private userTokenService: UserTokenService,
                private messageService: MessageService,
                private confirmationService: ConfirmationService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService
    ){ }

    ngOnInit() {
        this.activeIndex = 1;
        this.language = this.translateConfigService.getLanguage();
        this.userId = this.userTokenService.getUserId();
        this.item.orderId = this.order.id;


    //   this.cardForm =  this.formBuilder.group({
    //         cardNumber: ['', Validators.required],
    //         cardHolder: ['', Validators.required],
    //         cardExpiration: ['', Validators.required],
    //         cardCvv: ['', Validators.required]
    //     });



    }


    getPayment(){
        if (this.order.woPaymentId !== null){
            this.paymentService.getItem(this.order.woPaymentId, this.language).subscribe(
                {
                    next: (res) => {
                        if (res) {
                            this.item = res.data;
                        // this.getListOrderStatusHist();
                        }
                    },
                    error: (err) => {
                        console.log(`WoOrderDetailComponent - getServiceItem : ${err}.`);
                    }
                });
        }
        this.updateTotal(true);
    }



    updateReceived(){

            this.setUndefined();

            this.totalReceivedValue = this.item.pixValue + this.item.cardValue + this.item.moneyValue;
            this.totalDiscountValue = this.totalValue - this.item.discountValue;


            this.changeValue = this.totalReceivedValue - this.totalDiscountValue;

            if (this.changeValue > 0){
                this.missingValue = 0;
            }
            else{
                this.missingValue = this.changeValue;
                this.changeValue = 0
            }

            this.item.amount = this.totalReceivedValue;

            if (this.changeValue >= 0 && this.totalDiscountValue == (this.totalReceivedValue - this.changeValue))
                this.canSave = true;
            else
                this.canSave = false;
    }

    updateTotal(discountValue){

        this.setUndefined();

        if (discountValue)
        {
            if (this.item.discountValue != null && this.item.discountValue > 0){
                this.discountPercent = (this.item.discountValue * 100) / this.totalValue;
            }
        }
        else{
            if (this.discountPercent != null && this.discountPercent > 0){
                this.item.discountValue = (this.discountPercent/100) * this.totalValue;
            }
        }

        this.updateReceived();
    }

    setUndefined(){
        if (this.item.pixValue == undefined) this.item.pixValue = 0;
        if (this.item.cardValue == undefined) this.item.cardValue = 0;
        if (this.item.moneyValue == undefined) this.item.moneyValue = 0;
        if (this.totalReceivedValue == undefined) this.totalReceivedValue = 0;
        if (this.totalDiscountValue == undefined) this.totalDiscountValue = 0;
        if (this.discountPercent == undefined) this.discountPercent = 0;
        if (this.item.discountValue == undefined || this.item.discountValue == null) this.item.discountValue = 0;
    }

    save(){

        console.log('save');

        if (this.totalDiscountValue == (this.totalReceivedValue - this.changeValue)){

            this.item.amount = this.totalDiscountValue;

            if (this.item.id) {
                this.paymentService
                .updItem(this.item)
                .subscribe(
                    {
                        next: () => {
                            this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_updated'), life: 3000});
                            this.close(true);
                        },
                        error: (err) => console.log(err)
                });
            } else {
                this.paymentService
                    .addItem(this.item)
                    .subscribe(
                    {
                        next: () => {
                            this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_inserted'), life: 3000});
                            this.close(true);
                        },
                        error: (err) => console.log(err)
                    });
            }
        }
        else{
            this.messageService.add({severity: 'error', summary: this.translateService.instant('title.error'), detail: 'Valor Recebido diferente do valor total dos serviços.', life: 3000});
        }

    }

    save21(){

        console.log('save2');

        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_payment') + '?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                if (this.item.amount == this.totalReceivedValue)
                    if (this.item.id) {
                        this.paymentService
                        .updItem(this.item)
                        .subscribe(
                            {
                                next: () => {
                                    this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_updated'), life: 3000});
                                    this.close(true);
                                },
                                error: (err) => console.log(err)
                        });
                    } else {
                        this.paymentService
                            .addItem(this.item)
                            .subscribe(
                            {
                                next: () => {
                                    this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant('message.record_inserted'), life: 3000});
                                    this.close(true);
                                },
                                error: (err) => console.log(err)
                            });
                    }
                }
        });
    }

    close(updateStatus: boolean){
        this.closePopup.emit(updateStatus);
    }


    reciverFeedbackTotalValue(event)
    {
        this.totalValue = event;
        this.totalDiscountValue = event;
        this.missingValue = event - this.totalReceivedValue;
    }

    translate(){
        const sleep = (ms) => new Promise(r => setTimeout(r, ms));

        //Need 50ms to get and set de language by cookie
        (async () => {
            await sleep(50);
            this.language = this.translateConfigService.getLanguage();
            this.moneyType = this.translateService.instant('label.moneyType');
            this.moneyCode = this.translateService.instant('label.moneyCode');
        })();


    }


}
