import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ScCompanyService } from '../../sc-company.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'sc-company-add',
    templateUrl: './sc-company-add.component.html',
    styleUrls: ['./sc-company-add.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})

export class ScCompanyAddComponent implements OnInit {

    public subscription: Subscription;
    public items: MenuItem[];
    public activeIndex: number = 0;
    public labelCompanyInfo: string = "Informações da Empresa";
    public labelCompanyType: string = "Tipo da Empresa";
    public labelCompanyUser: string = "Informações de Usuário";
    public labelCompanyConfirm: string = "Confirmação";



    constructor(public messageService: MessageService,
                private companyService: ScCompanyService,
                public translateService: TranslateService,) {}

    ngOnInit() {

        this.translate();

        this.companyService.dialogCompanyAddNext
        .subscribe(
          () => this.next(),
          err => { }
        );

        this.companyService.dialogCompanyAddBack
        .subscribe(
          () => this.back(),
          err => { }
        );


        this.items = [
            {
                label: this.labelCompanyInfo,

            },
            {
                label: this.labelCompanyType
            },
            {
                label: this.labelCompanyUser
            },
            {
                label: this.labelCompanyConfirm
            }
        ];

        this.subscription = this.companyService.paymentComplete$.subscribe((info) => {
           // this.messageService.add({ severity: 'success', summary: 'Order submitted', detail: 'Dear, ' + info.name + ' ' + info.shortName + ' your order completed.' });
           console.log('sucesso');
           console.log(info.shortName);
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    translate()
    {
        const sleep = (ms) => new Promise(r => setTimeout(r, ms));
        (async () => {
             await sleep(100);
             this.labelCompanyInfo = this.translateService.instant('label.companyInfo');
             this.labelCompanyType = this.translateService.instant('label.companyType');
             this.labelCompanyUser = this.translateService.instant('label.companyUser');
             this.labelCompanyConfirm = this.translateService.instant('label.confirmation');
         })();
    }

    onActiveIndexChange(event: number) {
        this.activeIndex = event;
    }

    next()
    {
        if (this.activeIndex < 3)
            this.activeIndex++;
        
    }

    back()
    {
        if (this.activeIndex > 1)
            this.activeIndex--;
    }

    hideDialog() {
        this.companyService.dialogCompanyAdd.emit();
    }


}



