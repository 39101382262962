import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DropDownMeta } from 'src/app/shared/model/base-model';
import { ScCompanyStatusItem } from '../sc-company/sc-company.model';
import { WoNotarialSeal, WoNotarialSealItem, WoNotarialSealList } from './wo-notarial-seal.model';

@Injectable({
    providedIn: 'any'
})

export class WoNotarialSealService{
    private environmentUrl: string = "";

    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore + '/NotarialSeal/';
    }

    getItem(id: string): Observable<WoNotarialSealItem> {
        let url = this.environmentUrl + id;
        return this.httpClient.get<WoNotarialSealItem>(url);
    }

    getList(): Observable<WoNotarialSealList> {
        let url = this.environmentUrl;
        return this.httpClient.get<WoNotarialSealList>(url);
    }

    addItem(model: WoNotarialSeal, userId: string, language: string) {
        let url = this.environmentUrl;
        let input: WoNotarialSeal = this.insert(model, userId, language);

        return this.httpClient.post(url, input);
    }

    updItem(model: WoNotarialSeal, id: string, userId: string, language: string) {
        let url = this.environmentUrl + model.id;

        let input: WoNotarialSeal = this.update(model, id, userId, language);

        return this.httpClient.put(url, input);
    }

    delItem(id: string) {
        return this.httpClient.delete(this.environmentUrl + id);
    }

    getDropDownList(): Observable<DropDownMeta> {
        let url = this.environmentUrl + 'DropDown';
        return this.httpClient.get<DropDownMeta>(url);
    }


    insert(model: WoNotarialSeal, userId: string, language: string){

        let input: WoNotarialSeal = {};
        input.code = model.code;
        input.userIdCreated = userId;
        return input;
    }

    update(model: WoNotarialSeal, id: string, userId: string, language: string){

        let input: WoNotarialSeal = {};

        if (model.id != null && model.id != undefined && model.id != ""){
            input.id = model.id;
        }

        input.code = model.code;
        input.userIdUpdated = userId;

        return input;
    }

    reserve(id: string, userId: string): Observable<any> {
        let url = this.environmentUrl + 'reserve?id=' + id + '&userId=' + userId;
        return this.httpClient.patch(url, null);
    }

}
