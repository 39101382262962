import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

export interface Config {
    build: string;
    commit: string;
    version: string;
  }

@Injectable()
export class AppConfig {

    public config: Config;
    private userSubject = new BehaviorSubject<Config>(null);

    constructor(private http: HttpClient) {

    }


    public loadConfigs(){
        this.http.get('assets/config/config.json')
            .subscribe((data: Config) => {this.config = {
            version: data['version'],
            build:  data['build'],
            commit:  data['commit']
        }
        
        this.userSubject.next(this.config);
    }
        );
    }


    getConfig(){
        return this.userSubject.asObservable();
    }


    
}