import { environment } from './../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaPlan, PaPlanItem, PaPlanList, PaPlanOutput } from './pa-plan.model';
import { DropDownMeta } from '../../shared/model/base-model';

@Injectable({
    providedIn: 'any'
})

export class PaPlanService{

    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/Plan/';
    }

    getItem(id: string): Observable<PaPlanItem> {
        let url = this.environmentUrl + id;
        return this.httpClient.get<PaPlanItem>(url);
    }

    getList(language: string): Observable<PaPlanList> {
        let url = this.environmentUrl+ "?language="+language;
        return this.httpClient.get<PaPlanList>(url);
    }

    addItem(model: PaPlanOutput, language: string) {
        let url = this.environmentUrl + "?language="+language;
        return this.httpClient.post(this.environmentUrl, model);
    }

    updItem(model: PaPlanOutput, language: string) {

        let url = this.environmentUrl + model.id+"?language="+language;
        return this.httpClient.put(url, model);
    }

    delItem(id: string) {
        return this.httpClient.delete(this.environmentUrl + id);
    }

    getDropDownList(language: string): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown/?language="+language;
        return this.httpClient.get<DropDownMeta>(url);
    }



}
