import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';



@Component({
    selector: 'sf-dropdown-color',
    templateUrl: './dropdown-color.component.html',
    styleUrls: ['./dropdown-color.component.scss']
})

export class DropdownColorComponent implements OnInit, OnChanges{

    public selectedColor: string;

    @Input() color: string;
    @Input() isInLine: boolean = true;
    @Input() parentToAppend: string;
    @Output() chosedColor = new EventEmitter();


    ngOnInit(): void {
        this.selectedColor = this.color;
    }

    ngOnChanges(): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
        this.selectedColor = this.color;
    }


    feedback() {
        this.chosedColor.emit(this.selectedColor);
    }
}
