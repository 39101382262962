import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WoOrderServiceTemplateItem, WoOrderServiceTemplateList, WoOrderServiceTemplateOutput } from './wo-order-service-template.model';
import { DropDownMeta } from 'src/app/shared/model/base-model';
import { Observable } from 'rxjs';




@Injectable({
    providedIn: 'root'
  })

export class WoOrderServiceTemplateService{

    private environmentUrl: string = '';



    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/OrderService/Template/';
    }



    getItem(id: string): Observable<WoOrderServiceTemplateItem> {
        let url = this.environmentUrl+ id;
        return this.httpClient.get<WoOrderServiceTemplateItem>(url);
    }

    getList() : Observable<WoOrderServiceTemplateList>{
        let url = this.environmentUrl;
        return this.httpClient.get<WoOrderServiceTemplateList>(url);
    }

    addItem(model: WoOrderServiceTemplateOutput){
        let url = this.environmentUrl;
        return this.httpClient.post(url, model);
    }

    updItem(model: WoOrderServiceTemplateOutput){
        let url = this.environmentUrl+ model.id;
        return this.httpClient.put(url, model);
    }

    delItem(id: string) {
        let url = this.environmentUrl+id;
        return this.httpClient.delete(url);
    }

    getDropDownList(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown";
        return this.httpClient.get<DropDownMeta>(url);
    }

}



