import { Component, Input } from '@angular/core';

@Component({
    templateUrl:'./vmessage.component.html',
    selector: 'cria-vmessage'
})
export class VMessageComponent{

   @Input() text='';

}