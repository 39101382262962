<sf-loader></sf-loader>
<sf-notification></sf-notification>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="formgrid grid">
    <div class="field col-12 md:col-4 lg:col-4 xl:col-4">

        <!-- ======================= FOTO E AUDITORIA =============================== -->
        <div class="col-12">
            <div class="p-field center-img flex align-items-center justify-content-center">
                <div class="avatar-container">
                    <div class="image-container" [ngStyle]="{backgroundImage: 'url(' + item.pictureUri+ ')'}" *ngIf="hasPicture"></div>
                    <div class="image-container" [ngStyle]="{backgroundImage: 'url(assets/layout/images/perfil.svg)'}" *ngIf="!hasPicture"></div>
                    <label for="avatar-input-file">
                        <div class="icon-wrapper">
                            <input type="file" id="avatar-input-file" (change)="fileChangeEvent($event)" accept="image/*"/>
                            <i class="pi pi-upload icon"></i>
                        </div>
                    </label>
                </div>
            </div>


            <div class="p-field flex align-items-center justify-content-center font-bold text-2xl" *ngIf="item.email && item.login != item.email">
                {{item.login}}
            </div>
            <div class="p-field flex align-items-center justify-content-center font-bold text-2xl" *ngIf="item.email">
                {{item.email}}
            </div>
            <div class="p-field flex align-items-center justify-content-center font-bold text-2xl" *ngIf="item.email">
                {{item.name}}
            </div>

            <div class="grid mt-3">
                <div class="col"><label class="font-bold label-detail text-lg"><span translate>label.status</span>:</label></div>
                <div class="col ml-1 text-lg">
                    <span [style]="{'color':''+item.scUserStatusColor+''}">{{item.scUserStatusName}}</span>
                </div>
            </div>

            <div class="grid mt-1">
                <div class="col"><label class="font-bold label-detail text-lg"><span translate>label.access_number</span>:</label></div>
                <div class="col ml-1 text-lg">{{item.numberSuccessfulLogin}}</div>
            </div>
            <div class="grid mt-1">
                <div class="col"><label class="font-bold label-detail text-lg"><span translate>label.failures_number</span>:</label></div>
                <div class="col ml-1 text-lg">{{item.numberFailedLogin}}</div>
            </div>



            <div class="mt-4">
                    <p-card [header]="'label.user_history' | translate">
                        <div style="height: 500px;  overflow-y: scroll;">
                            <p-timeline [value]="userStatusHist" align="alternate" styleClass="customized-timeline">
                                <ng-template pTemplate="marker" let-event>
                                    <span class="custom-marker shadow-2" [style.backgroundColor]="event.statusColor">
                                            <i ngClass="pi pi-circle" [style.color]="event.statusColor"></i>
                                    </span>
                                </ng-template>
                                <ng-template pTemplate="content" let-event>
                                    <p-card [header]="event.statusDescription" [class]="card">
                                        <div class="grid">
                                            <div class="col p-text-secondary"><label class="font-bold"><span translate>label.user</span></label> : {{event.userLogin}}</div>
                                        </div>
                                        <div class="grid mt-1">
                                            <div  class="col p-text-secondary"><label class="font-bold"><span translate>label.date</span></label> : {{event.updatedAt | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
                                        </div>
                                        <div class="grid mt-1">
                                            <div class="col p-text-secondary">{{event.obs}}</div>
                                        </div>
                                    </p-card>
                                </ng-template>
                            </p-timeline>
                        </div>
                    </p-card>
            </div>

        </div>
    </div>

    <!-- ======================= DADOS DO user =============================== -->



    <div class="col-12 md:col-8 lg:col-8 xl:col-8">

        <p-tabView styleClass="tabview-custom">
            <p-tabPanel>
                <ng-template pTemplate="header" style="width: 200px;">
                        <div style="width: 250px;">
                        <i class="pi pi-user"></i>
                        <span class="ml-2" translate>label.user_details</span>
                    </div>
                </ng-template>
                <div class="formgrid grid">
                    <div class="field col">
                        <label for="email" class="mb1 font-bold"><span translate>label.email</span></label>
                        <input type="text" pInputText id="email" [(ngModel)]="item.email" autofocus class="mt-1" email="true" onchange="checkEmail(user.email)" [disabled]="!canUpdate" class="w-full"/>
                        <small class="p-invalid" *ngIf="(!item.email) || invalidEmail" translate>validation.invalid_field</small>

                    </div>
                    <div class="field col" *ngIf="canChangeLogin">
                        <label for="login" class="mb1 font-bold"><span translate>label.login_email</span></label>
                        <input type="text" pInputText id="login" [(ngModel)]="item.login"  onchange="checkLogin(user.login)" [disabled]="!canUpdate" class="w-full"/>
                        <!-- <small class="p-invalid" *ngIf="submitted && !item.login" translate>validation.required_field</small> -->
                    </div>
                </div>

                <div class="formgrid grid">
                    <div class="field col">
                        <label for="name" class="mb1 font-bold"><span translate>label.name</span></label>
                        <input type="text" pInputText id="name" [(ngModel)]="item.name" required  class="w-full"  [disabled]="!canUpdate"/>
                        <!-- <small class="p-invalid" *ngIf="submitted && !item.name" translate>validation.required_field</small> -->

                    </div>
                    <div class="field col">
                        <label for="phone" class="mb1 font-bold"><span translate>label.phone_number</span></label>
                        <p-inputMask mask="(99) 99999-9999" [(ngModel)]="item.phone" placeholder="(99) 99999-9999" id="phone"  styleClass="mask"  [disabled]="!canUpdate" [style]="{'width':'100%'}"></p-inputMask>
                    </div>
                </div>

                <div class="formgrid grid">
                    <div class="field col">
                        <label for="mobileCode" class="mb1 font-bold"><span translate>label.mobile_code</span></label>
                        <input type="text" pInputText [(ngModel)]="item.mobileCode" id="mobileCode"  class="w-full"  [disabled]="!canUpdate"/>
                    </div>

                    <div class="field col">
                        <label for="languageCode" class="mb1 font-bold"><span translate>label.default_language</span></label>
                        <input type="text" pInputText [(ngModel)]="item.languageCode" id="languageCode"  class="w-full"  disabled/>
                    </div>
                </div>

                <div class="formgrid grid mt-1">
                    <div class="field col">
                        <div class="field-checkbox">
                            <p-checkbox [(ngModel)]="item.isBlocked" binary="true" id="flBloqueado" name="flAtivo" ngDefaultControl class="p-d-inline" [disabled]="!canUpdate"> </p-checkbox>
                            <label for="flBloqueado" class="mb1 font-bold"><span translate>label.bloked_user</span></label>
                        </div>
                    </div>

                    <div class="field col">
                        <div class="field-checkbox">
                            <p-checkbox [(ngModel)]="item.isFirstAccess" binary="true" id="isFirstAccess" name="isFirstAccess" ngDefaultControl class="p-d-inline" [disabled]="!canUpdate"> </p-checkbox>
                            <label for="isFirstAccess" class="mb1 font-bold"><span translate>label.first_access</span></label>
                        </div>
                    </div>

                    <div class="field col">
                        <div class="field-checkbox">
                            <p-checkbox [(ngModel)]="item.isEmailConfirmed" binary="true" id="flAtivo" name="isEmailConfirmed" ngDefaultControl class="p-d-inline" [disabled]="!canUpdate"> </p-checkbox>
                            <label for="isEmailConfirmed" class="mb1 font-bold"><span translate>label.email_confirmation</span></label>
                        </div>
                    </div>
                </div>



                <div class="formgrid grid mt-1">
                    <div class="field col">
                        <label for="listaPerfil" class="mb-1 font-bold"><span translate>label.access_profile</span></label>
                        <sc-role-dropdown [id]="item.scRoleId" (chosedItem)="reciverFeedbackRole($event)" [disabled]="!canUpdate"></sc-role-dropdown>
                    </div>
                </div>

                <div class="p-field mt-1">
                    <label for="structure" class="mb-1 font-bold"><span translate>label.organizational_structure</span> (<span translate>label.access_level</span>)</label>
                    <sf-organizationStructure-tree [id]="detailItem.scOrganizationStructureId" (chosedItem)="reciverFeedbackStructure($event)" [placeHolderLanguage]="'label.select_organization_structure' | translate"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary w-full" style="appearance: auto;">
                    </sf-organizationStructure-tree>
                </div>


            </p-tabPanel>

            <p-tabPanel header="Header II">
                <ng-template pTemplate="header">
                    <div style="width: 250px;">
                        <i class="pi pi-users"></i>
                        <span class="ml-2" translate>label.employee_details</span>
                    </div>
                </ng-template>

                <div class="formgrid grid">
                    <div class="field col">
                        <label for="email" class="mb1 font-bold"><span translate>label.email</span></label>
                        <input type="text" pInputText id="email" [(ngModel)]="item.email" autofocus class="mt-1" email="true" onchange="checkEmail(user.email)" [disabled]="!canUpdate" class="w-full"/>
                        <small class="p-invalid" *ngIf="(!item.email) || invalidEmail" translate>validation.invalid_field</small>
                    </div>
                    <div class="field col" *ngIf="canChangeLogin">
                        <label for="login" class="mb1 font-bold"><span translate>label.login_email</span></label>
                        <input type="text" pInputText id="login" [(ngModel)]="item.login"  onchange="checkLogin(user.login)" [disabled]="!canUpdate" class="w-full"/>
                        <small class="p-invalid" *ngIf="submitted && !item.login" translate>validation.required_field</small>
                    </div>
                </div>

                <div class="formgrid grid">
                    <div class="field col">
                        <label for="email" class="mb1 font-bold"><span translate>label.skills</span></label>
                        <sf-wo-skill-dropdown-multi InputId="skill" [selectedItems]="selectedSkills" [placeHolderLanguage]="'label.selectSkill' | translate" (chosedItem)="reciverFeedbackSkill($event)"></sf-wo-skill-dropdown-multi>
                    </div>
                </div>

                <div class="formgrid grid" *ngIf="hasClerk">
                    <div class="field col">
                        <label for="clerk" class="mb1 font-bold"><span translate>label.clerk</span></label>
                        <sfc-dropdown-clerk [id]="item.scUserSignatureId" (selectedItemOutput)="reciverFeedbackUserClerk($event)" disabled="!canUpdate"></sfc-dropdown-clerk>
                    </div>
                </div>

            </p-tabPanel>
        </p-tabView>
        <div class="field col-12">
            <div style="float: right;">
                <button pButton pRipple icon="pi pi-arrow-left" style="width: 160px; margin: 3px;" class="p-button-warning p-mr-2 mt-3 font-medium" [label]="'button.close_back' | translate" (click)="close();"></button>
                <button pButton pRipple icon="pi pi-times" style="width: 160px; margin: 3px;" class="p-button-danger p-mr-2 mt-3 font-medium" [label]="'button.cancel' | translate" (click)="cancel();"  *ngIf="canUpdate"></button>
                <button pButton pRipple icon="pi pi-check" style="width: 160px; margin: 3px;" class="p-button-success p-mr-2 mt-3 font-medium" [label]="'button.confirm' | translate" (click)="saveItem();"  *ngIf="canUpdate" ></button>
            </div>
        </div>




    </div>


</div>




<p-dialog [(visible)]="userPicDialog" [style]="{width: '480px',height: '600px'}" [header]="'label.user_upload_avatar' | translate" [modal]="true" styleClass="p-fluid" >
<ng-template pTemplate="content">
    <div style="margin-top:1px;" class="grid">
        <div class="col-12 p-field flex align-items-center justify-content-center font-bold" style="width: 100%;" >

            <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [containWithinAspectRatio]="containWithinAspectRatio"
            [aspectRatio]="1"
            [resizeToWidth]="128"
            [cropperMinWidth]="128"
            [roundCropper]="true"
            [canvasRotation]="canvasRotation"
            [alignImage]="'center'"
            [style.display]="showCropper ? null : 'none'"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady($event)"
            (loadImageFailed)="loadImageFailed()">
            </image-cropper>
        </div>

        <!-- <div class="col-12 p-field flex align-items-center justify-content-center font-bold" >
            <label for="listaFuncao" class="mb-1 font-bold"><span translate>label.preview_avatar_image</span></label>
            <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" />
        </div>

        <div class="col-md-4">
            <h6>Image Preview</h6>
            <img [src]="imageUrl" />
          </div> -->
    </div>
</ng-template>

<ng-template pTemplate="footer">
    <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="hidePicDialog()"></button>
    <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="savePic()" [disabled]="!showCropper"></button>
</ng-template>
</p-dialog>

