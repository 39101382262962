import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';


import { TokenService } from '../services/token.service';
import { UserTokenService } from '../services/user-token.service';

@Injectable({providedIn:'root'})
export class AuthenticationGuard implements CanActivate{
    constructor(private tokenService: TokenService,
                private userTokenService: UserTokenService,
                private router:Router
    ){}

    public static guards = [ ];

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {


        if (this.tokenService.hasToken() && this.tokenService.isTokenExpired())
        {
            this.tokenService.removeToken();
            //this.router.navigate(['/login']);
            this.router.navigate(
              ['/login'],
              {
                  queryParams: {
                      fromUrl: state.url
                  }
              }
            );
            return false;
        }


        if(this.userTokenService.isLogged()){

            return true;
        }else{
            //this.router.navigate(['/login']);
            this.router.navigate(        
              ['/login'],
              {
                  queryParams: {
                      fromUrl: state.url
                  }
              }
            );
            return false;
        }

    }


    /*static forPermissions(permissions: string | string[]) {
        @Injectable()
        class AuthGuardServiceWithPermissions {
          constructor(private authGuardService: AuthenticationGuard) { } // uses the parent class instance actually, but could in theory take any other deps

          canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
            // checks typical activation (auth) + custom permissions
            return this.authGuardService.canActivate(route, state) && this.checkPermissions();
          }

          checkPermissions() {
            //const user = ... // get the current user
            // checks the given permissions with the current user
           // return user.hasPermissions(permissions);


            if("teste" != permissions)
                alert("sem permissão");
            return "teste" == permissions;
          }
        }
        console.log(permissions);
        AuthenticationGuard.guards.push(AuthGuardServiceWithPermissions);
        return AuthGuardServiceWithPermissions;
      }*/
}
