import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import localePT from '@angular/common/locales/pt';
import localeExtraPT from '@angular/common/locales/extra/pt';


import { TableModule } from 'primeng/table';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabViewModule } from 'primeng/tabview';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';

import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';

import { AuthService } from 'src/app/core/auth/auth.service';
import { AuthorizationGuard } from 'src/app/core/guards/authorization.guard';


import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';
import { SfDropDownModule } from 'src/app/shared/components/dropdown/dropdown.module';
import { WoOrderService } from '../wo-order/wo-order.service';
import { WoOrderServiceTemplateService } from './wo-order-service-template/wo-order-service-template.service';
import { WoOrderServiceTemplateGridComponent } from './wo-order-service-template/wo-order-service-template-grid/wo-order-service-template-grid.component';

import { LoaderModule } from 'src/app/shared/components/loader/loader.module';
import { ScModuleModule } from '../sc-module/sc-module.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { WoOrderServiceGridComponent } from './wo-order-service/wo-order-service-grid/wo-order-service-grid.component';


@NgModule({
    declarations: [WoOrderServiceTemplateGridComponent, WoOrderServiceGridComponent],
    exports: [],
    imports: [
        FormsModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        ButtonModule,
        InputTextModule,
        TranslateModule,
        MessageModule,
        MessagesModule,
        TableModule,
        TooltipModule,
        ConfirmDialogModule,
        TabViewModule,
        CardModule,
        DialogModule,
        NotificationsModule,
        InputNumberModule,
        CalendarModule,
        InputTextModule,
        SfDropDownModule,
        LoaderModule,
        DialogModule,
        ConfirmDialogModule,
        ScModuleModule,
        ProgressSpinnerModule
        ],
    providers: [NotificationsService, AuthService, AuthorizationGuard, WoOrderService, WoOrderServiceTemplateService]
})
export class WoOrderServiceModule { }


