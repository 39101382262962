import { Component, OnInit } from '@angular/core';
import { GenericItem, GenericItemOutput } from 'src/app/shared/model/base-model';
import { ScDocumentTypeService } from '../sc-document-type.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { ConstantsService } from 'src/app/core/services/constants.service';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';

@Component({
  selector: 'app-sc-document-type-list',
  templateUrl: './sc-document-type-list.component.html',
  styleUrls: ['./sc-document-type-list.component.scss']
})
export class ScDocumentTypeListComponent implements OnInit {
  public list: GenericItemOutput[] = [];
  public item: GenericItem;

  public logComponent: string = "ScDocumentTypeList";
  public language: string = "pt-BR";
  public flUpdate: boolean = false;
  public dialogHeader: string = "";
  public btnNewLabel: string = "";
  public type: string = "";

  constructor(private service: ScDocumentTypeService,
    private userTokenService: UserTokenService,
    private constantsService: ConstantsService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private translateConfigService: TranslateConfigService,
    private breadcrumbService: BreadcrumbService,
  ) { }

  ngOnInit(): void {

    this.language = this.translateConfigService.getLanguage();
    this.flUpdate = this.userTokenService.hasPermissionArray([this.constantsService.ROLE_SF_ADMIN]);
    this.getList();
    this.translate();
  }

  translate() {
    const sleep = (ms) => new Promise(r => setTimeout(r, ms));
    (async () => {
      await sleep(100);
      const labAccessControl = this.translateService.instant('label.accessControl') + ' ';
      const labPage = ' ' + this.translateService.instant('label.documentType');
      this.breadcrumbService.setItems([{ label: labAccessControl }, { label: labPage, routerLink: ['document-type'] }]);
      this.dialogHeader = this.translateService.instant('title.documentType');
      this.btnNewLabel = this.translateService.instant('button.typeNew');
    })();
  }

  getList() {
    this.service.getList().subscribe(
      {
        next: (res) => {
          if (res)
            this.list = res.data;
          console.log(res.data);
        },
        error: (err) => {
          console.log(`${this.logComponent} - getList : ${err}.`);
        }
      });
  }

  delete() {
    this.service
      .delItem(this.item.id)
      .subscribe(
        {
          next: () => {
            this.messageSuccess('message.record_deleted');
            this.getList();
          },
          error: (err) => {
            this.messageError('api_response.ERROR');
            console.log(`${this.logComponent} - delItem : ${err}.`);
          }
        });
  }

  save() {
    if (this.item.code.trim()) {
      if (this.item.id) {
        this.service
          .updItem(this.item.id, this.item)
          .subscribe(
            {
              next: () => {
                this.messageSuccess('message.record_updated');
                this.getList();
              },
              error: (err) => {
                this.messageError('api_response.ERROR');
                console.log(`${this.logComponent} - UpdItem : ${err}.`);
              }
            });

      } else {
        this.service
          .addItem(this.item)
          .subscribe(
            {
              next: () => {
                this.messageSuccess('message.record_inserted');
                this.getList();
              },
              error: (err) => {
                this.messageError('api_response.ERROR');
              }
            });
      }

      this.list = [...this.list];
      this.item = {};
    }
  }

  messageSuccess(message: string) {
    this.messageService.add({ severity: this.constantsService.SEVERITY_SUCCESS, summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 3000 });
  }

  messageError(message: string) {
    this.messageService.add({ severity: this.constantsService.SEVERITY_WARNING, summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 3000 });
  }


  reciverFeedbackType(type: string) {
    this.type = type;
  }

  reciverFeedbackItem(item: GenericItem) {
    this.item = item;
    if (this.type == this.constantsService.DELETE)
      this.delete();
    else
      this.save();
  }
}