import { NotificationsModule } from '../../shared/notifications/notifications.module';
import { AuthorizationGuard } from './../../core/guards/authorization.guard';
import { AuthService } from './../../core/auth/auth.service';
import { NotificationsService } from './../../shared/notifications/notifications.service';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { InputMaskModule } from 'primeng/inputmask';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SfDropDownModule } from './../../shared/components/dropdown/dropdown.module';
import { VMessageModule } from './../../shared/components/vmessage/vmessage.module';
import { WoWorkFlowService } from './wo-workflow.service';
import { WoWorkFlowGridComponent } from './wo-workflow-grid.component';
import { WoWorkFlowDetailComponent } from './wo-workflow-detail/wo-workflow-detail.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { ChipModule } from 'primeng/chip';

@NgModule({
    declarations: [WoWorkFlowGridComponent, WoWorkFlowDetailComponent],
    exports: [WoWorkFlowGridComponent, WoWorkFlowDetailComponent ],
    imports: [FormsModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        VMessageModule,
        RouterModule,
        ButtonModule,
        InputTextModule,
        TranslateModule,
        MessageModule,
        MessagesModule,
        TableModule,
        TooltipModule,
        InputMaskModule,
        CardModule,
        SfDropDownModule,
        DialogModule,
        ConfirmDialogModule,
        DropdownModule,
        TableModule,
        ConfirmDialogModule,
        NotificationsModule,
        LoaderModule,
        CheckboxModule,
        ListboxModule,
        ChipModule,
        InputTextareaModule

        ],
    providers: [NotificationsService, AuthService, AuthorizationGuard, WoWorkFlowService, {provide: LOCALE_ID, useValue: 'pt'}]
})
export class WoWorkFlowModule { }
