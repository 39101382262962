<sf-loader></sf-loader>
<sf-notification></sf-notification>

<div class="formgrid grid">
    <div class="field col-12 md:col-8 lg:col-8 xl:col-8">
        <div class="card">
            <div class="formgrid grid text-lg">
                <div class="field col-4">
                    <div class="avatar-container">
                        <div class="image-container" [ngStyle]="{backgroundImage: 'url(' + item.pictureUri+ ')'}" *ngIf="hasPicture"></div>
                        <div class="image-container vertical-align-baseline text-center"  *ngIf="!hasPicture"> <span class="fontOrangeBig" translate>label.companyLogo</span></div>
                        <label for="avatar-input-file">
                            <div class="icon-wrapper">
                                <input type="file" id="avatar-input-file" (change)="fileChangeEvent($event)" accept="image/*"/>
                                <i class="pi pi-upload icon"></i>
                            </div>
                        </label>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <label for="name" class="mb-1 font-bold text-lg"><span translate>label.code</span>*</label>
                    <input type="text" id="code" pInputText [(ngModel)]="item.code" class="w-full" [disabled]="!flUpdate" required>
                    <small class="p-invalid" *ngIf="submitted && !item.name" translate>validation.required_field</small>

                </div>
                <div class="field col-12 md:col-4">
                    <label for="ein" class=" block mb-2 font-bold text-lg"><span translate>label.scCompanyEin</span></label>
                    <p-inputMask id="ein" mask="99.999.999/9999-99" [(ngModel)]="item.ein" placeholder="99.999.999/9999-99" class=" w-full" [disabled]="!flUpdate" ></p-inputMask>
                </div>
            </div>

            <div class="formgrid grid text-lg">
                <div class="field col-12 md:col-6">
                    <label for="name" class="mb-1 font-bold text-lg"><span translate>label.name</span>*</label>
                    <input type="text" id="name" pInputText [(ngModel)]="item.name" class="w-full"  [disabled]="!flUpdate" required>
                    <small class="p-invalid" *ngIf="submitted && !item.name" translate>validation.required_field</small>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="fatasyName" class="mb-1 font-bold text-lg"><span translate>label.fantasyName</span></label>
                    <input type="text" id="fatasyName" [(ngModel)]="item.fantasyName" pInputText class="w-full" [disabled]="!flUpdate">
                </div>
            </div>
            <div class="formgrid grid text-lg">
                <div class="field col-12 md:col-10">
                    <label for="name" class="mb-1 font-bold text-lg"><span translate>label.address</span>*</label>
                    <input type="text" id="name" pInputText [ngModel]="formattedAddress" [ngModelOptions]="{standalone: true}" class="w-full">
                    <small class="p-invalid" *ngIf="submitted && !formattedAddress" translate>validation.required_field</small>
                </div>
                <div class="field col-12 md:col-2" *ngIf="flUpdate">
                    <div class="mt-2">
                    <p-button (click)="showDialog()" icon="pi pi-external-link" [label]="'button.addressUpd' | translate" ></p-button>
                </div>
                </div>
            </div>


        </div>  <!--Main Card -->


        <div class="card" >
            <div class="p-col-12 p-md-12">
                <div class="formgrid grid text-lg">
                    <div class="field col-4">
                        <label for="plan" class="mb-1 font-bold text-lg">
                            <span translate>label.licensingPlan</span>*
                        </label>
                        <sf-dropdown-generic
                            [list]="planList"
                            [id]="item.paPlanId"
                            (selectedItemOutput)="reciverFeedbackCompanyPlan($event)"                            
                            [disabled]="true"
                        ></sf-dropdown-generic>                        
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="plan" class="mb-1 font-bold text-lg">
                            <span translate>label.type</span>*
                        </label>
                        <sf-dropdown-generic
                            [list]="companyTypeList"
                            [id]="item.scCompanyTypeId"
                            (selectedItemOutput)="reciverFeedbackCompanyType($event)"
                            [disabled]="true"
                        ></sf-dropdown-generic>                        
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="status" class="mb-1 font-bold text-lg">
                            <span translate>label.status</span>*
                        </label>
                        <sf-dropdown-generic
                            [list]="companyStatusList"
                            [id]="item.scCompanyStatusId"
                            (selectedItemOutput)="reciverFeedbackCompanyStatus($event)"
                            [disabled]="true"
                        ></sf-dropdown-generic>                        
                    </div>
                </div>

                <div class="formgrid grid text-lg flex align-itens-center">
                   
    
                
                </div>


            </div>
        </div>
    </div>
    <div class="col-12 md:col-4 lg:col-4 xl:col-4 p-order-4 p-order-lg-2">
        
        <div class="card">
            
            <div class="field col-12 sm:col-12 md:col-12 lg:col-12">
                <label for="moduleAvailable" class="mb-1 font-bold text-lg">
                    <span translate>label.moduleAvailable</span>*
                </label>
                <div class="card flex justify-content-start">
                    <p-listbox 
                        [options]="moduleList" 
                        [(ngModel)]="selectedModules" 
                        optionLabel="name" 
                        [style]="{ width: '340px', height: '200px' }"
                        [filter]="true"
                        [multiple]="true"
                        [checkbox]="true"
                        (onSelectAllChange)="onSelectModuleAllChange($event)"
                        (onChange)="onModuleChange($event)"
                        [ngClass]="{ 'ng-invalid ng-dirty': (selectedModules.length === 0 && submitted) }"
                    ></p-listbox>                        
                </div>
                <small *ngIf="(selectedModules.length === 0 && submitted)" class="p-error">
                    <span translate>label.required_fields</span>
                </small>
            </div>
            <div class="field col-12 sm:col-12 md:col-12 lg:col-12">
                <label for="moduleAvailable" class="mb-1 font-bold text-lg">
                    <span translate>label.config</span>
                </label>
                <div class="card flex justify-content-start">
                    <p-listbox 
                        [options]="configList" 
                        [(ngModel)]="selectedConfigs" 
                        optionLabel="name" 
                        [style]="{ width: '340px', height: '200px' }"
                        [filter]="true"
                        [multiple]="true"
                        [checkbox]="true"
                        (onSelectAllChange)="onSelectConfigAllChange($event)"
                        (onChange)="onConfigChange($event)"
                    ></p-listbox>
                </div>
            </div>
        <div class="card text-center ">
            <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="close()"></button>
            <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="save()" [disabled]="!flUpdate"></button>
    </div>

    </div>
</div>

<!--
<p-dialog [(visible)]="visible" [style]="{width: '50vw', height:'70vh'}">
    <p-header> {{ 'label.address' | translate }} </p-header>
     <app-address (formattedAddressEmitter)="formattedAddressSubmitted($event)" (newAddressEmitter)="addressSubmitted($event)" [addressId]="item.scAddressId"></app-address> 
</p-dialog>
-->





