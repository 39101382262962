import { Pipe, PipeTransform} from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: 'safe' })

export class SafePipe implements PipeTransform {

constructor(private sanitizer: DomSanitizer) { }
transform(url) {    

    if (url && url != 'undefined' && url.includes('data:image/png;base64')){
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);      
    }
    else{
      let objectURL = 'data:image/jpeg;base64,' + url;
      return this.sanitizer.bypassSecurityTrustResourceUrl(objectURL);
    }
      

 
  }
}



    // transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    //     switch (type) {
    //         case 'html':
    //             return this._sanitizer.bypassSecurityTrustHtml(value);
    //         case 'style':
    //             return this._sanitizer.bypassSecurityTrustStyle(value);
    //         case 'script':
    //             return this._sanitizer.bypassSecurityTrustScript(value);
    //         case 'url':
    //             return this._sanitizer.bypassSecurityTrustUrl(value);
    //         case 'resourceUrl':
    //             return this._sanitizer.bypassSecurityTrustResourceUrl(value);
    //         default:
    //             return this._sanitizer.bypassSecurityTrustHtml(value);
    //     }
    // }
