<div class="p-grid">
    <div class="p-col-12">
        <div class="formgrid grid">            
            <div class="field col">
                <label for="cpf" class="mb1 font-bold"><span translate>label.person_ssn</span></label>
                <input type="number" pInputText id="cpf" autofocus class="mt-1" class="w-full" maxlength="11" minlength="11" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" onblur="if (this.value.length < this.minLength) this.value = '';" />
                <!-- <p-inputMask mask="999.999.999-99" placeholder="999.999.999-99" id="cpf" name="cpf" styleClass="mask" [style]="{'width':'100%'}"></p-inputMask> -->
                <!-- <small class="p-invalid" *ngIf="(!item.email) || invalidEmail" translate>validation.invalid_field</small> -->

            </div>
            <div class="field col">
                <label for="name" class="mb1 font-bold"><span translate>label.name</span></label>
                <input type="text" pInputText id="name" class="w-full" />
                <!-- <small class="p-invalid" *ngIf="submitted && !item.login" translate>validation.required_field</small> -->
            </div>
            <div class="field col" style="display: flex; justify-content: space-between; flex-direction: column-reverse;">
                <div style="float: right;">
                    <button pButton pRipple icon="pi pi-search" class="p-button-success mr-2 mt-3" (click)="search()" [disabled]="!flRead"></button>
                    <button pButton pRipple icon="pi pi-plus" class="p-button-primary mr-2 mt-3" (click)="insertPerson()" [disabled]="!flUpdate"></button>
                </div>
            </div>            
        </div>
    </div>
</div>

<div class="p-grid">
    <div class="p-col-12">
        <p-table #dt [value]="list.data" dataKey="id" [paginator]="true" [rows]="15" [showCurrentPageReport]="true"
            [currentPageReportTemplate]="pageMessage" [rowsPerPageOptions]="[15,50,100]"
            [globalFilterFields]="['cpf','name','rg']" styleClass="p-datatable-responsive-demo p-datatable-striped"
            [showCurrentPageReport]="true" [(selection)]="selectedPerson" selectionMode="single">

            <!-- <ng-template pTemplate="caption">
            </ng-template> -->

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 5%;"><span translate></span></th>
                    <th><span translate>label.person_ssn</span></th>                    
                    <th pSortableColumn="name"><span translate>label.name</span><p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <!-- <th pSortableColumn="code"><span translate>label.rg</span> <p-sortIcon field="code"></p-sortIcon>
                    </th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                    <td><input type="radio" pInputRadio (click)="selectedItem(item)"></td>
                    <td>{{item.cpf}}</td>
                    <td>{{item.name}}</td>
                    <td></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div style="float: right;">
        <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="hideDialog()"></button>
        <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="confirmItem()"></button>
    </div>
</div>