import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import {  MessageService } from 'primeng/api';

import { NotificationsComponent } from './notifications.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ToastModule } from 'primeng/toast';
import { LoaderModule } from '../components/loader/loader.module';





@NgModule({

  declarations: [
    NotificationsComponent,
     ],
  providers: [
    MessageService
  ],
  exports: [
    NotificationsComponent
  ],
  imports: [
        CommonModule,
        ImageCropperModule,
        ToastModule,
        LoaderModule

    ],
})



export class NotificationsModule {
    ImageCropperModule

}
