import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { TreeNode } from "primeng/api";
import { ScOrganizationStructure, ScOrganizationStructureItem, ScOrganizationStructureList, ScOrganizationStructureUpdateInput } from "./sc-organization-structure.model";
import { DropDownMeta } from "src/app/shared/model/base-model";

@Injectable({
    providedIn: 'any'
})

export class ScOrganizationStructureService{

    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/Structure/';
    }

    getItem(id: string): Observable<ScOrganizationStructureItem> {
        let url = this.environmentUrl + id;
        return this.httpClient.get<ScOrganizationStructureItem>(url);
    }

    getList(): Observable<ScOrganizationStructureList> {
        let url = this.environmentUrl;
        return this.httpClient.get<ScOrganizationStructureList>(url);
    }

    getTreeList(): Observable<any> {
        let url = this.environmentUrl+"GetTree";
        return this.httpClient.get<TreeNode[]>(url);
    }

    getDropDownList(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown";
        return this.httpClient.get<DropDownMeta>(url);
    }

    addItem(model: ScOrganizationStructure, userId: string) {
        let input: ScOrganizationStructureUpdateInput = this.adapt(model, userId);
        return this.httpClient.post(this.environmentUrl, input);
    }

    updItem(model: ScOrganizationStructure, userId: string) {

        let url = this.environmentUrl + model.id;
        let input: ScOrganizationStructureUpdateInput = this.adapt(model, userId);

        return this.httpClient.put(url, input);
    }

    delItem(id: string) {
        return this.httpClient.delete(this.environmentUrl + id);
    }

    adapt(model: ScOrganizationStructure, userId: string){

        var input: ScOrganizationStructureUpdateInput = {};

        if (model.id != null && model.id != undefined && model.id != ""){
            input.id = model.id;
        }
        input.scOrganizationStructureParentId = model.scOrganizationStructureParentId;
        input.scCompanyId = model.scCompanyId;
        input.scAddressId = model.scAddressId;
        input.scCityId = model.scCityId;
        input.code = model.code;
        input.name = model.name;
        input.level = model.level;
        input.lat = model.lat;
        input.lng = model.lng;
        input.userId = userId;
        return input;
    }




}
