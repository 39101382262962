<div class="signup-page">
  <div class="form">
    <div class="image-top-signup-container">
      <img src="assets/layout/images/logo.png" class="image-top-signup">
    </div>

    <form class="form-login nao-validar" [formGroup]="loginForm" (submit)="forgotPwd()">
      <h1 class="titulo">Experimente Grátis por 15 dias</h1>
      <div class="label-acesso">
        Preencha os dados abaixo para realizar o seu cadastro
      </div>

      <div class="form-group">
        <input #email formControlName="email" type="text" placeholder="Email*" />
      </div>
      <div class="form-group">
        <input #senha1Input formControlName="password" type="password" placeholder="Senha*" />
      </div>
      <div class="form-group">
        <input #senha2Input formControlName="password2" type="password" placeholder="Confirmar Senha*" />
      </div>
      <cria-vmessage text="*campos obrigatórios"></cria-vmessage>

      <button [disabled]="loginForm.invalid" type="submit" class="btn btn-primary button-login">Cadastrar</button>

      <p class="message"> <a [routerLink]="['politica']">Confira a nossa Política de Privacidade</a></p>

    </form>
  </div>
