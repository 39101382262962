import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { ScOrganizationStructureService } from '../sc-organization-structure.service';
import { TreeNode } from 'primeng/api';
import { ScOrganizationStructure } from '../sc-organization-structure.model';
import { UserTokenService } from 'src/app/core/services/user-token.service';


@Component({
    selector: 'sf-organizationStructure-tree',
    templateUrl: './sc-organization-structure-tree.component.html',
    styleUrls: ['./sc-organization-structure-tree.component.scss']
})

export class ScOrganizationStructureTreeComponent implements OnInit{

    public list: TreeNode[];
    public nodeId: string;
    public selectedItem!: TreeNode;

    @Input() placeHolderLanguage: string;
    @Input() id: string;
    @Output() chosedItem = new EventEmitter();

    constructor(  private organizationStructureService: ScOrganizationStructureService){ }

    ngOnInit(): void {
        this.getList();
    }

    ngOnChanges(): void {
        this.getList();
    }

    getList() {
        this.organizationStructureService.getTreeList().subscribe(
        {
            next: (res) => {
                if (res){
                    this.list = res.data.items;
                    if (this.list.length > 0){
                        if (this.id != null && this.id != undefined && this.id != "" && this.id != "undefined")
                        {
                            this.selectedItem = this.getNodeWithKey(this.id, this.list);
                        }
                    }
                }
            },
            error: (err) => {
                console.log(`ScOrganizationStructureTree - getList : ${err}.`);
            }
        });



    }

    getNodeWithKey(key: string, nodes: TreeNode[]): TreeNode | undefined {
        for (let node of nodes) {
            if (node.key === key) {
               return node;
            }

            if (node.children) {
              let matchedNode = this.getNodeWithKey(key, node.children);
              if (matchedNode) {
                return matchedNode;
              }
            }
        }
        return undefined;
    }

//     nodeSelect(event) {
//         this.item = {};
//         this.selectedItem = event.node;
//        // this.tree.filteredNodes = null;
//         this.nodeId = this.selectedItem.key;
// //        this.collapseAll();
//     }

    collapseAll(){
        this.list.forEach( node => {
            this.expandRecursive(node, false);
        });
    }

    private expandRecursive(node:TreeNode, isExpand:boolean){
        node.expanded = isExpand;
        if (node.children){
            node.children.forEach( childNode => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }

    feedback(event){
        this.selectedItem = event.node;
        this.chosedItem.emit(this.selectedItem)
    }

}
