import { Component, OnInit } from '@angular/core';
import { ScPersonList, ScPersonOutput } from '../../../../models/response/sc-person.model';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { MessageService } from 'primeng/api';
import { ScPersonService } from 'src/app/core/services/sc-person/sc-person.service';

@Component({
  selector: 'app-sc-person-search',
  templateUrl: './sc-person-search.component.html',
  styleUrls: ['./sc-person-search.component.scss']
})
export class ScPersonSearchComponent implements OnInit {

  public list: ScPersonList = {} as ScPersonList;
  public selectedPerson: ScPersonOutput = {} as ScPersonOutput;
  public pageMessage: string = "";
  flRead: boolean = false;
  flUpdate: boolean = false;


  constructor(private personService: ScPersonService,
              private userTokenService: UserTokenService,
              private messageService: MessageService)
  {}

  ngOnInit(): void {
    this.flRead = this.userTokenService.hasPermissionArray(['SF_ADMIN', 'SF_SELLER', 'SC_PERSON_R', 'SC_PERSON_U']);
    this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN', 'SF_SELLER', 'SC_PERSON_U']);

    this.personService.scPersonOutputEmitter
    .subscribe(data => 
      {
        console.log(data);
      }
    );
  }
    
  getList(cpf: string, name: string) {
    this.personService.getListPerson('', cpf, name)
      .subscribe(
      {
        next: (res) => {
            if (res)
              this.list = res;            
            //this.translate();
        },
        error: (err) => {
            console.log(`ScPersonSearch - getList : ${err}.`);
        }
    });
  }

  search() {
    const cpf = document.getElementById('cpf') as HTMLInputElement;
    const name = document.getElementById('name') as HTMLInputElement;
    console.log(cpf.value);
    console.log(name.value);
                        
    this.getList(cpf.value, name.value);
  }

  insertPerson() {
    //console.log(this.selectedPerson);
    console.log('Não implementado.');
  }

  confirmItem() {
    //console.log(this.selectedPerson);
    this.personService.scPersonOutputEmitter.emit(this.selectedPerson);
    this.hideDialog();
  }

  selectedItem(item: ScPersonOutput) {
    this.selectedPerson = item;
    //console.log(this.selectedPerson);
    this.hideDialog();
  }

  hideDialog() {
    //this.selectedPerson = null;
    this.personService.scPersonSearchDialogEmitter.emit();
  }
}