<sf-loader></sf-loader>
<!-- <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog> -->
<div class="p-col-12" style="margin-top: -5px;"></div>
    <div class="formgrid grid">

        <div class="field col-12 md:col-9 lg:col-9 xl:col-9">
            <div class="card">
                <div class="grid">
                    <div class="col font-bold">Senha: <span class="text-orange-600"> {{item.ticket}}</span></div>
                    <div class="col text-center"><h3 class=""><span class="font-bold">Atendimento #</span> {{ item.code }}  </h3></div>
                    <div class="col text-right">  <span [class]="'status-badge-small' " [style]="{'background-color':''+item.stageColor+'', 'color': '#FFFFFF'}">{{item.stageName}} - {{item.statusName}}</span> </div>
                    </div>

                <div class="formgrid grid text-lg">
                    <div class="field col-5">
                        <label for="atendenteResponsavel" class="font-bold">Atendente Responsável</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-users"></i>
                            </span>
                            <input id="atendenteResponsavel" pInputText placeholder="Responsável Atendimento" [value]="item.userName" readonly/>
                        </div>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="dataAbertura" class="font-bold">Data Abertura</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-calendar"></i>
                            </span>
                            <input id="dataAbertura" pInputText  [value]="item.createdDate | date: 'dd/MM/yy HH:mm'" readonly />
                        </div>
                    </div>
                    <!-- <div class="field col-12 md:col-2">
                        <label for="firstname2" class="font-bold">Cliente - Documento</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-id-card"></i>
                            </span>
                            <input pInputText placeholder="Digite o cpf do cliente" [value]="item.clientDoc" />
                        </div>
                    </div>
                    <div class="field col">
                        <label for="firstname2" class="font-bold">Cliente - Nome<p-rating style="margin-left: 30px;"  [ngModel]="item.clientRating" [readonly]="true" [cancel]="false" ></p-rating></label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <input pInputText placeholder="Digite o nome do cliente" [value]="item.clientName" /><div  style="margin-left: 10px; margin-top: -13px;"><button pButton pRipple icon="pi pi-search" [class]="classBtnUpd" (click)="getClient();"></button></div>
                        </div>
                    </div> -->
                    <div class="field col-12 md:col-5">
                        <label class="font-bold mr-2">Cliente</label><p-rating [ngModel]="item.clientRating" [readonly]="true" [cancel]="false" [iconOnStyle]="{'color':'#4743fb'}" ></p-rating>                        
                        <sf-sc-person-dropdown id="person" componentType="basic" [id]="item.scClientId" (chosedItem)="reciverFeedbackPerson($event)" [placeHolderLanguage]="'label.client' | translate" [isDisabled]="!item.canChangeClient"></sf-sc-person-dropdown>                        
                    </div>
                </div>

                <div class="formgrid grid text-lg">
                    <div class="field col">
                        <label for="observacao" class="font-bold">Observações</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-comment"></i>
                            </span>
                            <input id="observacao" pInputText placeholder="Adicione as observações" [(ngModel)]="item.obs" /><div  style="margin-left: 10px; margin-top: -13px;"><button pButton pRipple icon="pi pi-save" [class]="classBtnUpd" (click)="saveObs(item.obs);"></button></div>
                        </div>
                    </div>
                </div>

            </div>  <!--Main Card -->

            <div class="card">
                <div class="formgrid grid">
                    <div class="field col">
                        <span class="font-bold"># Total de Itens</span>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-list"></i>
                            </span>
                            <input type="text" pInputText [disabled]="isDisabled"  [value]="totalValue.quantity">
                        </div>
                    </div>
                    <div class="field col">
                        <span class="font-bold">Recompe</span>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">R$</span>
                            <input type="text" pInputText [disabled]="isDisabled" [value]="totalValue.valueA | number:'1.2-2'">
                        </div>
                    </div>

                    <div class="field col">
                        <span class="font-bold">Emolumentos</span>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">R$</span>
                            <input type="text" pInputText [disabled]="isDisabled" [value]="totalValue.valueB | number:'1.2-2'">
                        </div>
                    </div>
                    <div class="field col">
                        <span class="font-bold">Taxa Fiscalização</span>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">R$</span>
                            <input type="text" pInputText [disabled]="isDisabled" [value]="totalValue.valueTaxA | number:'1.2-2'">
                        </div>
                    </div>
                    <div class="field col">
                        <span class="font-bold">ISS</span>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">R$</span>
                            <input type="text" pInputText [disabled]="isDisabled" [value]="totalValue.valueTaxB | number:'1.2-2'">
                        </div>
                    </div>


                    <div class="field col">
                        <span class="font-bold">Valor Total</span>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">R$</span>
                            <input type="text" pInputText [disabled]="isDisabled"  [value]="totalValue.total | number:'1.2-2'">
                        </div>
                    </div>

                    <div class="field col mt-4" *ngIf="item.woPaymentId != '' && item.woPaymentId != undefined">                        
                        <!-- <span class="material-icons" pBadge value="OK">receipt</span> -->
                        <span [class]="'status-badge-small' " [style]="{'background-color':'#13a103', 'color': '#FFFFFF'}">Pagamento Realizado</span>
                    </div>

                    
                </div>

            </div> <!-- card total values -->


            <div class="card" >
                <div class="p-col-12 p-md-12">
                    <p-tabView    [(activeIndex)]="activeIndex">
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <span class="material-icons">view_list</span>
                                <span class="ml-2">Relação de Serviços</span>
                            </ng-template>
                            <div style="min-height: 360px;" class="mt-2">
                            <!-- <p-scrollPanel [style]="{ width: '100%', height: '390px' }"> -->
                                <sfc-service-grid [woOrderId]="id" [canExecuteService]="item.statusCanExecuteService" (chosedItem)="reciverFeedbackItem($event)" [woOrderTypeId]="item.woOrderTypeId" [scPerson]="item.clientName"> </sfc-service-grid>
                            <!-- </p-scrollPanel> -->
                            </div>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <span class="material-icons">account_box</span>
                                <span class="ml-2">Detalhes do Cliente</span>
                            </ng-template>
                            <p>
                                <button (click)="loadLazyComponent(1)">Load Lazy Component</button>
                                <ng-container *ngIf="lazyComponent">
                                  <ng-container *ngComponentOutlet="lazyComponent"></ng-container>
                                </ng-container>
                            </p>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <span class="material-icons">folder_shared</span>
                                <span class="ml-2"> Documentos Associados</span>
                            </ng-template>
                            <p>
                                <button (click)="loadLazyComponent(2)">Load Lazy Component</button>
                                <ng-container *ngIf="lazyComponent">
                                  <ng-container *ngComponentOutlet="lazyComponent"></ng-container>
                                </ng-container>
                            </p>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <span class="material-icons">description</span>
                                <!-- <i class="pi pi-list"></i> -->
                                <span class="ml-2">Detalhes do Atendimento</span>
                            </ng-template>
                            <p>

                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </p-tabPanel>

                    </p-tabView>
                </div>
            </div>


    </div>
    <div class="col-12 md:col-3 lg:col-3 xl:col-3 p-order-3 p-order-lg-2">
        <div class="card">
            <h5 class="text-center font-bold">Ações </h5>
            <span class="p-buttonset" >
                <div *ngIf="showBtn" class="text-center">
                    <div *ngFor="let task of listWorkflowTask" >
                        <button pButton pRipple label='{{task.code}}' icon="{{task.icon}}" (click)="updStatus(task.woOrderStatusNextId);" [style]="{'border-color':''+task.statusStageColor+'','background-color':''+task.statusStageColor+''}" class="btn-action"></button>
                    </div>
                </div>
                <!-- <div   style="margin-bottom:5px; margin-right:5px; display: inline-block;">
                    <button pButton pRipple label="Imprimir Atendimento" icon="pi pi-print" class="p-button-success p-mr-2" (click)="save()"  style="width: 200px;"></button>
                </div> -->
                <div class="text-center">
                    <button pButton pRipple icon="pi pi-times"  class="p-button-danger p-mr-2 mt-3 font-medium btn-action" label="Fechar e Voltar" (click)="closeCancel();"></button>
                </div>

            </span>
        </div>

        <div class="card">
            <div class="p-field p-col-12">
                <div class="card">
                    <h5 class="text-center font-bold">Histórico do Atendimento </h5>
                    <p-scrollPanel [style]="{width: '100%', height: '475px'}" styleClass="customized-timeline">
                        <sf-wo-order-status-timeline [woOrderId]="id" [languageCode]="language"></sf-wo-order-status-timeline>
                    </p-scrollPanel>
                </div>
            </div>
        </div>
    </div>
    <div class="field col-12 md:col-9 lg:col-9 xl:col-9">

    </div>
</div>








  <!--

        <p-scrollPanel [style]="{width: '100%', height: '700px'}" styleClass="customized-timeline">
            <p-card [header]="'label.user_history' | translate">
                <div style="height: 500px;  overflow-y: scroll;">
                    <p-timeline [value]="listOrderStatusHist" align="alternate" styleClass="customized-timeline">
                        <ng-template pTemplate="marker" let-event>
                            <span class="custom-marker shadow-2" [style.backgroundColor]="event.entityColor">
                                <i [ngClass]="event.entityIcon"></i>
                            </span>
                        </ng-template>
                        <ng-template pTemplate="content" let-event>
                            <p-card [header]="event.entityDescription" [class]="card">
                                <div class="grid">
                                    <div class="col p-text-secondary"><label class="font-bold"><span translate>label.updatedBy</span></label> : {{event.userLoginUpdated}}</div>
                                </div>
                                <div class="grid mt-1">
                                    <div  class="col p-text-secondary"><label class="font-bold"><span translate>label.date</span></label> : {{event.updatedAt | date: 'dd/MM/yyyy'}}</div>
                                </div>
                            </p-card>
                        </ng-template>
                    </p-timeline>
                </div>
            </p-card>
        </p-scrollPanel> -->

        <div *ngIf="formPaymentModal" class="overlay" >
            <p-dialog header="Pagamento" [visible]="formPaymentModal" [style]="{width: '700px;', height: '600px;'}">


                <!-- <div class="button-container">
                    <button pButton pRipple label="Dinheiro" (click)="continueRequest('Dinheiro')" class="payment-button"></button>
                    <button pButton pRipple label="Pix" (click)="continueRequest('Pix')" class="payment-button"></button>
                    <button pButton pRipple label="Crédito/Débito" (click)="continueRequest('Crédito/Débito')" class="payment-button"></button>
                </div> -->
                <div style="margin-top: 10px;">
                    <sf-wo-order-payment [order]="item" (closePopup)="reciverFeedbackPayment($event)"></sf-wo-order-payment>
                </div>

            
            </p-dialog>
        </div>
