import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { DropDownMeta } from 'src/app/shared/model/base-model';
import { WoOrderStatus, WoOrderStatusHistList, WoOrderStatusItem, WoOrderStatusList, WoOrderStatusOutput, WoOrderStatusStageList, WoOrderStatusStageOutput } from './wo-order-status.model';



@Injectable({
    providedIn: 'root'
  })

export class WoOrderStatusService{

    private environmentUrl: string = '';


    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/OrderStatus/';
    }



//======================================================
//================== ORDER ===========================
//======================================================

    getItem(id: string): Observable<WoOrderStatusItem> {
        let url = this.environmentUrl+ id;
        return this.httpClient.get<WoOrderStatusItem>(url);
    }

    getList() : Observable<WoOrderStatusList>{
        let url = this.environmentUrl;
        return this.httpClient.get<WoOrderStatusList>(url);
    }

    addItem(model: WoOrderStatus){
        let url = this.environmentUrl;
        return this.httpClient.post(url, model);
    }

    // addItemTicket(model: WoOrderStatusInput){
    //     let url = this.environmentUrl+"Ticket/";
    //     return this.httpClient.post(url, model);
    // }

    updItem(model: WoOrderStatus){
        let url = this.environmentUrl+ model.id;
        return this.httpClient.put(url, model);
    }

    delItem(id: string) {
        let url = this.environmentUrl+id;
        return this.httpClient.delete(url);
    }

    getStatusHist(woOrderId: string, language: string): Observable<WoOrderStatusHistList>{
        let url = this.environmentUrl+'Hist?language='+ language+'&search='+ woOrderId;
        return this.httpClient.get<WoOrderStatusHistList>(url);
    }

     getDropDownList(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown";
        return this.httpClient.get<DropDownMeta>(url);
    }

    // updStatus(id: string, woOrderStatusId: string, obs: string){
    //     let url = this.environmentUrl+"UpdateStatus/" + id;

    //     var input: WoOrderStatusInput = {};
    //     input.id = id;
    //     //input.statusDate = statusDate;
    //     input.obs = obs;
    //     input.woOrderStatusId = woOrderStatusId;

    //     return this.httpClient.put(url, input);
    // }




//=========================================================
//================== OrderType ==========================
//=========================================================
// getOrderTypeItem(id: string): Observable<WoOrderItem> {
//     let url = this.environmentUrl+"Type/" + id;
//     return this.httpClient.get<WoOrderItem>(url);
// }

// getOrderTypeList() : Observable<WoOrderStatusList>{
//     let url = this.environmentUrl+"Type/";
//     return this.httpClient.get<WoOrderList>(url);
// }

// addOrderTypeItem(model: WoOrderStatus){
//     let url = this.environmentUrl+"Type/";
//     return this.httpClient.post(url, model);
// }

// updOrderTypeItem(model: WoOrderStatus){
//     let url = this.environmentUrl+"Type/" + model.id;
//     return this.httpClient.put(url, model);
// }

// delOrderTypeItem(id: string) {
//     let url = this.environmentUrl+"Type/"+id;
//     return this.httpClient.delete(url);
// }

// getOrderTypeDropDownList(): Observable<DropDownMeta> {
//     let url = this.environmentUrl + "Type/DropDown";
//     return this.httpClient.get<DropDownMeta>(url);
// }



//=========================================================
//================== OrderStatus ==========================
//=========================================================
    // getOrderStatusItem(id: string): Observable<WoOrderItem> {
    //     let url = this.environmentUrl+"Status/" + id;
    //     return this.httpClient.get<WoOrderItem>(url);
    // }

    // getOrderStatusList() : Observable<WoOrderStatusList>{
    //     let url = this.environmentUrl+"Status/";
    //     return this.httpClient.get<WoOrderList>(url);
    // }

    // addOrderStatusItem(model: WoOrderStatus){
    //     let url = this.environmentUrl+"Status/";
    //     return this.httpClient.post(url, model);
    // }

    // updOrderStatusItem(model: WoOrderStatus){
    //     let url = this.environmentUrl+"Status/" + model.id;
    //     return this.httpClient.put(url, model);
    // }

    // delOrderSatusItem(id: string) {
    //     let url = this.environmentUrl+"Status/"+id;
    //     return this.httpClient.delete(url);
    // }

    // getOrderStatusDropDownList(): Observable<DropDownMeta> {
    //     let url = this.environmentUrl + "Status/DropDown";
    //     return this.httpClient.get<DropDownMeta>(url);
    // }



    //=========================================================
//================== OrderStatusStage ==========================
//=========================================================
    getOrderStatusStageItem(id: string, language: string): Observable<WoOrderStatusStageOutput> {
        let url = this.environmentUrl+"Status/Stage/" + id+"?language="+language;
        return this.httpClient.get<WoOrderStatusStageOutput>(url);
    }

    getOrderStatusStageList(language: string) : Observable<WoOrderStatusStageList>{
        let url = this.environmentUrl+"Status/Stage?language="+language;
        return this.httpClient.get<WoOrderStatusStageList>(url);
    }

    addOrderStatusStageItem(model: WoOrderStatusStageOutput, language: string){
        let url = this.environmentUrl+"Status/Stage/";
        model.languageCode = language;
         // let modelUpd: WoOrderStatusStage = {};
        // modelUpd.id = model.id;
        // modelUpd.code = model.code;
        // modelUpd.name = model.name;
        // modelUpd.color = model.color;
        // modelUpd.icon = model.icon;
        // modelUpd.isActive = model.isActive;
        // modelUpd.woOrderClassId = model.woOrderClassId;
        // modelUpd.languageCode = language;

        return this.httpClient.post(url, model);
    }

    updOrderStatusStageItem(model: WoOrderStatusStageOutput, language: string){
        let url = this.environmentUrl+"Status/Stage/" + model.id;
        model.languageCode = language;
        // let modelUpd: WoOrderStatusStage = {};
        // modelUpd.id = model.id;
        // modelUpd.code = model.code;
        // modelUpd.name = model.name;
        // modelUpd.color = model.color;
        // modelUpd.icon = model.icon;
        // modelUpd.isActive = model.isActive;
        // modelUpd.woOrderClassId = model.woOrderClassId;
        // modelUpd.languageCode = language;

        return this.httpClient.put(url, model);
    }

    delOrderSatusStageItem(id: string) {
        let url = this.environmentUrl+"Status/Stage/"+id;
        return this.httpClient.delete(url);
    }

    getOrderStatusStageDropDownList(language: string): Observable<DropDownMeta> {
        let url = this.environmentUrl + "Status/Stage/DropDown?language=" + language;
        return this.httpClient.get<DropDownMeta>(url);
    }






}


