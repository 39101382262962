import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppAccessdeniedComponent } from './app.accessdenied.component';
import { AppErrorComponent } from './app.error.component';
import { AppNotfoundComponent } from './app.notfound.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AppMiddlewareComponent } from './app-middleware/app-middleware.component';








@NgModule({
    declarations: [AppAccessdeniedComponent, AppErrorComponent, AppNotfoundComponent, AppMiddlewareComponent],
    exports: [AppAccessdeniedComponent, AppErrorComponent, AppNotfoundComponent,AppMiddlewareComponent],
    imports: [FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        TranslateModule,
        ProgressSpinnerModule
        ],
    providers: []
})
export class BasicModule { }
