<sf-loader></sf-loader>
<sf-notification></sf-notification>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="col-12 " style="margin-top: 25px;">
    <div class="card">
        <h1 class="text-center mb-8"><span class="font-bold">Solicitação da Senha de Atendimento</span></h1>

        <div class="grid" >

            <div *ngFor="let item of list" >
                <div class="col-6 ml-8">
                    <div class="icons-new-order  w-full">
                        <div class="image-new-order">
                            <div class="icon-new-order"  (click)="newOrder(item)">
                                <div style=" background-color:orange;">
                                    <img [src]="'../../../../../assets/images/images-order/'+item.icon+''" class="new-order">
                                </div>
                                <div style="padding: 16px;">
                                    <h6 class="new-order-title">{{item.name}}</h6>
                                    <span class="new-order-description">{{item.description}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div style="float: right;">
    <button pButton pRipple icon="pi pi-times" style="width: 250px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel_close' | translate" (click)="close()"></button>
    <!-- <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="saveItem()"></button> -->
</div>

<p-dialog header="Header" [(visible)]="showDialog" [modal]="true" [style]="{ width: '50rem' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <!-- <p-avatar image="https://primefaces.org/cdn/primeng/images/avatar/amyelsner.png" shape="circle" /> -->
            <span class="font-bold white-space-nowrap">Senha: {{item.ticket}}</span>
        </div>
    </ng-template>
    <p class="m-0">
        Obrigado por escolher o 8 Ofício de Notas! Sua senha foi gerada com sucesso. Fique tranquilo(a) e aguarde seu atendimento. Estamos aqui para tornar sua jornada no cartório mais simples e eficaz. Obrigado pela confiança!
    </p>
    <p>
        <p-progressBar [value]="progressValue"></p-progressBar>
    </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="closeDialog()" label="Ok" pAutoFocus [autofocus]="true" ngDefaultControl></p-button>
    </ng-template>
</p-dialog>
