
<sf-loader></sf-loader>
<sf-notification></sf-notification>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="grid">
    <div class="col-12 mt-2">
        <p-toolbar>
            <div class="flex">

                <button pButton pRipple icon="pi pi-plus" [label]="'button.menu_new' | translate" class="p-button-primary ml-2" style="width: 150px;"
                (click)="addItem()" *ngIf="canUpdate"></button>

                <button pButton pRipple icon="pipi-trash" [label]="'button.menu_delete' | translate" class="p-button-success ml-2" style="width: 150px;"
                (click)="delItem(item)" *ngIf="canUpdate"></button>

                <p-button icon="pi pi-refresh" class="ml-2" (click)="getList()"></p-button>
            </div>
        </p-toolbar>
    </div>

    <div class="col-12 md:col-4 lg:col-4 xl:col-4 mt-2">

            <p-card [header]="'title.sc_menu_treeview' | translate" class="mt-2" >
                <p-tree #tree [value]="list" selectionMode="single" [(selection)]="selectedItem"
                    (onNodeSelect)="nodeSelect($event)"
                    [metaKeySelection]="true" scrollHeight="900px" [filter]="true"></p-tree>
            </p-card>

    </div>
    <div class="col-12 md:col-8 lg:col-8 xl:col-8">
            <p-card [header]="'title.sc_menu_edit' | translate" class="mt-2" *ngIf="isUpdate">

                <div style="margin-top:2px;" >

                    <div class="field col-12 md:col-6">
                        <div class="field">
                            <label for="label2"><span class="font-bold" translate>label.name</span></label>
                            <input type="text" pInputText id="label2" [(ngModel)]="item.label" required class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                            <small class="p-invalid" *ngIf="submitted && !item.label"><span translate>label.field_name_required</span></small>
                        </div>

                        <div class="field">
                            <label for="link"><span class="font-bold"  translate>label.link</span></label>
                            <input type="text" pInputText id="link" [(ngModel)]="item.link"class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>

                        <div class="field">
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="badge"><span class="font-bold"  translate>label.badge</span></label>
                                    <input type="text" pInputText id="badge" [(ngModel)]="item.badge" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                                </div>
                                <div class="field col">
                                    <label for="badgeStyleClass"><span  class="font-bold" translate>label.badgeStyleClass</span></label>
                                    <input type="text" pInputText id="badgeStyleClass" [(ngModel)]="item.badgeStyleClass" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                                </div>
                            </div>
                        </div>


                        <div class="field">
                            <label for="order"><span class="font-bold"  translate>label.order</span></label>
                            <p-inputNumber [(ngModel)]="item.order" inputId="integeronly" inputId="order" class="ng-invalid ng-dirty" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"></p-inputNumber>
                        </div>

                        <div class="field">
                            <label for="claim"><span class="font-bold"  translate>label.claim</span></label>
                            <sf-sc-claim-dropdown-multi [selectedItems]="listSelectedClaim" (chosedItem)="reciverFeedbackClaims($event)" [placeHolderLanguage]="'label.select_claim' | translate"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary w-full appearance-auto">
                            </sf-sc-claim-dropdown-multi>
                        </div>

                        <div class="field col">
                            <div class="formgrid grid">
                            <label for="parent"><span class="font-bold"  translate>label.menu_parent</span></label>
                            <!-- <sf-sc-menu-dropdown [id]="parentId" [idDelete]="deleteId" (chosedItem)="reciverFeedbackParent($event)" [placeHolderLanguage]="'label.select_menu' | translate"
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary w-full" style="appearance: auto;">
                            </sf-sc-menu-dropdown>-->
                            <p-dropdown [options]="listDropDown" [(ngModel)]="selectedDropDown" optionLabel="label" [filter]="true" filterBy="label" [showClear]="true" (onChange)="reciverFeedbackParent()" [placeholder]="'label.select_menu' | translate"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary w-full appearance-auto">
                            </p-dropdown>
                        </div>

                        </div>
                        <div class="field">
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="icon"><span class="font-bold"  translate>label.icon</span></label>
                                    <sf-dropdown-icon [icon]="item.icon" (chosedIcon)="reciverFeedbackIcon($event)" [placeHolderLanguage]="'label.select_icon' | translate" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary w-full" style="appearance: auto;"></sf-dropdown-icon>
                                </div>
                                <div class="field col font-bold mt-5">
                                    <p-checkbox [(ngModel)]="item.isActive" [binary]="true" inputId="isActive" [label]="lbActive"></p-checkbox>
                                </div>
                            </div>
                        </div>

                        <div class="field font-bold">
                            <div style="float: right;">
                                <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="cancel()"></button>
                                <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="saveItem()"></button>
                            </div>
                        </div>
                    </div>
                    <div class="field col-12 md:col-6">

                    </div>

                </div>
            </p-card>
        </div>

</div>

