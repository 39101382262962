import { NgModule } from "@angular/core";
import { AddressComponent } from "./address.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SfDropDownModule } from "../dropdown/dropdown.module";
import { InputMaskModule } from "primeng/inputmask";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";

@NgModule({
    declarations: [AddressComponent],
    exports: [AddressComponent],
    imports: [FormsModule,
        SfDropDownModule,
        InputMaskModule, CommonModule, ReactiveFormsModule, TranslateModule, InputTextModule, ButtonModule,],
})
export class addressModule {

}