
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { DropDown, GenericItem} from '../../../model/base-model';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ConstantsService } from 'src/app/core/services/constants.service';


@Component({
    selector: 'sf-grid-generic-basic',
    templateUrl: './grid-generic-basic.component.html',
    styleUrls: ['./grid-generic-basic.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})

export class ScGridGenericBasicComponent implements OnInit {


    @Input() list: GenericItem[] = [];
    @Input() languageCode: string = "pt-BR";
    @Input() flUpdate: boolean = false;
    @Input() dialogHeader: string = "";
    @Input() btnNewLabel: string = "";
    @Output() choosedItem = new EventEmitter();
    @Output() choosedType = new EventEmitter();

    public id: string;
    public item: GenericItem;

    public submitted: boolean;
    public displayMsg: boolean = false;
    public displayDialog: boolean;


    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-warning p-m-1 mr-1";



    constructor(    private confirmationService: ConfirmationService,
                    private translateService: TranslateService,
                    private constantsService: ConstantsService,
                    private translateConfigService: TranslateConfigService) { }

    ngOnInit(): void {

        if (this.flUpdate == false)
        {
            this.classBtnUpd = "p-button p-button-secondary p-m-1 mr-1";
            this.classBtnDel = this.classBtnUpd;
        }
    }


    getItem(id) {
        if (this.list !== null)
        {
            var itemAux = this.list.find(x => x.id == id);
            if (itemAux != null)
                this.item = itemAux;
        }
    }

    addItem() {
        this.item = {};
        this.submitted = false;
        this.displayDialog = true;
        this.feedbackType(this.constantsService.INSERT);
    }

    updItem(item: GenericItem) {
        this.item = {...item};
        this.displayDialog = true;
        this.feedbackType(this.constantsService.UPDATE);
    }

    delItem(item: GenericItem) {
        this.item = {...item};
        this.feedbackType(this.constantsService.DELETE);

        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_delete')+' ' + item.name + '?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.feedbackItem();
            }
      });
    }

    saveItem()
    {
        this.feedbackItem();
        this.displayDialog = false;
    }

    translate()
    {
        this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')
    }

    hideDialog() {
        this.displayDialog = false;
        this.submitted = false;
    }



    clear(table: Table) {
        table.clear();
    }

    reciverFeedbackIcon(icon: DropDown) {
        this.item.icon = icon.id;
    }

    reciverFeedbackColor(color: string) {
        this.item.color = color;
    }

    feedbackItem(){
        this.choosedItem.emit(this.item);
    }

    feedbackType(type: string){
        this.choosedType.emit(type);
    }
}
