<div class="stepsdemo-content">
    <p-card>
        <ng-template pTemplate="title"> <span translate class="font-bold">label.companyType</span> </ng-template>
        <ng-template pTemplate="subtitle"> <span class="text-lg font-bold" translate>label.enterCompanyType</span> </ng-template>
        <ng-template pTemplate="content">
            <div class="formgrid grid text-lg">

                <div class="field field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="plan" class="mb-1 font-bold text-lg">
                        <span translate>label.licensingPlan</span>*
                    </label>
                    <sf-dropdown-generic
                        [list]="companyPlanList"
                        [id]="1"
                        (selectedItemOutput)="reciverFeedbackCompanyPlan($event)"
                        [ngClass]="{ 'ng-invalid ng-dirty': (!paPlanId && submitted) }"
                    ></sf-dropdown-generic>
                    <small *ngIf="(!paPlanId && submitted)" class="p-error">
                        <span translate>label.required_fields</span>
                    </small>
                </div>

                <div class="field field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="plan" class="mb-1 font-bold text-lg">
                        <span translate>label.type</span>*
                    </label>
                    <sf-dropdown-generic
                        [list]="companyTypeList"
                        [id]="1"
                        (selectedItemOutput)="reciverFeedbackCompanyType($event)"
                        [ngClass]="{ 'ng-invalid ng-dirty': (!scCompanyTypeId && submitted) }"
                    ></sf-dropdown-generic>
                    <small *ngIf="(!scCompanyTypeId && submitted)" class="p-error">
                        <span translate>label.required_fields</span>
                    </small>
                </div>

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="moduleAvailable" class="mb-1 font-bold text-lg">
                        <span translate>label.moduleAvailable</span>*
                    </label>
                    <div class="card flex justify-content-start">
                        <p-listbox
                            [options]="moduleList"
                            [(ngModel)]="selectedModules"
                            [selectAll]="selectModuleAll"
                            optionLabel="name"
                            [style]="{ width: '450px', height: '200px' }"
                            [virtualScroll]="false"
                            [filter]="true"
                            [virtualScrollItemSize]="43"
                            [multiple]="true"
                            [checkbox]="true"
                            [metaKeySelection]="false"
                            [showToggleAll]="true"
                            (onSelectAllChange)="onSelectModuleAllChange($event)"
                            (onChange)="onModuleChange($event)"
                            [listStyle]="{ 'max-height': '200px' }"
                            [ngClass]="{ 'ng-invalid ng-dirty': (!selectedModules.length > 0 && submitted) }"
                        ></p-listbox>
                    </div>
                    <small *ngIf="(!selectedModules.length > 0 && submitted)" class="p-error">
                        <span translate>label.required_fields</span>
                    </small>
                </div>

                <div class="field col-6 sm:col-12 md:col-12 lg:col-6">
                    <label for="moduleAvailable" class="mb-1 font-bold text-lg">
                        <span translate>label.config</span>
                    </label>
                    <div class="card flex justify-content-start">
                        <p-listbox
                            [options]="configList"
                            [(ngModel)]="selectedConfigs"
                            [selectAll]="selectConfigAll"
                            optionLabel="name"
                            [style]="{ width: '450px', height: '200px' }"
                            [virtualScroll]="false"
                            [filter]="true"
                            [virtualScrollItemSize]="43"
                            [multiple]="true"
                            [checkbox]="true"
                            [metaKeySelection]="false"
                            [showToggleAll]="true"
                            (onSelectAllChange)="onSelectConfigAllChange($event)"
                            (onChange)="onConfigChange($event)"
                            [listStyle]="{ 'max-height': '200px' }"
                        ></p-listbox>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="grid grid-nogutter justify-content-between">
                <p-button [label]="'label.back' | translate" (onClick)="prevPage()" icon="pi pi-angle-left"></p-button>
                <p-button [label]="'label.next' | translate" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
            </div>
        </ng-template>
    </p-card>
</div>
