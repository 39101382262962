import { Injectable , PLATFORM_ID, Inject} from '@angular/core';
import { isPlatformBrowser} from '@angular/common';

@Injectable({providedIn:'root'})
export class PlatformDetectorService{
    
    constructor(@Inject(PLATFORM_ID) private platformId: string){}

    sticky: boolean = false;

    isPlatformBrowser(){
        return isPlatformBrowser(this.platformId);
    }

    setSticky(sticky){
      this.sticky = sticky;
    }


}