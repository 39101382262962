import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from '../../app.main.component';

import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { MenuService } from './app.menu.service';

@Component({
    selector: 'app-menu',
    template: `
		<ul class="layout-menu">
			<li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
		</ul>        
    `
})

//TODO = When change language - refresh menu

export class AppMenuComponent implements OnInit {

    public model: any;
    public jsonMenu: string;
    private languageCode: string;




    constructor(public app: AppMainComponent,
                private translateConfigService: TranslateConfigService,
                private menuService: MenuService,
                private userTokenService: UserTokenService) {}

    async ngOnInit() {
        this.languageCode = this.translateConfigService.getLanguage();
        this.getMenuUser(this.userTokenService.getCompanyId(), this.userTokenService.getUserId());
    }

    getMenu(id: string){
        this.menuService.getMenu(id).subscribe(
        {
            next: (res) => {
                if (res){
                    this.jsonMenu = res.data.menuJson.toString();
                    this.model = JSON.parse(this.jsonMenu);
                }
            },
            error: (err) => {
                console.log(`app-menu - getMenu : ${err}.`);
            }
        });
    }

    getMenuUser(companyId: string, userId: string){
        this.menuService.getMenuUser(companyId, userId, this.languageCode).subscribe(
        {
            next: (res) => {
                if (res){
                    this.jsonMenu = res.data.menuJson.toString();
                    this.model = JSON.parse(this.jsonMenu);
                }

            },
            error: (err) => {
                console.log(`app-menu - getMenuUser : ${err}.`);
            }
        });
    }


}
